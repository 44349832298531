<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>系统活跃度</span>
        <span style="margin-left: 40%;font-size: 15px;font-weight: 400;" v-if="yearData.year">{{ yearData.year.replace(/[\-]{1}/,'年').replace(/[\-]{1}/,'月')}}日</span>
      </div>
    </template>
    <div class="echartsContainer">
      <div id="linbox" :ref="myCharts"></div>
    </div>
  </el-card>
</template>

<script>
import { reactive,ref, toRefs, onMounted,onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import {findData} from "../Homecard";
var myChart;
export default {
  name: "livenessCard",
  setup() {
    let refs = '';
    const myCharts = el => {
      refs = el;
    }
    const options = reactive({});

    const  echartsresize= () => {
      myChart.resize();
    }
    const yearData = reactive({
      year:''
    })

    onMounted(() => {
      getChartsList()
      window.addEventListener("resize",echartsresize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize',echartsresize)
    })

    let getChartsList = () =>{
      findData({}).then(res=>{
        if (res.data.code === '200'){
          let data = res.data.data.userActivityGroupingList
          let date = data.map(v=>{return v.loginTime})
          let count = data.map(v=>{return v.count})
          // let chartDom = document.getElementById("linbox");
          let option = {
            tooltip: {
              // trigger: 'axis',
              extraCssText: 'box-shadow: none;',
              position: function (point, params, dom, rect, size) {
                console.log('size',size);
                return [point[0]-(size.contentSize[0]/2), '1%'];
              },
              // formatter: function (params){
              //   return [params[0].value]
              // }
              trigger: 'axis',
              axisPointer: {
                type: 'line',
                axis: 'x',
              },
              // position: [20, 20],
              formatter: function (param) {
                // return param.name + '<br>' + (param.data.coord || '')
                param = param[0];
                return [
                  '<sapn style="color:red;font-size: 15px;">'+param.value+'人次'+'</sapn>'
                ].join('');
              }
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: date,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: count,
                type: "line",
                areaStyle: {
                  color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#D65D4B' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#FFFFFF' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  },
                  opacity:0.3
                },
                // smooth: true,
                lineStyle:{
                  color: '#D65D4B',
                   width: 1
                },
                itemStyle:{
                  color: '#D65D4B',
                  borderWidth: 0,
                },
                symbol: "circle", // 去除小圆点
                symbolSize: 1

              },
            ],
          }
          myChart = echarts.init(refs);
          myChart.setOption(option);
          myChart.on('mousemove', function (params) {
            console.log("params", params);
            yearData.year = params.name
          });
        }
      })
    }

    return {
      options,
      myCharts,
      yearData
    };
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 100%;
  height: 290px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(107,133,228,0.15);
  border-radius: 6px;
  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }

}
.echartsContainer {
  width: 100%;
  height: 100%;
  #linbox {
    width: 115%;
    height: 250px;
    margin-left: -6%;
    margin-top: -30px;
  }
}

</style>

