export const userData = {
    drafrList : [
        {name:'用户ID',text: 'numberId'},
        {name:'用户名称',text: 'name'},
        {name:'用户角色',text: 'roleName'},
        {name:'手机号码',text: 'phone'},
        {name:'创建时间',text: 'createTime'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['设置角色','删除','重置'],
    pageType: 1,
    lastState: 1000,

    userInput: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[],
    value: true
}