import {modifyPasswordInterface } from './personalApi'
import { ElMessage } from 'element-plus';

// 修改密码
export const changePasswords = (data,formLabelRef,router) => {
  let userId = JSON.parse(sessionStorage.getItem("user")).userId
  formLabelRef.value.validate((valid)=>{
    if(valid){
      let parame = {
        id :userId,
        oldPassword : data.formLabelAlign.oldPassword,
        newPassword : data.formLabelAlign.newPassword
      };
      modifyPasswordInterface(parame).then((res) => {
        if (res.data.code === '200') {
          ElMessage.success(res.data.msg)
          // data.formLabelAlign.oldPassword = '';
          // data.formLabelAlign.newPassword = '';
          // data.formLabelAlign.password = '';
          router.push('/login')
        } else {
          ElMessage.error(res.data.msg)
        }
      })
    }
  })
}

// 校验再次输入的密码
// export const confirmPassFns = (data) => {
//   if (data.formLabelAlign.password === '') {
//     ElMessage.error('确认登录密码不能为空')
//   } else if (data.formLabelAlign.password != data.formLabelAlign.newPassword) {
//     ElMessage.error('两次输入密码不一致!')
//   } else {
//       callback()
//   }
// }