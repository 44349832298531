import axios from '@/axios/globalaxios'

export const findType = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/findType",
        method: 'post',
        data:data
    })
}

//新增证券报告
export const separateReportsToAdd = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/separateReportsToAdd",
        method: 'post',
        data:data
    })
}
// 修改证券报告
export const updTFundFundfiles = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/updTFundFundfiles",
        method: 'post',
        data:data
    })
}

//新增外汇报告
export const foreignExchangeReportsToAdd = data =>{
    return axios({
      url: "/tradelabLx/currency_files/add",
      method: "post",
      data: data,
    });
}
// 修改外汇报告
export const foreignExchangeReportsToEdit = (data) => {
  return axios({
    url: "/tradelabLx/currency_files/upd",
    method: "post",
    data: data,
  });
};

// 查询报告详情
export const findFileByFileId = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/findFileByFileId",
        method: 'post',
        data:data
    })
}


// 获取全部码表
export const findStopwatchByFtype = data =>{
    return axios({
        url: "/tradelabLx/transaction/findStopwatchByFtype",
        method: 'post',
        data:data
    })
}


