import { cancelOrder, findStopwatchByFtype } from "./tradeApi";
import { getGroupTables } from "../group-manage/groupMethods";
import { findTargetamountByAcid } from "../stock-manage/stockApi";
import { cloneDeep } from "lodash";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import { getCharts } from "../trade-manage/tradeMethods";

//切换菜单 事件
export const menuChanges = (item, data, dom) => {
  if (item.type === "tradeMenu") {
    data.showOther = 1;
    dom.optionTab(1, -1);
    data.sonMenuType = item.key;
    // optionTab
  }
  if (item.type === "sonMenu") {
    data.showOther = item.key;
  }
  data.pageSize = 10;
  data.currentPage = 1;
  data.startTime = undefined;
  data.endTime = undefined;
  data.datePicker = "";
  data.spreadTable = [];
  allMedthods(data);
};

//  点击 盘口 获取数据
export const clickGetPrice = (item, data) => {
  data.price = item;
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  allMedthods(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  allMedthods(data);
};

// 方法汇总调用
export const allMedthods = (data) => {
  data.spreadTable = [];
  if (data.showOther === 2) {
    getBackTables(data);
  }
  if (data.showOther === 3) {
    data.tagType = parseInt(data.routeId);
    getGroupTables(data);
  }
  if (data.showOther === 4) {
    getBackTablesList(data);
  }
  if (data.showOther === 5) {
    getTradeLists(data);
  }
  if (data.showOther === 6) {
    getCapitalLists(data);
  }
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      // res[dealArr[j]] = res[dealArr[j]].toString().slice(0,4) +'-'+ res[dealArr[j]].toString().slice(4,6)+'-'+ res[dealArr[j]].toString().slice(6)
      res[dealArr[j]] = dayjs(res[dealArr[j]]).format("YYYY-MM-DD HH:mm:ss");
    }
  }
  return arr;
};

// 获取目标 list
export const getPlanLists = (data) => {
  data.loadingPlan = true;
  findStopwatchByFtype().then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTablePlan = newRes.map((item) => {
        return { code: item.fgsid, name: item.fname, exchange: item.fjys };
      });
      data.totalPlan = newRes.length;
      dealTableParame(data.spreadTablePlan);
      data.codeList = newRes;
      if (data.codeList.length === 0) {
        data.code = "";
      } else {
        data.code = dealCodeSearch(data.spreadTablePlan[0].code, data);
      }
      setTimeout(() => {
        data.loadingPlan = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChangePlans = (val, data) => {
  data.loadingPlan = true;
  data.pageSizePlan = val;
  setTimeout(() => {
    data.loadingPlan = false;
  }, 300);
};
// 切换页数
export const currentChangePlans = (val, data) => {
  data.loadingPlan = true;
  data.currentPagePlan = val;
  setTimeout(() => {
    data.loadingPlan = false;
  }, 300);
};

export const codeChanges = (val, data) => {
  data.code = dealCodeSearch(val, data);
};

export const clickButtons = (val, data) => {
  // 撤单
  clickBackOrder(val.item, data);
};

// 点击撤单
export const clickBackOrder = (val, data) => {
  let parame = {
    accountId: JSON.parse(sessionStorage.getItem("acid")),
    omid: val.omid,
  };
  cancelOrder(parame).then((res) => {
    if (res.data.code === "200") {
      if (res.data.msg === "撤单成功") {
        ElMessage.success(res.data.msg);
      } else {
        ElMessage.error(res.data.msg);
      }
      getBackTables(data);
    } else {
      ElMessage.warning(res.data.msg);
    }
  });
};

// 获取交易所
export const dealCodeSearch = (val, data) => {
  let codeList = data.codeList;
  for (let j in codeList) {
    if (val === codeList[j].fgsid) {
      let type = codeList[j].fjys === 0 ? "sz" : "sh";
      return type + val;
    }
  }
};

// 处理 默认 参数
export const dealTableParame = (table, arr) => {
  for (let i in table) {
    if (table[i].hasOwnProperty("exchange")) {
      if (table[i].exchange === 0) {
        table[i].exType = "深证";
      } else if (table[i].exchange === 1) {
        table[i].exType = "上证";
      } else if (table[i].exchange === 10) {
        table[i].exType = "中金";
      } else if (table[i].exchange === 11) {
        table[i].exType = "能源";
      } else if (table[i].exchange === 21) {
        table[i].exType = "上期";
      } else if (table[i].exchange === 22) {
        table[i].exType = "大商";
      } else if (table[i].exchange === 23) {
        table[i].exType = "郑商";
      } else if (table[i].exchange === 30) {
        table[i].exType = "外汇宝";
      } else if (table[i].exchange === 100) {
        table[i].exType = "上财";
      }
    }
    if (table[i].hasOwnProperty("stype")) {
      let num = parseInt(table[i].stype);
      switch (num) {
        case 0:
          table[i].exType = "股票";
          break;
        case 1:
          table[i].exType = "股票";
          break;
        case 6:
          table[i].exType = "债券";
          break;
        case 9:
          table[i].exType = "债券";
          break;
        case 201:
          table[i].exType = "期货";
          break;
        case 301:
          table[i].exType = "期货";
          break;
      }
    }
    if (table[i].direction)
      table[i].direction = table[i].direction === "0" ? "买入" : "卖出";
    if (table[i].tposition)
      table[i].tposition = table[i].tposition === "0" ? "开仓" : "平仓";
  }
  return table;
};
