const stock = {
    colums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'委托状态',text: 'orderStatusName'},
        {name:'委托价格',text: 'orderPrice'},
        {name:'委托数量',text: 'orderVol'},
        {name:'平均成交价',text: 'averagePrice'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
    ]
}
const futures = {
    colums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'委托状态',text: 'orderStatusName'},
        {name:'委托价格',text: 'orderPrice'},
        {name:'委托数量',text: 'orderVol'},
        {name:'平均成交价',text: 'averagePrice'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
    ]
}
const bonds = {
    colums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'委托状态',text: 'orderStatusName'},
        {name:'委托价格',text: 'orderPrice'},
        {name:'委托数量',text: 'orderVol'},
        {name:'平均成交价',text: 'averagePrice'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
    ]
}


export const Data = {
    stock,
    futures,
    bonds
}


export const WithwithdrawalData = {
    colums:[],
    tableData:[],
    operation:['撤单'],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    tableHeight: 550,
    datePicker:'',
    startTime:'',
    endTime:'',
}