import axios from '@/axios/globalaxios'

// 查找全部收盘记录
export const marketCloseRecords = data =>{
  return axios({
      url: "/tradelabLx/marketCloseRecord/marketCloseRecords",
      method: 'post',
      data:data
  })
}

// 强制收盘
export const forcedClosing = data =>{
  return axios({
      url: "/tradelabLx/marketCloseRecord/forcedClosing",
      method: 'post',
      data:data
  })
}

// 强制开盘
export const forcedOpening = data =>{
  return axios({
      url: "/tradelabLx/marketCloseRecord/forcedOpening",
      method: 'post',
      data:data
  })
}