<template>
	<div>
		<el-card class="box-card" style="width: 100%;height: 100%;">
			<el-empty description="暂无评论" v-if="data.plList.length == 0" />
			<div class="demo-collapse" v-else>
				<div>
					<div v-for="(item, index) in data.plList" :key="index" :name="item.id + index">
						<div style="margin: 10px;">
              <el-row>
                <el-col :span="20" @click="changeItem(item.id)">
                  <img src="@/assets/headSculpture.png" alt="" style="width: 30px;position: relative;top: 6px;"/>
                  <span style="color: black;font-size: 18px;">{{ item.userName }}：</span>
							<!-- {{ '发表问答:&nbsp;&nbsp;' }} -->
                  <div>
                    <span style="margin-left: 5px;color: #717174;font-size: 16px;margin-right: 15px;">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.title }}
                    </span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <el-button style="margin-top: 20px;margin-bottom: 5px;float: right;" size="mini" type="primary" @click="reply(item.id, item.id, item.userName)">回复</el-button>
                </el-col>
              </el-row>
							
							
						</div>
						<div class="reply-div" v-if="data.clickId === item.id">
              <div v-if="data.plList2 && data.plList2.length > 0">
                <div v-for="(item2, index2) in data.plList2" :key="index2">
                  <div @click="reply(item.id, item2.id, item2.userName)" style="margin: 10px;">
                    <span style="color: #2E2E2E;">{{ '&nbsp;&nbsp;&nbsp;&nbsp;' + item2.userName }}</span>
                    <el-icon style="font-weight: 900;color: #4e4e4ec0;padding: 0 5px;"><CaretRight/></el-icon>
                    <span style="color: #2E2E2E;">
                      {{ item2.puserName }}：</span>
                    <div style="color: #8B8B8E;margin-left: 20px;">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item2.title }}
                    </div>
                    <!-- <el-button size="small" style="float: right;"
                      @click="reply(item.id, item2.id, item2.userName)">回复此评论</el-button> -->
                  </div>
                </div>
                <div style="text-align: center;">
                  <el-button v-if="data.showMore" text @click="more(item.id)">加载更多</el-button>
                </div>
              </div>
              <div v-else>
                <el-empty :image-size="50" description="暂无回复" />
              </div>
							
							<!-- <el-collapse v-model="activeName2" accordion v-loading="data.activeloading2">
								

							</el-collapse> -->
						</div>
					</div>
				</div>
				<el-pagination :hide-on-single-page="data.page.handSinge" :page-size="data.page.pageSize"
					layout="prev, pager, next" :total="data.page.total" @current-change="changePage" />
			</div>
		</el-card>

		<el-input v-model="data.own" type="textarea" style="margin-top: 10px;" :placeholder="data.textPlaceholder" />
    <div>
      <el-button type="primary" style="margin-top: 10px;" @click="addPl()" size="mini">发表 </el-button>
      <el-button type="danger" plain style="margin-top: 10px;float: right;" @click="cancelReply()" size="mini" v-if="data.textPlaceholder != '发表评论'">取消回复</el-button>
    </div>
		
	</div>
</template>
<script setup >
import { defineComponent, onMounted, reactive, ref, } from 'vue';
import { listApprovaledFund, insertDiscussionArea, listDiscussionArea } from '../../api.js';
import { ElCollapse, ElCollapseItem } from 'element-plus';
import { ElMessage } from 'element-plus';


const activeName = ref('1')
const activeName2 = ref('2')

const isshow = ref(true)
let data = reactive({
	showMore: ref(false),
	fundNameSelect: ref(''),
	loading: ref(false),
	activeloading: ref(false),
	activeloading2: ref(false),
	fundList: [],
	textPlaceholder: '发表评论',
	own: ref(''),
	pid: 0,
	rid: 0,
	page: {
		pageSize: 10,
		total: 0,
		currentPage: 1,
		handSinge: ref(false)
	},
	page2: {
		pageSize: 5,
		total: 0,
		currentPage: 1,
		handSinge: ref(false)
	},
	plList: [],
  plList2: [],
  clickId:false
})


const changePage = (value) => {
  data.page.currentPage = value;
  cancelReply()
	listPl();
}

const props = defineProps({
	fundId: {
		type: [Number, String],
	}
})

const changeItem = (value) => {
  data.page2.currentPage = 1
	let parm = {
		"fundId": props.fundId,
		"pid": value,
		"page": 1,
		"pageSize": data.page2.pageSize,
		"rid": data.rid,
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = res.data.data.list;
		//如果评论小于5 不展示加载更多
		if (res.data.data.total > data.page2.pageSize) {
			data.showMore = true;
		} else {
			data.showMore = false;
    }
    data.clickId = value
	})
}

const more = (pid) => {
	data.page2.currentPage = data.page2.currentPage + 1;
	let parm = {
		"fundId": props.fundId,
		"pid": pid,
		"rid": data.rid,
		"page": data.page2.currentPage,
		"pageSize": data.page2.pageSize
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = data.plList2.concat(res.data.data.list);
		if (data.plList2.length < res.data.data.total) {
			data.showMore = true;
		} else {
			data.showMore = false;
		}
	})
}

// onMounted(() => {
//   listPl();
// })

const listPl = (fundId) => {
	let parm = {
		"fundId": fundId ? fundId : props.fundId,
		"pid": data.pid,
		"page": data.page.currentPage,
		"pageSize": data.page.pageSize
	};
	listDiscussionArea(parm).then(res => {
		data.plList = res.data.data.list;
		data.page.total = res.data.data.total;
	})
}

const reply = (pid, rid, userName) => {
	data.pid = pid;
	data.rid = rid;
  data.textPlaceholder = '回复' + userName;
  console.log('data.pid',data.pid);

}


const addPl = () => {
	if (data.own == '') {
		ElMessage({ type: 'error', message: '评论内容不能为空！' })
		return;
	}

	let parm = {
		"title": data.own,
		"createUser": JSON.parse(sessionStorage.getItem("user")).userId,
		"userName": JSON.parse(sessionStorage.getItem("user")).name,
		"fundId": props.fundId,
		"pid": data.pid,
		"respondent": data.rid
	}
	insertDiscussionArea(parm).then(res => {
		if (res.data.code == '200') {
			if (data.pid == 0) {
				ElMessage({ type: 'success', message: '发表成功！' })
				data.own = '';
				data.pid = 0;
				data.textPlaceholder = '发表评论';
				// relistPl();
			} else {
				ElMessage({ type: 'success', message: '回复成功！' })
				data.own = '';
				data.pid = 0;
				// relistPl2();
			}
		} else {
			ElMessage({ type: 'error', message: res.data.msg })
		}
		// location.reload();
	})
}

const cancelReply = () => {
  data.own = '';
  data.pid = 0;
  data.textPlaceholder = '发表评论';
}

const relistPl2 = () => {
	let parm = {
		"fundId": props.fundId,
		"pid": data.pid,
		"page": 1,
		"pageSize": 5
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = res.data.data.list;
		if (data.plList2.length < res.data.data.total) {
			data.showMore = true;
		} else {
			data.showMore = false;
		}
	})
}

const relistPl = () => {
	let parm = {
		"fundId": props.fundId,
		"pid": 0,
		"page": 1,
		"pageSize": 10
	};
	listDiscussionArea(parm).then(res => {
		data.plList = res.data.data.list;
		data.page.total = res.data.data.total;
	})
}

defineExpose({
	listPl
});

</script>
<style lang="less" scoped>
.demo-pagination-block+.demo-pagination-block {
	margin-top: 10px;
}

.demo-pagination-block .demonstration {
	margin-bottom: 16px;
}

.box-card {
	font-size: 16px;
	width: 100%;
	height: 500px;

	&:deep(.el-card__header) {
		border-bottom-color: transparent;
	}

	&:deep(.el-card__body) {
		padding-top: 10px;
	}
}

.card {
	overflow: auto;
	height: 200px;
	margin-bottom: 20px;
}

.othersComments {
	margin: 10px;
	padding: 10px;
	font-size: 16px;
	width: auto;
	height: auto;
	min-height: 40px;
	background: rgb(245, 241, 241);
}

.boxcard {
	font-size: 16px;
	width: 100%;
	height: 750px;

	&:deep(.el-card__header) {
		border-bottom-color: transparent;
	}

	&:deep(.el-card__body) {
		padding-top: 10px;
	}
}

.return {
	margin-top: 20px;
	margin-right: 20px;
	width: 100px;
	// float: right;
	margin-bottom: 10px;
}

/*滚动条的宽度*/
.notice::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/*滚动条滑块*/
.notice::-webkit-scrollbar-thumb {
	background-color: #ddd;
}
.reply-div{
  background-color: #dddddd4e;
  border-radius: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>