//  dataRefrehs 定时调用   clears清除定时
//  getHqLists获取行情
// getTypeLists 切换 行情类型
import axios from "@/axios/globalaxios";

export const getHqLists = (data) =>{
    let HqList = data.list
    // 处理成 行情 data  调用数据
    let xinlang = HqList.slice(data.startIndex,data.addIndex + data.startIndex)
    // for (let i in this.lists){
    //     if (this.lists[i].f_jys === 1){
    //         xinlang[i] = "sh"+this.lists[i].f_gsid
    //     }else if (this.lists[i].f_jys === 0){
    //         xinlang[i] = "sz"+this.lists[i].f_gsid
    //     }
    // }
    if (xinlang.length===0)return
    axios.get('/list='+xinlang.join(',')).then(response => {
        data.tableData = [];
        let res = response.data.replace(/=/g, ":");
        let newres = res.replace(/var/g,"").split(';');
        for (let i in newres){
            let sub = newres[i]
            let obj = {};
            if (sub.indexOf(":",0) !== -1){
                obj = sub.substring(sub.indexOf(":")+2,sub.length-1).split(',');
                if (obj.length<1){
                    continue;
                }else {
                    let nes = obj[3]-obj[2] >=0? obj[3]-obj[2] : obj[2] - obj[3];
                    let colorStyle = obj[3]-obj[2] >=0? '0':'1';
                    let changs = toPercent(nes/obj[2]);
                    let mon = (obj[9]/10000).toFixed(2);
                    let has = (obj[8]/100).toFixed(2);
                    data.tableData[i] = {code:xinlang[i],name:obj[0],changeS:changs,nes:nes.toFixed(2),nowprice: obj[3],
                        currentPrice: mon,hands:has,open: obj[1],close:obj[2],high:obj[4],lose:obj[5],start:colorStyle};
                    // if (this.startType!==''){
                    //     this.getStart()
                    // }
                }
            }
        }
        setTimeout(()=>{
            data.loading = false;
        },500)
    });
}
export const previousPages = (data) =>{
    clears(data)
    data.startIndex -= data.addIndex
    if (data.startIndex < 0 ){
        data.startIndex = data.list.length - data.addIndex
    }
    data.loading = true
    getHqLists(data)
    dataRefrehs(data)
}

export const nextPages = (data) =>{
    clears(data)
    data.startIndex += data.addIndex
    if (data.startIndex >= data.list.length ){
        data.startIndex = 0
    }
    data.loading = true
    getHqLists(data)
    dataRefrehs(data)
}

export const toPercent = (point) =>{
    var str=Number(point*100).toFixed(3);
    str+="%";
    return str;
}

export const getTypeLists = (type,data) =>{
    // 获取 对应码表数据 截取数据
    data.list = ['sh600000','sh600004','sh600007','sh600008','sh600009','sh600010','sh600011','sh600012','sh600015','sh600016','sh600018',
        'sh600019','sh600020','sh600021','sh600025','sh600026','sh600026','sh600027','sh600028','sh600029','sh600030',
        'sh600031','sh600036']
    data.startIndex = 0
    getHqLists(data)
}

export const dataRefrehs = (data) =>{
    data.intervalId = setInterval(() => {
        getHqLists(data); //加载数据函数
    }, 5000);
}
export const clears = (data) =>{
    //清除计时器data.intervalId = null;
    clearInterval(data.intervalId)
    data.intervalId = null //设置为null}
}
