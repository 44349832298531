import axios from '@/axios/globalaxios'

export const addResource = data =>{
    return axios({
        url: "/tradelabLx/resource/addResource",
        method: 'post',
        data:data
    })
}
//根据角色查资源
export const findResourceByRoleId = data =>{
  return axios({
      url: "/tradelabLx/resource/findResourceByRoleId",
      method: 'post',
      data:data
  })
}
export const saveRoleResource = data =>{
  return axios({
      url: "/tradelabLx/resource/saveRoleResource",
      method: 'post',
      data:data
  })
}
