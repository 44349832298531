

const stock = {
    TransactionDayColums:[
        {name:'交易单号',text: 'oid'},
        {name:'合同编号',text: 'id'},
        {name:'证券类型',text: 'exType'},
        {name:'证券代码',text: 'fgsid'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'开仓方向',text: 'tposition'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'成交价格',text: 'tradePrice'},
        {name:'下单数量',text: 'tradeVol'},
        {name:'成交时间',text: 'tradeTime'},
        {name:'最后成交时间',text: 'today'},
    ],
    DelegsignmentDayColums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'平均价格',text: 'averagePrice'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'下单数量',text: 'orderVol'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
        {name:'完成时间',text: 'addDateTime'},
    ],
    HistoryTransactionColums:[
        {name:'时间',text: 'time'},
        {name:'现金类型',text: 'cashflowName'},
        {name:'交易类型',text: 'currencyName'},
        {name:'现金周转',text: 'cashflow'},
        {name:'保留现金',text: 'remainedcash'},
        {name:'备注',text: 'comment'},
    ],
    capitalShares:[
        {name:'证券代码',text: 'marketSID'},
        {name:'证券名称',text: 'name'},
        {name:'持仓数量',text: 'amount'},
        {name:'可用数量',text: 'availableAmount'},
        {name:'冻结数量',text: 'frozenAmount'},
        {name:'成本价',text: 'averageOpenPrice'},
        {name:'市价',text: 'currentPrice'},
        {name:'市值',text: 'marketValue'},
        {name:'浮动盈亏',text: 'floatingPL'},
        {name:'昨结',text: 'yesterdaySettlement'},
        {name:'保证金',text: 'margin'},
        {name:'交易市场',text: 'marketPlace'},
    ]
}
const futures = {
    TransactionDayColums:[
        {name:'交易单号',text: 'id'},
        {name:'合同编号',text: 'oid'},
        {name:'证券类型',text: 'exType'},
        {name:'证券代码',text: 'fgsid'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'开仓方向',text: 'tposition'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'成交价格',text: 'tradePrice'},
        {name:'下单数量',text: 'tradeVol'},
        {name:'成交时间',text: 'tradeTime'},
        {name:'最后成交时间',text: 'today'},
    ],
    DelegsignmentDayColums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'平均价格',text: 'averagePrice'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'下单数量',text: 'orderVol'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
        {name:'完成时间',text: 'addDateTime'},
    ],
    HistoryTransactionColums:[
        {name:'时间',text: 'time'},
        {name:'现金类型',text: 'cashflowName'},
        {name:'交易类型',text: 'currencyName'},
        {name:'现金周转',text: 'cashflow'},
        {name:'保留现金',text: 'remainedcash'},
        {name:'备注',text: 'comment'},
    ],
    capitalShares:[
        {name:'证券代码',text: 'marketSID'},
        {name:'证券名称',text: 'name'},
        {name:'持仓数量',text: 'amount'},
        {name:'可用数量',text: 'availableAmount'},
        {name:'冻结数量',text: 'frozenAmount'},
        {name:'成本价',text: 'averageOpenPrice'},
        {name:'市价',text: 'currentPrice'},
        {name:'市值',text: 'marketValue'},
        {name:'浮动盈亏',text: 'floatingPL'},
        {name:'昨结',text: 'yesterdaySettlement'},
        {name:'保证金',text: 'margin'},
        {name:'交易市场',text: 'marketPlace'},
    ]
}
const bonds = {
    TransactionDayColums:[
        {name:'交易单号',text: 'id'},
        {name:'合同编号',text: 'oid'},
        {name:'证券类型',text: 'exType'},
        {name:'证券代码',text: 'fgsid'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'开仓方向',text: 'tposition'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'成交价格',text: 'tradePrice'},
        {name:'下单数量',text: 'tradeVol'},
        {name:'成交时间',text: 'tradeTime'},
        {name:'最后成交时间',text: 'today'},
    ],
    DelegsignmentDayColums:[
        {name:'交易单号',text: 'omid'},
        {name:'交易所',text: 'exType'},
        {name:'证券代码',text: 'code'},
        {name:'证券名称',text: 'fname'},
        {name:'买卖方向',text: 'direction'},
        {name:'平均价格',text: 'averagePrice'},
        {name:'下单价格',text: 'orderPrice'},
        {name:'下单数量',text: 'orderVol'},
        {name:'成交数量',text: 'tradedVol'},
        {name:'下单时间',text: 'addDateTime'},
        {name:'完成时间',text: 'addDateTime'},
    ],
    HistoryTransactionColums:[
        {name:'时间',text: 'time'},
        {name:'现金类型',text: 'cashflowName'},
        {name:'交易类型',text: 'currencyName'},
        {name:'现金周转',text: 'cashflow'},
        {name:'保留现金',text: 'remainedcash'},
        {name:'备注',text: 'comment'},
    ],
    capitalShares:[
        {name:'证券代码',text: 'marketSID'},
        {name:'证券名称',text: 'name'},
        {name:'持仓数量',text: 'amount'},
        {name:'可用数量',text: 'availableAmount'},
        {name:'冻结数量',text: 'frozenAmount'},
        {name:'成本价',text: 'averageOpenPrice'},
        {name:'市价',text: 'currentPrice'},
        {name:'市值',text: 'marketValue'},
        {name:'浮动盈亏',text: 'floatingPL'},
        {name:'昨结',text: 'yesterdaySettlement'},
        {name:'保证金',text: 'margin'},
        {name:'交易市场',text: 'marketPlace'},
    ]
}


export const Data = {
    stock,
    futures,
    bonds
}


export const OperationDayData = {
    bourse:'', //交易所
    code:'',//期货
    colums:[],
    tableData:[],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    tableHeight: 550,
    datePicker:'',
    startTime:'',
    endTime:'',
}
