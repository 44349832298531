import axios from '@/axios/globalaxios'
//添加菜单
export const addResource = data =>{
    return axios({
        url: "/tradelabLx/resource/addResource",
        method: 'post',
        data:data
    })
}
//查询全部菜单数据接口
export const resources = data =>{
  return axios({
      url: "/tradelabLx/resource/resources",
      method: 'post',
      data:data
  })
}
//修改资源接口
export const resourcesUpdate = data =>{
  return axios({
      url: "/tradelabLx/resource/update",
      method: 'post',
      data:data
  })
}

//删除资源接口
export const resourcesDelete = data =>{
  return axios({
      url: "/tradelabLx/resource/delete",
      method: 'post',
      data:data
  })
}
