<template>
  <div class="tab-table" >
    <el-button type="primary" size="mini"   class="right-export" @click="exportExl">
      导出至Excel
    </el-button>
    <spread-table :pageSizes="[5, 20, 50, 100]" v-loading="loading" :tableColumn="tableColumn" :tableData="tableData"
      :smallType="false" :pageSize="pageSize" :tableHeight="tableHeight" :currentPage="currentPage" :total="total" @sizeChange="sizeChange"
      @currentChange="currentChange" />
  </div>
</template>

<script>
import SpreadTable from "@/components/SpreadTable";
import { defineComponent, reactive, onMounted, toRefs } from "vue";
import { tabData } from "../datas";
import { } from "../methods";
import { cloneDeep } from "lodash"
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: "tab-table",
  components: {
    SpreadTable,
  },
  props: {
    findApi: {
      require: true,
    },
    exportApi: {
      require: true,
    },
    columns: {
      default: [],
    },
    dateRange: {
      default: [],
    },
    routeNames: {
      default: '',
    }
  },
  setup(props, { emit }) {
    let data = reactive(tabData);
    const mega = JSON.parse(sessionStorage.getItem('mega')) || {}
    onMounted(() => {
      data.tableData = []
      data.currentPage = 1
      data.pageSize = 5
      if (props.routeNames != "history-return") {
        findList()
      }
    });

    const sizeChange = size => {
      data.pageSize = size
      data.currentPage = 1
      findList()
    }

    const currentChange = current => {
      data.currentPage = current
      findList()
    }

    const exportExl = () => {
      if (props.routeNames == "history-return") {
        if (props.dateRange == null || !props.dateRange[1]) {
          ElMessage.warning('请选择筛选时间')
          return
        }
        if (data.tableData.length === 0) {
          ElMessage.warning('无可导出数据')
          return
        }
      }

      const reqData = {
        competitionId: mega.id,
        matchType: mega.matchType,
        benchmark: mega.benchmark,
        startTime: props.dateRange ? props.dateRange[0] : null,
        endTime: props.dateRange ? props.dateRange[1] : null,
      }
      props.exportApi(reqData).then(res => {
        const resData = res
        if (resData.status === 200) {
          let FileName = decodeURIComponent(resData.headers['content-disposition'].split('=')[1])
          const blob = new Blob([resData.data], { type: resData.headers['content-type'] });
          if (!!window.ActiveXObject || "ActiveXObject" in window) {
            navigator.msSaveBlob(blob, FileName)
          } else {
            const elink = document.createElement('a');
            elink.download = FileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        }
      }).catch(err => { })
    }

    const queryHistory = () => {
      data.currentPage = 1
      findList()
    }

    const findList = () => {
      const matchType = mega.matchType
      const temp = cloneDeep(props.columns)
      if (matchType == 1) {
        temp.splice(1, 2, { name: '组队名称 ', text: 'groupName' });
      }
      data.tableColumn = temp;
      if (props.routeNames == "history-return") {
        if (props.dateRange == null || !props.dateRange[1]) {
          ElMessage.warning('请选择筛选时间')
          return
        }
      }
      data.loading = true
      const reqData = {
        competitionId: mega.id,
        matchType: mega.matchType,
        benchmark: mega.benchmark,
        pageIndex: data.currentPage,
        pageSize: data.pageSize,
        startTime: props.dateRange ? props.dateRange[0] : null,
        endTime: props.dateRange ? props.dateRange[1] : null,
      }
      props.findApi(reqData)
        .then(res => {
          const resData = res.data
          if (resData.status === 0 || resData.code == 200) {
            data.tableData = resData.data.pageObject;
            data.tableData.forEach(item => {
              item.futuresRatio = item.futuresRatio || '0'
              item.shareRatio   = item.shareRatio   || '0'
              item.bondRatio    = item.bondRatio    || '0'
              item.cashRatio    = item.cashRatio    || '0'
            });
            data.total = resData.data.total;
          } else {
            ElMessage.error(resData.msg)
          }
          data.loading = false
        })
        .catch(e => {
          data.loading = false
        })
    }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
      queryHistory,
      exportExl,
    };
  },
});
</script>

<style scoped lang="less">
.tab-table {
  position: relative;
  margin-top: 20px;

  .right-export {
    top: -44px;

  }
}
</style>