<template>
  <div class="container">
    <!--    <CommonTable-->
    <!--      :colums="colums"-->
    <!--      :paginationConfig="paginationConfig"-->
    <!--      @handleChange="handleChange"-->
    <!--    />-->
    <div class="flexContainer">
      <el-row>
        <el-col :span="9">
          <div class="left">
            <SaleForm @codeChange="codeChange" :price="price" />
          </div>
        </el-col>
        <el-col :span="15">
          <div  class="right">
            <trade-card :code="code" @clickPrice="clickPrice"></trade-card>
          </div>
        </el-col>
      </el-row>


    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { saleData } from "../data";
import CommonTable from "../Table";
import SaleForm from "../SaleForm"
import TradeCard from '@/components/TradeDealCard'
export default {
  name: "sale",
  components: {
    CommonTable,
    SaleForm,
    TradeCard

  },
  setup() {
    const data = reactive({
      price: '',
      code: 'sh600000',
      colums: [
        {
          name: "序号",
          dataIndex: "order",
          width: '180px',
          align: ''
        },
        {
          name: "股票代码",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "股票简称",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "现价",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "涨幅（%）",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "最高",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "最低",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "开盘价",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "昨收",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "成交量",
          dataIndex: "",
          width: '',
          align: ''
        },
        {
          name: "成交额",
          dataIndex: "",
          width: '',
          align: ''
        },
      ],
      paginationConfig: {
        page: 1,
        pageSize: 20,
        total: 100
      },
      tableData: []
    });

    const handleChange = (key, val) => {
      data.paginationConfig[key] = val
    };

    const clickPrice = (value) => {
      data.price = value
    }

    let codeChange = (val) => {
      data.code = val
    }
    return {
      ...toRefs(data),
      clickPrice,
      handleChange,
      codeChange,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 65vh;
  .flexContainer {
    min-width: 1100px;
    .left {
      min-width: 280px;
      margin: 0 20px;
      min-height: 500px;
      padding: 20px 13%;
      background: #F4F4FA;
      color: #65676B;
      font-size: 14px
    }

    .right {
      width: 100%;
    }
  }
}
</style>
