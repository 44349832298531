import axios from '@/axios/globalaxios'

export const findFileByFindType = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/findFileByFindType",
        method: 'post',
        data:data
    })
}

export const delDraftBox = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/delDraftBox",
        method: 'post',
        data:data
    })
}

export const findForeignFileByFindType = (data) => {
  return axios({
    url: "/tradelabLx/currency_files/find",
    method: "post",
    data: data,
  });
};



