<template>
  <div class="process" style="padding: 40px;margin-top: 0;padding-top: 20px;">
    <div style="">
      <el-form :inline="true" :model="formInline" style="margin-top: -2px;" class="demo-form-inline">
        <el-row>
          <el-col :span="3">
            <div style="margin-bottom: 10px;float: left;margin-top: 10px;" class="box-title">讨论区审核</div>
          </el-col>
          <el-col :span="10">
            <el-form-item label="发表日期:">
              <el-date-picker 
                clearable size="mini"
                style="width: 100%;"
                v-model="formInline.date" 
                type="datetimerange" 
                :shortcuts="shortcuts"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
                @change="dateChange" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="发表人:" style="">
              <el-input v-model="formInline.user" size="mini" placeholder="请输入姓名" style="width: 80%;"/>
            </el-form-item>
            <el-button type="primary" size="mini" @click="getList" icon="el-icon-search">查询</el-button>
          </el-col>
          <!--        <el-col :span="6">
            <el-form-item label="审核状态:">
              <el-select v-model="formInline.state" size="mini" placeholder="请选择状态">
                <el-option label="已审核" value="1" />
                <el-option label="未审核" value="0" />
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="4">
            <el-form-item style="float: right;">
              
            </el-form-item>
          </el-col> -->
          <el-col :span="4">
            <div style="float: right;position: relative;top: 7px;">
              <el-button type="warning" size="mini" @click="batchAudit('2')">批量拒绝</el-button>
              <el-button type="primary" size="mini" @click="batchAudit('1')">批量通过</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    

    <el-table :data="tableData" style="width: 100%;margin-top: 10px;"  :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="createTime" label="发表日期" width="180" />
      <el-table-column prop="userName" label="发表人" />
      <el-table-column prop="state" label="审核状态">
        <template #default="scope">
          <span>{{ scope.row.state == 1 ? '已审核' : '未审核' }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="result" label="审核结果">
        <template #default="scope">
          <span>{{ scope.row.state ==0?'暂未审核':scope.row.result == 1?'已通过':'已拒绝' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="title" label="评论内容" width="150">
        <template #default="scope">
          <el-popover placement="left" title="评论详情" :width="300" trigger="hover" :content="scope.row.title">
            <template #reference>
              <div style="width: 150px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.title
              }}</div>
            </template>
          </el-popover>

        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template #default="scope">
          <el-button :underline="false" class="btn-primary" type="text"  @click="handleClick(scope.row, '1')">通过</el-button>
          <el-button :underline="false" class="btn-danger" type="text" @click="handleClick(scope.row, '2')">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="float: right;margin-top: 20px;" :small="true" v-model:current-page="currentPage"
      v-model:page-size="pageSize" :page-sizes="[5, 10, 20, 30]" :total="total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
    <el-dialog v-model="dialogVisible" title="评论详情" width="30%" :close-on-click-modal="false">
      <span>This is a message</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { onMounted } from 'vue'
import { reactive, toRefs, ref } from "vue"
import { ElMessage } from 'element-plus';
import dayjs from "dayjs";
import { listUnapprovedTask, updateUnapprovedTask, batchUpdateUnapprovedTask } from './indexApi.js'
export default {
  components: {

  },
  setup() {
    onMounted(() => {
      getList();
    })
    const detaile = ref(null);
    let data = reactive({
      total: 2,
      tableSelectionList: [],
      formInline: {},
      pageSize: 10,
      currentPage: 1,
      dialogVisible: false,
      startDate: '',
      endDate: '',
      createUser: '',
      status: '',
      tableData: [
        // {
        //   date: '2016-05-03',
        //   name: 'Tom',
        //   state: '1',
        //   result: '1',
        //   text: 'No. 189, Grove St, Los AngelesvahdfjscgvchsdVBCJHABJHEVDFHJbsdjhfvcnhdscbsdvc不达标深V非常v规范VBDSVFSBDACSHC VDSHFV WGEDFVDCVASBC VDFHCAGSC SBC ASBVCV DBSCV DBHVGDVGHVHDVHVDHDDVGHDVHGDVHVDHGW',
        // },
        // {
        //   date: '2016-05-02',
        //   name: 'Tom',
        //   state: '0',
        //   result: '',
        //   text: 'No. 189, Grove St, Los Angeles',
        // },
        // {
        //   date: '2016-05-04',
        //   name: 'Tom',
        //   state: '1',
        //   result: '0',
        //   text: 'No. 189, Grove St, Los Angeles',
        // },
        // {
        //   date: '2016-05-01',
        //   name: 'Tom',
        //   state: '0',
        //   result: '',
        //   text: 'No. 189, Grove St, Los Angeles'
        // },
      ],
      pageIndex: '',

    })

    const getList = () => {
      let parm = {
        "page": data.currentPage,
        "pageSize": data.pageSize,
        "startDate": data.startDate,
        "endDate": data.endDate,
        "createUser": data.formInline.user
      };
      listUnapprovedTask(parm).then(res => {
        let newRes = res.data
        if (newRes.code == 200) {
          console.log('newRes', newRes);
          data.total = newRes.data.total
          newRes.data.list.forEach(item => {
            item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
          })
          data.tableData = newRes.data.list
        }
      })
    }
    const handleSizeChange = (val) => {
      data.pageSize = val
      getList(data)
    }
    const handleCurrentChange = (val) => {
      data.currentPage = val
      getList(data)
    }
    //分页 
    const handleClick = (row, type) => {
      var formData = new FormData();
      formData.append("id", row.id);
      formData.append("status", type);
      formData.append("apper", JSON.parse(sessionStorage.getItem('user')).userId);
      updateUnapprovedTask(formData).then(res => {
        if (res.data.code == '200') {
          ElMessage({ type: 'success', message: '审批完成！' })
          getList();
        } else {
          ElMessage({ type: 'error', message: res.data.msg })
          getList();
        }
      })
    }

    //选择
    const handleSelectionChange = (val) => {
      data.tableSelectionList = JSON.parse(JSON.stringify(val))
    }
    const dateChange = (val) => {
      data.startDate = val[0];
      data.endDate = val[1];
    }
    const batchAudit = (type) => {
      if (data.tableSelectionList.length < 1) {
        ElMessage({ type: 'error', center: true, message: '请选择需审核的评论' })
        return
      }
      let ids = [];
      for (let i = 0; i < data.tableSelectionList.length; i++) {
        ids.push(data.tableSelectionList[i].id);
      }

      var formData = new FormData();
      formData.append("ids", ids);
      formData.append("status", type);
      formData.append("apper", JSON.parse(sessionStorage.getItem('user')).userId);
      batchUpdateUnapprovedTask(formData).then(res => {
        if (res.data.code == '200') {
          ElMessage({ type: 'success', message: '审批完成！' })
          getList();
        } else {
          ElMessage({ type: 'error', message: res.data.msg })
          getList();
        }
      })
    }
    return {
      ...toRefs(data),
      getList,
      handleClick,
      handleSizeChange,
      handleCurrentChange,
      handleSelectionChange,
      dateChange,
      batchAudit
    }


  }
}
</script>
<style lang="less" scoped>
.process{
  height: calc(100vh - 180px);
  border-radius: 6px;
  background-color: #fff;
  padding:20px;
  overflow: auto;

}
.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;

}

.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: rgb(48, 65, 86) !important;
  color: #fff !important;
  line-height: 24px;
}
</style>