export const modelData = {
    drafrList : [
        {name:'用户ID',text: 'numberId'},
        {name:'用户名称',text: 'name'},
        {name:'用户角色',text: 'roleName'},
        {name:'手机号码',text: 'phone'},
        {name:'创建时间',text: 'createTime'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    operation: ['查看','删除','修改余额','成员权限'],
    spreadTable:[],
    tableHeight: 400,
    pageType: 1,
    lastState: 1000,

    userInput: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[]
}