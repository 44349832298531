<template>
  <div class="grop">
    <!--    <el-card class="box-card">-->
    <!--      <div style="margin-bottom: 10px"><i class="card-line"></i>本基金现有成员</div>-->
    <!--      <spread-table  v-loading="loading" :tableHeight="tableHeight" :operation="operation"-->
    <!--                     :pageSize="pageSize" :currentPage="currentPage" :total="total"-->
    <!--                     @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton"-->
    <!--                     :tableColumn="peopleList" :tableData="peopleData"></spread-table>-->
    <!--    </el-card>-->
    <el-card class="box-card" style="margin-top: 10px">
      <div style="margin-bottom: 10px"><i class="card-line"></i>人员人事变更</div>
      <div style="margin: 10px 0">
        <span class="report-title">人员</span>
        <el-select v-model="peopleValue" placeholder="请选择" size="mini" @change="peopleChange">
          <el-option v-for="item in peopleOptions" :key="item.userId" :label="item.name" :value="item.userId">
          </el-option>
        </el-select>

        <span class="report-title" style="margin-left: 20px">新职务</span>
        <el-select v-model="nowJob" placeholder="请选择" size="mini">
          <el-option v-for="item in nowJobOptions" :key="item.id" :label="item.memberRoleName" :value="item.id">
          </el-option>
        </el-select>
        <el-button type="warning" style="margin-left: 15px" size="mini" @click="alterJob">变更</el-button>

      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="peopleList" :tableData="peopleData"></spread-table>
    </el-card>

  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { gropData } from "./gropData";
import { clickButtons, sizeChanges, currentChanges, getUserLists, peopleChanges, getUserTypes,getAllUserLists, alterJobs } from './gropMethods'

export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let data = reactive(gropData);
    onMounted(() => {
      getUserList()
      getUserType()
      getAllUserList()
    })
     let getAllUserList = () => { getAllUserLists(data) }
    let getUserList = () => { getUserLists(data) }
    let getUserType = () => { getUserTypes(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data) }
    let peopleChange = (val) => { peopleChanges(val, data) }
    let alterJob = () => { alterJobs(data) }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
      clickButton,
      getAllUserList,
      peopleChange,
      getUserType,
      alterJob,
    }

  }
})
</script>

<style scoped lang="less">
.grop {
  height: calc(100vh - 330px);
  overflow: auto;
  .card-line {
    display: inline-block;
    width: 3px;
    height: 13px;
    border-radius: 8px;
    margin-right: 10px;
    background: #faad14;
  }
}
.report-title {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
</style>