import axios from '@/axios/globalaxios'

//查找所有货币类型
export const findCurrencyType = () => {
  return axios({
    url: "/tradelabLx/forexOrder/findCurrencyType",
    method: 'get',
  })
}

// 添加外汇订单
export const add  = (data) => {
  return axios({
    url : '/tradelabLx/forexOrder/add',
    method: 'post',
    data:data
  })
}

// 查找用户的账号资金
export const findUserAccountAmount  = (data) => {
  return axios({
    url : '/tradelabLx/forexOrder/findUserAccountAmount',
    method: 'post',
    data:data
  })
}


