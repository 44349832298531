<template>
  <div class='process'>
    <div class="box-card" style="margin: 20px;margin-top: 11px;">
      <div style="margin-bottom: 20px" class="box-title">相关资料</div>

      <div style="margin: 10px 0;text-align: right" v-if="type === 1">
        <el-upload style="text-align: right" class="upload-demo" :show-file-list="false" :on-success="uploadSuccess"
          :multiple="false" :on-error="uploadError" :before-upload="beforeUpload" :data="{ userId: userId }"
          action="/tradelabLx/documentSharing/upload" :headers="headers" :limit="10">
          <el-button size="mini" type="primary">上传</el-button>
        </el-upload>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation"
        beforeRouteName="informationStudent" :pageSize="pageSize" :currentPage="currentPage" :total="total"
        @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList"
        :tableData="spreadTable"></spread-table>

    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { findDocumentSharing, delFile } from "./system-manage/role-manage/roleApi";
import { dealDate } from "./course-manage/trade-manage/tradeMethods";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "@/axios/globalaxios.js";

export default defineComponent({
  name: "information",
  components: {
    SpreadTable,
  },
  setup() {

    onMounted(() => {
      let token = sessionStorage.getItem("token")
      data.headers.Authorization = token
      data.vueExample = getCurrentInstance()
      let name = JSON.parse(sessionStorage.getItem('user'))
      data.type = name.userRoleName === '学生' ? 0 : name.userRoleName === '教师' ? 1 : 2
      data.userId = name.userId
      getUserList()
    })
    let data = reactive({
      headers: { token: "" },
      vueExample: {},
      type: 0,
      userId: 0,
      drafrList: [
        { name: '编号', text: 'id' },
        { name: '文件名称', text: 'revealFileName' },
        { name: '上传时间', text: 'uploadTime' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 550,
      operation: ['下载', '删除'],
    })
    // 查找 data 数据
    let getUserList = () => {
      let parame = {
        pageSize: data.pageSize,
        page: data.currentPage,
      }
      findDocumentSharing(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.spreadTable = newRes.documentSharingPageInfo.list
          data.total = newRes.documentSharingPageInfo.total
          dealDate(data.spreadTable, ['uploadTime'])
          setTimeout(() => {
            data.loading = false
          }, 300)
        }
      }).catch(() => {
        if (!navigator.onLine) {
          ElMessage({ type: 'error', message: '网络已断开' })
          location.reload()
        }
      })
    }
    let sizeChange = (val) => {
      data.pageSize = val
      getUserList()
    }
    let currentChange = (val) => {
      data.currentPage = val
      getUserList()
    }

    let clickButton = (val) => {
      if (val.type === '下载') {

        // window.location.href = '/tradelabLx/documentSharing/download/file?fileName=' + val.item.fileName
        // axios.get('/tradelabLx/documentSharing/download/file', {
        //   "fileName": val.item.fileName
        // }, { responseType: 'blob' })
        let url = "/tradelabLx/documentSharing/download/file"
        let params = {
          fileName: val.item.fileName
        }
        const { proxy } = data.vueExample
        proxy.$DownloadAsExcel(url, params, val.item.revealFileName)

      }
      if (val.type === '删除') {
        ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delDrafrFiles(val.item)
        })
      }
    }

    // 删除文件
    let delDrafrFiles = (item) => {
      delFile({ fileName: item.fileName }).then(res => {
        if (res.data.code === '200') {
          getUserList()
          ElMessage.success(res.data.msg)
        } else {
          ElMessage.error(res.data.msg)
        }
      })
    }

    let uploadSuccess = (res) => {
      if (res.code === '200') {
        ElMessage.success(res.data)
      } else {
        ElMessage.error(res.msg)
      }
      getUserList();
    }


    let beforeUpload = (file) => {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'doc'
      const extension2 = testmsg === 'docx'
      const extension3 = testmsg === 'pdf'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 doc、docx、pdf!',
          type: 'warning'
        });
      }
      // if(!isLt2M) {
      //     this.$message({
      //         message: '上传文件大小不能超过 10MB!',
      //         type: 'warning'
      //     });
      // }
      // return extension || extension2 && isLt2M
      return extension || extension2 || extension3
    }

    let uploadError = (err, file, fileList) => {
      ElMessage.error("上传失败！")
    }

    return {
      ...toRefs(data),
      clickButton,
      sizeChange,
      currentChange,
      uploadSuccess,
      uploadError,
      beforeUpload,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  border-left: 6px solid #3B6EFB;
}

.content {
  line-height: 40px;
  margin: 10px 0 0 30px;
}

// :deep(.el-card__body){
//   height: calc(100vh - 165px);
// }
.process {
  height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding: 0px 20px;
  padding-top: 20px;

}</style>
