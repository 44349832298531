export const gropData = {
    peopleList : [
        {name:'小组ID',text: 'competitionGroupId'},
        {name:'用户ID',text: 'userId'},
        {name:'人员名称',text: 'userName'},
        {name:'人员职务',text: 'memberRoleName'},
    ],
    peopleJobList : [
        {name:'小组ID',text: 'groupId'},
        {name:'人员名称',text: 'userName'},
        {name:'变更前名称',text: 'beforeChange'},
        {name:'变更后名称',text: 'afterChange'},
    ],
    currentPage:1,
    currentPage2:1,
    pageSize: 5,
    pageSize2: 5,
    total: 0,
    total2: 0,
    loading: false,
    peopleData:[],
    tableHeight: 300,
    operation: [],
    peopleValue: '',
    roleName: '',
    peopleOptions: [],
    peopleJobLogData:[],

    nowJob: '',
    nowJobOptions: [],
}