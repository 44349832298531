import axios from '@/axios/globalaxios'

export const updTFundFundfiles = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/updTFundFundfiles",
        method: 'post',
        data:data
    })
}
export const findTargetamountByAcid = data =>{
    return axios({
        url: "/tradelabLx/stockPoolAndTFundTargetamount/findTargetamountByAcid",
        method: 'post',
        data:data
    })
}

export const findForeignExchangeByAcid = (data) => {
  return axios({
    url: "/tradelabLx/currency_files/findTargetAmountByAcid",
    method: "post",
    data: data,
  });
};
export const findDetailedStockCode = data =>{
    return axios({
        url: "/tradelabLx/stockPoolAndTFundTargetamount/findDetailedStockCode",
        method: 'post',
        data:data
    })
}
export const findStockPoolByAcid = data =>{
    return axios({
        url: "/tradelabLx/stockPoolAndTFundTargetamount/findStockPoolByAcid",
        method: 'post',
        data:data
    })
}
export const consolidatedStockAdjustmentTarget = data =>{
    return axios({
        url: "/tradelabLx/stockPoolAndTFundTargetamount/consolidatedStockAdjustmentTarget",
        method: 'post',
        data:data
    })
}

export const combinedCurrency = (data) => {
  return axios({
    url: "/tradelabLx/currency_files/combinedCurrency",
    method: "post",
    data: data,
  });
};

export const findFileByFileId = (data) => {
  return axios({
    url: "/tradelabLx/tFundFundfiles/findFileByFileId",
    method: "post",
    data: data,
  });
};
