import {
  findFileByFindType,
  findForeignFileByFindType,delDraftBox,
} from "./drafrApi";
import { ElMessage, ElMessageBox } from "element-plus";
export const getDrafrLists = (data, classId, researchReport) => {
  data.disabled = true;
  let parame = {
    classId: JSON.parse(sessionStorage.getItem("classId")),
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    findType: data.pageType?data.pageType:'',
    lastState: data.lastState?data.lastState:null,
    filetype: data.filetype?data.filetype:'',
    pageSize: data.pageSize?data.pageSize:'',
    page: data.currentPage?data.currentPage:'',
  };
  data.spreadTable = []
  data.total = ''
  if (data.financeRadio == '外汇') {
    parame.acId = parame.acid;
    parame.courseId = parame.classId;
    findForeignFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        // let name =
        //   data.pageType === 1
        //     ? "reportManagementPageInfo"
        //     : data.pageType === 2
        //     ? "relocationManagementPageInfo"
        //     : "reportManagementPageInfo";
        data.spreadTable =getCurrencyListName(newRes.currencyManagement.list);
        //判断是否是是现有研究报告页面
        if (researchReport) {
          data.spreadTable = newRes.currencyManagement.list.map((element) => {
            let item = {
              ...element,
              singelSelect: element.fileId,
            };
            return item;
          });
        }

        data.total = newRes.currencyManagement.total;
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  } else { 
    findFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        let name =
          data.pageType === 1
            ? "reportManagementPageInfo"
            : data.pageType === 2
            ? "relocationManagementPageInfo"
            : "reportManagementPageInfo";
        data.spreadTable = newRes[name].list;
        //判断是否是是现有研究报告页面
        if (researchReport) {
          data.spreadTable = newRes[name].list.map((element) => {
            let item = {
              ...element,
              singelSelect: element.fileId,
            };
            return item;
          });
        }

        data.total = newRes[name].total;
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  }
  
};

export const getCurrencyListName = (arr) => {
  arr.forEach((item) => {
    item.currencyName = "";
    if (item.currencyList && item.currencyList.length > 0) {
      item.currencyList.forEach((item2, index) => {
        if (index == item.currencyList.length - 1) {
          item.currencyName = item.currencyName + item2.name;
        } else {
          item.currencyName = item2.name + "," + item.currencyName;
        }
      });
    }
  });

  return arr
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getDrafrLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getDrafrLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    fieldId: item.fileId,
  };
  delDraftBox(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getDrafrLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data, router) => {
  console.log("val", val);
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该报告, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "编辑") {
    router.push({
      path: "/course/report/edit",
      query: { pageType: 6,financeRadio:data.financeRadio , value: JSON.stringify(val.item) },
    });
  }
  if (val.type === "查看") {
    router.push({
      path: "/course/report/edit",
      query: {
        pageType: 6,
        value: JSON.stringify(val.item),
        financeRadio: data.financeRadio,
        showStudent: data.showStudent,
      },
    });
    // router.push({path:"/course/report/edit",query:{pageType:6,value:JSON.stringify(val.item)}});
  }
};
