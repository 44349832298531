<template>
    <div class="my-courses" :class="{'courses-have-inh-and':classStart===2}"  @click="checkCard(item)">
        <div class="title">{{item.courseName}}</div>
        <div class="courseContainer">
        <!-- <div class="item">
           <div class="label">主讲教师：</div>
           <div class="props">{{item.createUserName}}</div>
        </div> -->
        <div class="item">
            <div class="label">参赛人数：</div>
            <div class="props">{{item.numberOfPeople}}</div>
        </div>
        <div class="item">
            <div class="label">大赛时间：</div>
            <div class="props" style="font-size: 12px">{{item.classTime}} ~ {{ item.endTime }}</div>
          </div>
        <div class="item">
            <div class="label">创建时间：</div>
            <div class="props" style="font-size: 12px">{{item.createTime}}</div>
        </div>
    </div>
        <div class="set">
          <span v-if="route.path=='/index'" class="status have-inh-and">进行中</span>
          <span v-else class="status" :class="{'completed':classStart===2,'have-inh-and':classStart===1}">{{ classStart===2?'已结束':(classStart===1?'进行中':'未开始') }}</span>
          <div>
          <span @click.stop="setCourse(item)" v-if="showButton" class="set-up">设置</span>
          <span @click.stop="delCourse(item)" v-if="showButton" class="delete">删除</span></div>
        </div>
 
    </div>
    
    <!-- <el-card class="box-card" @click="checkCard(item)">
      <template #header>
        <div class="card-header">
          <span class="title"> <strong>{{item.courseName}}</strong> </span>
        </div>
      </template>
      <div class="courseContainer">
        <div class="specific">
          <div class="item">
            <div class="label">教师：</div>
            <div class="props">{{item.createUserName}}</div>
          </div>
          <div class="item">
            <div class="label">学生人数：</div>
            <div class="props">{{item.numberOfPeople}}</div>
          </div>
          <div class="item">
            <div class="label">创建时间：</div>
            <div class="props" style="font-size: 12px">{{item.createTime}}</div>
          </div>
          <div class="item">
            <div class="label">大赛时间：</div>
            <div class="props" style="font-size: 12px">{{item.classTime}} ~ {{ item.endTime }}</div>
          </div>
        </div>
        <div class="set">
          <span @click.stop="setCourse(item)" v-if="showButton" style="margin-right: 15px">设置</span>
          <span @click.stop="delCourse(item)" v-if="showButton" style="color: #f56c6c">删除</span>
        </div>
      </div>
  
    </el-card> -->
  </template>
  
  <script>
  import { onMounted, reactive, toRefs } from "vue"
  import { useRouter,useRoute } from "vue-router";
  import { checkCards } from './cardMethods'
  import { classData } from "./classData";
  import { ElMessage } from "element-plus";
  
  export default {
    name: 'course',
    props: {
      item: { type: [Object, Number, String] },
      classStart: { type: Number }
    },
    setup(props, { emit }) {
        console.log(props,'props')
      let data = reactive(classData)
      let show = reactive({ showButton: false })
      let router = useRouter()
      let route = useRoute()
      onMounted(() => {
        if (JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师') {
          show.showButton = true
        }
      })
      const setCourse = (item) => {
        emit('setcourse', item)
      }
      let checkCard = (item) => {
        if (props.classStart !== 1) return ElMessage.warning('课程状态不可进入')
        sessionStorage.setItem('course-duration',item.classTime+','+item.endTime)
        
        checkCards(item, router, data);
      }
      let delCourse = () => {}
      return {
        ...toRefs(data),
        ...toRefs(show),
        checkCard,
        setCourse,
        delCourse,
        route
      }
    }
  }
  </script>
  
  <style lang="less" scoped>

  .my-courses{
    cursor: pointer;
    padding:25px 20px;
    background: url('../../../../../assets/course-not-started.png');
    margin-bottom:20px;
    background-size: 100% 100%;
    padding-bottom:15px ;
    .title{
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #03032C;
        line-height: 18px;
        margin-bottom:10px;
    }
    .item{
        display: flex;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #686480;
        line-height: 28px;

    }
    .set{
        margin-top: 15px;
        border-top:1px solid #D5D7E6;
        padding-top:10px;
        display: flex;
        justify-content: space-between;
        .status{
            width: 68px;
            height: 32px;
            background: #F9FAFF;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3B6EFB;
        }
        .set-up{
            display: inline-block;
            margin-right: 30px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #686480;
            line-height: 14px;
        }
        .delete{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #E34433;
            line-height: 14px;
        }
        .have-inh-and{
            background: #ECFDF1;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00DE3D;
        }
        .completed{
            background: #F8F8F8;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #686480;
        }
    }
  }
  .courses-have-inh-and{//课程已结束时显示的背景图
    background: url('../../../../../assets/course-has-ended.png');
    background-size: 100% 100%;
  }
//   .box-card {
//     cursor: pointer;
//     height: auto;
//     margin-bottom: 20px;
//     width: 100%;
//     .title {
//       display: inline-block;
//       text-align: center;
//       width: 100%;
//       background: #fecd75;
//       padding: 18px 20px;
//       box-sizing: border-box;
//     }
//     &:deep(.el-card__header) {
//       padding: 0 0;
//       border-bottom: #fecd75 !important;
//     }
//     &:deep(.el-card__body) {
//       padding: 0px 0;
//     }
//     .courseContainer {
//       .specific {
//         background: #fecd75;
//         border-bottom-left-radius: 10px;
//         border-bottom-right-radius: 10px;
//         overflow: hidden;
//         .item {
//           display: flex;
//           align-items: center;
//           color: #fff;
//           padding: 0 40px;
//           padding-right: 10px;
//           margin-bottom: 10px;
//           font-size: 14px;
//           .label {
//             width: 85px;
//             background: rgba(228, 186, 109, 0.8);
//             text-align: center;
//             margin-right: 10px;
//             padding: 5px 0;
//             border-radius: 4px;
//           }
//         }
//       }
//       .set {
//         text-align: right;
//         font-size: 14px;
//         color: #1890ff;
//         background: #fff;
//         padding: 10px 20px;
//         cursor: pointer;
//       }
//     }
//   }
  </style>
  