<template>
  <!-- /course/trade-personal/stock -->
  <!-- 学生课程点击首页 -->
  <div class="trade-personal-stock">
    <el-card >
      <div v-if="route.path === '/course/trade-personal/stock'" >
        <router-view />
      </div>
      <div v-else>
        <div>
          <div class="name-div"><i class="card-line"></i>{{ courseName}}</div>
          <div v-if="alertParam.currentWeek" class="week-div">{{alertParam.currentWeek}}</div>
        </div>
        <div  v-if="courseDuration" style="clear: both;margin-bottom: 20px;font-size: 14px;color: #686480;">

          <span class="course-time">大赛时间：{{courseDuration[0]}} - {{courseDuration[1]}}</span>
        </div>
        <div style="clear: both;background: #686480;width: 110%;margin: 0 -22px;">
          <nav-son-menu :menuData="roleList" :routerType="routerType" :direction="direction"></nav-son-menu>
        </div>
        
        <!-- <el-alert style="margin: 10px 0 " type="warning" :closable="false" center show-icon>
          <template #title>
            <span style="display:inline-block;margin-right: 15px">大赛名称：{{ courseName}}</span>
            <span>开始日期：{{ alertParam.startTime }}</span>
            <span style="margin: 0 15px">结束日期：{{ alertParam.endTime }}</span>
            <span>当前周次：{{ alertParam.currentWeek }}</span>
          </template>
        </el-alert> -->
        <!-- <div style="margin: 15px 20px;">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div> -->
        <div style="overflow: auto;clear: both;" class="main-content">
          <router-view :key="route.fullPath"/>
        </div>
      </div>
    </el-card>
    
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import NavSonMenu from '@/components/NavSonMenu'
import { menuData } from './menuData'
import { findTimeRoundByCourseId } from "../teacher-manage/indexApi";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    NavSonMenu,
  },
  setup() {
    let data = reactive({
      roleList: [],
      courseName:'',
      'courseDuration':[],
      // menuList: [
      //   {
      //     name: '汇总统计',
      //     router: '/course/home',
      //     routerTag: 1,
      //     key: 1,
      //   },
      //   {
      //     name: '行情展示',
      //     router: '/course/summary/1',
      //     routerTag: 2,
      //     key: 2,
      //   },
      //   {
      //     name: '组合管理',
      //     router: '/course/group',
      //     routerTag: 3,
      //     key: 3,
      //   },
      //   {
      //     name: '交易管理',
      //     router: '/course/trade',
      //     routerTag: 4,
      //     key: 4,
      //     children: [
      //       { name: '股票', router: '/course/trade/1', key: 14, },
      //       { name: '债券', router: '/course/trade/2', key: 15, },
      //       { name: '期货', router: '/course/trade/3', key: 16, },
      //       { name: '盘后申报', router: '/course/trade/declare', key: 17, },
      //     ]
      //   },
      //   {
      //     name: '报告管理',
      //     router: '/course/report',
      //     routerTag: 5,
      //     key: 5,
      //     children: [
      //       { name: '报告编写', router: '/course/report/edit', key: 5 - 1, },
      //       { name: '草稿箱', router: '/course/report/draft', key: 5 - 2, },
      //       { name: '状态展示', router: '/course/report/state', key: 5 - 3, },
      //       { name: '报告浏览器', router: '/course/report/browser', key: 5 - 4, },
      //       // { name: '业绩报告', router: '/course/report/performance', key: 5 - 5, },
      //     ]
      //   },
      //   {
      //     name: '报告管理',
      //     router: '/course/report',
      //     routerTag: 5,
      //     key: 6,
      //     children: [
      //       { name: '草稿箱', router: '/course/report/draft', key: 20, },
      //       { name: '状态展示', router: '/course/report/state', key: 21, },
      //       { name: '报告浏览器', router: '/course/report/browser', key: 22, },
      //       // { name: '业绩报告', router: '/course/report/performance', key: 23, },
      //     ]
      //   },
      //   {
      //     name: '成员',
      //     router: '/course/user',
      //     routerTag: 6,
      //     key: 7,
      //     children: [
      //       { name: '管理', router: '/course/user/account', key: 17, },
      //       { name: '历史记录', router: '/course/user/jobChange', key: 18, },
      //     ]
      //   },
      //   {
      //     name: '历史记录',
      //     routerTag: 7,
      //     router: '/course/user/jobChange',
      //     key: 8,
      //   },
      //   {
      //     name: '调仓管理',
      //     routerTag: 8,
      //     router: '/course/stock',
      //     key: 9,
      //   },
      //   {
      //     name: '风控管理',
      //     routerTag: 9,
      //     router: '/course/control',
      //     key: 10,
      //   },
      //   {
      //     name: '作业评分',
      //     routerTag: 10,
      //     router: '/course/homework',
      //     key: 11,
      //   },
      // ],
      routerType: false,
      direction: true,
      alertParam: {},
    })
    const route = useRoute();
    onMounted(() => {
      if(sessionStorage.getItem('course-duration')){
        data.courseDuration= sessionStorage.getItem('course-duration').split(',')
      }

      if (route.path === '/course/trade-personal/stock') {
        return
      }
      checkWeek()
      data.roleList = []
      let role = [];
      let arr = JSON.parse(sessionStorage.getItem('roleList')).filter((item) => {
        if (item.url === '/course') {
          return item;
        }
      })
      // 去除盘后申报路径
      // let routeList = ['/course/trade-personal/stock', '/course/trade/declare', '/course/report/edit', '/course/report/draft', '/course/report/state', '/course/report/browser', '/course/user/account', '/course/user/jobChange']
      let routeList = ['/course/trade-personal/stock',  '/course/report/edit', '/course/report/draft', '/course/report/state', '/course/report/browser', '/course/user/account']
      let newArr = [];
      let index = 0;
      arr[0].childResources.sort((a, b) => {
	    return a.sort - b.sort
      })
      // console.log(' arr[0].childResources', arr[0].childResources);
      arr[0].childResources.forEach((element) => {
        if (!routeList.includes(element.url)) { 
          // console.log("element.url", element.url);
          index = index + 1
          let obj = {
            name: element.urlDescription,
            router: element.url,
            key: index,
          }
          if (element.url == '/course/summary/:id') {
            obj.router = '/course/summary/1'
          }
         
          //交易管理子菜单
          if (element.url == '/course/trade/:id') {
            obj.router = '/course/trade'
            obj.children = [
              { name: '股票', router: '/course/trade/1', key: 14, },
              { name: '债券', router: '/course/trade/2', key: 15, },
              // { name: '期货', router: '/course/trade/3', key: 16, },
              { name: '外汇', router: '/course-manage/table?showAbbr=true', key: 17, },
            ]

          } else {
            delete obj.children;
          }
          newArr.push(obj)
        }
        data.roleList = newArr;
        

      });
      newArr.push({
        name: '成员',
        router: '/course/user',
        routerTag: data.roleList.length + 1,
        key: data.roleList.length + 1,
        children: []
      })
      newArr.push({
        name: '报告管理',
        router: '/course/report',
        routerTag: data.roleList.length + 1,
        key: data.roleList.length + 1,
        children: []
      })
      newArr.forEach((item, index) => {
        if (item.router === '/course/trade' ) {
          arr[0].childResources.forEach((element) => {
            // if (element.url.startsWith('/course/trade/declare')) {
            //   item.children.push({ name: element.urlDescription, router: element.url, key: 17, })
            // }
          })
        }
        //报告管理子菜单
        if (item.router === '/course/report') {
          arr[0].childResources.forEach((element, index) => {
            if (element.url.startsWith('/course/report/')) {
              item.children.push({ name: element.urlDescription, router: element.url, key: index, })
            }
          })
        }
        if (item.router === '/course/user') {
          arr[0].childResources.forEach((element, index) => {
            if (element.url.startsWith('/course/user/')) {
              item.children.push({ name: element.urlDescription, router: element.url, key: index, })
            }
          })
        }
        // if (item.router === '/course/table') {
        //   arr[0].childResources.forEach((element, index) => {
        //     if (element.url.startsWith('/course/table/')) {
        //       item.children.push({ name: element.urlDescription, router: element.url, key: index, })
        //     }
        //   })
        // }

      })
      let ieEDit=false;
      let isReportShow=false;
      if (JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师') {
        // role = [1, 2, 3, 4, 6, 8, 9, 10]
        role = ['/course/home', '/course/summary/1', '/course/group', '/course/trade', '/course/report', '/course/user/jobChange', '/course/stock', '/course/control', ]
      } else {
        if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '基金经理') {
          ieEDit=true;
          // role = [1, 2, 3, 4, 5, 7, 9, 10, 11]
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/trade', '/course/report', '/course/user', '/course/stock', '/course/control','/course/homework','/course/fund']
        } else if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '研究员') {
          ieEDit=true;
          // role = [1, 2, 3, 5, 8, 11]
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/report', '/course/user/jobChange', '/course/homework','/course/fund']
        } else if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '风险控制') {
          // role = [1, 2, 3, 6, 8, 10, 11]
          ieEDit=true;
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/report', '/course/user/jobChange','/course/control', '/course/homework','/course/fund']
        } else if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '周报管理') {
          // role = [1, 2, 3, 5, 8, 11]
          ieEDit=true;
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/report', '/course/user/jobChange', '/course/homework','/course/fund']
        } else if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '交易员') {
          // role = [1, 2, 3, 4, 6, 8, 11]
          ieEDit=true;
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/trade', '/course/report', '/course/user/jobChange', '/course/homework','/course/fund']
        } else if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '临时工') {
          // role = [1, 2, 3, 8, 11]
          isReportShow=true;
          role = ['/course/home', '/course/summary/1', '/course/group', '/course/user/jobChange', '/course/homework','/course/fund']
        }
      }

      data.roleList=[]
      for(let i in role){
        newArr.forEach((item,index)=>{
          if(role[i]===item.router){
            data.roleList.push(item)
          }
        })
      }
      // console.log(' data.roleList', data.roleList);
      //判断为临时工时需要显示报告浏览器
      if(isReportShow){
          arr[0].childResources.forEach((element) => {
            if (element.url === '/course/report/browser' ) {
            data.roleList.push({
               name: element.urlDescription, router: element.url, key: 22, 
            })
          }
        })

      }
      //判断是否显示报告编写
      if(!ieEDit){
        deleRepoct()
      }
      if (JSON.parse(sessionStorage.getItem('courseType')) !== 99) {
        for (let i in data.roleList) {
          if (data.roleList[i].router === '/course/trade') {
            // 股票 courseType=1时只显示 股票和盘后申报
            if (JSON.parse(sessionStorage.getItem('courseType')) === 1) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/2','/course-manage/table?showAbbr=true','/course/trade/3'])
            }
            // 债券 courseType=1时只显示 债券和盘后申报
            if (JSON.parse(sessionStorage.getItem('courseType')) === 2) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1','/course-manage/table?showAbbr=true','/course/trade/3'])
            }
            // 期货
            if (JSON.parse(sessionStorage.getItem('courseType')) === 3) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1','/course/trade/2','/course-manage/table?showAbbr=true','/course/trade/declare'])
            }

            // 外汇
            if (JSON.parse(sessionStorage.getItem('courseType')) === 6) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1','/course/trade/2','/course/trade/3'])
            }
            
            // 股票&债券
            if (JSON.parse(sessionStorage.getItem('courseType')) === 5) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/3','/course-manage/table?showAbbr=true'])
            }
            
            // 股票&外汇
            if (JSON.parse(sessionStorage.getItem('courseType')) === 7) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/2','/course/trade/3'])
            }
            
            // 外汇&债券
            if (JSON.parse(sessionStorage.getItem('courseType')) === 8) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1','/course/trade/3'])
            }
            
            // 外汇&期货
            if (JSON.parse(sessionStorage.getItem('courseType')) === 9) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1','/course/trade/2'])
            }
            
            // 股票、债券、期货
            if (JSON.parse(sessionStorage.getItem('courseType')) === 4) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course-manage/table?showAbbr=true'])
            }
            
            // 外汇、股票、债券
            if (JSON.parse(sessionStorage.getItem('courseType')) === 10) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/3'])
            }
            
            // 外汇、股票、期货
            if (JSON.parse(sessionStorage.getItem('courseType')) === 11) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/2'])
            }
            
            // 外汇、债券、期货
            if (JSON.parse(sessionStorage.getItem('courseType')) === 12) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/1'])
            }

            // 股票、债券、期货、外汇
            if (JSON.parse(sessionStorage.getItem('courseType')) === 13) {
              data.roleList[i].children= filterList( data.roleList[i].children,['/course/trade/declare'])
            }
            
          }
        }
      }
      data.roleList.forEach((item,index)=>{
        if (item.router === '/course/report') {
          sessionStorage.setItem('reportList',JSON.stringify(item.children))
        }
      })
      
      sessionStorage.setItem('menu', JSON.stringify(data.roleList))
    })
    let filterList=(list,courseList)=>{
     
      let newlist= list.filter((item)=>{
                if(!courseList.includes(item.router)){
                  return item
                }
              })
      return newlist

    }
    let deleRepoct =()=>{
      data.roleList.forEach((item,index)=>{
        if(item.router==='/course/report'){
          data.roleList[index].children= item.children.filter((element)=>{
            if(element.router!='/course/report/edit'){
              return element
            }
          })
        }
      })
    }
    let checkWeek = () => {
      let param = {
        courseId: sessionStorage.getItem('classId'),
      }
      findTimeRoundByCourseId(param).then(res => {
        if (res.data.code === '200') {
          console.log(res.data,'res.data')
          data.courseName=sessionStorage.getItem("courseName");
          let parNew = res.data.data.timeRound
          data.alertParam.currentWeek = parNew?.currentWeek
          sessionStorage.setItem('studentCurrentWeek',data.alertParam.currentWeek||'null')
          data.alertParam.startTime = parNew.startTime.toString().slice(0, 4) + '-'
            + parNew.startTime.toString().slice(4, 6) + '-' + parNew.startTime.toString().slice(6)
          data.alertParam.endTime = parNew.endTime.toString().slice(0, 4) + '-'
            + parNew.endTime.toString().slice(4, 6) + '-' + parNew.endTime.toString().slice(6);
            
        }
      })
    }
    return {
      ...toRefs(data),
      checkWeek,
      route,
      filterList,
      deleRepoct
    }
  }
})
</script>

<style lang="less" scoped>
.main-content{
  // height: calc(100vh - 295px);
  overflow-x: hidden !important;
}
.trade-personal-stock{
  box-sizing: border-box;
  overflow-x: hidden;
  box-shadow: 0px 2px 20px 0px rgba(107,133,228,0.15);
  border-radius: 6px;
  // min-height:calc(100vh - 120px);

  // background-color: #fff;
}

.week-div{
  margin: 22px 0 0 1px;
  text-align: center;
  float: left;
  width: 60px;
  height: 16px;
  font-size: 10px;
  padding: 2px;
  color: #3B6EFB;
  border: #3B6EFB 1px solid;
  border-radius: 15px;
}
.name-div{
  margin: 20px 8px 2px 0px;
  font-size: 20px;
  font-weight: bold;
  float: left;
}
.card-line {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 6px;
  height: 20px;
  margin-right: 10px;
  background: #3B6EFB;
}
.course-time{
  display: inline-block;
  margin-top:10px;

}
</style>
