<template>
  <div class="commonbox">
    <el-table
      :data="tableData"
      style="width: 100%"
      header-row-class-name="customtable"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
    <el-table-column
      v-if="selection"
      type="selection"
      width="55" />
    
      <el-table-column
        v-for="(item, index) in colums"
        :key="index"
        :prop="item.dataIndex"
        :label="item.name"
        :width="item.width"
        :align="item.align"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="showPage"
      class="page_box"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationConfig.page"
      :page-sizes="[10, 20, 50]"
      :page-size="paginationConfig.pageSize"
      layout=" prev, pager, next,sizes,jumper"
      :total="paginationConfig.total"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Commontables",
  props: {
    tableData: { type: Array, default: [] },
    colums: { type: Array, default: [] },
    loading:{type:Boolean,default:false},
    paginationConfig:{
        type:Object,
        default:{
            page:1,
            pageSize:20,
            total:100
        }
    },
    selection:{type:Boolean,default:false},
    showPage:{type:Boolean,default:true}
  },
  setup(props, { emit }) {

      const handleSizeChange = (val) => {
          emit('handleChange','pageSize',val)
      }

      const handleCurrentChange = (val) => {
          emit('handleChange','page',val)
      }

        //多选
      const handleSelectionChange = (val) => {}

      return {
          handleSizeChange,
          handleCurrentChange,
          handleSelectionChange
      }
  },
};
</script>

<style lang="less" scoped>
:deep(.customtable) {
  background: #e5e7e9;
  tr,
  th {
    background: #e5e7e9;
    color: #000;
    font-weight: 400;
  }
}
.commonbox{
    &:deep(.page_box){
        text-align: right;
        padding-top: 20px;
       .el-pager{
           li:not(.disabled).active{
               background: #666666;
           }
           li:hover{
               color: #606266;
           }
           .active {
                background: #666666;
                color: #fff;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}
</style>
