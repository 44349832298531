export const props = {
  smallType: {
    type: Boolean,
    default: false, //  传 true 默认小型table
  },
  selection: {
    type: Boolean,
    default: false, //  传 true 为table选择  默认正常table
  },
  upreport: {
    type: Boolean,
    default: false, //  传 true 报告类型操作按钮
  },
  showStudent: {
    type: Boolean,
    default: true,
  },
  disabledTeacher: {
    type: Boolean,
    default: false,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  currentPageShow: {
    type: Number,
    default: 0,
  },
  pageSize: {
    type: Number,
    default: 10,
  },
  beforeRouteName: {
    type: String,
    default: "0",
  },
  total: {
    type: Number,
    default: 400,
  },
  tableHeight: {
    type: Number,
    default: 300,
  },
  tableData: {
    type: Array,
    default: [],
  },
  tableColumn: {
    type: Array,
    default: [],
  },
  operation: {
    type: Array,
    // default: ['编辑','查看']
    default: [],
  },
  indexMode: {
    type: Boolean,
    default: false, //  传 true 增加序号列  默认不显示
  },
  indexTitle: {
    type: String,
    default: "", // indexMode下的标题
  },
  highlight: {
    //单选高亮
    type: Boolean,
    default: false,
  },
  pageSizes: {
    //一页显示数据条数
    default: [10, 20, 50, 100],
  },
  singelSelect: {
    //单选模式 需传入列singelSelect 做的双绑 所以还需要配置highlightChange
    type: Boolean,
    default: false,
  },
  addEvenTable: {
    //是否监听表格滚动事件
    type: Boolean,
    default: false,
  },
  tableDetail: {
    default: {},
  },
  singelSelectRadio: {
    default: "",
  },
};
