<template>
  <div class="course-homework">
    <el-card class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>作业评分</div>
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="24">
          <span class="report-title">选择周次</span>
          <el-select v-model="weekValue" placeholder="请选择" size="mini" clearable @change="currenyChange">
            <el-option v-for="(item,index) in weekList" :key="index" :label="item.currentWeek" :value="item.startTime+'/'+item.endTime">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 15px" size="mini" @click="getDrafrList" icon="el-icon-search">查询</el-button>
        </el-col>
      </el-row>
      <spread-table v-loading="loading" :tableHeight="tableHeight"  :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange"  :tableColumn="startList" :tableData="spreadTable"></spread-table>
    </el-card>

  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import WangEditor from "@/components/WangEnduit";

import { useRouter } from "vue-router";
import { findEvenWeeksByCourseId, findScoreByCourseIdOrAcidAndUserId } from "./homeApi";
import { dealDate } from "../../course-manage/upreport-manage/Upreport-State/startMethods";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    WangEditor,
  },
  setup() {
    let data = reactive({
      weekValue: '',
      weekList: [],
      startList: [
        { name: '姓名', text: 'name' },
        { name: '角色', text: 'memberRoleName' },
        { name: '分数', text: 'allscore' },
        { name: '计算开始时间', text: 'begindate' },
        { name: '计算结束时间', text: 'enddate' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      spreadTable: [],
      tableHeight: 450,
      param: {},
    })
    onMounted(() => {
      checkScoke()
    })

    let getDrafrList = () => {
      if(!data.weekValue) return ElMessage.warning('请选择周次')
      data.loading = true
      let parma = {
        userId: JSON.parse(sessionStorage.getItem('user')).userId,
        courseId: sessionStorage.getItem('classId'),
        startTime: data.param.slice(0, 8),
        endTime: data.param.slice(9),
        pageSize: data.pageSize,
        page: data.currentPage,
      }
      findScoreByCourseIdOrAcidAndUserId(parma).then(res => {
        if (res.data.code === '200') {
          data.spreadTable = res.data.data.scorePrecedures.list
          dealDate(data.spreadTable, ['begindate', 'enddate'])
          data.loading = false
        }
      })
    }
    let checkScoke = () => {
      let param = {
        courseId: sessionStorage.getItem('classId'),
      }
      findEvenWeeksByCourseId(param).then(res => {
        if (res.data.code === '200') {
          data.weekList = res.data.data.timeRoundList
        }
      })
    }

    let currenyChange = e => {
      data.param = e
    }

    return {
      ...toRefs(data),
      checkScoke,
      currenyChange,
      getDrafrList,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.report-title {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
.el-col{
  text-align: right;
  margin-top:-30px;
  margin-bottom:20px;
  }
  .course-homework{
    height: calc(100vh - 320px);
    overflow:auto;
    background-color: #fff;


  }
</style>
