export const controlData = {
    // table 属性
    tableList : [
        {name:'文件编号',text: 'fileId'},
        {name:'文件名称',text: 'name'},
        {name:'文件类型',text: 'fileTypeName'},
        {name:'状态',text: 'fileStateName'},
    ],
    currentPage:1,
    pageSize: 5,
    total: 0,

    loading: true,
    spreadTable:[],

    tableListOf : [
        {name:'代码',text: 'name'},
        {name:'简称',text: 'internalsid'},
        {name:'数目',text: 'targetamount'},
    ],
    currentPageOf:1,
    pageSizeOf: 5,
    totalOf: 0,
    loadingOf: false,
    spreadTableOf:[],

    tableListPlan : [
        {name:'代码',text: 'name'},
        {name:'简称',text: 'internalsid'},
        {name:'数目',text: 'targetamount'},
    ],
    fileIdPlan: null,
    currentPagePlan:1,
    pageSizePlan: 5,
    totalPlan: 0,
    loadingPlan: true,
    spreadTablePlan:[],

    operation:['查看','对应目标','通过','否决'],
    pageType: 3,
    filetype: 4,
    routeId: undefined,

    // 查看
    fileName: '',
    diaStart: false,
    valueFile: '',
}


