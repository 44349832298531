const stock = {
    historyColums:[
        {
            name: "历史成交股票",
            dataIndex: "",
            width:'',
            align:'center'
        },
    ],
    capitalColums:[
        {
            name: "资金流水股票",
            dataIndex: "",
            width:'',
            align:'center'
        }
    ]
}
const futures = {
    historyColums:[
        {
            name: "历史成交期货",
            dataIndex: "",
            width:'',
            align:'center'
        },
    ],
    capitalColums:[
        {
            name: "资金流水期货",
            dataIndex: "",
            width:'',
            align:'center'
        }
    ]
}
const bonds = {
    historyColums:[
        {
            name: "历史成交债券",
            dataIndex: "",
            width:'',
            align:'center'
        },
    ],
    capitalColums:[
        {
            name: "资金流水债券",
            dataIndex: "",
            width:'',
            align:'center'
        }
    ]
}

export const Data = {
    stock,
    futures,
    bonds
}



export const HistoryData = {
    date:[],
    colums:[],
    tableData:[],
    paginationConfig:{
        page:1,
        pageSize:20,
        total:100
    },
    
}