<template>
  <el-card class="box-card" v-if="false">
    <template #header>
      <div class="card-header">
        <span>个人交易区</span>
      </div>
    </template>
    <div class="personageContainer">
      <div class="item" v-for="(item,index) of list" :key="index">
        <router-link :to="item.link">
          <img :src="item.img" alt="">
        </router-link>
        <div class="name">{{item.name}}</div>
      </div>

    </div>
  </el-card>
</template>

<script>
import gp from "@/assets/gp.png"
import qh from "@/assets/qh.png"
import zq from "@/assets/zq.png"
import wh from "@/assets/wh.png"
import { reactive, toRefs } from "vue"
export default {
  name: 'personalCard',
  setup() {
    const list = reactive([
      {
        name: '股票',
        img: gp,
        link: '/course/trade-personal/stock?componentName=market&pageName=stock&activeTableftkey=1&activeTableTopkey=1&componentType='
      },
      {
        name: '债券',
        img: zq,
        link: '/course/trade-personal/stock?componentName=market&pageName=bonds&activeTableftkey=1&activeTableTopkey=2&componentType='
      },
      // {
      //   name: '期货',
      //   img: qh,
      //   link: '/course/trade-personal/stock?componentName=ForwardMarket&pageName=futures&activeTableftkey=1&activeTableTopkey=3&componentType='
      // },
      {
        name: '外汇',
        img: wh,
        link: '/course/trade-personal/stock?componentName=ForeignExchangeMarket&pageName=ForeignExchange&activeTableftkey=1&activeTableTopkey=4&componentType='
      },
    ])
    return {
      list
    }
  }
}
</script>

<style lang="less" scoped>
.box-card {
  width: 100%;
  width: 360px;
  height: 290px !important;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(107,133,228,0.15);
  border-radius: 6px;
  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }
  &:deep(.el-card__body) {
    padding-top: 0px;
  }
  .personageContainer {
    display: flex;
    justify-content: space-around;
    .item {
      width: 90px;
      height: 212px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border: 1px solid rgba(255,114,99,0.2);
        
      background: linear-gradient(0deg, rgba(255,114,99,0.07) 0%, #FFFFFF 100%);
      a {
        display: block;
        background: #fee6b9;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 20px;
        img {
          width: 68px;
          height: 68px;
        }
      }
    }
    .item:nth-child(2){
      border: 2px solid #FFFFFF;
      background: linear-gradient(180deg, rgba(253,253,253,0) 0%, #F2DDFE 100%);
      border: 2px solid #FFFFFF;
      border-radius: 6px;
      border: 1px solid rgba(180,59,251,0.2);
    }
    .item:nth-child(3){
      background: linear-gradient(180deg, rgba(253,253,253,0) 0%, #F3F6FF 100%);
      border-radius: 6px;
      border: 2px solid #FFFFFF;
      border: 1px solid rgba(59,110,251,0.2);
    }
  }
}
</style>
