import {
  findTargetAmountByAcid
} from './tableApi'

//查看币种调整目标
export const transactionCurrency = async (data,type) => {
  let params = {
    acid: JSON.parse(sessionStorage.getItem('acid')),
    lastState: 1
  }
  let res = await findTargetAmountByAcid(params)
  let newRes = res.data
  if (newRes.code == 200) {
    let arr = JSON.parse(localStorage.getItem('CurrencyOptions'))
    let newalist = newRes.data.currencyTargetAmountPageInfos.list
    if (newalist.length < 1) {
      data.tableData = []
      return
    }
    newalist.forEach(every => {
      // every.directionName = every.direction == 0 ? '买' : '卖'
      arr.forEach(item => {
        if (item.value == every.currencyId) {
          every.name = item.label;
          every.abbr = item.abbr
          // console.log('itemitem', every);
        }
      })
    });
    
    data.tableData = newalist
    if (type == 'abbrPart') {
      console.log('data.tableData',data.tableData);
      data.options = JSON.parse(JSON.stringify(data.tableData))
      data.options2 = JSON.parse(JSON.stringify(data.tableData))
    }
    // data.tableData.forEach(item =>{
      
      data.abbrList = JSON.parse(JSON.stringify(data.tableData))   

      console.log('data.abbrList', data.abbrList);
  }
}