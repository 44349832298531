export const menuData = {
    menuList :[
        {
            name: '小组排名',
            router: '/groupManage/groupRank',
            key: 1,
        },
        {
            name: '优秀研报',
            router: '/groupManage/findReport',
            key:2,
        },
        {
            name: '大赛分组',
            router: '/groupManage/classGroup',
            key:3,
        },
        // {
        //     name: '模块编辑',
        //     router: '/groupManage/modelEdit',
        //     key:4,
        // },
        // {
        //     name: '指标展示',
        //     router: '/a',
        //     key:5,
        // },
        // {
        //     name: '作业评分',
        //     router: '/a',
        //     key:6,
        // },
        {
            name: '学生管理',
            router: '/groupManage/studentManage',
            key:7,
        },

    ],
    routerType: false,
    direction: true,
}