<template>
  <div class="container">
    <div style="min-width: 1100px;">
      <el-row>
        <el-col :span="9">
          <div class="left">
            <bond-sale-form @codeChange="codeChange" :price="price" @editPrice="editPrice" />
          </div>
        </el-col>
        <el-col :span="15">
          <div class="right">
            <trade-card :code="code" @clickPrice="clickPrice" :codeType="2"></trade-card>
          </div>
        </el-col>
      </el-row>
    </div>
    


  </div>
</template>

<script>
import { reactive, toRefs } from "vue"
import { bsaleData } from "../bondsdata"
import TradeCard from '@/components/TradeDealCard'
import BondSaleForm from '../BondSaleForm'
export default {
  name: 'bsale',
  components: {
    TradeCard,
    BondSaleForm
  },
  setup() {
    const data = reactive({
      price: '',
      code: 'sh127627',
      codeList: 'sh127627',
    })

    const clickPrice = (val) => {
      data.price = val
    }
    let codeChange = (val) => {
      data.code = val
    }
    let editPrice = (val) => {
      data.price = val
    }
    return {
      clickPrice,
      editPrice,
      codeChange,
      ...toRefs(data)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;

  .left {
    min-width: 280PX;
    margin: 10px 20px;
    min-height: 40vh;
    overflow: auto;
    padding: 30px 13%;
    background: #F4F4FA;
    color: #65676B;
    font-size: 14px
  }

  .right {
    width: 100%;
    margin: 10px 20px;
  }
}</style>
