import {
  delTimeTurn,
  findAllTimrTurn,
  addTimeTurn,
  updTimeTurn,
  findById,
} from "./timeApi";
import { ElMessage } from "element-plus";
import { ElMessageBox } from "element-plus";
import dayjs from "dayjs";
import { dealDate } from "../../course-manage/upreport-manage/Upreport-State/startMethods";

// 查询
export const getUserLists = (data) => {
  data.loading = true;
  data.showDate2 = false;
  let parame = {
    courseId: sessionStorage.getItem("classId"),
    pageSize: 1000,
    page: 1,
  };
  findAllTimrTurn(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.pageInfo.list;
      // data.total = newRes.pageInfo.total
      dealDate(data.spreadTable, ["startTime", "endTime"]);
      if (data.spreadTable.length > 0) {
        let time = data.spreadTable[data.spreadTable.length - 1].endTime;
        data.date = new Date(time).getTime() + 86400000;
        data.num = data.spreadTable.length + 1;
        data.dateRange = data.date;
        let defaultDate = dayjs(data.date).format("YYYYMM");
        data.yearNew = parseInt(defaultDate.slice(0, 4));
        data.dayNew = parseInt(defaultDate.slice(4));
        data.showDate2 = true;
        data.showDate = true;
      } else {
        let timestamp = Date.parse(new Date());
        let defaultDate = dayjs(timestamp).format("YYYYMM");
        data.yearNew = parseInt(defaultDate.slice(0, 4));
        data.dayNew = parseInt(defaultDate.slice(4));
        data.showDate2 = true;
        data.showDate = false;
      }
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

// 删除 周次管理
export const delDrafrFiles = (item, data) => {
  let parame = {
    timeRoundId: item.timeRoundId,
  };
  delTimeTurn(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("是否确定删除该轮次?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "编辑") {
    dealRole(val.item, data);
  }
};

// 添加周次管理
export const addTimes = (data) => {
  if (data.dateRange === "" || data.dateRange2 === "")
    return ElMessage.warning("请输入时间轮次区间");
  //如需添加时分秒，放开此处,下边时间判断删掉
  // data.startTime = data.showDate
  //   ? dayjs(data.dateRange).format("YYYY-MM-DD") + " 00:00:00"
  //   : dayjs(Date.parse(data.dateRange)).format("YYYY-MM-DD") + " 00:00:00";
  // data.endTime = dayjs(Date.parse(data.dateRange2)).format("YYYY-MM-DD") + " 23:59:59";
  // let startTime = dayjs(data.startTime).valueOf();
  // let endTime = dayjs(data.endTime).valueOf();
  // if (endTime < startTime) {
  //   return ElMessage.warning("开始时间不能大于结束时间");
  // }
  data.startTime = data.showDate
    ? dayjs(data.dateRange).format("YYYYMMDD")
    : dayjs(Date.parse(data.dateRange)).format("YYYYMMDD");
  data.endTime = dayjs(Date.parse(data.dateRange2)).format("YYYYMMDD");
  if (data.endTime < data.startTime) {
    return ElMessage.warning("开始时间不能大于结束时间");
  }
  let obj = {
    classId: sessionStorage.getItem("classId"),
  };
  findById(obj).then((res) => {
    if (res.data.code === "200") {
      let classEndTime = dayjs(res.data.data.endTime).format("YYYY-MM-DD") + " 23:59:59";
      let weekEndTime = dayjs(Date.parse(data.dateRange2)).format("YYYY-MM-DD") + " 23:59:59";
      classEndTime = dayjs(classEndTime).valueOf();
      weekEndTime = dayjs(weekEndTime).valueOf();
      if (classEndTime < weekEndTime) {
        return ElMessage.warning("周次结束时间不能大于课程结束时间");
      } else {
        let parame = {
          timeRound: {
            courseId: sessionStorage.getItem("classId"),
            createUser: JSON.parse(sessionStorage.getItem("user")).username,
            currentWeek: "第" + toChinesNum(data.num) + "周",
            startTime: data.startTime,
            endTime: data.endTime,
          },
        };
        addTimeTurn(parame).then((res) => {
          if (res.data.code === "200") {
            ElMessage.success(res.data.msg);
            backInfo(data);
            getUserLists(data);
          } else {
            ElMessage.error(res.data.msg);
          }
        });
      }
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

// 处理数字
export const toChinesNum = (num) => {
  var arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var arr2 = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
  ]; //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return "零";
  }
  var english = num.toString().split("");
  var result = "";
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; //倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十");
  //合并中间多个零为一个零
  result = result.replace(/零+/g, "零");
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万");
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, "亿");
  //移除末尾的零
  result = result.replace(/零+$/, "");
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, "十");
  return result;
};

// 取消修改
export const backInfo = (data) => {
  data.num = 1;
  data.dateRange = "";
  data.dateRange2 = "";
};
