import {
  findForexFlowFunds,
  findCurrencyType
} from './ForeignExchangeCapitalFlowApi'
import dayjs from "dayjs";
import {
  ElMessage
} from 'element-plus';
import {
  ergodic
} from '../commonMethods'
import {
  thousandthConversion
} from '../commonMethods'
// 查询已委托的外汇订单
export const entrustedOrders = async (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    pageIndex: data.currentPage,
    pageSize: data.pageSize
  }
  let res = await findForexFlowFunds(params)
  let newRes = res.data
  if (newRes.code == 200) {
    data.total = newRes.data.total
    if (newRes.code == 200) {
      newRes.data.forexFlowFunds.forEach(item => {
        item.currencyAmounts = thousandthConversion(item.currencyAmount)
        item.currencyBalances = thousandthConversion(item.currencyBalance)
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
      });
      data.forexOrderVOList = newRes.data.forexFlowFunds
    }
  }


}

//查询货币
export const findCurrency = async (data) => {
  let res = await findCurrencyType()
  let newRes = res.data
  if (newRes.code == 200) {
    data.currencyData = newRes.data.currencyData
  }
}

// 查询历史
export const transactionFundss = async (data) => {
  let startTime = "";
  let endTime = "";
  if (data.historicalTime != null) {
    if (
      data.historicalTime[0] &&
      data.historicalTime[0] != null &&
      data.historicalTime[0] != undefined
    ) {
      startTime = data.historicalTime[0];
      endTime = data.historicalTime[1].substring(0, 11) + "23:59:59";
    }
  }
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    currency: data.account,
    pageIndex: data.currentPage,
    pageSize: data.pageSize,
    startTime: startTime,
    endTime: endTime
  }

  let res = await findForexFlowFunds(params)
  let newRes = res.data
  if (newRes.code == 200) {
    newRes.data.forexFlowFunds.forEach(item => {
      item.currencyAmounts = thousandthConversion(item.currencyAmount)
      item.currencyBalances = thousandthConversion(item.currencyBalance)
    })
    data.forexOrderVOList = newRes.data.forexFlowFunds
    data.total = newRes.data.total

    ergodic(data)
  }

}