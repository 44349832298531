<template>
  <el-card class="box-card notice" style="overflow: auto">
    <template #header>
      <div class="card-header">
        <span>公告</span>
        <div class="more" @click="moreRou">
          更多
        </div>
      </div>
    </template>
    <div class="nociteContainer">
      <div v-for="(item, index) of spreadTable" @click="checkNotice(item)" class="main">
        <div class="item" :key="index" style="line-height: 20px">
          <div class="date">
           <span class="moon">{{  item.releaseTime.substring(5, 10).replace("-",".") }}</span>
           <span class="year">{{  item.releaseTime.substring(0, 4) }}</span>
          </div>
          <div class="line"></div>
          <div class="content"> {{ item.announcementName }}</div>
          <!-- <div class="date">{{ item.releaseTime.substring(0, 10) }}</div> -->
          <!-- <div class="desc">
            <b> <span style="margin-left: 10px"> {{ item.announcementName }}</span></b>
          </div>
          <b>
            <div class="name">{{ item.publisher }}</div>
          </b>
          <b>
            <div class="date">{{ item.releaseTime.substring(0, 10) }}</div>
          </b> -->
        </div>
        <!-- <el-divider ></el-divider> -->
      </div>
    </div>
  </el-card>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { announcements } from "../../views/system-manage/notice-manage/noticeApi";
import { dealDate } from "../../views/course-manage/trade-manage/tradeMethods";
import { useRouter } from "vue-router";
export default {
  name: "noticeCard",
  setup() {
    const data = reactive({
      spreadTable: []
    });
    onMounted(() => {
      getUserList()
    })
    let router = useRouter()

    let moreRou = () => {
      router.push({ path: '/index/moreNotice' })
    }
    let getUserList = () => {
      let parame = {
        "schoolId": JSON.parse(sessionStorage.getItem('user')).schoolId,
        pageSize: 5,
        pageIndex: 1,
      }
      announcements(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          //原本后端数据 将   announcementPageInfo  更改为  announcementVOList
          // data.spreadTable = newRes.announcementPageInfo.list
          data.spreadTable = newRes.announcementVOList
          dealDate(data.spreadTable, ['releaseTime'])
        }
      })
    }
    let checkNotice = (item) => {
      router.push({ path: '/index/checkNotice', query: { id: item.id } })
    }
    return {
      ...toRefs(data),
      moreRou, checkNotice
    };
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 100%;
 
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(107,133,228,0.15);
  border-radius: 6px;
  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }
  &:deep(.el-card__body) {
    padding-top: 0px;
    height: 214px ;
    overflow: auto;
  }
}
.nociteContainer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: #FFFFFF;
  .main{
    width:48%;
  }
  .item {
    display: flex;
    width: 100%;
    height: 96px;
    background: #F8F8F8;
    border-radius: 6px;
    margin-bottom:20px;
    padding:26px 20px;
    color:#757594 ;
    box-sizing: border-box;
    justify-content: start;
    .date{
       display: flex;
       flex-direction: column;
      
       .moon{
        font-size: 24px;
        font-family: Arial-Black, Arial;
        font-weight: 900;
        line-height: 24px;
        margin-bottom:5px;
       }
       .year{
        font-size: 16px;
        font-family: Arial-Black, Arial;
        font-weight: 900;
        line-height: 16px;
       }

    }
    .line{
       width: 0px;
       height: 47px;
       border: 1px solid #D5D7E6;
       margin:0px 20px;
    }
    .content{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      // color: #3B6EFB;
      line-height: 28px;
    }
  //   .desc {
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     width: 380px;
  //     cursor: pointer;
  //     a {
  //       color: #707070;
  //     }
  //   }
  //   display: flex;
  //   color: #707070;
  //   justify-content: space-between;
  //   margin-bottom: 5px;
  }
  .item:hover{
    color:#3B6EFB;
  }
}
/*滚动条的宽度*/
.notice::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/*滚动条滑块*/
.notice::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
.card-header {
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 30px;
  // margin-top: -18px;
  // padding-top: 20px;
  .more{
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B6EFB;
    line-height: 26px;
    width: 68px;
    height: 28px;
    text-align: center;
    background: #F9FAFF;
    border-radius: 4px;
    border: 1px solid #3B6EFB;
    cursor: pointer;
  }
 
}

.name {
  margin-left: -150px;
}
</style>
