<template>
  <!-- 外汇个人交易-账户信息 -->

  <el-row :gutter="24">
    <el-col :span="24">
      <div style="text-align: center;margin-bottom: 30px;">
        <el-tooltip  effect="dark" content="全部换算成人民币" placement="top">
          <i style="color: #3B6EFB" class="el-icon-warning"></i>
        </el-tooltip>
        <span class="account" style="margin-right: 100px">账户总额：&nbsp &nbsp {{ cnyTotalAmount }} 人民币</span>
        <el-tooltip  effect="dark" content="全部换算成美元" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>
        <span class="account" style="">账户总额：&nbsp &nbsp {{ usdTotalAmount }} 美元</span>
      </div>
      
    </el-col>
    <el-col :span="12">
      
      <div shadow="always" style="margin-left: 20px;">
        <div v-loading="loading">
          <el-table :data="userAccountAmount" border :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
            <el-table-column prop="currencyType" label="币种类型" />
            <el-table-column prop="amountss" label="总资产" />
            <el-table-column prop="freeAmounts" label="空闲资产" />
            <el-table-column prop="frozenAmounts" label="冻结资金" />
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="box-card">
        <div style="margin-bottom: 10px"><i class="card-line"></i>净值图</div>
        <span v-if="option">
          <ECharts :heightCharts="heightCharts" :option="option"></ECharts>
        </span>
        <span v-else>
          <el-empty description="暂无数据" />
        </span>
      </div>
    </el-col>

  </el-row>
</template>
<script>
import { reactive, toRefs, onMounted, ref, defineComponent } from 'vue';
import ECharts from '@/components/Echarts'
import { information, balanceConversion, netWorthChart } from './AccountInformationMethods'

export default defineComponent({
  components: {
    ECharts,
  },
  setup() {
    onMounted(() => {
      information(data),
        balanceConversion(data)
      netWorthChart(data)
    })

    let data = reactive({
      cnyTotalAmount: '',
      usdTotalAmount: '',
      loading: true,
      option: {},
      heightCharts: 480,
      userAccountAmount: [],
      currencyData: [],
    })
    return {
      ...toRefs(data),
    }
  }
})
</script>
<style lang="less" scoped >
.account {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  margin-left: 8px;
}
</style>