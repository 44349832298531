<template>
  <div class="container">
    <div class="item">
      选择日期：<el-date-picker style="margin-right:10px;" size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" size="mini" style="margin-right:10px;" icon="el-icon-search">查询</el-button>
      <ExportButton @exportTimeChange="exportTimeChange" v-if="type === '1'" />
    </div>
    <CommonTable :colums="colums" :tableData="tableData" :paginationConfig="paginationConfig" @handleChange="handleChange" />
  </div>
</template>

<script>
import { Data, HistoryData } from "./index"
import { reactive, toRefs, watch } from "vue"
import CommonTable from "../Table"
import ExportButton from "@/components/Export/index.vue"
import { JudgmentHistoryCapital } from "../tools.js"
export default {
  name: 'HistoryTransaction',
  components: {
    CommonTable,
    ExportButton
  },
  props: {
    type: { type: String },
    componentType: { type: String }
  },
  setup(props) {
    const { type, componentType } = props
    const data = reactive(HistoryData)

    watch([() => props.type, () => props.componentType], ([newType, newComponentType], [prvnewType, prenewComponentType]) => {
      const { result } = JudgmentHistoryCapital(newType, newComponentType, Data)
      data.colums = result

      data.paginationConfig.page = 1

    }, { immediate: true })



    const handleChange = (key, val) => {
      data.paginationConfig[key] = val;
    };

    const exportTimeChange = (dateArr) => {}


    return {
      handleChange,
      exportTimeChange,
      ...toRefs(data)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
