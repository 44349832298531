import axios from '@/axios/globalaxios'

// 查询当前周次
export const findTimeRoundByCourseId = data =>{
    return axios({
        url: "/tradelabLx/timeRound/findTimeRoundByCourseId",
        method: 'post',
        data:data
    })
}

