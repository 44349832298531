<template>
  <div>
    <mini-tag :tagList="tagList" @tagChange="tagChange"></mini-tag>
    <div class="box-main">
      <div class="box-card">
        <spread-table :smallType="true" v-loading="loading" :pageSize="pageSize" :currentPage="currentPage" :total="total"
          @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="tagName == '外汇' ? whtableList : tableList"
          :tableData="tagName == '外汇' ? orderinformation : spreadTable"></spread-table>
      </div>
      <div class="main" style="margin-top: 20px">
        <div class="box-card main-card">
          <div style="margin: 10px" class='box-title'>
            指标
            <!-- 下拉选择 -->
            <el-select v-model="activeName" placeholder="请选择币种！" @change="handleClick" size="mini" style="float: right"
              v-if="tagName == '外汇'">
              <el-option v-for="items in currencyOptions" :label="items.label" :value="items.value" />
            </el-select>
          </div>
          <div v-if="tagName !== '外汇'">
            <el-row :gutter="20" class="row-norm" v-for="(item, index) in normIndex" :key="index">
              <el-col class="title-norm" :span="10">{{ item }}</el-col>
              <el-col class="value-norm" :span="14">{{ normItem[index] }}</el-col>
            </el-row>
          </div>
          <div style="padding: 10px;" v-loading="loading" v-if="tagName == '外汇'">
            <el-row :gutter="20" class="row-norm">
              <el-col class="title-norm" :span="10">夏普44比率:</el-col>
              <el-col class="value-norm" :span="14">{{ tabForm.sharpeRatio }}</el-col>
            </el-row>
            <el-row :gutter="20" class="row-norm">
              <el-col class="title-norm" :span="10">詹森测度α:</el-col>
              <el-col class="value-norm" :span="14">{{ tabForm.jensenMeasure }}</el-col>
            </el-row>
            <el-row :gutter="20" class="row-norm">
              <el-col class="title-norm" :span="10">特雷诺比率:</el-col>
              <el-col class="value-norm" :span="14">{{ tabForm.tenorRatio }}</el-col>
            </el-row>
            <el-row :gutter="20" class="row-norm">
              <el-col class="title-norm" :span="10">信息比率:</el-col>
              <el-col class="value-norm" :span="14">{{ tabForm.informationRatio }}</el-col>
            </el-row>
          </div>
        </div>
        <div class="box-card asset-trends">
          <div style="margin: 10px" class='box-title'>资产走势</div>
          <!--          <mini-tag :tagList="indicatorList" @tagChange="tagChange"></mini-tag>-->
          <div style="margin: 10px">
            <e-charts :heightCharts="heightCharts" :option="option"></e-charts>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>



import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import SpreadTable from '@/components/SpreadTable'
import MiniTag from '@/components/MiniTag'
import { groupData } from './groupData'
import ECharts from '@/components/Echarts'
import {
  tagChanges, getGroupTables, trendCharts, groupIndexs, sizeChanges, currentChanges,
  foreignExchangeOrder, foreignExchangeIndicators, obtainAssetTrend,
} from './groupMethods'
import axios from "@/axios/globalaxios";
import { left } from '@popperjs/core'

export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    MiniTag,
    ECharts,
  },
  setup() {
    let data = reactive({
      currencyOptions: [],
      activeName: '0',
      tabForm: {},
      loading: true,
      // table 属性
      tableList: [
        { name: '证券代码', text: 'marketSID' },
        { name: '简称', text: 'name' },
        // { name: '买卖方向', text: 'direction' },
        { name: '持仓数量', text: 'amount' },
        { name: '可用数量', text: 'availableAmount' },
        { name: '冻结数量', text: 'frozenAmount' },
        { name: '成本价', text: 'averageOpenPrice' },
        { name: '市价', text: 'currentPrice' },
        { name: '市值', text: 'marketValue' },
        { name: '浮动盈亏', text: 'floatingPL' },
        // { name: '昨结', text: 'yesterdaySettlement' },
        { name: '保证金', text: 'margin' },
        // {name:'盈亏比例',text: 'profitAndLossRatio'},
        { name: '交易市场', text: 'marketPlace' },
      ],
      whtableList: [
        { name: '交易时间', text: 'createTime' },
        { name: '币种类型', text: 'buyerCurrency' },
        { name: '购入金额', text: 'purchasingAmount' },
        { name: '交易汇率', text: 'exchangeRate' },
        { name: '订单状态', text: 'orderState' },
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      loading: true,
      tagName: '',
      spreadTable: [],
      balance: [],//货币
      currencyData: [],//货币类型
      orderinformation: [],// 订单信息

      // tab 属性
      tagType: 1,
      pageIndex: 1,
      pageSize: 10,
      normIndex: ['夏普比率:', '詹森测度α:', '特雷诺比率:', '信息比率:'],
      normItem: [],
      //groupData数据与此冲突，已注掉
      tagList: [
        { name: '股票', key: 1, type: 'groupTag', router: '/course/trade/1', },
        { name: '债券', key: 2, type: 'groupTag', router: '/course/trade/2', },
        { name: '期货', key: 3, type: 'groupTag', router: '/course/trade/3', },
        // 外汇table切换暂时不用
        { name: '外汇', key: 4, type: 'groupTag', router: '/course-manage/table', },
      ],
      indicatorList: [{ name: '股票', key: 0, type: 'indicatorTag' }, { name: '期货', key: 1, type: 'indicatorTag' }, { name: '债券', key: 2, type: 'indicatorTag' },
      { name: '总计', key: 3, type: 'indicatorTag' }],
      // charts 属性
      option: {},
      heightCharts: 300,
    })
    onMounted(() => {
      data.currencyOptions = JSON.parse(localStorage.getItem('CurrencyOptions'))
      if (JSON.parse(sessionStorage.getItem('courseType')) !== 99) {
        // let newList = data.tagList[data.tagType - 1]
        // console.log(newList, 'newListnewListnewList');
        data.tagType = JSON.parse(sessionStorage.getItem('courseType'))
        changeRoleList()
        //key值变了会影响传参，此处逻辑不通，先注掉
        // newList.key = 0
        // data.tagList = [newList]
      }

      groupIndex()
      newObtainAssetTrend()
    })
    let tagChange = (value) => {
      tagChanges(value, data)
      data.tagName = value.name
      newObtainAssetTrend()
    }

    let handleClick = () => {
      obtainAssetTrend(data)
      foreignExchangeIndicators(data)
    }
    let newObtainAssetTrend = () => {
      if (data.tagName == '外汇') {
        obtainAssetTrend(data)
        foreignExchangeIndicators(data)
        foreignExchangeOrder(data)
      } else {
        trendChart()
      }
    }
    let getGroupTable = (item) => {
      data.tagType = 1
      getGroupTables(data, item)
    }  //  获取table数据
    let trendChart = () => { trendCharts(data) }  //  资产走势
    let groupIndex = () => { groupIndexs(data) }  //  指标
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let abc = () => {
      axios.post('/tradelabLx/competition/findKeyRatios', {
        "competitionId": "50000000450000",
        "matchType": 0,
        "isExport": true,
        "pageIndex": 1,
        "pageSize": 10
      }, { responseType: 'blob' }
      ).then(res => {
        let FileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1])
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          navigator.msSaveBlob(blob, FileName)
        } else {
          const elink = document.createElement('a');
          elink.download = FileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      }).catch(err => { })
    }
    let changeRoleList = () => {

      // 股票 courseType=1时只显示 股票和盘后申报
      if (data.tagType === 1) {
        data.tagList = filterList(data.tagList, ['/course/trade/2', '/course-manage/table', '/course/trade/3'])  //   暂时注释外汇需要时再加上
      }
      // 债券 courseType=1时只显示 债券和盘后申报
      if (data.tagType === 2) {
        data.tagList = filterList(data.tagList, ['/course/trade/1', '/course-manage/table', '/course/trade/3'])  //  '/course-manage/table',   暂时注释外汇需要时再加上
      }
      // 期货
      if (data.tagType === 3) {
        data.tagList = filterList(data.tagList, ['/course/trade/1', '/course/trade/2', '/course-manage/table', '/course/trade/declare'])  //  '/course-manage/table',   暂时注释外汇需要时再加上
      }

      // 外汇
      if (data.tagType === 6) {
        data.tagList = filterList(data.tagList, ['/course/trade/1', '/course/trade/2', '/course/trade/3'])
      }

      // 股票&债券
      if (data.tagType === 5) {
        data.tagList = filterList(data.tagList, ['/course/trade/3', '/course-manage/table']) //  '/course-manage/table' 暂时注释外汇需要时再加上
      }

      // 股票&外汇
      if (data.tagType === 7) {
        data.tagList = filterList(data.tagList, ['/course/trade/2', '/course/trade/3'])
      }

      // 外汇&债券
      if (data.tagType === 8) {
        data.tagList = filterList(data.tagList, ['/course/trade/1', '/course/trade/3'])
      }

      // 外汇&期货
      if (data.tagType === 9) {
        data.tagList = filterList(data.tagList, ['/course/trade/1', '/course/trade/2'])
      }

      // 股票、债券、期货
      if (data.tagType === 4) {
        data.tagList = filterList(data.tagList, ['/course-manage/table'])     //  '/course-manage/table'   暂时注释外汇需要时再加上
      }

      // 外汇、股票、债券
      if (data.tagType === 10) {
        data.tagList = filterList(data.tagList, ['/course/trade/3'])
      }

      // 外汇、股票、期货
      if (data.tagType === 11) {
        data.tagList = filterList(data.tagList, ['/course/trade/2'])
      }

      // 外汇、债券、期货
      if (data.tagType === 12) {
        data.tagList = filterList(data.tagList, ['/course/trade/1'])
      }

      // 股票、债券、期货、外汇
      if (data.tagType === 13) {
        data.tagList = filterList(data.tagList, ['/course/trade/declare'])
      }
      getGroupTable(data.tagList[0])
    }
    let filterList = (list, courseList) => {

      let newlist = list.filter((item) => {
        if (!courseList.includes(item.router)) {
          return item
        }
      })
      return newlist

    }
    return {
      ...toRefs(data),
      tagChange,
      sizeChange,
      currentChange,
      abc,
      filterList,
      changeRoleList,
      handleClick,
      newObtainAssetTrend
    }
  }
})
</script>

<style scoped lang="less">
.main {
  display: flex;
}

.box-card {
  /*height: 500px;*/
  max-height: 450px;

  .row-norm {
    line-height: 50px;
    padding: 0 30px;
    font-size: 14px;
    font-family: ArialMT;
    color: #03032C;
    line-height: 60px;

    .title-norm {
      color: #000;
      text-align: left;
      font-size: 13px;
    }

    .value-norm {
      text-align: right;
      font-size: 14px;
    }
  }

  .row-norm:nth-child(odd) {
    background: #FFFFFF;
    background: rgba(255, 255, 255, 0.6);
  }
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

.box-main {
  height: calc(100vh - 370px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.main-card {
  width: 440px;
  background: #F4F4FA;
  border-radius: 6px;
  box-sizing: border-box;
  padding-top: 10px;

  .box-title {
    padding-left: 10px;
    margin-bottom: 20px !important;

  }

  .target-box {
    font-size: 14px;
    font-family: ArialMT;
    color: #03032C;
    line-height: 60px;

    div {
      padding: 0 30px;
    }

    div:nth-child(odd) {
      background: #FFFFFF;
      background: rgba(255, 255, 255, 0.6);

    }
  }

}

.asset-trends {
  margin-left: 20px;
  flex: 1;
  background: #FFFFFF;
  border-radius: 0px 6px 6px 0px;
  border: 3px solid #F4F4FA;

}</style>
