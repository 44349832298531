
<template>
  <el-button v-if="data.labelName" 
      style="float: right;margin-top:15px;z-index: 2;position: absolute;right:60px;" 
      size="mini" 
      :type=" data.status == '审批通过' ? 'warning' : 'info' " 
      :disabled=" data.status == '审批通过' ? false : true " 
      @click="clickBtn"
      >
      {{  data.labelName }}
    </el-button>
  <el-tabs :tab-position="top"  class="demo-tabs" @tab-click="handleClick">
    <el-tab-pane label="申请">
      <div class="demo-main" > 
      <FundApply @getFundId="getFundId"  @getValue = "getValue"  ref="fundApply"></FundApply>
    </div>
    </el-tab-pane>
  <!--    <el-tab-pane label="详情">
        <FundDetails></FundDetails>
                </el-tab-pane> -->
    <el-tab-pane label="管理费">
      <div class="demo-main" >
      <FundExpense   ref="fundExpense"></FundExpense>
    </div>
    </el-tab-pane>
    <el-tab-pane label="基金详情">
        <!-- 交易规则 -->
        <div class="demo-main" >
        <FundInternalDetails   ref="detaile" :fundId="data.fundId"></FundInternalDetails>
      </div>
    </el-tab-pane>
  </el-tabs>

</template>
<script setup >
import FundApply from './fundApply/index.vue'//申请
import FundDetails from './fundDetails/index.vue'//详情
import FundExpense from './fundExpense/index.vue'//管理费
import FundInternalDetails from './fundInternalDetails/index.vue'//交易规则
// import TrendChart from '@/components/TrendChart/index.vue' //走势图
// import FundInvestor from '@/views/trade-personal/components/FundInvestor/index.vue'
import { top } from '@popperjs/core';
import { onActivated, onMounted, ref, reactive } from 'vue'
//定义子组件实例，名称要和上面的ref相同
const fundExpense = ref(null);
const fundApply=ref(null)
const detaile = ref(null);
let fundId = ref(null);
let data = reactive({
  fundId:0,
  labelName: '清算',
  status:''
}) 
// onMounted(()=>{
//   getFundId(data.fundId)
// })

const getFundId = (e) => {
  data.fundId = e;
};
const clickBtn =()=>{
  if(data.labelName==='交易划转'){
    fundExpense.value.clickManualStroke();
  }else if(data.labelName==='清算'){
    fundApply.value.qs();
  }
}
const getValue = (e) => {
  data.status=e
 }
function handleClick(tab, event) {
  data.labelName=tab.props.label==='申请'?'清算':''
  
  if (tab.props.label == '管理费') {
    data.labelName='交易划转';
    console.log('9999',tab.props.label,data.labelName)
    fundExpense.value.manageFee();
  }else if(tab.props.label == '基金详情'){
	  if(fundId != undefined || fundId != null ){
		  detaile.value.marketQuotations(data.fundId);
		  detaile.value.listPl(data.fundId);
		  detaile.value.getFundInfo1(data.fundId);
	  }
  }
}

</script>
<style scoped lang="less">
:deep(.el-tabs__header){
  padding-bottom:10px;
  border-bottom:1px solid #D5D7E6;
}
.demo-tabs {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;

}
.demo-main{
  height: calc(100vh - 445px);
  overflow: auto;
    padding-bottom:50px;
    padding-right: 10px;
  }
.el-tabs__content {
  height: auto;
  /* background-color: #6b778c; */
}

.el-tabs__item {
  width: 100px;
  height: 50px;
  line-height: 50px;
}
:deep(.el-tabs__nav-wrap::after){
  display: none !important;
}
:deep(.el-tabs__active-bar){
  display: none !important;
}
:deep(.el-tabs__item.is-active){
  color:#3B6EFB;
}
:deep(.el-tabs__item):hover{
  color:#3B6EFB;
}
</style>
