import axios from '@/axios/globalaxios'

export const sysRoles = data =>{
    return axios({
        url: "/tradelabLx/sysRole/sysRoles",
        method: 'post',
        data:data
    })
}

export const delDraftBox = data =>{
    return axios({
        url: "/tradelabLx/tFundFundfiles/delDraftBox",
        method: 'post',
        data:data
    })
}



