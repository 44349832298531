import axios from '@/axios/globalaxios'

// 查找首页数据
export const findData = data =>{
    return axios({
        url: "/tradelabLx/systemHome/data",
        method: 'post',
        data:data
    })
}
// 查找收益率数据
export const findRateOfReturn = data =>{
    return axios({
        url: "/tradelabLx/transaction/findRateOfReturn",
        method: 'post',
        data:data
    })
}

