<template>
  <div class="grop">
    <!--    <el-card class="box-card">-->
    <!--      <div style="margin-bottom: 10px"><i class="card-line"></i>本基金现有成员</div>-->
    <!--      <spread-table  v-loading="loading" :tableHeight="tableHeight" :operation="operation"-->
    <!--                     :pageSize="pageSize" :currentPage="currentPage" :total="total"-->
    <!--                     @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton"-->
    <!--                     :tableColumn="peopleList" :tableData="peopleData"></spread-table>-->
    <!--    </el-card>-->
    <el-card class="box-card" style="margin-top: 10px">
      <div style="margin-bottom: 10px"><i class="card-line"></i>职务变更记录</div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="peopleList" :tableData="peopleData"></spread-table>
    </el-card>

  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { jobChangeData } from "./jobChangeData";
import { sizeChanges, currentChanges, getUserLists } from './jobChangeMethods'

export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let data = reactive(jobChangeData);
    onMounted(() => {
      getUserList()
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
    }

  }
})
</script>

<style scoped lang="less">
.grop {
  height: calc(100vh - 330px);
  overflow: auto;
  .card-line {
    display: inline-block;
    width: 3px;
    height: 13px;
    border-radius: 8px;
    margin-right: 10px;
    background: #faad14;
  }
}
.report-title {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
</style>