import axios from '@/axios/globalaxios'


//
export const findEvenWeeksByCourseId = data =>{
    return axios({
        url: "/tradelabLx/systemRating/findEvenWeeksByCourseId",
        method: 'post',
        data:data
    })
}

export const findScoreByCourseIdOrAcidAndUserId = data =>{
    return axios({
        url: "/tradelabLx/systemRating/findScoreByCourseIdOrAcidAndUserId",
        method: 'post',
        data:data
    })
}

// 评分按钮
export const calScoreByCourseId = data =>{
    return axios({
        url: "/tradelabLx/systemRating/calScoreByCourseId",
        method: 'post',
        data:data
    })
}
// 导出报表
export const exportReport = data =>{
    return axios({
        url: "/tradelabLx/systemRating/exportScore",
        method: 'post',
        data:data,
        responseType: "blob",
    })
}







