export const noticeData = {
    drafrList : [
        {name:'学校名称',text: 'schoolName'},
        {name:'浏览次数',text: 'views'},
        {name:'公告名称',text: 'announcementName'},
        {name:'发布人',text: 'publisher'},
        {name:'发布时间',text: 'releaseTime'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['编辑','删除'],
    pageType: 1,
    filetype: null,

    userInput: ''
}