import axios from '@/axios/globalaxios'

// 查询班级下的学生
export const findAllTimrTurn = data =>{
    return axios({
        url: "/tradelabLx/timeRound/findAll",
        method: 'post',
        data:data
    })
}
// 添加周次管理
export const addTimeTurn = data =>{
    return axios({
        url: "/tradelabLx/timeRound/add",
        method: 'post',
        data:data
    })
}
// 修改周次管理
export const updTimeTurn = data =>{
    return axios({
        url: "/tradelabLx/timeRound/upd",
        method: 'post',
        data:data
    })
}
// 删除周次管理
export const delTimeTurn = data =>{
    return axios({
        url: "/tradelabLx/timeRound/del",
        method: 'post',
        data:data
    })
}

//查询课程信息
export const findById = data =>{
    return axios({
      url: "/tradelabLx/course/findById",
      method: "post",
      data: data,
    });
}


