<template>
  <div class="rank">
    <tab-table
      :columns="columns"
      :findApi="findRanKing"
      :exportApi="exportRanking"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { findRanKing, exportRanking,getAcidByclassID,getCompGroupId} from "../apis";
import { rankData } from "../datas";

export default defineComponent({
  name: "rank",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(rankData)
    onMounted(() => {
		getCompAcid();
    })
	
    let getCompAcid = () => {
		//如果角色是老师 不查询
		if(JSON.parse(sessionStorage.getItem("user")).userRoleId != 50000000080000){
			const comp = JSON.parse(sessionStorage.getItem('mega'));
			//0是单人
			if(comp.matchType == 0){
				var parame ={
				  username:JSON.parse(sessionStorage.getItem("user")).username,
				  classId:comp.id,
				  acidType:3
				};
				getAcidByclassID(parame).then(res => {
				    sessionStorage.setItem('acid',res.data.data)
				})
			}else{
				var p = {
					userId:JSON.parse(sessionStorage.getItem("user")).userId,
					compId:comp.id
				};
				getCompGroupId(p).then(res => {
					var parame ={
					  compGroupID:res.data.data,
					  classId:comp.id,
					  acidType:2
					};
					getAcidByclassID(parame).then(res => {
					    sessionStorage.setItem('acid',res.data.data)
					})
				})
			}
		}
    }
    return{
      ...toRefs(data),
      findRanKing,
      exportRanking,
    }
  }
})
</script>

<style scoped lang="less">
.rank {
  margin-top: 60px;
}
</style>