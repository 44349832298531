<template>
  <div class="box-card my-courses" shadow="hover" :class="{'courses-have-inh-and':info.status==='已结束'||info.status==='已关闭'}" @click="setting(activeTab === '0' ? type : '详情', info)">
    <div class="title">{{info.name}}</div>
    <div class="number-of-people"> <strong>{{ info.number }}</strong> <span>人参加</span></div>
    <div class="courseContainer">
        <div class="item">
           <div class="label">报名日期：</div>
           <div class="props"> {{ dayjs(info.enrollStartTime).format('YYYY-MM-DD') }} 至 {{ dayjs(info.enrollEndTime).format('YYYY-MM-DD') }}</div>
        </div>
        <div class="item">
            <div class="label">比赛日期：</div>
            <div class="props">{{ dayjs(info.matchStartTime).format('YYYY-MM-DD') }} 至 {{ dayjs(info.matchEndTime).format('YYYY-MM-DD') }}</div>
        </div>
        </div>
    <div class="courseContainer">
      <div class="specific">
      </div>
      <div class="set">
        <span  class="status" :class="{'completed':info.status==='已结束'||info.status==='已关闭','have-inh-and':info.status==='进行中'}">{{ info.status }}</span>
        <el-button type="text" class="button" @click.stop="setting(type, info)">{{ type }}</el-button>
        <template  v-if="activeTab=='1' && type=='设置' && info.status !== '已关闭' && info.status !== '进行中'">
        <el-popover
          placement="top"
          :width="160"
          v-model:visible="visible"
          trigger="click"
        >
          <p>是否确认关闭此大赛？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click.stop="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click.stop="setting('关闭', info)">确定</el-button>
          </div>
          <template #reference>
            <el-button type="text" class="button" style="color: #f56c6c">关闭</el-button>
          </template>
        </el-popover>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { ref } from "vue";
export default {
  name: "banner",
  props: {
    info: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '0',
    }
  },
  setup(props, { emit }) {
    const setting = (type, info) => {
      emit("setting", type, info)
    };
    return {
      setting,
      dayjs,
      visible: ref(false)
    };
  },
};
</script>

<style lang="less" scoped>
  .my-courses{
    cursor: pointer;
    padding:25px 20px;
    background: url('../../assets/course-not-started.png');
    margin-bottom:25px;
    background-size: 100% 100%;
    padding-bottom:15px ;
    .title{
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #03032C;
        line-height: 18px;
        margin-bottom:10px;
        text-align: center;
    }
    .number-of-people{
      // text-align: center;
    }
    .item{
        display: flex;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #686480;
        line-height: 28px;

    }
    .set{
        margin-top: 15px;
        border-top:1px solid #D5D7E6;
        padding-top:10px;
        display: flex;
        justify-content: space-between;
        .status{
            width: 68px;
            height: 32px;
            background: #F9FAFF;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3B6EFB;
        }
        .set-up{
            display: inline-block;
            margin-right: 30px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #686480;
            line-height: 14px;
        }
        .delete{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #E34433;
            line-height: 14px;
        }
        .have-inh-and{
            background: #ECFDF1;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00DE3D;
        }
        .completed{
            background: #F8F8F8;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #686480;
        }
    }
  }
  .courses-have-inh-and{//课程已结束时显示的背景图
    // ../../../../../assets/course-has-ended.png
    background: url('../../assets/course-has-ended.png');
    background-size: 100% 100%;
  }

// .box-card {
//   height: auto;
//   width: 100%;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
//   cursor: pointer;
//   .title {
//     display: inline-block;
//     text-align: center;
//     width: 100%;
//     background: #fecd75;
//     padding: 18px 20px 0px 20px;
//     box-sizing: border-box;
//   }
//   .number {
//     padding-bottom: 18px;
//     color: #fff;
//     strong {
//       font-size: 26px;
//     }
//   }
//   &:deep(.el-card__header) {
//     padding: 0 0;
//     border-bottom-color: #fecd75 !important;
//     border: 0;
//   }
//   &:deep(.el-card__body) {
//     padding: 0px 0;
//   }
//   .courseContainer {
//     .specific {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: center;
//       width: 100%;
//       background: #fecd75;
//       border-bottom-left-radius: 10px;
//       border-bottom-right-radius: 10px;
//       overflow: hidden;
//       .item {
//         display: flex;
//         align-items: center;
//         color: #fff;
//         margin-bottom: 10px;
//         font-size: 14px;
//         .label {
//           width: 24px;
//           height: 24px;
//           line-height: 24px;
//           background: rgba(228, 186, 109, 0.8);
//           text-align: center;
//           margin-right: 20px;
//           border-radius: 50%;
//         }
//       }
//     }
//     .set {
//       text-align: right;
//       padding: 0px 20px;
//     }
//   }
// }
</style>
