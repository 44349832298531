<template>
  <div class="nav-title"><div v-for="(item ,index) in transactionManagement" :key="index" @click="tabBtn(item.router)" :class="{'active-item':item.router.includes(route.path+'?showAbbr')}">  <span v-if="!item.hide" style="margin-right: 30px;"> {{ item.name }} </span>  </div></div>
  <div v-if="showAbbr">
    <!-- 课程管理——外汇 -->
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card">
          <el-radio-group v-model="radio" style="height:30px;margin:0" size="mini">
            <el-radio-button label="币种池" />
            <!-- <el-radio-button label="可卖持仓" /> -->
          </el-radio-group>
          <el-table :data="tableData" height="240" style="width: 100%;margin-top: 0;"
            :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
            <el-table-column prop="name" label="币种" />
            <el-table-column prop="abbr" label="简称" />
            <el-table-column prop="targetAmount" label="目标数量" />
            <!-- <el-table-column prop="directionName" label="交易方向" /> -->
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card class="box-card">
          <el-empty description="暂无数据"></el-empty>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <div class="assembly">
    <NavSonMenu :activeTableftkey="activeTableTopkey" :menuData="navList" @menuChange="menuChange($event, 'top')" />
    <div>
      <Component :is="componentName" :showAbbr="showAbbr" :abbrList="!showAbbr ?[]: componentName== 'ForeignExchangeTransactions' ? abbrList :[]  " :type="activeTableTopkey"></Component>
    </div>
  </div>
</template>
<script>
import { onMounted } from 'vue'
import NavSonMenu from "@/components/NavSonMenu"//公用组件
import { transactionCurrency } from './tableMethods' //引入方法
// 课程外汇
import ForeignExchangeTransactions from "../../trade-personal/components/ForeignExchangeTransactions"// 交易
import CourseTransactionDetails from "../../trade-personal/components/CourseTransactionDetails"// 课程成交详情
import CourseFundFlow from "../../trade-personal/components/CourseFundFlow"// 课程资金流水
import ForeignExchangeAccountInformation from "../../trade-personal/components/ForeignExchangeAccountInformation"// 课程账户信息
import ForeignExchangeCancellation from "../../trade-personal/components/ForeignExchangeCancellation"// 课程撤单
import { reactive, toRefs,onBeforeMount } from "vue"
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    NavSonMenu,
    ForeignExchangeTransactions,
    CourseTransactionDetails,
    CourseFundFlow,
    ForeignExchangeAccountInformation,
    ForeignExchangeCancellation
  },
  
  setup() {
    onBeforeMount(()=>{
      data.showAbbr = JSON.parse(route.query.showAbbr)
    })
    onMounted(() => {
      if(sessionStorage.getItem('transactionManagement')){
        data.transactionManagement=JSON.parse(sessionStorage.getItem('transactionManagement'))
        console.log(data.transactionManagement,'data.transactionManagement')
      }
      transactionCurrency(data)
    })
    const route = useRoute();
    const router = useRouter();

    let data = reactive({
      abbrList:[],
      showAbbr: false,
      radio: '币种池',
      transactionManagement:[],
      componentName: 'ForeignExchangeTransactions',//当前展示的vue组件
      activeTableTopkey: '1',//当前顶部tab栏被选中的key
      tableData: [],//币种池
      navList: [
        {
          name: '交易',
          type: 'ForeignExchangeTransactions',
          key: '1',
          siwtchKey: 'ForeignExchangeTransactionsList',
          pageName: 'ForeignExchangeTransactions',
          componentName: 'ForeignExchangeTransactions'
        },
        {
          name: '撤单',
          type: 'ForeignExchangeCancellation',
          key: '2',
          siwtchKey: 'ForeignExchangeCancellationList',
          pageName: 'ForeignExchangeCancellation',
          componentName: 'ForeignExchangeCancellation'
        },
        {
          name: '成交详情',
          type: 'CourseTransactionDetails',
          key: '3',
          siwtchKey: 'ourseTransactionDetailsList',
          pageName: 'ourseTransactionDetails',
          componentName: 'CourseTransactionDetails'
        },
        {
          name: '资金流水',
          type: 'CourseFundFlow',
          key: '4',
          siwtchKey: 'CourseFundFlowList',
          pageName: 'CourseFundFlow',
          componentName: 'CourseFundFlow'

        },
        {
          name: '账户信息',
          type: 'AccountInformation',
          key: '5',
          siwtchKey: 'AccountInformationList',
          pageName: 'AccountInformation',
          componentName: 'ForeignExchangeAccountInformation'
        },

      ],
    })

    const menuChange = (navitem, Navtype) => {
      data.componentName = navitem.componentName

    }
    const tabBtn=(url)=>{
      router.push(url)
    }
    // let withdrawMoney = (data) => {
    //   data.tableData = JSON.parse(sessionStorage.getItem("accountAssets"))
    //   console.log('000000', data.tableData);
    // }
    return {
      ...toRefs(data),
      route,
      menuChange,
      // withdrawMoney,
      transactionCurrency,
      tabBtn
    }


  }
}
</script>
<style lang="less" scoped>
.box-card {
  height: 300px;
}

.assembly {
  margin: 20px 0px 55px
}
.nav-title{
  display: flex;
  margin-bottom:20px;
  margin-left: 20px;
  div{
    cursor: pointer;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #03032C;
  }
  .active-item{
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3B6EFB;
}
}
</style>