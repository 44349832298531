//全局index通用

export const fatherRoute = "/megagame";
export const menuData = {
  //大赛管理菜单
  menuList: [
    // {
    //   name: "大赛排名",
    //   router: fatherRoute + "/rank",
    //   key: 1,
    // },
    // {
    //   name: "关键比率",
    //   router: fatherRoute + "/key-ratio",
    //   key: 2,
    // },
    // {
    //   name: "资产配置",
    //   router: fatherRoute + "/asset-allocation",
    //   key: 3,
    // },
    // {
    //   name: "风险分析",
    //   router: fatherRoute + "/risk-analysis",
    //   key: 4,
    // },
    // {
    //   name: "重仓证券",
    //   router: fatherRoute + "/security",
    //   key: 5,
    // },
    // {
    //   name: "行业配置",
    //   router: fatherRoute + "/industry-allocation",
    //   key: 6,
    // },
    // {
    //   name: "历史回报",
    //   router: fatherRoute + "/history-return",
    //   key: 7,
    // },
    // {
    //   name: "交易管理",
    //   router: fatherRoute + "/trade",
    //   hide: true,
    //   key: 8,
    //   children: [
    //     { name: "股票", router: fatherRoute + "/trade/1", hide: true, key: 12 },
    //     { name: "债券", router: fatherRoute + "/trade/2", hide: true, key: 13 },
    //     { name: "期货", router: fatherRoute + "/trade/3", hide: true, key: 14 },
    //   ],
    // },
    // {
    //   name: "小组管理",
    //   router: fatherRoute + "/group-manage",
    //   hide: true,
    //   key: 9,
    // },
    // {
    //   name: "成员",
    //   router: fatherRoute + "/user-manage",
    //   hide: true,
    //   key: 10,
    // },
    // {
    //   name: "成员管理",
    //   router: fatherRoute + "/participants",
    //   hide: true,
    //   key: 11,
    // },
  ],
  toGroupListMega:true,
  mainMode: true,
  competitionName: "",
  mineMode: "0",
  menuIndex:'',
};

//主页
export const mainData = {
  loading: false,
  status: "",
  competitionName: "",
  title: "",
  activeTab: "0",
  visible: false,
  form: {},
  descriptions: {},
  isTeacher: false,
  isApply: false,
  start: false,
  list: [
    {
      name: "交易大赛1",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛2",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛3",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛4",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛5",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛6",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
    {
      name: "交易大赛7",
      number: 40,
      enrollStartTime: "2021-01-10",
      enrollEndTime: "2021-02-10",
      matchStartTime: "2021-02-10",
      matchEndTime: "2021-03-10",
    },
  ],
  pageIndex: 0,
  pageSize: 5,
};

//主页弹出框dialog
export const dialogData = {
  transactionType:[],
  loading: false,
  groupTable: [],
  groupMode: "加入小组",
  groupName: "",
  competitionGroupId: null, // 加入小组时的小组ID
  transactionTypeList: [
    {
      label: "股票",
      value: "gp",
      disabled: true,
    },
    {
      label: "债券",
      value: "zq",
      disabled: true,
    },
    {
      label: "期货",
      value: "qh",
      disabled: true,
    },
    {
      label: "外汇",
      value: "wh",
      disabled: true,
    },
  ],
};

//创建大赛
export const createData = {
  loading: false,
  rawForm: {},
  vueExample: {},
  codeType:0,
  transactionTypeList: [
    {
      label: "股票",
      value: "gp",
      disabled: false,
    },
    {
      label: "债券",
      value: "zq",
      disabled: false,
    },
    {
      label: "期货",
      value: "qh",
      disabled: false,
    },
    {
      label: "外汇",
      value: "wh",
      disabled: false,
    },
  ],
  transactionType: [],
};

//tab表格
export const tabData = {
  tableColumn: [
    //表格列名
  ],
  currentPage: 1,
  pageSize: 5,
  total: 0,
  loading: false,
  tableData: [],
  tableHeight:600
};

//大赛排名
export const rankData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "仓值", text: "position" },
    { name: "最新净值", text: "latestNetWorth" },
    { name: "收益率", text: "rateOfReturn" },
    { name: "操作次数", text: "operationsNumber" },
  ],
};

//关键比率
export const keyRatioData = {
  financeRadio: "证券",
  courseType:0,
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "夏普比率", text: "sharpeRatio" },
    { name: "詹森测度α", text: "jensenMeasure" },
    { name: "特雷诺比率", text: "tenorRatio" },
    { name: "市场时机曲线(T-M)a值", text: "marketTimingCurve_A" },
    { name: "市场时机曲线(T-M)c值", text: "marketTimingCurve_C" },
  ],
};

//资产配置
export const assetAllocationData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "股票比率", text: "shareRatio" },
    // { name: "期货比率", text: "futuresRatio" },
    // { name: "债券比率", text: "bondRatio" },
    { name: "现金比率", text: "cashRatio" },
    { name: "外汇比率", text: "cashRatio" },
  ],
};

//风险分析
export const riskAnalysisData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "收益率标准差", text: "standardDeviationOfYield" },
    { name: "最大回撤", text: "maximumBacktest" },
    { name: "单月最低回报", text: "minimumReturnPerMonth" },
  ],
};

//重仓证券
export const securityData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "Top1", text: "top1Zc" },
    { name: "Top2", text: "top2Zc" },
    { name: "Top3", text: "top3Zc" },
    { name: "Top4", text: "top4Zc" },
    { name: "Top5", text: "top5Zc" },
  ],
};

//行业配置
export const industryAllocationData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "Top1", text: "top1Hy" },
    { name: "Top2", text: "top2Hy" },
    { name: "Top3", text: "top3Hy" },
    { name: "Top4", text: "top4Hy" },
    { name: "Top5", text: "top5Hy" },
  ],
};

//历史回报
export const historyReturnData = {
  columns: [
    //表格列名
    { name: "大赛排名", text: "id" },
    { name: "姓名", text: "name" },
    { name: "学号", text: "studentNumber" },
    { name: "复权净值增长率", text: "netReturnGrowthRate" },
  ],
  dateRange: null,
  routeNames: "history-return",
};

//参赛人群
export const participantsData = {
  columns: [
    //表格列名
    { name: "姓名", text: "name" },
    { name: "学号", text: "numberId" },
    { name: "手机号", text: "phone" },
    { name: "学校名称", text: "schoolName" },
  ],
  competitionName: "",
  operation: ["删除"], //,'成员权限'
  currentPage: 1,
  pageSize: 5,
  total: 0,
  loading: false,
  tableData: [],
};

//参赛人群
export const participantsGroupData = {
  columns: [
    //表格列名
    { name: "大赛id", text: "competitionId" },
    { name: "小组名称", text: "groupName" },
  ],
  userColumns: [
    //表格列名
    { name: "用户id", text: "userId" },
    { name: "用户名称", text: "userName" },
    { name: "用户权限", text: "memberRoleName" },
  ],
  showRoleUser: false,
  loadingUser: false,
  idMega: "",
  megaUser: {},
  spreadTable: [],
  competitionName: "",
  operation: ["查看"], //,'成员权限'
  operationUser: ["删除"], //,'成员权限'
  currentPage: 1,
  currentPageShow: 1,
  pageSize: 5,
  currentPage2: 1,
  pageSize2: 100,
  total: 0,
  loading: false,
  showRoleDia: false,
  tableData: [],
  groupUserData: [],
  peopleData: [],
  tableHeight: 300,
  peopleValue: "",
  peopleOptions: [],
  nowJob: "",
  nowJobOptions: [],
};
