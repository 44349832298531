<template>
  <div class="participants">
    <nav>
    </nav>

    <div style="text-align: right;margin: 5px 0">
      <el-button type="warning" size="mini" @click="checkUser">同步</el-button>
    </div>
    <spread-table :pageSizes="[5, 20, 50, 100]" v-loading="loading" :tableColumn="columns" :tableData="tableData" :smallType="false" :pageSize="pageSize" :currentPage="currentPage" :operation="operation" :total="total" :indexMode="true" indexTitle="acid" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" />
  </div>

  <el-dialog title="同步用户" v-model="showRoleUser" width="70%">
    <span>
      <span>课程选取：</span>{{megaUser.title}}
      <el-table row-key="date" ref="filterTable" :loading="loadingUser" :data="spreadTable" height="400" style="width: 100%">
        <el-table-column prop="courseNo" label="课程编号" sortable></el-table-column>
        <el-table-column prop="courseName" label="大赛名称" :filters="className" :filter-method="filterClass"></el-table-column>
        <el-table-column prop="courseClassName" label="大赛名称" :filters="courseName" :filter-method="filterCourse"></el-table-column>
        <el-table-column prop="courseType" label="课程类型" :filters="courseType" :filter-method="filterCourseType"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="synchro(scope.row)">关联</el-button>
          </template>
        </el-table-column>
      </el-table>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="backDia">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog title="小组成员" v-model="showRoleDia" width="50%">
    <span>
      <spread-table :pageSizes="[100]" v-loading="loading" :tableColumn="userColumns" :tableData="groupUserData" :smallType="false" :pageSize="pageSize2" :currentPage="currentPage2" :currentPageShow="1" :operation="operationUser" :total="total" :indexMode="true" indexTitle="acid" @sizeChange="sizeChange" @clickButton="clickButtonUser" />
    </span>
    <template #footer>

      <el-card class="box-card" style="margin-top: 10px;">
        <div style="margin-bottom: 10px;text-align: left"><i class="card-line"></i>人员职务变更</div>
        <div style="margin: 10px 0;text-align: left">
          <span class="report-title" style="text-align: left">已选人员</span>
          <el-select v-model="peopleValue" placeholder="请选择" size="mini" style="text-align: left">
            <el-option v-for="item in peopleOptions" :key="item.userId" :label="item.name" :value="item.userId">
            </el-option>
          </el-select>

          <span class="report-title" style="margin-left: 20px">变更职务</span>
          <el-select v-model="nowJob" placeholder="请选择" size="mini" style="text-align: left">
            <el-option v-for="item in nowJobOptions" :key="item.id" :label="item.memberRoleName" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="warning" style="margin-left: 15px;text-align: left" size="mini" @click="handleClick">职务变更</el-button>

        </div>
        <!--      <spread-table  v-loading="loading" :tableHeight="tableHeight"-->
        <!--                     :pageSize="pageSize" :currentPage="currentPage" :total="total"-->
        <!--                     @sizeChange="sizeChange" @currentChange="currentChange"-->
        <!--                     :tableColumn="peopleJobList" :tableData="peopleJobLogData"></spread-table>-->
      </el-card>

      <span class="dialog-footer">
        <!--      <el-button @click="showRoleDia = false">取 消</el-button>-->
        <!--      <el-button type="primary" @click="showRoleDia = false,getUserList">确 定</el-button>-->
      </span>
    </template>
  </el-dialog>
</template>

<script>
import SpreadTable from "@/components/SpreadTable";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { getUserByCompetitionGroup } from "../apis";
import { deleteSingleUser } from "../apis";
import { findUserByGroupId } from "../apis";
import { deleteGroupUser } from "../apis";
import { getUserTypes } from "../apis";
import { alterJobsComp } from "../apis";
import { participantsGroupData } from "../datas";
import { userInfo } from "../methods";
import { ElMessage, ElMessageBox } from "element-plus";
import { updGroupRole } from "@/views/megagame/participants-user/groupApi";
import { syncStudent } from "../../teacher-manage/student-manage/studentApi";
import { pullUsersByCourseIdAndCompetitionId, viewSchoolCourses } from "@/components/Gradeform/gradeApi";

export default defineComponent({
  name: "participants-group",
  components: {
    SpreadTable,
  },
  setup() {
    let data = reactive(participantsGroupData);

    let handleClick = () => { alterJobsComp(data) }

    onMounted(() => {
      data.currentPage = 1
      data.pageSize = 5
      data.pageSize2 = 100
      getUser()
      getUserTypes(data)
    });

    const getUser = () => {

      data.loading = true;
      data.tableData = [];
      data.total = 0;
      data.currentPage = 1;
      const id = JSON.parse(sessionStorage.getItem('mega')).id;
      const reqData = {
        competitionId: id,
        roleName: userInfo().roleName,
        userId: userInfo().userId,
        pageIndex: data.currentPage,
        pageSize: data.pageSize,
      };
      getUserByCompetitionGroup(reqData)
        .then(res => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.tableData = resData.data.competitionGroupVOPageInfo ? resData.data.competitionGroupVOPageInfo.list : []
            data.total = resData.data.competitionGroupVOPageInfo ? resData.data.competitionGroupVOPageInfo.total : 0
          } else {

          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };


    const confirmUser = () => {

    }

    const sizeChange = size => {
      data.pageSize = size
      data.currentPage = 1
      getUser()
    }

    const currentChange = current => {
      // alert(current);
      data.currentPage = current
      getUser()
    }
    let clickButton = (val) => {
      let groupId = val.item.id;
      const id = JSON.parse(sessionStorage.getItem('mega')).id;
      if (val.type === '查看') {
        let reqData = {
          competitionId: id,
          competitionGroupId: groupId,
          roleName: userInfo().roleName,
          userId: userInfo().userId,
          pageIndex: 1,
          pageSize: 100,
        };

        findUserByGroupId(reqData)
          .then(res => {
            const resData = res.data;
            if (resData.status === 0 || resData.code == 200) {
              data.groupUserData = resData.data.competitionGroupUserVOList.list
              data.total = resData.data.competitionGroupUserVOList.total

              data.peopleOptions = data.groupUserData.map(v => {
                return { userId: v.userId, name: v.userName }
              })

              data.showRoleDia = true;
            }
          })
      }
      if (val.type === '删除') {

        const id = JSON.parse(sessionStorage.getItem('mega')).id;

        const reqData = {
          competitionId: id,
          userId: val.item.id,
          userName: userInfo().userName
        }
        deleteSingleUser(reqData).then(res => {
          if (res.data.code === '200') {
            ElMessage.success(res.data.msg)
            getUser();
          } else {
            ElMessage.error(res.data.msg)
          }

        })
      }
    }

    let clickButtonUser = (val) => {
      if (val.type === '删除') {
        ElMessageBox.confirm('此操作将永久删除该成员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const id = JSON.parse(sessionStorage.getItem('mega')).id;

          const reqData = {
            competitionId: id,
            competitionGroupId: val.item.competitionGroupId,
            userId: val.item.userId,
            userName: val.item.userName,
          }
          deleteGroupUser(reqData).then(res => {
            if (res.data.code === '200') {
              ElMessage.success(res.data.msg)

              let reqData = {
                competitionId: id,
                competitionGroupId: val.item.competitionGroupId,
                roleName: userInfo().roleName,
                userId: userInfo().userId,
                pageIndex: 1,
                pageSize: 100,
              };
              findUserByGroupId(reqData)
                .then(res => {
                  const resData = res.data;
                  if (resData.status === 0 || resData.code == 200) {
                    data.groupUserData = resData.data.competitionGroupUserVOList.list
                    data.total = resData.data.competitionGroupUserVOList.total

                    data.showRoleDia = true;
                    getUser()
                  }
                })


            } else {
              ElMessage.error(res.data.msg)
            }

          })
        })
      }
    }



    // 职务变更按钮
    const alterJobsComp = (data) => {
      let parame = {
        userId: data.peopleValue,
        userName: userInfo().userName,
        competitionGroupId: data.tableData[0].id,
        memberRoleId: data.nowJob

      }
      updGroupRole(parame).then(res => {
        if (res.data.code === '200') {
          ElMessage.success(res.data.msg)
          data.peopleValue = ''
          data.nowJob = ''


          const id = JSON.parse(sessionStorage.getItem('mega')).id;
          let reqData = {
            competitionId: id,
            competitionGroupId: data.tableData[0].id,
            roleName: userInfo().roleName,
            userId: userInfo().userId,
            pageIndex: 1,
            pageSize: 100,
          };
          findUserByGroupId(reqData)
            .then(res => {
              const resData = res.data;
              if (resData.status === 0 || resData.code == 200) {
                data.groupUserData = resData.data.competitionGroupUserVOList.list
                data.total = resData.data.competitionGroupUserVOList.total

                data.showRoleDia = true;
              }
            })


        } else {
          ElMessage.error(res.data.msg)
        }
      })
    }

    const backDia = () => {
      data.showRoleUser = false
      data.megaUser = {};
      data.idMega = '';
    }
    const saveInfo = () => {
      if (!data.idMega) return ElMessage.warning('请选择关联大赛')
      let param = {
        originalCourseId: data.idMega,
        competitionId: JSON.parse(sessionStorage.getItem('mega')).id
      }
      pullUsersByCourseIdAndCompetitionId(param).then(res => {
        if (res.data.code === '200') {
          backDia()
          ElMessage.success(res.data.msg)
        }
      })

    }
    const checkUser = () => {
      data.showRoleUser = true
      data.loadingUser = false
      viewSchoolCourses({
        "teacher": JSON.parse(sessionStorage.getItem('user')).username
      }).then(res => {
        if (res.data.code === '200') {
          data.spreadTable = res.data.data.viewSchoolCourseList
          let className = []
          let courseName = []
          let courseType = []
          for (let i in data.spreadTable) {
            className.push(data.spreadTable[i].courseName)
            courseName.push(data.spreadTable[i].courseClassName)
            courseType.push(data.spreadTable[i].courseType)
          }
          className = [...new Set(className)]
          courseName = [...new Set(courseName)]
          courseType = [...new Set(courseType)]
          data.className = delArrList(className)
          data.courseName = delArrList(courseName)
          data.courseType = delArrList(courseType)

          data.total = res.data.data.viewSchoolCourseList.length
          setTimeout(() => {
            data.loadingUser = false
          }, 300)
        }
      })
    }

    let filterClass = (value, row) => { return row.courseName === value }
    let filterCourse = (value, row) => { return row.courseClassName === value }
    let filterCourseType = (value, row) => { return row.courseType === value }
    let delArrList = (arr) => {
      let arrs = []
      for (let i in arr) {
        arrs.push({ text: arr[i], value: arr[i] })
      }
      return arrs
    }
    let synchro = (row) => {
      data.megaUser.title = row.courseName
      data.idMega = row.courseNo
    }
    return {
      ...toRefs(data),
      getUser,
      confirmUser,
      clickButton,
      clickButtonUser,
      sizeChange,
      currentChange,
      handleClick,
      backDia,
      saveInfo,
      checkUser,
      filterClass,
      filterCourse,
      filterCourseType,
      synchro,
    };
  },
});
</script>

<style scoped lang="less">
.participants {
}
</style>
