import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./permission.js"
//全局初始化样式
import "@/styles/base.css"
// //引入按需加载ui组件
// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale'
// locale.use(lang)
import { RegisteredUiComponents } from "@/demandload/element_plus.js"
import { DownloadAsExcel, DownloadAsExcelPost } from "@/components/download";
import { findResourceByRoleId } from "@/views/system-manage/role-manage/dataApi.js"
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app =  createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
import VueWechatTitle from 'vue-wechat-title'
import 'xe-utils'
import VxeTable from 'vxe-table'
import 'vxe-table/lib/style.css'

import videoPlay from 'vue3-video-play' // 引入组件 
import 'vue3-video-play/dist/style.css' // 引入css 

function useTable (app) {
    app.use(VxeTable)
}

RegisteredUiComponents(app)
//注册全局下载方法
DownloadAsExcel(app)// git请求方式 全局下载
DownloadAsExcelPost(app) //post请求方式 全局下载
app.use(router)
app.use(VueWechatTitle)
app.use(videoPlay)
// app.use(ElementPlus);
// createApp(App).use(router).use(ElementPlus, { locale }).mount("#app");
app.use(useTable).mount('#app')
