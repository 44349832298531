<template>
  <div class='security'>
    <tab-table
      :columns="columns"
      :findApi="findHeavyweightSecurities"
      :exportApi="exportHeavyweightSecurities"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { findHeavyweightSecurities, exportHeavyweightSecurities } from '../apis'
import { securityData } from '../datas'
import {} from '../methods'

export default defineComponent({
  name: "security",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(securityData)
    onMounted(() => {
        
    })
    
    return{
      ...toRefs(data),
      findHeavyweightSecurities,
      exportHeavyweightSecurities,
    }
  }
})
</script>

<style scoped lang="less">
.security {
  margin-top:60px;
}
</style>