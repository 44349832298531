<template>
  <div>
	<el-form style="padding-left: 38px;" >
		<el-form-item label="基金名称:" prop="name">
			<el-col :span="8">
				<el-select v-model="data.fundNameSelect" filterable remote reserve-keyword
					placeholder="基金名称" :remote-method="listFund" :loading="data.loading" @change="listPl">
					<el-option v-for="(item,index) in data.fundList" :key="item.id" :label="item.fundName" :value="item.id" />
				</el-select>
			</el-col>
		</el-form-item>
	</el-form>
	  
	<el-card class="box-card" style="width: 100%;height: 100%;">
		<el-empty description="暂无评论" v-if="data.plList.length == 0" />
		<div class="demo-collapse" v-else>
		  <el-collapse v-model="activeName" accordion @change="changeItem" v-loading="data.activeloading"> 
		    <el-collapse-item  v-for="(item,index) in data.plList" :key="index" :name="item.id+index" >
		      <template #title>
				  <span style="color: burlywood;">{{item.userName}}&nbsp;&nbsp;</span>
		        {{'发表问答:&nbsp;&nbsp;'}}
				<el-button size="small" style="float: right;" @click="reply(item.id,item.id,item.userName)">回复作者</el-button>
		      </template>
			  <div class="demo-collapse">
				  <div>
					  {{item.title}}
				  </div>
				<el-collapse v-model="activeName2" accordion v-loading="data.activeloading2">
 				  <el-collapse-item v-for="(item2,index2) in data.plList2" :name="item2.id+index2">
					  <template #title>
						  <span style="color: burlywood;">{{'&nbsp;&nbsp;&nbsp;&nbsp;'+item2.userName}}&nbsp;&nbsp;</span>
					    {{'回复:&nbsp;&nbsp;'}}
						<span style="color: burlywood;">
						{{item2.puserName}}&nbsp;&nbsp;</span>
					  		<el-button size="small" style="float: right;" @click="reply(item.id,item2.id,item2.userName)">回复此评论</el-button>
					  </template>
					  <div>
					  	&nbsp;&nbsp;&nbsp;&nbsp;{{item2.title}}
					  </div>
				</el-collapse-item> 
				<div style="text-align: center;">
					<el-button v-if="data.showMore" text @click="more(item.id)">加载更多</el-button>
				</div>
				
				</el-collapse>  
		      </div>
		    </el-collapse-item>
		  </el-collapse>
		  <el-pagination :hide-on-single-page="data.page.handSinge" :page-size="data.page.pageSize" layout="prev, pager, next" :total="data.page.total" @current-change="changePage"/>
		</div>
	</el-card>
	
	<el-input v-model="data.own" type="textarea" style="margin-top: 10px;" :placeholder="data.textPlaceholder" />
	<el-button type="primary" style="margin-top: 10px;" @click="addPl()" size="small">发表 </el-button>
  </div>
</template>
<script setup >
import { defineComponent, onMounted, reactive, ref, } from 'vue';
import { listApprovaledFund,insertDiscussionArea,listDiscussionArea } from '../../api.js';
import { ElCollapse,ElCollapseItem } from 'element-plus';
import { ElMessage } from 'element-plus';


const activeName = ref('1')
const activeName2 = ref('2')

const isshow = ref(true)
let data = reactive({
  showMore:ref(false),
  fundNameSelect:ref(''),
  loading :ref(false),
  activeloading:ref(false),
  activeloading2:ref(false),
  fundList:[],
  textPlaceholder:'发表评论',
  own:ref(''),
  pid:0,
  rid:0,
  page:{
	  pageSize:10,
	  total:0,
	  currentPage:1,
	  handSinge:ref(false)
  },
  page2:{
  	  pageSize:5,
  	  total:0,
  	  currentPage:1,
  	  handSinge:ref(false)
  },
  plList:[],
  plList2:[]
 })

//下拉查询
const listFund = (fund) => {
	var formData = new FormData();
	formData.append("fundName", fund);
	listApprovaledFund(formData).then(res => {
		data.fundList = res.data.data;
	})
}
const changePage = (value) => {
	data.page.currentPage = value;
	listPl();
}

const changeItem = (value) => {
	let parm ={
		"fundId":data.fundNameSelect,
		"pid":value,
		"page":1,
		"pageSize":data.page2.pageSize,
		"rid":data.rid,
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = res.data.data.list;
		//如果评论小于5 不展示加载更多
		if(res.data.data.total > data.page2.pageSize){
			data.showMore = true;
		}else{
			data.showMore = false;
		}
	})
}

const more = (pid) => {
	data.page2.currentPage = data.page2.currentPage +1;
	let parm ={
		"fundId":data.fundNameSelect,
		"pid":pid,
		"rid":data.rid,
		"page":data.page2.currentPage,
		"pageSize":data.page2.pageSize
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = data.plList2.concat(res.data.data.list);
		if(data.plList2.length < res.data.data.total){
			data.showMore = true;
		}else{
			data.showMore = false;
		}
	})
}


const listPl = () => {
	let parm ={
		"fundId":data.fundNameSelect,
		"pid":data.pid,
		"page":data.page.currentPage,
		"pageSize":data.page.pageSize
	};
	listDiscussionArea(parm).then(res => {
		data.plList = res.data.data.list;
		data.page.total = res.data.data.total;
	})
}

const reply = (pid,rid,userName) => {
	data.pid = pid;
	data.rid = rid;
	data.textPlaceholder = '回复'+userName;
	
}


const addPl = () => {
	if(data.fundNameSelect == '' || data.own ==''){
		ElMessage({type:'error',message:'评论内容不能为空！'})
		return;
	}
	
	let parm ={
		"title":data.own,
		"createUser":JSON.parse(sessionStorage.getItem("user")).userId,
		"userName":JSON.parse(sessionStorage.getItem("user")).name,
		"fundId":data.fundNameSelect,
		"pid":data.pid,
		"respondent":data.rid
		}
	insertDiscussionArea(parm).then(res => {
		if(res.data.code =='200'){
			if(data.pid == 0){
				ElMessage({type:'success',message:'发表成功！'})	
				data.own='';
				data.pid = 0;
				data.textPlaceholder = '发表评论';
				relistPl();
			}else{
				ElMessage({type:'success',message:'回复成功！'})	
				//relistPl();
				relistPl2();
				data.own='';
				data.pid = 0;
			}
		}else{
			ElMessage({type:'error',message:res.data.msg})
		}
		// location.reload();
	})
}


const relistPl2 = () => {
	let parm ={
		"fundId":data.fundNameSelect,
		"pid":data.pid,
		"page":1,
		"pageSize":5
	};
	listDiscussionArea(parm).then(res => {
		data.plList2 = res.data.data.list;
		if(data.plList2.length < res.data.data.total){
			data.showMore = true;
		}else{
			data.showMore = false;
		}
	})
}

const relistPl = () => {
	let parm ={
		"fundId":data.fundNameSelect,
		"pid":0,
		"page":1,
		"pageSize":10
	};
	listDiscussionArea(parm).then(res => {
		data.plList = res.data.data.list;
		data.page.total = res.data.data.total;
	})
}


</script>
<style lang="less" scoped>
.demo-pagination-block+.demo-pagination-block {
  margin-top: 10px;
}

.demo-pagination-block .demonstration {
  margin-bottom: 16px;
}

.box-card {
  font-size: 16px;
  width: 100%;
  height: 500px;

  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }

  &:deep(.el-card__body) {
    padding-top: 10px;
  }
}

.card {
  overflow: auto;
  height: 200px;
  margin-bottom: 20px;
}

.othersComments {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  width: auto;
  height: auto;
  min-height: 40px;
  background: rgb(245, 241, 241);
}

.boxcard {
  font-size: 16px;
  width: 100%;
  height: 750px;

  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }

  &:deep(.el-card__body) {
    padding-top: 10px;
  }
}

.return {
  margin-top: 20px;
  margin-right: 20px;
  width: 100px;
  // float: right;
  margin-bottom: 10px;
}

/*滚动条的宽度*/
.notice::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*滚动条滑块*/
.notice::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
</style>