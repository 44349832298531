import {cloneDeep} from "lodash"

export const tableList = ({row, column, rowIndex, columnIndex}) =>{
    // return 'rowStyle'
    return 'background-color:#1989fa;color:#fff;font-weight:400;'
}
export const handleSizeChanges = (val,ctx) =>{
    ctx.emit('sizeChange',val)
}
export const handleCurrentChanges = (val,ctx) => {
    ctx.emit('currentChange',val)
}
export const clickButtons = (val, item, ctx) => {
  console.log("item", cloneDeep(item));
    ctx.emit('clickButton', {type:val,item:cloneDeep(item)})
}
export const handleSelectionChanges = (val,ctx) => {
    ctx.emit('handleSelectionChange', cloneDeep(val))
}
export const toggleSelections = (ctx) => {
    ctx.clearSelection()
}

export const controls = (val, ctx) => {
    ctx.emit("control", val);
}
export const SwitchButtons = (val,ctx) => {
  ctx.emit("SwitchButton", val);
}
