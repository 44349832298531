<template>
  <div class="container">
    <globalheader class="globalheader" />
    <div class="container-main">
    <el-container>
      <listnav/>
      <el-main class="el-main">
        <router-view :key="uuid" />
      </el-main>
    </el-container>
    <companytip />
  </div>
</div>
</template>

<script>
import { ref } from "vue";
import listnav from "../Headermenu/index"
import { v4 as uuidv4 } from "uuid";
import globalheader from "./headers";
import companytip from "./footer";
export default {
  name: "layout",
  components: {
    globalheader,
    companytip,
    listnav
  },
  setup() {
    const uuid = ref(uuidv4());
    return {
      uuid,
    };
  },
};
</script>

<style lang="less" scoped>
  .container-main{
   
  }
  .el-main{
    padding: 20px 30px;
    height:calc(100vh - 80px) ;
    background: #E8E9F1;
    min-width: 1200px;
    overflow: auto;
    
  }
</style>
