import axios from "axios"
import { ElMessage } from 'element-plus';
import router from '../router'


let instance = axios.create({
  // baseURL:'http://localhost:8080/',//接口统一域名
  // baseURL: "https://exp1.sufe.edu.cn/exp/tradelabLx/",//接口统一域名
  timeout: 50000, //设置超时
});

instance.interceptors.request.use(
  (config) => {

    const accessToken = sessionStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);

  }
);

//--响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let {message} =error
    if(message=== "Network Error"){
      ElMessage({type:'error',message:'网络已断开,请检查网络后刷新页面'})
      return
    }
    // 对响应错误做点什么
    if (error.response) {
        const status_code = error.response.status;
        const status_msg = error.response.data.msg
        if (status_code === 401) {
            // 提示token失效
            ElMessage({type:'error',message:status_msg})
            //跳转登录页面
            router.push({ path: "/login" })
        } else if (status_code === 500) {
            ElMessage({type:'error',message:'系统繁忙,请刷新重试'})
           

        } else {
            return Promise.reject(error);
        }

    }
  }
);

export default instance;
