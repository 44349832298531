import axios from "@/axios/globalaxios.js";

// git请求方式
function downloadAsExcel(url, params, name) {
  axios({
    url: url,
    method: "get",
    responseType: "blob",
    params,
  }).then((res) => {
    // data是二进制流，通过new Blob方法转化为Blob,type是下载文件格式，本方法以xlsx为例
    // name是你想下载的文件名
    parameter(res,name)
  });
}

// post请求方式
function downloadAsExcelPost(url, data, name) {
  axios({
    url: url,
    method: "post",
    responseType: "blob",
    data,
  }).then((res) => {
    parameter(res,name)
  });
}

// 定义一个gie和post请求方式共用的方法
function parameter(res,name) {
  // data是二进制流，通过new Blob方法转化为Blob,type是下载文件格式，本方法以xlsx为例
    // name是你想下载的文件名
  let data = res.data;
  const url = window.URL.createObjectURL(
    new Blob([data], {
      // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      type: "application/vnd.ms-excel",
    })
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", `${name}` || "template.xls");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// 导出git请求方式并再main.js里面注册全局使用
export const DownloadAsExcel = (app) => {
  app.config.globalProperties.$DownloadAsExcel = downloadAsExcel;
};
// 导出post请求方式并再main.js里面注册全局使用
export const DownloadAsExcelPost = (app) => {
  app.config.globalProperties.$DownloadAsExcelPost = downloadAsExcelPost;
};