<template>
  <div class="container">
    <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage" :total="total" @clickButton="clickButton" @sizeChange="sizeChange" @currentChange="currentChange" :operation="operation" :tableColumn="colums" :tableData="tableData"></spread-table>
  </div>
</template>

<script>
import { Data, WithwithdrawalData } from "./index";
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import CommonTable from "../Table";
import SpreadTable from '@/components/SpreadTable'

import { JudgmentTpye } from "../tools.js"
import { clickButtons, dealTableParame, getBackTables } from "../../../course-manage/trade-manage/tradeMethods";
import { cancelOrder, findDelegation } from "../../../course-manage/trade-manage/tradeApi";
import { ElMessage } from "element-plus";
import { onBeforeRouteUpdate } from "vue-router";
export default {
  name: "Withwithdrawal",
  components: {
    CommonTable,
    SpreadTable
  },
  props: {
    /**
     * @param type
     * 1 股票
     * 2 期货
     * 3 债券
     */
    type: { type: String, default: '1' }
  },
  setup(props) {
    const data = reactive(WithwithdrawalData);
    const { colums } = JudgmentTpye(props.type, Data)
    data.colums = colums


    let vueexample = reactive({})
    onMounted(() => {
      vueexample = getCurrentInstance()
      getTableData()
    })

    // 获取撤单数据
    let getTableData = () => {
      data.loading = true
      let types = props.type === '2' ? 10 : 0
      let parame = {
        acid: JSON.parse(sessionStorage.getItem('acid')),
        exchange: types,
        pageSize: data.pageSize,
        page: data.currentPage,
        type: props.type, 
        cancellations:1
      }
      findDelegation(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.tableData = newRes.delegationPageInfo.list
          data.total = newRes.delegationPageInfo.total
          dealTableParame(data.tableData)
          // dealDate(data.spreadTable,['date'])
          setTimeout(() => {
            data.loading = false
          }, 300)
        }
      })
    }

    let sizeChange = (val) => {
      data.pageSize = val
      getTableData()
    }
    let currentChange = (val) => {
      data.currentPage = val
      getTableData()
    }

    let clickButton = (val) => {
      data.loading = true
      let parame = {
        accountId: JSON.parse(sessionStorage.getItem('acid')),
        omid: val.item.omid,
      }
      cancelOrder(parame).then(res => {
        if (res.data.code === '200') {
          if (res.data.msg === '撤单成功') {
            ElMessage.success(res.data.msg)
          } else {
            ElMessage.error(res.data.msg)
          }
         setTimeout(() => {
          getTableData()
          data.loading = false
         },3000)
        } else {
          ElMessage.warning(res.data.msg)
        }
      })
    }


    return {
      sizeChange,
      currentChange,
      clickButton,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-bottom: 20px;
}
</style>
