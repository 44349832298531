import axios from '@/axios/globalaxios'

export const getTableList = data =>{
    return axios({
        url: "/tradelabLx/marketPortfolioManagement/findPortfolioManagementByAcidAndType",
        method: 'post',
        data:data
    })
}
export const findFundTrendByAcid = data =>{
    return axios({
        url: "/tradelabLx/marketPortfolioManagement/findFundTrendByAcid",
        method: 'post',
        data:data
    })
}
export const findIndexByAcidAndFid = data =>{
    return axios({
        url: "/tradelabLx/marketPortfolioManagement/findIndexByAcidAndFid",
        method: 'post',
        data:data
    })
}

export const findForeignAmountByAcid = (data) => {
  return axios({
    url: "/tradelabLx/currency_files/findTargetAmountByAcid",
    method: "post",
    data: data,
  });
};