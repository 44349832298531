<template>
  <div class="menuForm-components">
    <el-dialog width="60%" v-model="dialogTableVisible" :title="menuTitle" destroy-on-close @close="closeForm">
      <el-form ref="ruleFormRef" :model="menuState" label-width="200px" :rules="rules">
        <el-form-item :label="labelList.titleName" size="mini" prop="urlDescription">
          <el-input v-model="menuState.urlDescription" />
        </el-form-item>
        <el-form-item :label="labelList.titleUrl" size="mini" prop="url">
          <el-input v-model="menuState.url" />
        </el-form-item>
        <el-form-item :label="labelList.titleFileUrl" size="mini" prop="fileUrl">
          <el-input v-model="menuState.fileUrl" />
        </el-form-item>
        <el-form-item label="排序" size="mini">
          <el-input v-model="menuState.sort" type="number" />
        </el-form-item>

      </el-form>
      <div class="dialog-footer">
        <el-button @click="submitForm" type="warning">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, defineEmits } from 'vue';
import { addResource, resourcesUpdate } from "./dataApi.js"
import { ElMessage } from "element-plus";
export default defineComponent({
  name: 'MenuForm',
  props: {
    dialogTableVisibleForm: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '一级'
    },
    menuTitle: {
      type: String,
      default: '添加菜单'
    },
    menuId: {
      type: Number,
      default: 0
    },
    info: {
      type: Object,
    }
  },

  setup(props, ctx) {
    const rules = reactive({
      urlDescription: [
        { required: true, message: '菜单名字不能为空', trigger: 'change' },
      ],
      fileUrl: [
        { required: true, message: '菜单组件地址不能为空', trigger: 'change' },
      ],
      url: [
        {
          required: true,
          message: '菜单地址不能为空',
          trigger: 'change',
        },]

    })
    const ruleFormRef=ref();
    const form = reactive({
      dialogTableVisible: props.dialogTableVisibleForm,
      tableData: [{ menuName: '首页', url: 'home.vue', }],
      labelList: {
        titleFileUrl: props.title + '菜单组件地址',
        titleUrl: props.title + '菜单地址',
        titleName: props.title + '菜单名称',
      },
      menuState: {
        url: props.info?.url || '',
        urlDescription: props.info?.urlDescription || '',
        type: props.info?.type || '',
        fileUrl: props.info?.fileUrl || '',
        sort: Number(props.info?.sort) || 0,
      }
    })
    const closeForm = () => {
      ctx.emit('closeForm', false)
    }
//     const submitForm = async (formEl: FormInstance | undefined) => {
//   if (!formEl) return
//   await formEl.validate((valid, fields) => {
//     if (valid) {
//     } else {
//     }
//   })
// }
  if(props.menuTitle == '添加菜单'){
    form.menuState={
        url:  '',
        urlDescription:  '',
        type: '',
        fileUrl:  '',
        sort: 0,
      }
  }
    const submitForm = () => {
      ruleFormRef.value.validate((valid)=>{
        if(valid){
          if (props.menuTitle == '添加菜单') {
        if (props.title == '二级') {
          form.menuState.type = '2';
          addForm({ ...form.menuState, menuId: props.menuId })
        } else {
          form.menuState.type = '1';
          addForm(form.menuState)
        }
      } else {

        if (props.title == '二级') {
          editForm({ ...form.menuState, id: props.info.id, menuId: props.info.menuId })
        } else {
          //编辑菜单
          editForm({ ...form.menuState, id: props.info.id })

        }
      }
        }

      })

    }
    // 添加菜单
    let addForm = (menuState) => {
      menuState.sort=Number(menuState.sort)
      addResource(menuState).then(res => {
        if (res.data.code == '200') {
          ElMessage.success(res.data.msg);
          ctx.emit('closeForm', true)
        } else {
          ElMessage.error(res.data.msg);
        }
      })
    }
    let editForm = (menuState) => {
      menuState.sort=Number(menuState.sort)
      // 编辑菜单
      resourcesUpdate(menuState).then(res => {
        if (res.data.code == '200') {
          ElMessage.success(res.data.msg);
          ctx.emit('closeForm', true)
        } else {
          ElMessage.error(res.data.msg);
        }
      })
    }

    return {
      ...toRefs(form),
      closeForm,
      addForm,
      submitForm,
      rules,
      ruleFormRef
    }
  }
})

</script>
<style scoped>
.dialog-footer {
  text-align: right;
}
</style>