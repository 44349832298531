import {
    ElButton,
    ElInput,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElRadio,
    ElRadioGroup,
    ElCol,
    ElRow,
    ElContainer,
    ElHeader,
    ElPopover,
    ElMain,
    ElFooter,
    ElAside,
    ElAvatar,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElIcon,
    ElCard,
    ElForm,
    ElFormItem,
    ElDialog,
    ElDatePicker,
    ElTag,
    ElTable,
    ElTableColumn,
    ElPagination,
    ElPageHeader,
    ElTabs,
    ElTabPane,
    ElDescriptions,
    ElDescriptionsItem,
    ElDivider,
    ElLoading,
    ElEmpty,
    ElMessageBox,
    ElMessage,
    ElCheckboxGroup,
    ElCheckbox,
    ElRadioButton,
    ElTooltip,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElSwitch,
    ElUpload,
    ElAlert,
} from 'element-plus';

import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import local from 'element-plus/lib/locale'
// local.use(lang)


let ElementPlusComponents = [
    ElButton,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElRadio,
    ElRadioGroup,
    ElTable,
    ElTableColumn,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElIcon,
    ElPagination,
    ElPageHeader,
    ElTabs,
    ElTabPane,
    ElDescriptions,
    ElDescriptionsItem,
    ElDivider,
    ElRow,
    ElCol,
    ElContainer,
    ElHeader,
    ElPopover,
    ElMain,
    ElFooter,
    ElAside,
    ElAvatar,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElIcon,
    ElCard,
    ElInput,
    ElEmpty,
    ElLoading,
    ElForm,
    ElFormItem,
    ElDialog,
    ElDatePicker,
    ElTag,
    ElEmpty,
    ElMessageBox,
    ElMessage,
    ElCheckboxGroup,
    ElCheckbox,
    ElRadioButton,
    ElTooltip,
    ElBreadcrumb,
    ElSwitch,
    ElUpload,
    ElAlert,
    ElBreadcrumbItem
]


export const RegisteredUiComponents = (app) => {
    app.config.globalProperties.$message = ElMessage;

    for (let componentName in ElementPlusComponents) {
        app.use(ElementPlusComponents[componentName])
    }
}
