import axios from "@/axios/globalaxios";
export const getCodes = (code, data, ctx) => {
  let typeCode =
    code.indexOf("10nf_") !== -1
      ? "3"
      : code.indexOf("11nf_") !== -1
      ? "2"
      : "1";
  if (typeCode === "2" || typeCode === "3") {
    code = code.slice(2);
  }
  //http://qt.gtimg.cn/q
  axios.get("/list=" + code).then((response) => {
    let hqData = {};
    // 获取字符串转数组
    let res = response.data.replace(/=/g, ":");
    let sub = res.replace(/var/g, "").split(";")[0];
    //腾讯接口数据处理
    // let res = response.data.split('"')[1];
    // let sub = res.split("~");
    // sub.unshift(code);
    let obj = {};
    if (sub.indexOf(":", 0) !== -1) {
      obj = sub.substring(sub.indexOf(":") + 2, sub.length - 1).split(",");
      if (obj.length > 1) {
        if (typeCode === "1") {
          // if (typeCode === "1")
          let sellList = [
            { text: "卖五", num: obj[28], price: obj[29] },
            { text: "卖四", num: obj[26], price: obj[27] },
            { text: "卖三", num: obj[24], price: obj[25] },
            { text: "卖二", num: obj[22], price: obj[23] },
            { text: "卖一", num: obj[20], price: obj[21] },
          ];
          let buyList = [
            { text: "买一", num: obj[10], price: obj[11] },
            { text: "买二", num: obj[12], price: obj[13] },
            { text: "买三", num: obj[14], price: obj[15] },
            { text: "买四", num: obj[16], price: obj[17] },
            { text: "买五", num: obj[18], price: obj[19] },
          ];
         
          hqData = {
            codeName: obj[0],
            newOpen: obj[1],
            lastClose: obj[2],
            newPrice: obj[3],
            upPrice: obj[4],
            downPrice: obj[5],
            sellList,
            buyList,
            date: obj[30],
            time: obj[31],
          };

          //腾讯接口将"price"与"num"位置调换
          // let buyList = [
          //   { text: "买一", price: obj[10], num: obj[11] },
          //   { text: "买二", price: obj[12], num: obj[13] },
          //   { text: "买三", price: obj[14], num: obj[15] },
          //   { text: "买四", price: obj[16], num: obj[17] },
          //   { text: "买五", price: obj[18], num: obj[19] },
          // ];
          // let sellList = [
          //   { text: "卖五", price: obj[28], num: obj[29] },
          //   { text: "卖四", price: obj[26], num: obj[27] },
          //   { text: "卖三", price: obj[24], num: obj[25] },
          //   { text: "卖二", price: obj[22], num: obj[23] },
          //   { text: "卖一", price: obj[20], num: obj[21] },
          // ];
          // hqData = {
          //   codeName: obj[0],
          //   newOpen: obj[6],
          //   lastClose: obj[5],
          //   newPrice: obj[3],
          //   upPrice: obj[4],
          //   downPrice: obj[5],
          //   buyList,
          //   sellList,
          //   date: obj[30],
          //   time: obj[31],
          // };
        } else if (typeCode === "2") {
          // 期货
          let sellList = [
            { text: "卖五", num: "-", price: "-" },
            { text: "卖四", num: "-", price: "-" },
            { text: "卖三", num: "-", price: "-" },
            { text: "卖二", num: "-", price: "-" },
            { text: "卖一", num: obj[12], price: obj[7] },
          ];
          let buyList = [
            { text: "买一", num: obj[11], price: obj[6] },
            { text: "买二", num: "-", price: "-" },
            { text: "买三", num: "-", price: "-" },
            { text: "买四", num: "-", price: "-" },
            { text: "买五", num: "-", price: "-" },
          ];
        
          hqData = {
            codeName: obj[0],
            newOpen: obj[2],
            lastClose: obj[14],
            newPrice: obj[8],
            upPrice: obj[3],
            downPrice: obj[4],
            sellList,
            buyList,
            date: obj[17],
            time: "",
          };
        } else {
          // 期货  中金所
          let sellList = [
            { text: "卖五", num: "-", price: "-" },
            { text: "卖四", num: "-", price: "-" },
            { text: "卖三", num: "-", price: "-" },
            { text: "卖二", num: "-", price: "-" },
            { text: "卖一", num: obj[27], price: obj[26] },
          ];
          let buyList = [
            { text: "买一", num: obj[17], price: obj[16] },
            { text: "买二", num: "-", price: "-" },
            { text: "买三", num: "-", price: "-" },
            { text: "买四", num: "-", price: "-" },
            { text: "买五", num: "-", price: "-" },
          ];
        
          hqData = {
            codeName: obj[49],
            newOpen: obj[0],
            lastClose: obj[10],
            newPrice: obj[6],
            upPrice: obj[34],
            downPrice: obj[35],
            sellList,
            buyList,
            date: obj[36],
            time: "",
          };
        }
      }
    }
    // data.codeName
    ctx.emit("codeName", hqData.codeName);
    ctx.emit("newPrice", hqData.newPrice);
    data.hqData = hqData;
    setTimeout(() => {
      data.loading = false;
    }, 500);
  });
};

export const dealNums = (num, data, codeType) => {
  if (data.indexOf("nf") !== -1) {
    return num;
  } else {
    let number = codeType === 2 ? 10 : 100;
    var result = [],
      counter = 0;
    // num什么都不用做 不需要
    num = (parseInt(num / number) || 0).toString().split("");
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) {
        result.unshift(",");
      }
    }
    return result.join("");
  }

  // return 32
};

export const dataRefrehs = (props, data, ctx) => {
  data.intervalId = setInterval(() => {
    getCodes(props, data, ctx); //加载数据函数
  }, 5000);
};
export const clears = (data) => {
  //清除计时器data.intervalId = null;
  clearInterval(data.intervalId);
  data.intervalId = null; //设置为null}
};
export const clickPrices = (data, ctx) => {
  // 获取 当前价格
  ctx.emit("clickPrice", data);
};
