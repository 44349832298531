import axios from '@/axios/globalaxios'

export const findClassByUserId = data =>{
    return axios({
        url: "/tradelabLx/course/findClassByUserId",
        method: 'post',
        data:data
    })
}
export const findGroupByUserIdAndClassId = data =>{
    return axios({
        url: "/tradelabLx/courseGroup/findGroupByUserIdAndClassId",
        method: 'post',
        data:data
    })
}

