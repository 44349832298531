<template>
  <div class="process">
    <el-card class="box-card" style="height: auto;padding: 20px;">
      
      <!-- <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table> -->

        <el-form :model="menuState" style="">
          <el-row style="margin-bottom: 10px;">
            <el-col :span="4">
              <div style="margin-bottom: 10px;margin-top: 5px;" class="box-title">菜单管理</div>
            </el-col>
            <el-col :span="4">
              <el-form-item label="菜单名称" size="mini">
                <el-input style="width:80%;margin-right: 10px;" v-model="menuState.urlDescription" clearable placeholder="请输入关键字"/>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="菜单地址" size="mini">
                <el-input style="width:80%;margin-right: 10px;" v-model="menuState.url" clearable placeholder="请输入关键字"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="菜单组件地址" size="mini">
                <el-input style="width:80%;margin-right: 10px;" v-model="menuState.fileUrl" clearable placeholder="请输入关键字"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <span style="margin-top: 1.5px;float: right;">
                <el-button @click="getUserList" size="mini" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="addMenu(1)" size="mini" type="warning">添加菜单</el-button>
              </span>
            </el-col>
          </el-row>

        </el-form>
      <el-table :data="tableData" :row-class-name="getRowClassName"
        :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }" style="width: 100%;" >
        <el-table-column type="expand">
          <template #default="props">
            <el-table :data="props.row.childResources" :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }"
              style="width: 100%">
              <el-table-column prop="urlDescription" label="菜单名称" />
              <el-table-column prop="url" label="地址" />
              <el-table-column prop="fileUrl" label="组件地址" />
              <el-table-column prop="createTime" label="创建时间" />
              <el-table-column prop="createTime" label="操作">
                <template #default="scope">
                  <el-button type="text" @click="edit(2, props.row,scope.row)">编辑</el-button>
                  <el-button type="text" @click="deleteMenu(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="urlDescription" label="菜单名称" />
        <el-table-column prop="url" label="地址" />
        <el-table-column prop="fileUrl" label="组件地址" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column prop="createTime" label="操作">
          <template #default="scope">
            <el-button type="text" @click="addMenu(2,scope.row)">添加</el-button>
            <el-button  type="text" @click="edit(1,scope.row)">编辑</el-button>
            <el-button style="color:red" type="text" @click="deleteMenu(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <MenuForm :dialogTableVisibleForm="dialogTableVisibleForm" @closeForm="closeForm" :menuTitle="menuTitle"
        :title="title" v-if="dialogTableVisibleForm" :menuId="menuId" :info="info" />
    </el-card>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import MenuForm from "@/components/MenuForm"
import { resources, resourcesDelete } from "@/components/MenuForm/dataApi.js"
// import {dealDate} from "@/views/teacher-manage/classMethods.js"
import { dealDate } from "@/views/course-manage/trade-manage/tradeMethods";
import { ElMessage, ElMessageBox } from "element-plus";
export default defineComponent({
  name: "information",
  components: {
    SpreadTable,
    MenuForm
  },
  setup() {

    onMounted(() => {
      let name = JSON.parse(sessionStorage.getItem('user'))
      data.type = name.userRoleName === '学生' ? 0 : name.userRoleName === '教师' ? 1 : 2
      data.userId = name.userId
      getUserList()
    })
    let data = reactive({
      userId: 0,
      loading: true,
      dialogTableVisibleForm: false,
      spreadTable: [],
      tableHeight: 400,
      title: '一级',
      menuTitle: '',
      menuId:0,
      menuState: {
        url: '',
        urlDescription: '',
        fileUrl: ''
      },
      info:{},
      operation: ['下载', '删除'],
      tableData: [ ]
    })

    let getRowClassName = ({ row, rowIndex }) => {
      if (!row.childResources?.length > 0) {
        return 'row-expand-cover';
      }
    }

    // 查找 data 数据
    let getUserList = () => {
      // 查找全部资源应该不需要传参数，直接返回所有的数据，返回的数据是父子关系还是平级关系需要前端处理
      let param = {
        pageIndex: 1,
        pageSize: 100,
        ...data.menuState
      }
      resources(param).then((res) => {
        data.tableData = res.data.data.resourceVOList;
        data.tableData.forEach((item)=>{
          if(item.childResources.length>0){
          dealDate(item.childResources, ["createTime"]);
        }
        })
     
        dealDate(data.tableData, ["createTime"]);
      })
    }
    //添加菜单
    let addMenu = (type,row) => {
      editTitle(type, '添加菜单',row?.id)
    }
    let editTitle = (type, name,id) => {
      data.menuTitle = name
      if (type === 1) {
        data.menuId=0;
        data.title = '一级'
      } else {
        data.menuId=id;
        data.title = '二级'
      }
      data.dialogTableVisibleForm = true;
    }
    let edit = (type, prop, row) => {
      data.info=type===1?prop:row
      editTitle(type, '编辑菜单',row?.id)

    }
    let closeForm = (type) => {
      if(type){
        getUserList()
      }
      data.dialogTableVisibleForm = false;
    }
    let deleteMenu = (row) => {
      ElMessageBox.confirm('是否确认删除此条菜单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          resourcesDelete({ id: row.id }).then(res => {
        if (res.data.code == '200') {
          ElMessage.success(res.data.msg);
          getUserList();
        } else {
          ElMessage.error(res.data.msg);
        }
      })
        })
 
    }
    return {
      ...toRefs(data),
      addMenu,
      closeForm,
      getRowClassName,
      edit,
      deleteMenu,
      getUserList
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

.content {
  line-height: 40px;
  margin: 10px 0 0 30px;
}

.add-menu {
  text-align: right;
  margin-bottom: 20px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*滚动条滑块*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

/deep/ .row-expand-cover .el-table__expand-icon {
  visibility: hidden;
}
.process{
  height: calc(100vh - 125px);
  border-radius: 6px;
  background-color: #fff;
  // padding:20px;
  overflow: auto;

}
</style>
