<template>
    <div>
    <div v-if="route.path==='/megagame/groupList'">
    <router-view/>
    </div>
    <el-card class="box-cards" v-else>
      <template #header>
        <div class="card-header">
          <span>我的大赛</span>
        </div>
      </template>
      <div class="courseContainer" >
        <div style="margin-bottom: 20px" v-if="type===0">
          <span>大赛状态：</span>
          <el-select v-model="classStart" placeholder="请选择" size="mini"  @change="refreshData">
            <el-option :key="0" label="未开始" :value="0"></el-option>
            <el-option :key="1" label="进行中" :value="1"></el-option>
            <el-option :key="2" label="已结束" :value="2"></el-option>
          </el-select>
  
          <el-input size="mini" style="float: right;width: 150px"
              placeholder="请输入关键字" suffix-icon="el-icon-search"
              v-model="classInput"  @input="refreshData">
          </el-input>
        </div>
        <div class="curriculum">
        <el-row :gutter="20" >
          <el-empty v-if="itemList.length===0" description="暂无课程"  style="width: 100%;"></el-empty>
          <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6" v-for="(item,index) in itemList" :key="index" :loading="loading">
            <class-card :classStart="classStart" :item="item" @setcourse="setcourse"></class-card>
          </el-col>
        </el-row>
    </div>
      </div>
      <gradeform v-model:visbleBoolean="visbleBoolean" @modify="modify" :modifyData="modeItem" />
  
    </el-card>
  </div>
  </template>
  
  <script>
  import classCard from "./components/ClassCard/newIndex.vue";//更换样式由于组件复用，所以新建了一个页面
  import {classData} from "./components/ClassCard/classData";
  import {onMounted, reactive, toRefs} from "vue";
  import gradeform from "@/components/Gradeform/index.vue"
  import {getClassLists} from "./classMethods";
  import { useRoute, useRouter } from "vue-router";
  
  
  export default {
    name: "index",
    components:{classCard,gradeform},
    props:{
      type:{
        type: Number,
        default: 0
      }
    },
    setup(props){
      let data = reactive(classData)
      onMounted(() => {
        getClassList()
      })
      const route=useRoute()
      const setcourse = (item) => {
        data.modeItem = item
        data.visbleBoolean = true;
      }
      const modify = (formdata) => {
        data.visbleBoolean = false;
      }
      let getClassList = () =>{getClassLists(data,props.type)}
      let refreshData = () =>{getClassLists(data,props.type)}
      return{
        refreshData,
        setcourse,
        modify,
        ...toRefs(data),
        route
      }
    }
  }
  </script>
  
  <style scoped lange="less">
  .curriculum{
    box-sizing: border-box;
    width: 100%;
    max-height: 645px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  </style>