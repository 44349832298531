<template>
  <div class="container">
    <div class="title">账户信息
      <ExportButton class="btn" v-if="type === '1'" />
    </div>
    <CommonTable :colums="AccountColums" :tableData="tableData" :showPage="false" />
    <div class="title">持仓信息
      <ExportButton class="btn" v-if="type === '1'" />
    </div>
    <CommonTable :colums="PositionColums" :tableData="tableData" :paginationConfig="paginationConfig" @handleChange="handleChange" />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import CommonTable from "../Table";
import ExportButton from "@/components/Export/index.vue"
import { Data, FundsData } from "./index";
import { JudgmentTpye } from "../tools.js"
export default {
  name: "Funds",
  components: {
    CommonTable,
    ExportButton
  },
  props: {
    /**
     * @param type
     * 1 股票
     * 2 期货
     * 3 债券
     */
    type: { type: String }
  },
  setup(props) {
    const { type } = props
    const data = reactive(FundsData);
    /**
     * 判断使用是股票、期货、债券的colums
     */
    const { AccountColums, PositionColums } = JudgmentTpye(type, Data)
    data.AccountColums = AccountColums
    data.PositionColums = PositionColums






    const handleChange = (key, val) => {
      data.paginationConfig[key] = val;
    }






    return {
      ...toRefs(data),
      handleChange
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  .title {
    margin: 20px 0;
    display: flex;
    align-items: center;
    &:deep(.btn) {
      margin-left: 20px;
    }
  }
}
</style>
