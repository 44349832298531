<template>
  <div class="banner-group" v-loading="loading">
    <el-row :gutter="20" v-if="list.length > 0" >
      <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6" v-for="(info, index) in list" :key="index">
        <banner class="banner" :info="info" :activeTab="activeTab" :type="type === '报名' && !info.isSignUp ? '查看' : type" @setting="setting" />
      </el-col>
    </el-row>
    <el-empty v-else description="暂无数据" style="width: 100%"></el-empty>
  </div>
</template>

<script>
import banner from "@/components/MegagameBanner";
import { defineComponent, } from "vue";

export default defineComponent({
  name: "banner-group",
  components: {
    banner,
  },
  props: {
    loading: {
      defalut: false,
    },
    list: {
      defalut: [],
    },
    activeTab: {
      default: "0",
    },
    type: {
      default: '详情',
    }
  },
  setup(props, { emit }) {
    const setting = (type, info) => {
      emit('setting', type, info)
    }

    return {
      setting,
    }
  },
});
</script>

<style scoped lang="less">
.banner-group {
  height: calc(100vh - 300px);
  overflow-y:auto;
  width: 100%;
  overflow-x: hidden;
  padding-right: 15px;
  span {
    line-height: 40px;
  }
  .banner {
    margin-bottom: 20px;
  }
}
</style>
