<template>
  <div class="content-mian">
    <div v-if="[1, 2, 3, 5, 4 ].indexOf(courseType) === -1" class="content-title">
        <el-radio-group v-model="financeRadio" :disabled="disabled" size="default" @change="getDataList" style="margin:10px -15px;" text-color="#3B6EFB" fill="#FFFF">
          <el-radio-button style="width: 50px;font-size: 20px;" label="证券" value="证券"/>
          <el-radio-button style="width: 50px;font-size: 20px;" label="外汇" value="外汇"/>
        </el-radio-group>
    </div>
  <div class="mian">
    <el-card style="max-height: 450px;overflow: auto;overflow-x: hidden;">
      <!-- 外汇table切换暂时不用 -->
      <div style="margin-bottom: 10px">
        <div class="card-line"></div>现有调仓报告
      </div>
      <spread-table :smallType="true" v-loading="loading" :pageSize="pageSize" :currentPage="currentPage" :total="total" @clickButton="clickButton" @sizeChange="sizeChange" @currentChange="currentChange" :operation="operation" :upreport="true" :beforeRouteName="beforeRouteName" :disabledTeacher="disabledTeacher" :tableColumn="tableList" :tableData="spreadTable"></spread-table>
    </el-card>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="12">
        <el-card class="box-card">
          <div style="margin-bottom: 10px">
            <div class="card-line"></div>{{financeRadio == '证券'?'现有可调整股票目标':'现有可调整币种目标'}}
          </div>
          <spread-table :smallType="true" v-loading="loadingOf" :pageSize="pageSizeOf" :currentPage="currentPageOf" :total="totalOf" @sizeChange="sizeChangeOf" @currentChange="currentChangeOf" :tableColumn="financeRadio == '证券'?tableListOf:whTableListOf" :tableData="spreadTableOf"></spread-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div style="margin-bottom: 10px">
            <div class="card-line"></div>{{financeRadio == '证券'?'现有股票目标':'现有币种目标'}}
          </div>
          <spread-table :smallType="true" v-loading="loadingPlan" :pageSize="pageSizePlan" :currentPage="currentPagePlan" :total="totalPlan" @sizeChange="sizeChangePlan" @currentChange="currentChangePlan" :tableColumn="financeRadio == '证券' ? tableListPlan :whTableListPlan" :tableData="spreadTablePlan"></spread-table>
        </el-card>
      </el-col>
    </el-row>

    <check-file :value="valueFile" :fileName="fileName" :diaStart="diaStart" @setClose="closeDia"></check-file>
  </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import SpreadTable from '@/components/SpreadTable'
import MiniTag from '@/components/MiniTag'
import ECharts from '@/components/Echarts'
import checkFile from "../upreport-manage/checkFile";

import { controlData } from './controlData'
import { sizeChanges, currentChanges, getControlLists, sizeChangeOfs, currentChangeOfs, clickButtons } from './controlMethods'
import { currentChangePlans, getStockListPlans, sizeChangePlans } from "../stock-manage/stockMethods";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    MiniTag,
    ECharts,
    checkFile,
  },
  setup() {
    let data = reactive({
      courseType:0,
      financeRadio: "证券",
      disabled:false,
      // table 属性
      tableList: [
        { name: '报告编号', text: 'fileId' },
        { name: '报告名称', text: 'name' },
        { name: '报告类型', text: 'fileTypeName' },
        { name: '状态', text: 'fileStateName' },
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,

      loading: true,
      spreadTable: [],

      tableListOf: [
        { name: '代码', text: 'internalsid' },
        { name: '简称', text: 'name' },
        { name: '数量', text: 'targetamount' },
      ],
      whTableListOf: [
        { name: '币种', text: 'name' },
        { name: '简称', text: 'abbr' },
        { name: '数量', text: 'targetAmount' },
      ],
      currentPageOf: 1,
      pageSizeOf: 5,
      totalOf: 0,
      loadingOf: false,
      spreadTableOf: [],
      disabledTeacher:false,
      beforeRouteName: 'courseControl',
      tableListPlan: [
        { name: '代码', text: 'internalsid' },
        { name: '简称', text: 'name' },
        { name: '数量', text: 'targetamount' },
      ],
      whTableListPlan: [
        { name: '币种', text: 'name' },
        { name: '简称', text: 'abbr' },
        { name: '数量', text: 'targetAmount' },
        // { name: '买卖方向', text: 'directionName' },
      ],
      fileIdPlan: null,
      currentPagePlan: 1,
      pageSizePlan: 5,
      totalPlan: 0,
      loadingPlan: true,
      spreadTablePlan: [],

      operation: ['查看', '对应目标', '通过', '否决'],
      pageType: 3,
      filetype: 4,
      routeId: undefined,

      // 查看
      fileName: '',
      diaStart: false,
      valueFile: '',
    })
    const router = useRouter();
    onMounted(() => {
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      data.disabledTeacher = JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师' ? true : false
      getControlList()
      getStockListPlan()
    })
    let getControlList = () => { getControlLists(data) }
    let getStockListPlan = () => { getStockListPlans(data) }

    let sizeChange = (val) => { sizeChanges(val, data) }
    let sizeChangeOf = (val) => { sizeChangeOfs(val, data) }
    let sizeChangePlan = (val) => { sizeChangePlans(val, data) }

    let currentChange = (val) => { currentChanges(val, data) }
    let currentChangeOf = (val) => { currentChangeOfs(val, data) }
    let currentChangePlan = (val) => { currentChangePlans(val, data) }
    let clickButton = (val) => {
      console.log("val--2",val);
      clickButtons(val, router, data)
    }
    let closeDia = () => { data.diaStart = false }
    let getDataList = () => {  
      console.log("financeRadio",data.financeRadio);
      getControlList()
      getStockListPlan()
    }

    return {
      ...toRefs(data),
      sizeChange,
      sizeChangeOf,
      sizeChangePlan,
      currentChange,
      currentChangeOf,
      currentChangePlan,
      clickButton,
      closeDia,
      getDataList
    }
  }
})
</script>

<style scoped lang="less">
.content-title{
  margin-top:-17px;
  margin-bottom: -2px;
}
.content-mian .mian{
  padding-bottom:50px;
    height: calc(100vh - 420px);
    overflow: auto;
    padding-right: 10px;
}
.box-card {
  /*height: 500px;*/
  min-height: 450px;
  .row-norm {
    line-height: 50px;
    .title-norm {
      color: #000;
      text-align: right;
      font-size: 13px;
    }
    .value-norm {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
::v-deep.el-radio-button{
  .el-radio-button__inner {
    background: #FFFF;
    color:#333;
    border: 0 !important;
  }
}
</style>