<template>
  <div class="container">
    <div class="selectbox">
      证券代码：
      <CodeSearch @codeSelectChane="codeSelectChane" codeType="3" :multiple="false" :codeList="code"></CodeSearch>
    </div>
    <div class="selectbox">
      证券名称：
      <span style=" font-size: 14px">{{codeName}}</span>
    </div>
    <div class="charts_box">
      <div class="left" style="margin:20px 0 0 20px">
        <storageForm :code="code" :price="price" @editPrice="editPrice" />
        <!--        <div id="echartsData"></div>-->
      </div>
      <div class="right">
        <trade-card :code="codes" @clickPrice="clickPrice" :codeType="3"></trade-card>
      </div>
    </div>
    <!--    <div class="bottom">-->
    <!--    </div>-->
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import { storageData } from "../futuresData";
import * as echarts from 'echarts';
import TradeCard from '@/components/TradeDealCard'
import storageForm from "../StorageForm"
import CodeSearch from "@/components/CodeSearch"
import { dealCodeSearch } from "../../../course-manage/trade-manage/tradeMethods";
import { findQuotationByCondition } from "../../../course-manage/trade-manage/tradeApi";
import { codeDealName } from "../commonMethods.js";

var myChart;
export default {
  name: "futuresStorage",
  components: {
    TradeCard,
    storageForm,
    CodeSearch
  },
  setup() {
    const data = reactive({
      price: '',
      code: 'RB2302',
      codes: '11nf_RB2302',
      codeName: '沪螺钢2302合约',
      option: {
        xAxis: {
          data: ['2017-10-24', '2017-10-25', '2017-10-26', '2017-10-27', '2017-10-28', '2017-10-29', '2017-10-30', '2017-10-31']
        },
        yAxis: {},
        series: [{
          type: 'k',
          data: [
            [20, 34, 10, 38],
            [40, 35, 30, 50],
            [31, 38, 33, 44],
            [38, 15, 5, 42],
            [38, 15, 5, 42],
            [38, 15, 5, 42],
            [38, 15, 5, 10],
          ]
        }]
      }
    }
    );

    onMounted(() => {
      // getChart()
      window.addEventListener("resize", echartsresize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", echartsresize);
    });

    // 期货的图标数据
    let getChart = () => {
      var chartDom = document.getElementById('echartsData');
      myChart = echarts.init(chartDom)
      let exchangeType = data.code.slice(0, 2) === "sh" ? "1" : "0";
      let parame = {
        startTime: (Date.parse(new Date()) / 1000 - 365 * 3 * 24 * 3600),
        endTime: Date.parse(new Date()) / 1000,
        code: data.code.slice(2),
        exchangeType: exchangeType
      }
      findQuotationByCondition(parame).then(res => {
        let arr = []
        if (res.data.code === '200') {
          let newRes = res.data.data
          for (let i in newRes.marketInformations) {
            let dataMations = newRes.marketInformations[i]
            arr.push([dataMations.date, dataMations.fopen, dataMations.fclose, dataMations.flow, dataMations.fhigh])
          }
          let resOption = splitData(arr);
          function splitData(rawData) {
            var categoryData = [];
            var values = [];
            for (var i = 0; i < rawData.length; i++) {
              categoryData.push(rawData[i].splice(0, 1)[0])
              values.push(rawData[i])
            }
            return {
              categoryData: categoryData,
              values: values
            }
          }
          let xAxis = resOption.categoryData
          let value = resOption.values

          data.option = {
            // backgroundColor: '#12cf96',
            backgroundColor: '#fff',
            title: {
              text: data.codeName,
              left: 0
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            // legend: {
            //   data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
            // },
            grid: {
              left: '10%',
              right: '10%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              data: xAxis,
              scale: true,
              boundaryGap: false,
              axisLine: { onZero: false },
              splitLine: { show: false },
              splitNumber: 20,
              min: 'dataMin',
              max: 'dataMax'
            },
            yAxis: {
              scale: true,
              splitArea: {
                show: true
              }
            },
            dataZoom: [
              {
                type: 'inside',
                start: 30,
                end: 70
              },
              {
                show: true,
                type: 'slider',
                y: '90%',
                start: 0,
                end: 75
              }
            ],
            series: [
              {
                name: '日K',
                type: 'k',
                data: value,
                itemStyle: {
                  normal: {
                    color: '#ec0000',
                    color0: '#00da3c',
                    borderColor: '#8A0000',
                    borderColor0: '#008F28'
                  }
                },
                markPoint: {
                  label: {
                    normal: {
                      formatter: function (param) {
                        return param != null ? Math.round(param.value) : ''
                      }
                    }
                  },
                  data: [
                    // {
                    //   name: 'highest value',
                    //   type: 'max',
                    //   valueDim: 'highest'
                    // },
                    // {
                    //   name: 'lowest value',
                    //   type: 'min',
                    //   valueDim: 'lowest'
                    // },
                    // {
                    //   name: 'average value on close',
                    //   type: 'average',
                    //   valueDim: 'close'
                    // }
                  ],
                  tooltip: {
                    formatter: function (param) {
                      return param.name + '<br>' + (param.data.coord || '')
                    }
                  }
                },
                markLine: {
                  symbol: ['none', 'none'],
                  data: [
                    [
                      {
                        name: 'from lowest to highest',
                        type: 'min',
                        valueDim: 'lowest',
                        symbol: 'circle',
                        symbolSize: 10,
                        label: {
                          normal: { show: false },
                          emphasis: { show: false }
                        }
                      },
                      {
                        type: 'max',
                        valueDim: 'highest',
                        symbol: 'circle',
                        symbolSize: 10,
                        label: {
                          normal: { show: false },
                          emphasis: { show: false }
                        }
                      }
                    ]
                  ]
                }
              }
            ],
          }
          myChart.setOption(data.option)
        }
      })
    }

    const echartsresize = () => {
      myChart.resize();
    };

    let editPrice = (val) => {
      data.price = val
    }

    let clickPrice = (value) => {
      data.price = value
    };

    // 切换 搜索
    let codeSelectChane = (value) => {
      // data.codeList = value
      // data.code = dealCodeSearch(value, { routeId: '3' }).slice(5)
      data.code = dealCodeSearch(value, { routeId: '3' })
      data.codes = dealCodeSearch(value, { routeId: '3' })
     
      data.codeName = codeDealName(value, '3');
      // getChart()
    }
    return {
      editPrice,
      clickPrice,
      codeSelectChane,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  .selectbox {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .charts_box {
    display: flex;
    margin-bottom: 20px;
    .left {
      width: 55%;
      #echartsData {
        height: 500px;
        width: 100%;
      }
    }
    .right {
      width: 45%;
    }
  }
  .bottom {
    // padding-right: 30%;
  }
}
</style>
