<template>
  <div class='risk-analysis'>
    <tab-table
      :columns="columns"
      :findApi="findRiskAnalysis"
      :exportApi="exportRiskAnalysis"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { findRiskAnalysis, exportRiskAnalysis } from '../apis'
import { riskAnalysisData } from '../datas'
import {} from '../methods'

export default defineComponent({
  name: "risk-analysis",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(riskAnalysisData)
    onMounted(() => {
        
    })
    
    return{
      ...toRefs(data),
      findRiskAnalysis,
      exportRiskAnalysis,
    }
  }
})
</script>

<style scoped lang="less">
.risk-analysis {
  margin-top:60px;
  
}
</style>