import axios from '@/axios/globalaxios'

// 查询用户管理 信息
export const sysUsers = data =>{
    return axios({
        url: "/tradelabLx/sysUser/sysUsers",
        method: 'post',
        data:data
    })
}
// 删除 用户信息
export const deleteInfo = data =>{
    return axios({
        url: "/tradelabLx/sysUser/delete",
        method: 'post',
        data:data
    })
}
// 更新 用户信息
export const saveSysUserRole = data =>{
    return axios({
        url: "/tradelabLx/sysUser/saveSysUserRole",
        method: 'post',
        data:data
    })
}
// 查询课程小组
export const findGroupByClassId = data =>{
    return axios({
        url: "/tradelabLx/course/findGroupByClassId",
        method: 'post',
        data:data
    })
}

// 新建小组
export const addAGroup = data =>{
    return axios({
        url: "/tradelabLx/course/addAGroup",
        method: 'post',
        data:data
    })
}
// 加入小组
export const joinTheGroup = data =>{
    return axios({
        url: "/tradelabLx/courseGroup/joinTheGroup",
        method: 'post',
        data:data
    })
}