import {
  joinTheGroup,
  addAGroup,
  saveSysUserRole,
  findGroupByClassId,
} from "./listApi";
import { ElMessage, ElNotification } from "element-plus";
import { ElMessageBox } from "element-plus";
import { editFiles } from "../../stock-manage/stockMethods";
import { findGroupByUserIdAndClassId } from "../classApi";
import { delDrafrFiles } from "../../../system-manage/user-manage/userMethods";

export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    classId: data.classId,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findGroupByClassId(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.groupCoursePageInfo.list;
      data.total = newRes.groupCoursePageInfo.total;
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

// 设置角色
export const dealRole = (val, data) => {
  getRoleList(data);
  data.showRoleDia = true;
  data.userItem = val;
  data.userValue = val.roleName;
};

// 确认修改
export const saveInfos = (data, router) => {

  if (!data.userValue) return ElMessage.warning("小组名称不能为空");
  let parame = {
    classId: data.classId,
    username: JSON.parse(sessionStorage.getItem("user")).username,
    groupName: data.userValue,
    userId: JSON.parse(sessionStorage.getItem("user")).userId,
  };
  data.clickBtn=true;
  console.log( data.clickBtn,' data.clickBtn')
  addAGroup(parame).then((res) => {

    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.userValue=''
      backInfo(data);
      succeessInfo(0, router);
      checkCards(router, data);
    } else {
      ElMessage.error(res.data.msg);
    }
    data.clickBtn=false;
  })
  .catch(()=>{
    data.clickBtn=false;
  })
};
// 查找小组下的acid
export const checkCards = (router, data) => {
  let param = {
    userId: JSON.parse(sessionStorage.getItem("user")).userId,
    classId: data.classId,
    pageSize: 20,
    page: 1,
  };
  findGroupByUserIdAndClassId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      sessionStorage.setItem("classInfo", JSON.stringify(newRes.userGroup));
      sessionStorage.setItem("acid", newRes.userGroup.acid);
      sessionStorage.setItem("groupId", newRes.userGroup.groupId);
      sessionStorage.setItem("courseUserId", newRes.userGroup.courseUserId);
      // router.push('/course')
      router.push("/course/home");
    }
  });
};

// 取消修改
export const succeessInfo = (data, router) => {
  let tip = data === 0 ? "创建" : "加入";
  ElNotification({
    title: tip + "成功",
    message: "即将进入模拟大赛",
    type: "success",
  });
  // ElMessage.success('成功');
  setTimeout(() => {
    if (data === 1) router.push("/course/home");
  }, 300);
};
// 取消修改
export const backInfo = (data) => {
  data.userValue = "";
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
    }
  });
};

export const clickButtons = (val, data, router) => {
  if (val.type === "加入") {
    ElMessageBox.confirm("确定是否加入该小组, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      joinMega(val.item, data, router);
    });
  }
};

export const joinMega = (val, data, router) => {
  let param = {
    username: JSON.parse(sessionStorage.getItem("user")).username,
    courseGroupUser: {
      userId: JSON.parse(sessionStorage.getItem("user")).userId,
      courseGroupId: val.courseGroupId,
    },
  };
  joinTheGroup(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      succeessInfo(1, router);
      checkCards(router, data);
    } else {
      ElMessage.error(res.data.msg)
    }
  });
  // succeessInfo(1,router)
};
