<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <spread-table :smallType="true" v-loading="loadingPlan" :tableHeight="270" :pageSize="pageSizePlan" :currentPage="currentPagePlan" :total="totalPlan" @sizeChange="sizeChangePlan" @currentChange="currentChangePlan" :tableColumn="tableListPlan" :tableData="spreadTablePlan.slice((currentPagePlan-1)*pageSizePlan,currentPagePlan*pageSizePlan)"></spread-table>
        </el-card>
      </el-col>
    </el-row>

    <div style="margin: 20px 0 10px">
      <nav-son-menu :ref="dom" :menuData="sonMenu[sonMenuType]" @menuChange="menuChange"></nav-son-menu>
    </div>
    <el-row v-if="showOther === 1" :gutter="20">
      <el-col :span="12">
        <div>
          <trade-details :codeList="codeList" :price="price" @codeChange="codeChange" :type="sonMenuType" :code="code" :megaMode="megaMode" @getChart="getChart" :codeType="'4'" @changeTitle="changeTitle"></trade-details>
        </div>
      </el-col>
      <el-col :span="12">
        <trade-card :code="code" @codeName="codeNameChange" :codeType="codeType" @newPrice="newPrice"></trade-card>
      </el-col>
    </el-row>
    <div v-else>
      <div v-if="showOther===2">
        <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage" :total="total" @clickButton="clickButton" @sizeChange="sizeChange" @currentChange="currentChange" :operation="operation" :tableColumn="tableListAll[showOther]" :tableData="spreadTable"></spread-table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted, watch } from "vue";
import NavSonMenu from '@/components/NavSonMenu'
import SpreadTable from '@/components/SpreadTable'
import TradeCard from '@/components/TradeDealCard'
import {
  menuChanges, clickGetPrice, currentChanges, sizeChanges, DateChanges, currentChangePlans, sizeChangePlans,
  getPlanLists, clickButtons, allMedthods, codeChanges
} from "./tradeMethods";
import TradeDetails from './components/tradeDetails/index'
import ECharts from '@/components/Echarts'
import { useRoute } from "vue-router";

export default defineComponent({
  name: "index",
  components: {
    NavSonMenu,
    SpreadTable,
    TradeCard,
    TradeDetails,
    ECharts,
  },
  setup() {
    let data = reactive({
      codeType: 1,
      sonMenu: {
        '1': [
          {
            name: '交易',
            type: 'sonMenu',
            key: 1,
          },
          // 1{
          //   name: '撤单',
          //   type:'sonMenu',
          //   key: 2,
          // },
        ],
      },
      sonMenuType: 1,
      tableList: [
        { name: '代码', text: 'date' },
        { name: '名称', text: 'name' },
        { name: '数量', text: 'address' },
      ],
      code: '',
      codeName: '',
      price: '',
      direction: false,
      showOther: 1,

      heightCharts: 305,
      option: {},
      // tag类型  股票 基金 ...
      tagType: 1,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],

      startTime: undefined,
      endTime: undefined,
      datePicker: '',
      // 期货10 其他0
      exchange: 0,

      tableListAll: {
        '2': [
          { name: '交易单号', text: 'omid' },
          { name: '交易所', text: 'exType' },
          { name: '证券代码', text: 'code' },
          { name: '证券名称', text: 'fname' },
          { name: '买卖方向', text: 'direction' },
          { name: '委托状态', text: 'orderStatusName' },
          { name: '委托价格', text: 'orderPrice' },
          { name: '委托数量', text: 'orderVol' },
          { name: '平均成交价', text: 'averagePrice' },
          { name: '成交数量', text: 'tradedVol' },
          { name: '下单时间', text: 'addDateTime' },
        ],
      },
      tableHeight: 550,

      tableListPlan: [
        { name: '代码', text: 'code' },
        { name: '简称', text: 'name' },
        { name: '交易所', text: 'exType' },
      ],
      currentPagePlan: 1,
      pageSizePlan: 5,
      totalPlan: 0,
      loadingPlan: true,
      spreadTablePlan: [],
      codeList: [],

      routeId: 1,
      megaMode: false,// route.name === 'trade' 为大赛
      megaChartList: [], //大赛charts
      chartTitle: '', // 大赛页面时的chart标题
      operation: ['撤单'],

      dateRange: null,
      sizeNum: 0,
    })

    const route = useRoute();

    let refs = '';
    const dom = el => {
      refs = el;
    }
    onMounted(() => {
      getPlanList()
    })

    let getChart = (val) => { getCharts(data, val) }
    let getPlanList = () => { getPlanLists(data) }
    let menuChange = (value) => { menuChanges(value, data, refs); }
    // let clickPrice = (value) => {clickGetPrice(value, data)}
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }

    let codeChange = (val) => { codeChanges(val, data) }
    let codeNameChange = (val) => { data.codeName = val }
    let newPrice = (val) => { data.price = val }
    let clickButton = (val) => { clickButtons(val, data) }

    let sizeChangePlan = (val) => { sizeChangePlans(val, data) }
    let currentChangePlan = (val) => { currentChangePlans(val, data) }


    const changeTitle = title => {
      data.chartTitle = title
    }

    return {
      ...toRefs(data),
      getChart,
      menuChange,
      dom,
      sizeChange,
      currentChange,
      codeChange,
      codeNameChange,
      newPrice,
      clickButton,
      changeTitle,
      sizeChangePlan,
      currentChangePlan,
    }
  }
})
</script>

<style scoped lang="less">
.box-card {
  /*height: 500px;*/
  min-height: 300px;
}
.block {
  position: relative;
  text-align: right;
  .demonstration {
    font-size: 13px;
    color: #707070;
    margin-right: 10px;
  }
}
nav {
  // position: absolute;
  // right: 0px;
  // top: -54px;
}
</style>