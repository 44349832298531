<template>
  <div>
    <el-card class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>模块编辑</div>

      <!--      <div style="margin: 10px 0">-->
      <!--        <span style="font-size: 13px;font-weight: 400">用户名：</span>-->
      <!--        <el-input v-model="userInput" style="width: 200px;margin: 0 10px" placeholder="请输入关键字" clearable size="mini"></el-input>-->
      <!--        <el-button size="mini" type="warning" @click="getUserList">搜索</el-button>-->
      <!--      </div>-->
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </el-card>

    <el-dialog title="设置角色" v-model="showRoleDia" width="30%">
      <span>
        <span>设置角色：</span>
        <el-select v-model="userValue" placeholder="请选择">
          <el-option v-for="item in userOptions" :key="item.id" :label="item.roleName" :value="item.id">
          </el-option>
        </el-select>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="backDia">取 消</el-button>
          <el-button type="primary" @click="saveInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { modelData } from "./modelData";
import {
  clickButtons, currentChanges, sizeChanges, getUserLists,
  backInfo, saveInfos
} from "./modelMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive(modelData)
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let backDia = () => { backInfo(data) }
    let saveInfo = () => { saveInfos(data) }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      backDia,
      saveInfo,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
</style>