<template>
	<!-- 开放式基金申请页面 -->
	<div>
		<!-- <div style="position:absolute">
				<el-button style="float: right;" :disabled="getData.qsDisabled" @click="qs"  type="primary" size="small">清算</el-button>
		</div> -->
		<el-form size="mini">
			<el-form-item label="">
				<div style="position:relative">
					<span class="p" style="display: inline-block;width: 70px;text-align: right;">基金名称:</span>
					<span style="width: 300px;display: inline-block;margin-left:15px;">
						<el-input v-model="input" placeholder="请输入基金名称！" :disabled="getData.fundNameDisabled"
							style="float: left,margin-top:10px" />
					</span>

				</div>
			</el-form-item>
			<el-form-item label="招募说明书:">
				<div>
					<div v-if="getData.showFile" style="font-size:14px;float: left;margin-right: 10px;">
						<span style="">{{ getData.file.fileName }}</span>
						<el-icon class="fileNameText" @click="dw(getData.file.fileUrl, getData.file.fileName)">
							<Download />
						</el-icon>
						<!-- </el-button> -->
					</div>
					<el-upload class="upload-demo" :http-request="uploadFile" :show-file-list="false" :file-list="filesUrls">
						<el-button size="mini" :class="getData.fundNameDisabled ? 'upload-buttonDisabled' : 'upload-button'"
							:disabled="getData.fundNameDisabled">点击上传</el-button>
					</el-upload>
				</div>


			</el-form-item>

		</el-form>
		<div style="margin-top:20px">
			<PriceList></PriceList>
		</div>
		<div style="">
			<el-button class="upload-button" style="width: 200px;" :disabled="getData.tjDisabled" @click="submit()"
				color="#3B6EFB">提交申请</el-button>
		</div>

		<div style="float: none; margin-top:20px">
			<el-table :data="getData.tableData" border style="width: 100%;"
				:header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
				<el-table-column prop="id" label="id" width="180" />
				<el-table-column prop="applyTime" label="申请时间" width="180" />
				<el-table-column prop="status" label="结果" />
				<el-table-column prop="rejectionReason" label="意见" />
				<el-table-column label="操作">
					<template #default="scope">
						<el-button :disabled="getData.cxsqDisabled" size="mini" type="primary" plain
							@click="repeatApply(scope.row.id)">重新提交</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script setup>
import { onActivated, onMounted, ref, reactive, getCurrentInstance, defineEmits } from 'vue'
import { uploadFundFile, openFundApply, getFundApplyStatus, openFundRepeatApply, delistingLiquidation } from '../fundApi.js'
import { ElMessage, ElLink, ElMessageBox } from 'element-plus';
import PriceList from '../../../../components/OpenFund/priceList.vue'//申请
import dayjs from "dayjs"

let input = ref('')//创建
let filesUrls = [];
let getData = reactive({
	fundId: ref(null),
	tableData: [],
	file: {
		"fileName": '',
		"fileUrl": ''
	},
	showFile: ref(false),
	cxsqDisabled: ref(false),
	qsDisabled: ref(true),
	fundNameDisabled: ref(false),
	tjDisabled: ref(true),
	vueExample: {}
})

onMounted(() => {
	determineIdentity()
	getStatus();
	getData.vueExample = getCurrentInstance()
})

const emits = defineEmits(['changeCancel', 'getValue']);

function setFundId(fundId) {
	emits('getFundId', fundId)
}


function determineIdentity() {
	if (JSON.parse(sessionStorage.getItem('classInfo')).roleName === '基金经理') {
		getData.qsDisabled = false
	} else (
		getData.qsDisabled = true
	)
}

function dw(fileUrl, fileName) {
	let url = "/tradelabLx/documentSharing/download/openFunddFile"
	let params = {
		fileName: fileName,
		url: fileUrl
	}
	const { proxy } = getData.vueExample
	proxy.$DownloadAsExcel(url, params, fileName)
}

function getStatus() {

	var formData = new FormData();
	formData.append("acid", sessionStorage.getItem("acid"));
	getFundApplyStatus(formData).then(res => {
		if (res.data.data != null) {
			//sessionStorage.setItem("fundId",res.data.data.id)
			setFundId(res.data.data.id);
			getData.fundId = res.data.data.id;
			input.value = res.data.data.fundName;
			let tab = {
				"id": res.data.data.id,
				"applyTime": dayjs(res.data.data.applyTime).format("YYYY-MM-DD HH:mm:ss"),
				"status": res.data.data.status,
				"rejectionReason": res.data.data.rejectionReason
			}
			if (res.data.data.status == '申请中' || res.data.data.status == '审批通过' || res.data.data.status == '已退市') {
				getData.cxsqDisabled = true
				getData.fundNameDisabled = true
			} else {
				getData.cxsqDisabled = false;
				getData.fundNameDisabled = false
			}

			if (res.data.data.status == '审批通过' || res.data.data.status == '已退市') {
				getData.qsDisabled = false;
				getData.fundNameDisabled = true
			} else {
				getData.fundNameDisabled = false
			}

			getData.tableData.push(tab);
			if (res.data.data.files.length != 0) {
				getData.file = { "fileName": res.data.data.files[0].fileName, "fileUrl": res.data.data.files[0].fileUrl };

				let file2 = {
					"fileName": res.data.data.files[0].fileName,
					"fileType": res.data.data.files[0].fileType,
					"fileUrl": res.data.data.files[0].fileUrl
				}
				filesUrls.push(file2);
				getData.showFile = true;
			} else {
				getData.showFile = false;
			}
		} else {
			setFundId(undefined);
			getData.tjDisabled = false;
		}
		emits('getValue', res.data.data.status)
	});
}

function qs() {
	ElMessageBox.confirm(
		'清算将退出市场，是否确认清算?',
		'清算确认',
		{
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning',
		}
	)
		.then(() => {
			var formData = new FormData();
			formData.append("fundId", getData.fundId);
			console.log(getData.fundId);
			delistingLiquidation(formData).then(res => {
				if (res.data.code == '200') {
					ElMessage({
						type: 'success',
						message: '清算成功！'
					})
					input = ''
					location.reload()
				} else {
					ElMessage({
						type: 'error',
						message: res.data.msg
					})
				}
			})
		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: '取消',
			})
		})
}
function uploadFile(params) {
	filesUrls = [];
	const _file = params.file;
	var formData = new FormData();
	formData.append("file", _file);
	uploadFundFile(formData).then(res => {
		let file = {
			"fileName": res.data.data.fileName,
			"fileType": res.data.data.fileType,
			"fileUrl": res.data.data.fileUrl
		};
		filesUrls.push(file);
		getData.file = { "fileName": res.data.data.fileName, "fileUrl": res.data.data.fileUrl };
		getData.showFile = true;
	});
};

function removeFile() {
	filesUrls = [];
}

function submit() {
	if (input.value == '') {
		ElMessage({ type: 'error', message: '基金名称不能为空！' });
		return;
	}

	if (filesUrls.length == 0) {
		ElMessage({ type: 'error', message: '必须上传招募说明书！' });
		return;
	}

	let data = {
		"filesUrls": filesUrls,
		"acid": sessionStorage.getItem("acid"),
		"fundName": input.value,
		"applyer": JSON.parse(sessionStorage.getItem("user")).userId,
		"groupId": JSON.parse(sessionStorage.getItem("groupId")),
		"courseId": sessionStorage.getItem('classId')
	};
	openFundApply(data).then(res => {
		if (res.data.code == '200') {
			ElMessage({ type: 'success', message: '申请成功！请等待审批！' })
			getStatus();
		} else {
			ElMessage({ type: 'error', message: res.data.msg })
		}
	});
};

function repeatApply(fundId) {
	location.reload()
	if (input.value == '') {
		ElMessage({ type: 'error', message: '基金名称不能为空！' });
		return;
	}

	if (filesUrls.length == 0) {
		ElMessage({ type: 'error', message: '必须上传招募说明书！' });
		return;
	}

	// var formData = new FormData();
	// formData.append("fundId", fundId);
	let data = {
		"filesUrls": filesUrls,
		"fundName": input.value,
		"fundId": fundId
	}
	openFundRepeatApply(data).then(res => {
		if (res.data.code == '200') {
			ElMessage({ type: 'success', message: '重新申请成功！请等待审批！' })
			getStatus();
		} else {
			ElMessage({ type: 'error', message: res.data.msg })
		}
	})
};



const tableReta = [
	{ Fees: '管理费', modeRate: '1.50%' },
	{ Fees: '托管费', modeRate: '0.25%' }
]
defineExpose({
	qs
});
</script>

<style lang="less" scoped>
p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #03032C;
}

.recruit {
	width: 400px;
	height: 100px;
	border: 1px solid;
	display: block;
	text-align: center;
	line-height: 100px;
}

.fileNameText {
	// background: rgba(36, 136, 251, 0.06);
	// border: 1px solid rgba(36, 136, 251, 0.16);
	margin-left: 5px;
	margin-right: 15px;
	font-size: 16px;
	// border-radius: 15px;
	position: relative;
	top: 3px;
	color: #f58080 !important;
}

.fileNameText:hover {
	color: #f23c3c !important;
	cursor: pointer;
}

:deep(.el-form-item__label) {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #03032C;

}

.upload-button {
	background: rgba(36, 136, 251, 0.06);
	border: 1px solid rgba(36, 136, 251, 0.16);
	color: #2488FB !important;
}

.upload-button:hover {
	background-color: #2488FB !important;
	color: #fff !important;
}

.upload-buttonDisabled {
	background: rgba(119, 119, 120, 0.06);
	border: 1px solid rgba(133, 134, 134, 0.16);
	color: #919397 !important;
}

.upload-buttonDisabled:hover {
	background: rgba(119, 119, 120, 0.06);
	border: 1px solid rgba(133, 134, 134, 0.16);
	color: #919397 !important;
}

:deep(.el-button--primary.is-plain) {
	color: rgba(36, 136, 251) !important;
	background-color: #ecf5ff !important;
	border-color: #b3d8ff !important;
}

.file-name {
	border-radius: 6px;
	border: 1px solid #BEDCFE;
	max-width: 500px;
	padding: 12px;
	display: flex;
	justify-content: space-between;
}

:deep(.el-button--primary.is-plain.is-disabled) {
	background: #F2F3F6 !important;
	border-radius: 4px !important;
	border: 1px solid #ECECED !important;
	color: #B5B5C0 !important;
}

:deep(.el-button--primary.is-plain) {
	background-color: #fff;
}

.p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #03032C;
}</style>



