export const gropData = {
    peopleList : [
        {name:'小组ID',text: 'groupId'},
        {name:'学号',text: 'numberId'},
        {name:'人员名称',text: 'name'},
        {name:'人员职务',text: 'memberRoleName'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: false,
    peopleData:[],
    tableHeight: 470,
    operation: ['删除'],
    peopleValue: '',
    peopleOptions: [],

    nowJob: '',
    nowJobOptions: [],
}