export const userInfo = () => {
  //获取用户信息
  const userInfo = JSON.parse(sessionStorage.getItem("user")) || {};
  return {
    userName: userInfo.username,
    userId: userInfo.userId,
    schoolId: userInfo.schoolId || 50000000000000,
    roleName: userInfo.sysRoleList
      ? userInfo.sysRoleList[0].roleName
      : userInfo.userRoleName,
    //   matchType: mega.matchType || 0,
    //   benchmark: mega.benchmark,
    //   competitionId: mega.competitionId,
  };
};
