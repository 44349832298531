import axios from '@/axios/globalaxios'

// 查询班级下的学生
export const findUserByClassId = data =>{
    return axios({
        url: "/tradelabLx/course/findUserByClassId",
        method: 'post',
        data:data
    })
}
// 移除学生到 课程外
export const eliminateUsers = data =>{
    return axios({
        url: "/tradelabLx/course/eliminateUsers",
        method: 'post',
        data:data
    })
}
// 移除学生到 课程外
export const findDoesNotExistUserByClassId = data =>{
    return axios({
        url: "/tradelabLx/course/findDoesNotExistUserByClassId",
        method: 'post',
        data:data
    })
}
// 将用户拉入课程
export const pullInCourse = data =>{
    return axios({
        url: "/tradelabLx/course/pullInCourse",
        method: 'post',
        data:data
    })
}
// 同步课程下的学生信息
export const syncStudent = data =>{
    return axios({
        url: "/tradelabLx/financial/viewSchoolCoursesByCourseId",
        method: 'post',
        data:data
    })
}



