<template>
  <div>
    <el-form>
      <el-form-item>
        <card class="recruit">净值图</card>
      </el-form-item>
      <el-form-item>
        <card class="recruit">愿意公开的路演报告</card>
      </el-form-item>
      <el-form-item>
        <card class="recruit">当前规模总资产</card>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>

</script>
<style lang="less" scoped>
.recruit {
  width: 400px;
  height: 100px;
  border: 1px solid;
  display: block;
  text-align: center;
  line-height: 100px;
}
</style>