// 现有研究报告
import { getDrafrLists } from "../upreport-manage/Upreport-Draft/drafrMethods";
import {
  updTFundFundfiles,
  findTargetamountByAcid,
  findStockPoolByAcid,
  consolidatedStockAdjustmentTarget,
  findFileByFileId,
  combinedCurrency,
  findForeignExchangeByAcid,
} from "./stockApi";
import { foreignExchangeReportsToEdit } from "@/views/course-manage/upreport-manage/UprePort-Edit/editApi.js";
import { ElMessage } from "element-plus";
import { cloneDeep } from "lodash";
import { findForeignFileByFindId } from "@/views/course-manage/upreport-manage/Upreport-State/startApi.js";

export const getStockLists = (data) => {
  getDrafrLists(data,undefined,'researchReport');
};
// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getStockLists(data);
};
// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getStockLists(data);
};
// 现有目标仓位
export const getStockListPlans = (data) => {
  data.loadingPlan = true;
  let parame = {
    // fmid:1145,
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    exchange: data.routeId,
    lastState: 1,
    pageSize: data.pageSizePlan,
    page: data.currentPagePlan,
  };
  if (data.financeRadio == "外汇") {
    findForeignExchangeByAcid(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTablePlan = getMoneyName(newRes.currencyTargetAmountPageInfos.list);
        data.totalPlan = newRes.currencyTargetAmountPageInfos.total;
        setTimeout(() => {
          data.loadingPlan = false;
        }, 300);
      }
    });;
  } else {
    findTargetamountByAcid(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTablePlan = newRes.tfundTargetamountPageInfo.list;
        data.totalPlan = newRes.tfundTargetamountPageInfo.total;
        setTimeout(() => {
          data.loadingPlan = false;
        }, 300);
      }
    });
  }
  
};
// 切换条数
export const sizeChangePlans = (val, data) => {
  data.pageSizePlan = val;
  getStockListPlans(data);
};
// 切换页数
export const currentChangePlans = (val, data) => {
  data.currentPagePlan = val;
  getStockListPlans(data);
};
// 现有可用
export const getStockListUsas = (val, data) => {
  data.loadingUsa = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    pageSize: data.pageSizeUsa,
    page: data.currentPageUsa,
    fileID: data.fileId,
  };
  if (data.financeRadio == "外汇") {
    if (val.fileState == 1) {
      data.spreadTableUsa = val.currencyList?(changeCurrencyOptions(val.currencyList)):[];
      data.totalUsa = val.currencyList.length;
      data.loadingUsa = false;
    } else {
      data.spreadTableUsa = [];
      data.totalUsa = 0;
      data.loadingUsa = false;
    }
  } else {
    findStockPoolByAcid(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTableUsa = newRes.returnStockPoolInformations.list;
        data.totalUsa = newRes.returnStockPoolInformations.total;
        setTimeout(() => {
          data.loadingUsa = false;
        }, 300);
      }
    });
  }
};

//修改币种简称
export const changeCurrencyOptions = (data) => {
  let arr = data
  let currencyOptions = JSON.parse(localStorage.getItem("CurrencyOptions"));
  arr.forEach((element) => {
    element.id = element.currencyId;
    for (let index = 0; index < currencyOptions.length; index++) {
      if ((element.name == currencyOptions[index].label)) {
        element.abbr = currencyOptions[index].abbr;
      }
    }
  });
  return arr
};
// 切换条数
export const sizeChangeUsas = (val, data) => {
  data.pageSizeUsa = val;
  getStockListUsas(val,data);
};
// 切换页数
export const currentChangeUsas = (val, data) => {
  data.currentPageUsa = val;
  getStockListUsas(val,data);
};
// 调整目标
export const getStockListAlls = (data) => {};
// 切换条数
export const sizeChangeAlls = (val, data) => {
  data.pageSizeAll = val;
  getStockListAlls(data);
};
// 切换页数
export const currentChangeAlls = (val, data) => {
  data.currentPageAll = val;
  getStockListAlls(data);
};

//查看报告详情
export const getMores = (codes, data) => {
  if (data.financeRadio == "外汇") {
    findForeignFileByFindId({ fileId: codes[0] }).then((res) => {
      console.log("res",res);
      if (res.data.code == "200") {
        data.valueFile = res.data.data.files;
      }
    });
  } else {
    findFileByFileId({ fieldIds: codes }).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        //获取内容
        data.valueFile = newRes.tfundFundfiles[0].files;
      }
    });
  }
  
};

export const clickButtons = (val, data) => {
  if (val.type === "查看") {
    data.diaStart = true;
    let codes = [];
    codes.push(val.item.fileId);
    getMores(codes, data);
    data.fileName = val.item.name;
  } else {
    editFiles(val, data);
  }
};
// 报告操作
export const editFiles = (val, data) => {
  if (val.item.fileState !== 0)
    return ElMessage.warning("报告状态只能编辑一次");
  let type = val.type === "通过" ? 1 : -2;
  let parame = {
    tfundFundfiles: {
      fileId: val.item.fileId,
      fileState: type,
    },
  };
  if (data.financeRadio == "外汇") {
    let newParame = {
      currencyFiles: parame.tfundFundfiles,
    };
    foreignExchangeReportsToEdit(newParame).then((res) => {
      if (res.data.code === "200") {
        getStockLists(data);
        getStockListUsas(val,data);
      }
    });
  } else {
    updTFundFundfiles(parame).then((res) => {
      if (res.data.code === "200") {
        getStockLists(data);
        getStockListUsas(val,data);
      }
    });
  }
  
};

export const handleSelectionChanges = (val, data, type) => {
  if (type == '调整目标') { 
    data.rightMoveList=[]
  } else {
    data.selectList=[]
  }

  for (let i in val) {
    
    if (data.financeRadio == "外汇") {
      let targetAmount = val[i].targetAmount ? val[i].targetAmount : 0;
      if (type == "调整目标") {
        data.rightMoveList.push({
          currencyId: val[i].currencyId,
          name: val[i].name,
          targetAmount,
        });
      } else {
        data.selectList.push({
          currencyId: val[i].currencyId,
          name: val[i].name,
          targetAmount,
        });
      }
    } else {
      let num = val[i].num ? val[i].num : 0;
      if (type == "调整目标") {
        data.rightMoveList.push({
          marketSID: val[i].marketSID,
          name: val[i].name,
          num,
          fileid: val[i].fileid,
          exchange: val[i].exchange.toString(),
        });
      } else {
        data.selectList.push({
          marketSID: val[i].marketSID,
          name: val[i].name,
          num,
          fileid: val[i].fileid,
          exchange: val[i].exchange.toString(),
        });
      }
    }
    
  }
};
// 添加数量到调整目标
export const rightMoves = (refs, data) => {
  if (data.selectList.length === 0)
    return ElMessage.warning("至少选择一条数据");
    if(data.numInput===0) return ElMessage.warning("数量不能为0");
  // if (data.numInput<=0) return ElMessage.warning('请输入数量')
  for (let i in data.selectList) {
    if (data.financeRadio == "外汇") { 
      data.selectList[i].targetAmount = data.numInput;
    } else {
      data.selectList[i].num = data.numInput;
    }
  }
  data.spreadTableAll = data.spreadTableAll.concat(data.selectList);
  // data.spreadTableAll.push(data.selectList)
  let parame = {
    mergedStockTargets: cloneDeep(data.spreadTableAll),
  };
  if (data.financeRadio == "外汇") {
    parame.currencyTargetAmounts = parame.mergedStockTargets;
    combinedCurrency(parame).then((res) => {
      if (res.data.code === "200") {
        data.spreadTableAll = [];
        data.spreadTableAll = getMoneyName(res.data.data.currencyTargetAmounts);
        data.numInput = 0;
      }
    });
  } else {
    consolidatedStockAdjustmentTarget(parame).then((res) => {
      if (res.data.code === "200") {
        data.spreadTableAll = [];
        data.spreadTableAll = res.data.data.collect;
        data.numInput = 0;
      }
    });
  }
  
  refs.toggleSelection();
};

//获取币种名称

export const getMoneyName = (datas) => { 
  let currencyOptions = JSON.parse(localStorage.getItem("CurrencyOptions"));
  datas.forEach((item) => {
    // item.directionName = item.direction == 0 ? "买" : "卖";
    for (let i = 0; i < currencyOptions.length; i++) {
      if (item.currencyId == currencyOptions[i].value) {
        item.name = currencyOptions[i].label;
        item.abbr = currencyOptions[i].abbr;
        
      }
    }
  });

  return datas;
}

// 减除数量到现有可用
export const leftMoves = (refs, data) => {
  if (data.rightMoveList.length === 0)
    return ElMessage.warning("至少选择一条数据");
  let arr = cloneDeep(data.rightMoveList).map((v) => v.marketSID);
  data.rightMoveList.forEach((element) => {
    if (data.financeRadio == "外汇") {
      element.targetAmount = Number(element.targetAmount) - Number(data.numInput);
    } else {
      element.num = Number(element.num) - Number(data.numInput);
    }
    
  });
  // data.spreadTableAll = cloneDeep(data.spreadTableAll.filter(item => {
  //   console.log('ITEM',item);
  //   if(!arr.includes(item.marketSID)){
  //     return item;
  //   }
  // }))
  data.rightMoveList.forEach((item) => {
    for (let i = 0; i < data.spreadTableAll.length; i++) {
      if (data.financeRadio == "外汇") {
        if (data.spreadTableAll[i].currencyId == item.currencyId) {
          data.spreadTableAll[i] = cloneDeep(item);
        }
      } else {
        if (data.spreadTableAll[i].marketSID == item.marketSID) {
          data.spreadTableAll[i] = cloneDeep(item);
        }
      }
      
    }
  })
  // for (let i in arr) {
  //   arr[i].num = -data.numInput;
  // }
  // data.spreadTableAll = data.spreadTableAll.concat(arr);
  let parame = { mergedStockTargets: cloneDeep(data.spreadTableAll) };
  if (data.financeRadio == "外汇") {
    parame.currencyTargetAmounts = parame.mergedStockTargets;
    combinedCurrency(parame).then((res) => {
      if (res.data.code === "200") {
        data.spreadTableAll = [];
        data.spreadTableAll = getMoneyName(res.data.data.currencyTargetAmounts);
        data.numInput = 0;
      }
    });
  } else { 
    consolidatedStockAdjustmentTarget(parame).then((res) => {
      if (res.data.code === "200") {
        data.spreadTableAll = [];
        data.spreadTableAll = res.data.data.collect;
        data.numInput = 0;
      }
    });
  }
  
  refs.toggleSelection();
};

// 编写调仓报告按钮
export const editUpreports = (router, data) => {
  let arr = cloneDeep(data.rightMoveList);
  if (arr.length === 0) return ElMessage.warning("至少选择一个调整目标");
  // if (data.direction != 0 && data.direction != 1) {
  //   console.log("data.direction", data.direction);
  //   return ElMessage.warning("请选择买卖方向");
  // } 
  // for (let i in arr){
  //     if (arr[i].num <= 0){
  //         ElMessage.warning('请选择数目大于0的目标')
  //         return
  //     }
  // }
  // arr.forEach((item) => {
  //   item.direction = Number(data.direction);
  // })
  arr = dealCodeSearch(arr, data);
  router.push({
    path: "/course/report/edit",
    query: { pageType: 4,financeRadio:data.financeRadio , value: JSON.stringify(arr) },
  });
};

export const dealCodeSearch = (arrs,data) => {
  for (let i in arrs) {
    arrs[i].acid = JSON.parse(sessionStorage.getItem("acid"));
    arrs[i].laststate = 0;
    if (data.financeRadio == "外汇") {
      arrs[i].lastState = 0;
    }
    arrs[i].fundfile = arrs[i].fileid;
    arrs[i].targetamount = arrs[i].num;
    arrs[i].internalsid = arrs[i].marketSID;
    arrs[i].exchange = arrs[i].exchange;
    delete arrs[i].fileid;
    delete arrs[i].num;
    delete arrs[i].name;
    delete arrs[i].marketSID;
  }
  return arrs;
};
export const highlightChanges = (val, data) => {
  getStockListUsas(val,data);

}
