export const props = {
    code:{
        type: String,
        default: 'sh600000'
    },
    codeType:{
        type: Number,
        default: 1
    },
}
