<template>
  <div class='industry-allocation'>
    <tab-table
      :columns="columns"
      :findApi="findIndustryAllocation"
      :exportApi="exportIndustryAllocation"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { findIndustryAllocation, exportIndustryAllocation } from '../apis'
import { industryAllocationData } from '../datas'
import {} from '../methods'

export default defineComponent({
  name: "industry-allocation",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(industryAllocationData)
    onMounted(() => {
        
    })
    
    return{
      ...toRefs(data),
      findIndustryAllocation,
      exportIndustryAllocation,
    }
  }
})
</script>

<style scoped lang="less">
.industry-allocation {
  margin-top:60px;
}
</style>