<template>
  <div class="menuForm-components">
    <el-dialog width="30%" v-model="dialogTableVisible" title="菜单" destroy-on-close @close="close">
      <div class="main">
        <el-tree v-loading="loading" ref="treeRef" node-key="id" default-expand-all show-checkbox :data="propsMenu"
          :default-checked-keys="defaultList" :props="defaultProps">
        </el-tree>
      </div>
      <div>
        <el-button @click="getCheckedNodes">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, defineEmits, onMounted, getCurrentInstance } from 'vue';
import { findResourceByRoleId, saveRoleResource } from "../dataApi.js"
import { ElMessage } from "element-plus";
import { resources } from "@/components/MenuForm/dataApi.js"
export default defineComponent({
  name: 'MenuList',
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx) {
    const defaultProps = {
      children: 'children',
      label: 'label',
    }
    const { proxy } = getCurrentInstance();
    const treeRef = ref()
    const form = reactive({
      dialogTableVisibleForm: false,
      defaultList: [],
      loading: false,
      propsMenu: [
        {
          id: 50000003730017,
          label: '首页',
        },
        {
          // 教师 
          id: 2,
          label: '教师',
          children: [
            {
              id: 5,
              label: '小组管理',
            },
            {
              id: 6,
              label: '小组排名',
            },
          ],
        },
        {
          id: 3,
          label: '大赛',
          children: [
            {
              id: 7,
              label: '大赛管理',
            },
            {
              id: 8,
              label: '大赛排名',
            },
          ],
        }]
    })
    const setCheckedKeys = (list) => {
      let arr = form.propsMenu.filter((item) => {
        if (item?.children && item.children.length > 0) {
          return item
        }
      })
      let newArr = []
      arr.forEach((item) => {
        newArr.push(item.id)
      })
      let newList = list.filter((element) => {
        if (!newArr.includes(element)) {
          return element
        }
      })
      proxy.$refs['treeRef'].setCheckedKeys(newList)

      form.loading = false;
    }
    onMounted(() => {
      form.loading = true;
      getUserList();


    })
    let formatTree = () => {
      let propsMenu = [];
      form.propsMenu.forEach((item, index) => {
        let obj = {
          id: item.id,
          label: item.urlDescription,
        }
        if (item.childResources.length > 0) {
          obj.children = [];
          item.childResources.forEach(elem => {
            let newObj = {
              id: elem.id,
              label: elem.urlDescription,
            }
            obj.children.push(newObj)
          })
        }
        propsMenu.push(obj)
      })
      form.propsMenu = propsMenu;
    }
    let getUserList = () => {
      // 查找全部资源应该不需要传参数，直接返回所有的数据，返回的数据是父子关系还是平级关系需要前端处理
      let param = {
        pageIndex: 1,
        pageSize: 100,
      }
      resources(param).then((res) => {
        form.propsMenu = res.data.data.resourceVOList;
        form.propsMenu.sort((a, b) => {
          return a.sort - b.sort
        })
        form.propsMenu.forEach((item)=>{
          if( item?.childResources.length>0){
            item.childResources.sort((a, b) => {
          return a.sort - b.sort
        })
          }
         
        })
        formatTree()
        query()
      })
    }
    let query = () => {
      let param = {
        sysRoleId: props.id
      }

      findResourceByRoleId(param).then((res) => {
        let checkoutList = res.data.data.assignedResourceList.map((item) => {
          return item.id
        })

        setCheckedKeys(checkoutList)
      })
    }
    //添加菜单
    const close = () => {
      ctx.emit('close', false)
    }
    let closeForm = () => {
      form.dialogTableVisibleForm = false;
    }
    let submit = (params) => {
      saveRoleResource(params).then((res) => {
        if (res.data.code === '200') {
          ctx.emit('close', false)
          ElMessage.success(res.data.msg)
        } else {
          ElMessage.error(res.data.msg)
        }
      })
    }
    let getCheckedNodes = () => {
      let arr = proxy.$refs['treeRef'].getCheckedNodes(false, true).map((item => {
        return item.id
      }))
      let param = {
        sysRoleId: props.id,
        sysResourceIds: arr
      }
      submit(param)
    }
    return {
      ...toRefs(form),
      close,
      closeForm,
      defaultProps,
      getCheckedNodes
    }
  }
})

</script>
<style lang="less" scoped>
.menuForm-components {
  .main {
    height: 500px;
    max-height: 500px;
    overflow: auto;
    margin-bottom: 20px;
  }

}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*滚动条滑块*/
::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

/deep/ .row-expand-cover .el-table__expand-icon {
  visibility: hidden;
}
</style>