<template>
  <div>
    <!-- 外汇-交易-资金流水 -->
    <div style="margin:5px 0 20px 20px;height: 30px;">
      <!-- 币种类型： -->
      <div style="float: left;">
        <el-select v-model="accountType" clearable @change="historicalTimehistoricalTime" placeholder="请选择币种" style="width: 280px;margin-right: 5px;" size="mini">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr" />
      </el-select>
        <el-tooltip  effect="dark" content="选择币种查询" placement="top">
          <i style="color: #3B6EFB" class="el-icon-warning"></i>
        </el-tooltip>
      </div>
    
      <!-- 查询历史 -->
      <div style="float: right;"> 
          <!-- 查询历史 &nbsp -->
        <el-tooltip effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
        <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期"
          value-format="YYYY-MM-DD HH:mm:ss" end-placeholder="结束日期" @change="historicalTimehistoricalTime">
        </el-date-picker>
      </div>
      
    </div>
    <!-- 外汇个人交易-成交详情 -->
    <!-- <div style="margin-top:20px">币种类型：
      <el-select v-model="accountType" clearable @change="historicalTimehistoricalTime" placeholder="请选择币种类型！" style="width: 280px;" size="mini">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr" />
      </el-select>
      <span style="padding-left: 50px;"> 查询历史 &nbsp
        <el-tooltip class="item" effect="dark" content="默认查询当日" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
      </span>
      <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期"
        value-format="YYYY-MM-DD HH:mm:ss" end-placeholder="结束日期" @change="historicalTimehistoricalTime">
      </el-date-picker>
    </div> -->

    <!--成交详情表 -->
    <div style="margin-top:20px;margin-left: 20px;">
      <el-table :data="forexOrderVOList" stripe style="width: 100%"
        :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
        <el-table-column label="交易时间" prop="createTime" width="230" />
        <el-table-column label="买入币种" prop="buyerCurrency" />
        <el-table-column label="卖出币种" prop="sellerCurrency" />
        <el-table-column label="卖出金额" prop="purchasingAmounts" />
        <el-table-column label="交易汇率" prop="exchangeRate" />
        <el-table-column label="订单状态" prop="orderState" />
        

      </el-table>
    </div>
    <!-- 分页 -->
    <div class="demo-pagination-block">
      <el-pagination :current-page="pageIndex" :page-size="pageSize" :page-sizes="[5, 10, 15, 20]"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="sizeChange"
        @current-change="currentChange" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
import { details, findCurrency, historicalTimehistoricalTimes } from './ForeignExchangeTransactionDetailsMethods'
export default {
  setup() {
    onMounted(() => {
      details(data),
        findCurrency(data)
    })
    let data = reactive({
      historicalTime: ['',''],
      accountType: '',//币种类型
      pageIndex: 1,//当前页面为1
      pageSize: 10,//每页业条数
      total: '',//条目总数
      options: [
        { value: '25', id: 1, },
        { value: '45', id: 3, },
        { value: '445', id: 4, },
        { value: '3445', id: 5, },
        { value: '4544', id: 6, },
      ],
      forexOrderVOList: [],//成交详情订单
      currencyData: [],//货币
    })
    let sizeChange = (val) => {
      data.pageSize = val
      details(data)
    }
    let currentChange = (val) => {
      data.pageIndex = val
      details(data)
    }
    let historicalTimehistoricalTime = () => {
      historicalTimehistoricalTimes(data)
    }
    return {
      ...toRefs(data),
      sizeChange,
      currentChange,
      historicalTimehistoricalTime,
    }
  },
}
</script>
<style lang="less" scoped>
.demo-pagination-block {
  margin-top: 20px;
  float: right
}
</style>