import {findAllGroupName, findAllCourseName, findAcidByCourseID, findAllAcidByCompetitionID, findList, updateEntrust, dealreliquidate} from './liquidationApi'
import {ElMessage} from 'element-plus'
import dayjs from "dayjs"
import {cloneDeep} from "lodash"

// 获取清算列表
export const getLists = (val, data) => {
    data.step = 0;
    data.selectList = [];
    const api = data.labelType === '课程' ? findAllCourseName : findAllGroupName;
    data.loading = true
    api({}).then(res=>{
        const resData = res.data
        data.spreadTable = resData
        setTimeout(()=>{
            data.loading = false
        }, 300)
    })
}

// 获取清算 acid
export const getAcids = (data) =>{
    if (data.loading) return
    if(data.selectList.length === 0) return ElMessage.warning('至少选择一组数据')
    data.loading = true
    const key = data.labelType === '课程' ? 'courseID' : 'competitionID'
    const api = data.labelType === '课程' ? findAcidByCourseID : findAllAcidByCompetitionID
    const reqData = {
        listCompetitionID: data.selectList.map(v=>v[key])
    }
    api(reqData).then(res=>{
        const resData = res.data
        data.acidTable = resData
        data.dateRange = null
        data.step = 1
        data.acidSelectList = [];
        data.loading = false
    })
}

// 多选列
export const handleSelectionChanges = (val,data) =>{
    data.step === 1 ? data.acidSelectList = val : data.selectList = val
}

// 点击修改清算按钮 liq清算 edit编辑
export const liquidations = (mode, data, refs) =>{
    if (data.loading) return
    if (data.dateRange === null) return ElMessage.warning('请选择清算区间')
    if (data.acidSelectList.length === 0) return ElMessage.warning('至少选择一组数据')
    data.loading = true
    data.startTime = capDate(data.dateRange[0])
    data.endTime = capDate(data.dateRange[1])
    const reqData = {
        listacid: data.acidSelectList.map(v=>v.acid),
        startDate: data.startTime,
        endDate: data.endTime,
    }
    mode === 'liq' ? liq(reqData, data, refs) : edit(reqData, data)
}

const capDate = date => parseInt(dayjs(date).format('YYYYMMDD'))

const liq = (reqData, data, refs) => {
    dealreliquidate(reqData).then(res=>{
        const resData = res.data;
        if(resData.status === 0 || resData.code == 200) {
            ElMessage.success(resData.msg || '已进入清算阶段，请等待一段时间后再进行清算相关操作')
            // backInfo(data)
        }else {
            ElMessage.error(resData.msg || '清算失败')
        }
        data.loading = false
    })
}

// 查看修改历史委托单的数据
const edit = (reqData, data) => {
    findList(reqData).then(res=>{
        const resData = res.data;
        if(resData.status === 0 || resData.code == 200) {
            if(resData.data.dateList) {
                data.editMode = false
                // 初次打开修改历史委托单弹窗传值不同 返回值也不同
                data.acidOptions = reqData.listacid;
                data.dateOptions = resData.data.dateList;
                data.acidValue = "",
                data.dateValue = "",
                data.showRoleDia = true
            }else {
                data.editMode = true
                data.saveValue = reqData
            }
            data.scopeIndex = -1
            data.historyTable = resData.data.tradeOrdersHistoryList;
        }else {
            ElMessage.error(resData.msg || '查询失败')
        }
        data.loading = false
    })
}

export const findHistoryTables = (data) => {
    if (data.loading) return
    if (!data.acidValue) return ElMessage.warning('请选择账户ID')
    if (!data.dateValue) return ElMessage.warning('请选择日期')
    const reqData = {
        acid: data.acidValue,
        date: data.dateValue,
    }
    data.loading = true
    edit(reqData, data)
}

export const handleSaves = (data) => {
    data.loading = true
    data.scopeIndex = -1
    let historyTable = cloneDeep(data.historyTable)
    historyTable.forEach(h => {
        delete h.omhid
    })
    const reqData = {
        acid: data.saveValue.acid,
        date: data.saveValue.date,
        tradeOrdersHistoryList: historyTable,
    }
    updateEntrust(reqData).then(res=>{
        const resData = res.data
        if(resData.status === 0 || resData.code == 200) {
            ElMessage.success(resData.msg || '保存成功')
        }else {
            ElMessage.error(resData.msg || '保存失败')
        }
        data.loading = false
    })
}

// 取消修改
export const backInfo = (data,refs) =>{
    data.dateRange = null
    data.loading = false
    getLists(data.labelType, data)
    // refRemove(refs)
}

export const refRemove = (refs) => {
    refs.toggleSelection()
}