import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import Layout from "@/components/layout/index.vue";

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/index",
    meta: { requiteAuth: true },
//     children: [
//       {
//         path: "/index",
//         name: "homePage",
//         meta: { requiteAuth: true, title: "证券投资基金虚拟仿真平台" },
//         component: Home,
//         children:[{
//             path: "/index/moreNotice",
//             name: "moreNotice", //公告管理
//             meta: { requiteAuth: true, title: "公告管理" },
//             component: () => import("@/views/teacher-manage/notice-manage"),
//           },
//           {
//             name: "checkNotice", //添加报告
//             path: "/index/checkNotice",
//             meta: { requiteAuth: true, title: "查看公告" },
//             component: () =>
//               import("@/views/teacher-manage/notice-manage/addNotice"),
//           },
//         ]
//       },
//       {
//         path: "/Menu",
//         name: "/Menu",
//         meta: { requiteAuth: true, title: "菜单管理" },
//         component: () => import("@/views/Menu"),
//       },
//       {
//         path: "/information",
//         name: "/information",
//         meta: { requiteAuth: true, title: "金融知识库" },
//         component: () => import("@/views/information"),
//       },
//       {
//         path: "/userClass",
//         name: "/userClass",
//         meta: { requiteAuth: true, title: "课程管理" },
//         component: () => import("../views/course-manage/class-manage"),
//       },

//       {
//         path: "个人交易",
//         name: "CourseManage",
//         meta: { requiteAuth: true,title:'创建小组' },
//         component: () => import("../views/course-manage/index"),
//         children: [
//           {
//             path: "/course/home",
//             name: "HomeManage",
//             meta: { requiteAuth: true, title: "汇总统计" },
//             component: () => import("@/views/teacher-manage/notice-manage"),
//           },
//           {
//             path: "/course/summary/:id",
//             name: "summaryManage",
//             meta: { requiteAuth: true, title: "行情展示" },
//             component: () =>
//               import(
//                 /* webpackChunkName: "about" */ "../views/course-manage/summary-manage"
//               ),
//           },
//           {
//             path: "/course/group",
//             name: "groupManage",
//             meta: { requiteAuth: true, title: "组合管理" },
//             component: () => import("../views/course-manage/group-manage"),
//           },
//           {
//             path: "/course/trade/:id",
//             name: "tradeManage",
//             meta: { requiteAuth: true, title: "交易管理" },
//             component: () => import("../views/course-manage/trade-manage"),
//           },
//           {
//             path: "/course/trade/declare", //盘后申报
//             name: "declare",
//             meta: { requiteAuth: true, title: "交易管理" },
//             component: () => import("../views/course-manage/declare-manage"),
//           },
//           {
//             path: "/course/user/jobChange",
//             name: "jobChange",
//             meta: { requiteAuth: true, title: "历史记录" },
//             component: () =>
//               import("../views/course-manage/account-manage/job-change"),
//           },
//           {
//             path: "/course/user/account",
//             name: "account",
//             meta: { requiteAuth: true, title: "子账户管理" },
//             component: () =>
//               import("../views/course-manage/account-manage/grop-list"),
//           },
//           {
//             path: "/course/stock",
//             name: "stock",
//             meta: { requiteAuth: true, title: "调仓管理" },
//             component: () => import("../views/course-manage/stock-manage"),
//           },
//           {
//             path: "/course/control",
//             name: "control",
//             meta: { requiteAuth: true, title: "风控管理" },
//             component: () => import("../views/course-manage/control-manage"),
//           },
//           {
//             path: "/course/report/edit",
//             name: "reportEdit",
//             meta: { requiteAuth: true, title: "报告编写" },
//             component: () =>
//               import("../views/course-manage/upreport-manage/UprePort-Edit"),
//           },
//           {
//             path: "/course/report/draft",
//             name: "draft",
//             meta: { requiteAuth: true, title: "草稿箱" },
//             component: () =>
//               import("../views/course-manage/upreport-manage/Upreport-Draft"),
//           },
//           {
//             path: "/course/report/state",
//             name: "state",
//             meta: { requiteAuth: true, title: "状态展示" },
//             component: () =>
//               import("../views/course-manage/upreport-manage/Upreport-State"),
//           },
//           {
//             path: "/course/report/browser",
//             name: "browser",
//             meta: { requiteAuth: true, title: "报告浏览器" },
//             component: () =>
//               import("../views/course-manage/upreport-manage/Upreport-Browser"),
//           },
//           {
//             path: "/course/report/performance",
//             name: "index",
//             meta: { requiteAuth: true, title: "业绩报告" },
//             component: () =>
//               import("../views/course-manage/performance-manage"),
//           },
//           {
//             name: "/course/homework", //学生 作业评分
//             path: "/course/homework",
//             meta: { requiteAuth: true, title: "作业评分" },
//             component: () => import("../views/course-manage/home-work/index"),
//           },
//           {
//             name: "trade-personalstock",
//             path: "/course/trade-personal/stock",
//             meta: { requiteAuth: true, title: "行情展示" },
//             component: () => import("@/views/trade-personal/indexStock.vue"),
//           },
//         ],
//       },
// //  {
// //             name: "trade-personalstock",
// //             path: "/course/trade-personal/stock",
// //             meta: { requiteAuth: true, title: "行情展示" },
// //             component: () => import("@/views/trade-personal/indexStock.vue"),
// //           },
//       {
//         name: "megagame", //大赛管理
//         path: "/megagame",
//         component: () => import("@/views/megagame"),
//         meta: { requiteAuth: true, title: "大赛管理" },
//         children: [
//           {
//             path: "/megagame/rank", //大赛排名
//             name: "rank",
//             meta: { requiteAuth: true, title: "大赛管理" },
//             component: () => import("@/views/megagame/rank"),
//           },
//           {
//             path: "/megagame/key-ratio", //关键比率
//             name: "key-ratio",
//             meta: { requiteAuth: true, title: "关键比率" },
//             component: () => import("@/views/megagame/key-ratio"),
//           },
//           {
//             path: "/megagame/asset-allocation", //资产配置
//             name: "asset-allocation",
//             meta: { requiteAuth: true, title: "资产配置" },
//             component: () => import("@/views/megagame/asset-allocation"),
//           },
//           {
//             path: "/megagame/risk-analysis", //风险分析
//             name: "risk-analysis",
//             meta: { requiteAuth: true, title: "风险分析" },
//             component: () => import("@/views/megagame/risk-analysis"),
//           },
//           {
//             path: "/megagame/security", //重仓证券
//             name: "security",
//             meta: { requiteAuth: true, title: "重仓证券" },
//             component: () => import("@/views/megagame/security"),
//           },
//           {
//             path: "/megagame/industry-allocation", //行业配置
//             name: "industry-allocation",
//             meta: { requiteAuth: true, title: "行业配置" },
//             component: () => import("@/views/megagame/industry-allocation"),
//           },
//           {
//             path: "/megagame/history-return", //历史回报
//             name: "history-return",
//             meta: { requiteAuth: true, title: "历史回报" },
//             component: () => import("@/views/megagame/history-return"),
//           },
//           {
//             path: "/megagame/trade/:id", //交易管理
//             name: "trade",
//             meta: { requiteAuth: true, title: "交易管理" },
//             component: () => import("@/views/course-manage/trade-manage"),
//           },
//           {
//             path: "/megagame/group-manage", //教师端小组管理
//             name: "group-manage",
//             meta: { requiteAuth: true, title: "小组管理" },
//             component: () => import("@/views/megagame/participants-group"),
//           },
//           {
//             path: "/megagame/user-manage", //教师端成员管理
//             name: "user-manage",
//             meta: { requiteAuth: true, title: "成员" },
//             component: () => import("@/views/megagame/participants"),
//           },
//           {
//             path: "/megagame/participants", //学生端成员管理
//             name: "participants",
//             meta: { requiteAuth: true, title: "成员管理" },
//             component: () => import("@/views/megagame/participants-user"),
//           },
//           {
//             path: "/megagame/participants", //学生端成员管理
//             name: "participants",
//             meta: { requiteAuth: true, title: "成员管理" },
//             component: () => import("@/views/megagame/participants-user"),
//           },
//           {
//             path: "/megagame/groupList",
//             name: "/megagame/groupList",
//             meta: {requiteAuth: true, title: "小组列表" },
//             component: () =>
//               import("../views/course-manage/class-manage/group-list"),
//           },
//           {
//             path: "/megagame/groupListMega",
//             name: "groupListMega",
//             meta: { requiteAuth: true, title: "创建小组" },
//             component: () => import("../views/megagame/groupList"),
//           },
//           // {
//           //   path: '/megagame/participants/competition', //学生端成员管理
//           //   name: 'participants',
//           //   meta: {requiteAuth:true},
//           //   component: () => import('../views/teacher-manage/Liquidation'),
//           // },
//         ],
//       },
//       {
//         path: "/liquidation", //清算
//         name: "liquidation",
//         meta: { requiteAuth: true, title: "清算管理" },
//         component: () => import("@/views/teacher-manage/Liquidation"),
//       },
//       {
//         name: "userManage", //用户管理
//         path: "/userManage",
//         meta: { requiteAuth: true, title: "用户管理" },
//         component: () => import("@/views/system-manage/user-manage"),
//       },
//       {
//         //角色管理页面
//         name: "roleManage", //角色管理
//         path: "/roleManage",
//         component: () => import("@/views/system-manage/role-manage"),
//       },
//       {
//         name: "schoolManage", //学校管理
//         path: "/schoolManage",
//         meta: { requiteAuth: true, title: "学校管理" },
//         component: () => import("@/views/system-manage/school-manage"),
//       },
//       {
//         name: "noticeManage", //公告管理
//         path: "/noticeManage",
//         meta: { requiteAuth: true, title: "公告管理" },
//         component: () => import("@/views/system-manage/notice-manage"),
//         children:[{ 
//           name: "addNotice", //添加报告
//           path: "/noticeManage/addNotice",
//           meta: { requiteAuth: true, title: "添加公告" },
//           component: () =>
//             import("@/views/system-manage/notice-manage/addNotice"),
//         },]
//       },
//       {
//         name: "classManage", //教师 课程管理
//         path: "/classManage",
//         meta: { requiteAuth: true, title: "课程管理" },
//         component: () => import("@/views/teacher-manage/class-manage"),
//       },
//       {
//         name: "groupTeacherManage", //教师 小组管理
//         path: "/groupManage",
//         meta: { requiteAuth: true, title: "小组管理" },
//         component: () => import("@/views/teacher-manage"),
//         children: [
//           {
//             name: "groupRank", //教师 小组排名
//             path: "/groupManage/groupRank",
//             meta: { requiteAuth: true, title: "小组排名" },
//             component: () => import("@/views/teacher-manage/group-rank"),
//           },
//           {
//             name: "findReport", //教师 优秀研报
//             path: "/groupManage/findReport",
//             meta: { requiteAuth: true, title: "优秀研报" },
//             component: () => import("@/views/teacher-manage/fine-report"),
//           },
//           {
//             name: "classGroup", //教师 班级分组
//             path: "/groupManage/classGroup",
//             meta: { requiteAuth: true, title: "班级分组" },
//             component: () => import("@/views/teacher-manage/class-group"),
//           },
//           {
//             name: "modelEdit", //教师 班级分组
//             path: "/groupManage/modelEdit",
//             meta: { requiteAuth: true, title: "班级分组" },
//             component: () => import("@/views/teacher-manage/model-edit"),
//           },
//           {
//             name: "studentManage", //教师 学生管理
//             path: "/groupManage/studentManage",
//             meta: { requiteAuth: true, title: "学生管理" },
//             component: () => import("@/views/teacher-manage/student-manage"),
//           },
//           {
//             name: "key-ratios", //教师 关键比率
//             path: "/groupManage/megagame/key-ratio",
//             meta: { requiteAuth: true, title: "关键比率" },
//             component: () =>
//               import("../views/teacher-manage/exponent-manage/key-ratio"),
//           },
//           {
//             name: "asset-allocations", //教师 资产配置
//             path: "/groupManage/megagame/asset-allocation",
//             meta: { requiteAuth: true, title: "资产配置" },
//             component: () =>
//               import(
//                 "../views/teacher-manage/exponent-manage/asset-allocation"
//               ),
//           },
//           {
//             name: "risk-analysies", //教师 风险分析
//             path: "/groupManage/megagame/risk-analysis",
//             meta: { requiteAuth: true, title: "风险分析" },
//             component: () =>
//               import("../views/teacher-manage/exponent-manage/risk-analysis"),
//           },
//           {
//             name: "industry-allocations", //教师 行业配置
//             path: "/groupManage/megagame/industry-allocation",
//             meta: { requiteAuth: true, title: "行业配置" },
//             component: () =>
//               import(
//                 "../views/teacher-manage/exponent-manage/industry-allocation"
//               ),
//           },
//           {
//             name: "securitys", //教师 总仓证券
//             path: "/groupManage/megagame/security",
//             meta: { requiteAuth: true, title: "总仓证券" },
//             component: () =>
//               import("../views/teacher-manage/exponent-manage/security"),
//           },
//           //清算放到管理员里了，教师和学生端都不需要
//           {
//             name: "timeTurn", //教师 时间轮次
//             path: "/groupManage/timeTurn",
//             meta: { requiteAuth: true, title: "时间轮次" },
//             component: () => import("../views/teacher-manage/time-turn"),
//           },
//           {
//             name: "homework", //教师 作业评分
//             path: "/groupManage/homework",
//             meta: { requiteAuth: true, title: "作业评分" },
//             component: () =>
//               import("../views/teacher-manage/home-work/homeWork"),
//           },
//         ],
//       },
//     ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
  },
  // {
  //   path: "/login/Single",
  //   name: "logins",
  //   component: () => import("@/views/login/logins"),
  // },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

export default router;

// router.beforeEach((to, from, next) => {
//   if (to.meta.requiteAuth) {
//     //修改缓存方式
//     // if (localStorage.getItem("token") && sessionStorage.getItem("token"))
//       if (sessionStorage.getItem("token")) {
//         next();
//       } else {
//         // process.env.NODE_ENV ? next() : next({path:'/login'})
//         // next({path:'/login'})
//         next({ path: "/login/Single" });
//       }
//   } else {
//     next();
//   }
// });
