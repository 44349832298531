import {
  findForexFlowFunds,
  findCurrencyType
} from './CourseFundFlowApi.js'
import dayjs from "dayjs";
import {
  ElMessage
} from 'element-plus';
import {thousandthConversion} from '../commonMethods'
import { ergodic} from '../commonMethods'
// 查询已委托的外汇订单
export const entrustedOrders = (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    pageIndex: data.currentPage,
    pageSize: data.pageSize
  }
  findForexFlowFunds(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.total = newRes.data.total
      console.log(newRes, '78878787878');
      if (newRes.code == 200) {
        newRes.data.forexFlowFunds.forEach(item => {
          item.currencyAmounts = thousandthConversion( item.currencyAmount)
          item.currencyBalances = thousandthConversion( item.currencyBalance)
          item.exchangeRates = thousandthConversion( item.exchangeRate)
          item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
          if (item.orderState == 1) {
            item.orderState = "委托中"
          } else if (item.orderState == 2) {
            item.orderState = "已取消"
          } else if (item.orderState == 3) {
            item.orderState = "交易完成"
          }
        });
        data.loading = false
        data.forexOrderVOList = newRes.data.forexFlowFunds
      }
    }

  })
}

//查询货币
export const findCurrency = (data) => {
  findCurrencyType().then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.currencyData = newRes.data.currencyData
    }
  })
}

//资金流水历史交易
export const courseFundss = (data) => {
  console.log("data.historicalTime", data.historicalTime);
  let startTime = ''
  let endTime = '';
  if (data.historicalTime != null) {
    if (
      data.historicalTime[0] &&
      data.historicalTime[0] != null &&
      data.historicalTime[0] != undefined
    ) {
      startTime = data.historicalTime[0];
      endTime = data.historicalTime[1].substring(0, 11) + "23:59:59";
    }
  }
  let params = {
    acId:JSON.parse(sessionStorage.getItem('acid')),
    currency: data.accountType,
    pageIndex: data.currentPage,
    pageSize: data.pageSize,
    startTime: startTime,
    endTime: endTime,
  };
  findForexFlowFunds(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      newRes.data.forexFlowFunds.forEach(item =>{
        item.currencyAmounts = thousandthConversion( item.currencyAmount)
        item.currencyBalances = thousandthConversion( item.currencyBalance)
        item.exchangeRates = thousandthConversion( item.exchangeRate)
      })
      data.forexOrderVOList = newRes.data.forexFlowFunds
      data.total = newRes.data.total
      
      ergodic(data)
    }
  })
  data.loading = false
}