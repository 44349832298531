<template>
  <el-card class="box-card"  @click="checkCard">
    <template #header>
      <div class="card-header">
        <span class="title"> <strong>金融一班</strong> (2018)</span> 
      </div>
    </template>
    <div class="courseContainer">
        <div class="specific">
            <div class="item">
                <div class="label">教师：</div>
                <div class="props">教师乙</div>
            </div>
            <div class="item">
                <div class="label">学生人数：</div>
                <div class="props">6/10</div>
            </div>
            <div class="item">
                <div class="label">创建时间：</div>
                <div class="props">2021-01</div>
            </div>
            <div class="item">
                <div class="label">大赛时间：</div>
                <div class="props">2021-01 ~ 2021-10</div>
            </div>
        </div>
        <div class="set">
<!--            <span @click="setcourse(item)">设置</span>-->
        </div>
    </div>
  </el-card>
</template>

<script>
import {reactive,toRefs} from "vue"
import {useRouter} from "vue-router";
export default {
  name:'course',
  props:{
      item:{type:[Object,Number,String]}
  },
  setup(props,{emit}) {
    let router = useRouter()
   const setcourse = (item) => {
       emit('setcourse',item)
   }
    let checkCard = () =>{router.push('/groupManage/groupRank')}
    return {
      setcourse,
      checkCard
    }
  }
}
</script>

<style lang="less" scoped>
.box-card{
  cursor: pointer;
  height: auto;
  width: 100%;
    .title{
        display: inline-block;
        text-align: center;
        width: 100%;
        background: #FECD75;
        padding: 18px 20px;
        box-sizing: border-box;

    }
    &:deep(.el-card__header) {
        padding: 0 0 ;
        border-bottom-color: #FECD75 !important;
    }
    &:deep(.el-card__body) {
        padding: 0px 0;
    }
    .courseContainer{
        .specific{
            background: #FECD75;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            overflow: hidden;
            .item{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    padding: 0 40px;
                    padding-right: 10px;
                    margin-bottom: 10px;
                    font-size: 14px;
                    .label{
                        width: 100px;
                        background: rgba(228, 186, 109, 0.8);
                        text-align: center;
                        margin-right: 20px;
                        padding: 5px 0;
                        border-radius: 4px;
                    }

            }
        }
        .set{
            text-align: right;
            font-size: 14px;
            color:#1890FF;
            background: #fff;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}
</style>
