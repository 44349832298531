import {
  ElMessage
} from 'element-plus'
import dayjs from "dayjs";
import {
  forexOrders,
  findCurrencyType
} from './CourseTransactionDetailsApi'
import {thousandthConversion} from '../commonMethods'
import { ergodic} from '../commonMethods'
//获取成交数据
export const courseAccount = (data) => {
  findCurrency(data)
  let params = {
    orderStates: [3],
    acId: JSON.parse(sessionStorage.getItem('acid')),
    pageIndex: data.currentPage,
    pageSize: data.pageSize
  }
  forexOrders(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.total = newRes.data.total
      newRes.data.forexOrderVOList.forEach((item) => {
        console.log('item',item);
        item.purchasingAmounts = thousandthConversion( item.purchasingAmount)
        if (item.orderState == 3) {
          item.orderState = '交易完成'
        } 
       
      })
      data.forexOrderVOList = newRes.data.forexOrderVOList
      data.forexOrderVOList.forEach((item) => {
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
        item.capitalBalance = item.buyerCurrency + ':' + item.buyerBalance + item .sellerCurrency + ':' + item.sellerBalance
      })
      data.loading = false
    }
  })
}

//查询货币
export const findCurrency = (data) => {
  findCurrencyType().then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.currencyData = newRes.data.currencyData
    }
  })
}

// 账户历史 
export const accountHistorys = (data) => {
  let startTime = "";
  let endTime = "";
  if (data.historicalTime != null) {
    if (
      data.historicalTime[0] &&
      data.historicalTime[0] != null &&
      data.historicalTime[0] != undefined
    ) {
      startTime = data.historicalTime[0];
      endTime = data.historicalTime[1].substring(0, 11) + "23:59:59";
    }
  }
  let params = {
    acId:JSON.parse(sessionStorage.getItem('acid')),
    orderStates: [3],
    tradingDirection: data.accountType,
    startTime: startTime,
    endTime: endTime,
  };
  forexOrders(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      newRes.data.forexOrderVOList.forEach(item=>{
        if (item.orderState == 3) {
          item.orderState = "交易完成"
        }
        item.purchasingAmounts = thousandthConversion( item.purchasingAmount)
      })
      data.forexOrderVOList = newRes.data.forexOrderVOList
      data.total = newRes.data.total
      
      ergodic(data)

    }
  })
}