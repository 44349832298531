import axios from '@/axios/globalaxios'


//
export const findEvenWeeksByCourseId = data =>{
    return axios({
        url: "/tradelabLx/systemRating/findEvenWeeksByCourseId",
        method: 'post',
        data:data
    })
}

export const findScoreByCourseIdOrAcidAndUserId = data =>{
    return axios({
        url: "/tradelabLx/systemRating/findScoreByCourseIdOrAcidAndUserId",
        method: 'post',
        data:data
    })
}



