<template>
  <div class="login-body">
    <div class="main">
      <div class="login-img"></div>
      <div class="login-main">
        <h3>Trade Lab</h3>
        <h4>
          <span class="line"></span>
          <span>证劵投资基金虚拟仿真</span>
          <span>平台</span>

          <span class="line"></span>
        </h4>
        <div class="login-form">
          <el-form label-position="left" :rules="rules" :model="ruleForm" ref="loginForm" hide-required-asterisk
            autocomplete="off">
            <!-- <el-form-item label=" " prop="schoolId" class="lableName">
              <el-select :popper-append-to-body="false" v-model="ruleForm.schoolId" size="medium" style="width: 100%;"
                placeholder="请选择学校">
                <el-option v-for="item in options" :key="item.id" :label="item.schoolName" :value="item.id">
                </el-option>
              </el-select> -->
              <!-- <el-input type="text" placeholder="schoolName" v-model.trim="ruleForm.schoolName" autocomplete="off"></el-input> -->
            <!-- </el-form-item> -->
            <el-form-item label=" " prop="username" style="margin-top: 30px;" class="lableName">
              <el-input type="text" style="font-size: 16px;" placeholder="请输入学号" v-model.trim="ruleForm.username"
                autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="password" style="margin-top: 30px;" class="lableName">
              <el-input type="password" placeholder="请输入密码" v-model.trim="ruleForm.password" autocomplete="new-password"
                @keydown.enter="submitForm"></el-input>
            </el-form-item>
            <!--          <div style="color: #333">登录表示您已同意<a>《服务条款》</a></div>-->
            <el-form-item label=" ">
              <el-button class="login-btn" type="primary" @click="submitForm" color="#3B6EFB" :loading="loading">登录
              </el-button>
            </el-form-item>
            <el-form-item label=" " v-if="type">
              <el-button class="login-register" @click="registerRou" color="#3B6EFB" :loading="loading">注册
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios/globalaxios.js'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getSchools } from "@/views/system-manage/school-manage/schoolApi.js";
import { findCurrencyType } from "@/views/trade-personal/components/ForeignExchangeTransactions/ForeignExchangeTransactionsApi.js";

// import { localSet } from '@/utils'
import { ElMessage } from 'element-plus';
export default {
  name: 'Login',
  setup() {
    const router = useRouter()
    const loginForm = ref(null)
    const type = ref(false)
    const state = reactive({
      ruleForm: {
        schoolId: '50000000000000',
        username: '',
        password: '',
        peopleType: '0',
      },
      loading: false,
      rules: {
        schoolId: [
          { required: true, message: '学校不能为空', trigger: 'change' }
        ],
        username: [
          { required: 'true', message: '学号不能为空', trigger: 'blur' }
        ],
        peopleType: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        password: [
          { required: 'true', message: '密码不能为空', trigger: 'blur' },
        ]
      },
      options: [],
    })

    onMounted(() => {
      getRegister()
      getSchoolName()
    })
    let getSchoolName = () => {
      let parame = {
        schoolName: '',
        pageSize: 10000,
        pageIndex: 1,
      };
      getSchools(parame).then((res) => {
        if (res.data.code === "200") {
          let newRes = res.data.data;
          state.options = newRes.schoolPageInfo.list;
          newRes.schoolPageInfo.list.forEach((item) => {
            if(item.id == '50000000000000'){
              type.value =item.registeredSwitch;
            }
            
          })
          // dealDate(data.spreadTable, ["createTime"]);
          // setTimeout(() => {
          //   data.loading = false; 
          // }, 300);
        }
      });
    }
    let getRegister = () => {
      axios.post('/tradelabLx/sysUser/getRegisterButtonSwitchKey').then(res => {
        if (res.data.code === '200') {
          // type.value = res.data.msg
        }
      })
    }
    const submitForm = async () => {
      sessionStorage.clear()
      loginForm.value.validate((valid) => {

        state.loading = true
        if (valid) {
          axios.post('/tradelabLx/user/login', {
            username: state.ruleForm.username,
            password: state.ruleForm.password,
            schoolId: state.ruleForm.schoolId,
          }).then(res => {
            if (res.data.code === '200') {
              let nesRow = res.data.data
              // localStorage.setItem('user',JSON.stringify(nesRow))
              // localStorage.setItem('token', nesRow.token)
              console.log("nesRow", nesRow);
              sessionStorage.setItem('user', JSON.stringify(nesRow))
              sessionStorage.setItem('token', nesRow.token)
              sessionStorage.setItem('acid', res.data.data.acId)
              sessionStorage.setItem('logger_tv', encodeURI(escape("信息是" + res.data.data.userRoleName)));
              sessionStorage.setItem('competition-status', '')
              //将大赛状态清空
              sessionStorage.setItem('roleList', JSON.stringify(res.data.data.userRoleResources))
              ElMessage.success(res.data.msg)
              getCurrencyOptions()
              getAllCode(1)
              getAllCode(2)
              getAllCode(3)
              getAllCode(null)
              router.push('/index')
              state.loading = false
            } else {
              state.loading = false
              ElMessage.error(res.data.msg)
            }
          })
        } else {
          state.loading = false
          return false;
        }
      })
    }

    const getAllCode = (num) => {
      let name = num === null ? 'all' : num === 1 ? 'shares' : num === 2 ? 'bond' : num === '3' ? 'forward' : 'all'
      axios.post('/tradelabLx/transaction/findStopwatchByFtype', { type: num }).then(res => {
        if (res.data.code === '200') {
          let resNew = res.data.data
          localStorage.setItem(name, JSON.stringify(resNew.stopwatches))
        }
      })
    }

    const getCurrencyOptions = () => {

      findCurrencyType().then((res) => {
        if (res.data.code == 200) {
          let options = []
          res.data.data.currencyData.forEach(element => {
            options.push({
              value: element.id,
              label: element.name,
              abbr: element.abbr,
            })
          });
          console.log("options", options);
          localStorage.setItem('CurrencyOptions', JSON.stringify(options))
        }
      })
    }

    const registerRou = () => { router.push('/register') }

    const resetForm = () => {
      loginForm.value.resetFields();
    }
    return {
      ...toRefs(state),
      loginForm,
      type,
      getSchoolName,
      submitForm,
      registerRou,
      resetForm
    }
  }
}
</script>

<style lang="less" scoped>
.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #EEEFF5;
  background-size: 100%;

  .main {
    min-width: 1200px;
    height: 700px;
    background: #FFFFFF;
    box-shadow: 0px 2px 140px 0px rgba(107, 133, 228, 0.15);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      flex: 1;
    }
  }


  .login-img {
    height: 100%;
    background: url('../../assets/login-img.png') no-repeat;
    background-size: 98% 100%;
  }

  .login-main {
    height: 100%;
    padding-top: 10%;
    box-sizing: border-box;

    h3 {
      width: 100%;
      text-align: center;
      height: 46px;
      font-size: 46px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #222222;
      line-height: 46px;
      margin-bottom: 13px;

    }

    h4 {
      width: 100%;
      padding-bottom: 7%;
      justify-content: center;
      display: flex;
      align-items: center;

      // padding-left:30px;
      .line {
        width: 12px;
        height: 1px;
        background: #999999;
        border: 1px solid #999999;
        margin-right: 10px;
        border-radius: 1px;
      }

      span:nth-child(1) {
        margin-left: 22px;
      }

      span:nth-child(2) {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3B6EFB;
        line-height: 28px;
      }

      span:nth-child(3) {
        color: #999999;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        margin-right: 10px;
      }
    }

    .login-form {
      width: 100%;
      padding: 0 120px;
      box-sizing: border-box;
    }

    .login-btn {
      width: 100%;
      height: 54px;
      background: #3B6EFB !important;
      border-radius: 8px;
      border: 0px !important;
      margin-top: 15px;
    }

    .login-btn:hover {
      opacity: 0.5;
    }

    .login-register {
      margin-top: 10px;
      width: 100%;
      height: 54px;
      border-radius: 6px;
      border: 1px solid #D8DDE8;
    }
  }

  .el-input__inner {
    color: #03032C;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}

:deep(.el-select-dropdown__item) {
  width: 80%;
  margin-left: 10%;
  border-bottom: #F4F6FA 2px solid !important;
  padding: 0 !important;
}

:deep(.el-select-dropdown__item.hover) {
  color: #658DFC !important;
  background-color: #FFFFFF !important;
  font-weight: 500 !important;
}

:deep(.el-select-dropdown__item:hover) {
  color: #658DFC !important;
  background-color: #FFFFFF !important;
  font-weight: 500 !important;
}
</style>
<style>
.el-form--label-top .el-form-item__label {
  padding: 0;
}

.lableName .el-form-item__label {
  font-size: 15px
}

.login-form .el-form-item {
  margin-bottom: 15px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
  /*背景色透明  生效时长  过渡效果  启用时延迟的时间*/
}

input:-webkit-autofill::first-line {
  font-size: 16px;
}

input:-webkit-autofill {

  /* -webkit-box-shadow: 0 0 0 400px #E8ECED inset; */

  -webkit-text-fill-color: #03032C;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

}


input {
  background-color: transparent;
}

:-webkit-autofill::first-line {
  font-size: 24px;
}</style>
