<template>
  <div>
    <el-card class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>小组排名</div>
      <div class="components-tableDemo" style="background-color: white;">
        <div style="text-align: right">
          <el-button size="mini" @click="distribution" style="margin-bottom: 10px;margin-right: 10px">权重分配</el-button>
          <el-button size="mini" @click="addList" style="margin-bottom: 10px;margin-right: 10px">汇总成绩</el-button>
          <el-button size="mini" @click="saveData" type="primary"
            style="margin-bottom: 10px;margin-right: 10px">提交更新</el-button>
          <el-button size="mini" @click="TBData" type="primary" style="margin-bottom: 10px">同步成绩</el-button>
        </div>
        <vxe-table :data="tableData" :edit-config="{ trigger: 'click', mode: 'cell' }" row-key highlight-current-row
          :tree-config="{ children: 'children' }" :checkbox-config="{ labelField: 'name' }" align="center">
          <vxe-column field="name" title="小组" width="160" tree-node></vxe-column>

          <!--      <vxe-table-column field="name" title="姓名" width="160"></vxe-table-column>-->
          <vxe-colgroup :title="'詹森测度α ' + '(' + zs + '%)'" width="200">
            <vxe-column field="jensen_measure_key" title="值" sortable width="100"></vxe-column>
            <!--        <vxe-table-column field="jensen_measure_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>-->
            <vxe-column field="jensen_measure_val" title="分(百分制)" width="150"
              :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }">
              <template v-slot:edit="{ row }">
                <vxe-input type="string" :min="0" v-model="row.jensen_measure_val" @blur="editAge(row)"></vxe-input>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="'特雷诺测度 ' + '(' + tln + '%)'" width="200">
            <vxe-column field="treynor_measure_key" title="值" sortable width="100"></vxe-column>
            <!--        <vxe-table-column field="treynor_measure_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>-->
            <vxe-column field="treynor_measure_val" title="分(百分制)" width="150"
              :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }">
              <template v-slot:edit="{ row }">
                <vxe-input type="string" :min="0" v-model="row.treynor_measure_val" @blur="editAge(row)"></vxe-input>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="'夏普测度 ' + '(' + xp + '%)'" width="200">
            <vxe-column field="sharpe_measure_key" title="值" sortable width="100"></vxe-column>
            <!--        <vxe-table-column field="sharpe_measure_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>-->
            <vxe-column field="sharpe_measure_val" title="分(百分制)" width="150"
              :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }">
              <template v-slot:edit="{ row }">
                <vxe-input type="string" :min="0" v-model="row.sharpe_measure_val" @blur="editAge(row)"></vxe-input>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <!--      <vxe-table-column :title="'总收益率 '+'('+all+'%)'" width="200">-->
          <!--        <vxe-table-column field="total_yield_key" title="值" sortable width="100"></vxe-table-column>-->
          <!--&lt;!&ndash;        <vxe-table-column field="total_yield_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>&ndash;&gt;-->
          <!--        <vxe-table-column field="total_yield_val" title="分(百分制)" width="150" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" >-->
          <!--          <template v-slot:edit="{ row }">-->
          <!--            <vxe-input type="string" :min="0" v-model="row.total_yield_val" @blur="editAge(row)"></vxe-input>-->
          <!--          </template>-->
          <!--        </vxe-table-column>-->
          <!--      </vxe-table-column>-->
          <vxe-colgroup :title="'成交量 ' + '(' + cj + '%)'" width="200">
            <vxe-column field="volume_key" title="值" sortable width="100"></vxe-column>
            <!--        <vxe-table-column field="volume_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>-->
            <vxe-column field="volume_val" title="分(百分制)" width="150"
              :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }">
              <template v-slot:edit="{ row }">
                <vxe-input type="string" :min="0" v-model="row.volume_val" @blur="editAge(row)"></vxe-input>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="'报告 ' + '(' + bg + '%)'" width="200">
            <vxe-column field="report_key" title="值" sortable width="100"></vxe-column>
            <!--        <vxe-table-column field="report_val" :edit-render="{name: 'input', immediate: true, attrs: {type: 'text'}}" title="分(百分制)" width="150"></vxe-table-column>-->
            <vxe-column field="report_val" title="分(百分制)" width="150"
              :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }">
              <template v-slot:edit="{ row }">
                <vxe-input type="string" :min="0" v-model="row.report_val" @blur="editAge(row)"></vxe-input>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-column field="total" title="汇总成绩" width="100"></vxe-column>
        </vxe-table>
      </div>
      <el-dialog title="权重分配" v-model="distri" width="30%">
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="distri = false">取 消</el-button>
            <el-button type="primary" @click="handleOk">确 定</el-button>
          </span>
        </template>
        <!--      <el-dialog v-model="distri" title="权重分配" @ok="handleOk">-->
        <p class="ptag-title">
          <span style="margin-right: 30px;font-weight: 600">詹森测度α：</span>
          <el-input-number v-model="zs" style="width: 200px" class="input-title" :min="0" :max="100"
            :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
        </p>
        <p class="ptag-title">
          <span style="margin-right: 30px;font-weight: 600">特雷诺测度：</span>
          <el-input-number v-model="tln" style="width: 200px" class="input-title" :min="0" :max="100"
            :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
        </p>
        <p class="ptag-title">
          <span style="margin-right: 30px;font-weight: 600">夏普测度：</span>
          <el-input-number v-model="xp" style="width: 200px" class="input-title" :min="0" :max="100"
            :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
        </p>
        <!--    <p class="ptag-title">-->
        <!--      <span style="margin-right: 30px;font-weight: 600">总收益率：</span>-->
        <!--      <a-input-number-->
        <!--        v-model="all"-->
        <!--        style="width: 200px" class="input-title"-->
        <!--        :min="0"-->
        <!--        :max="100"-->
        <!--        :formatter="value => `${value}%`"-->
        <!--        :parser="value => value.replace('%', '')"-->
        <!--      />-->
        <!--    </p>-->
        <p class="ptag-title">
          <span style="margin-right: 30px;font-weight: 600">成交量：</span>
          <el-input-number v-model="cj" style="width: 200px" class="input-title" :min="0" :max="100"
            :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
        </p>
        <p class="ptag-title">
          <span style="margin-right: 30px;font-weight: 600">报告：</span>
          <el-input-number v-model="bg" style="width: 200px" class="input-title" :min="0" :max="100"
            :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
        </p>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import axios from '@/axios/globalaxios'

const Columns = [
  {
    title: '大赛名称',
    dataIndex: 'course_class_name',
  },
  {
    title: '课程编号',
    dataIndex: 'course_number',
  },
  {
    title: '大赛名称',
    dataIndex: 'course_name',
  },
  {
    title: '小组名称',
    dataIndex: 'RealName',
  },
  {
    title: '所剩余额',
    dataIndex: 'Amounts',
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: "index",
  data() {
    return {
      Columns,
      rankData: [],
      visible: false,
      distri: false,
      grodName: '',
      mark: '',
      infoItem: {},
      measure: [],
      ItemList: [],
      zs: 10,
      tln: 10,
      xp: 20,
      all: 10,
      cj: 10,
      bg: 50,
      TBlist: [],
      lstTag: [],
      tableData: [
        // {
        //   id: 10001,
        //   name: '小组1',
        //   jensen_measure_key: '0.1564',
        //   treynor_measure_key: '0.6548',
        //   sharpe_measure_key: '0.2654',
        //   total_yield_key: '0.26',
        //   volume_key: '300',
        //   report_key: '5',
        //   total: '',
        //   jensen_measure_val: '',
        //   treynor_measure_val: '',
        //   sharpe_measure_val: '',
        //   total_yield_val: '',
        //   volume_val: '',
        //   report_val: '',
        //   children: [
        //     {
        //       id: 10002,
        //       name: '张三',
        //       jensen_measure_key: '0.1564',
        //       treynor_measure_key: '0.6548',
        //       sharpe_measure_key: '0.2654',
        //       total_yield_key: '0.26',
        //       volume_key: '300',
        //       report_key: '5',
        //       total: '',
        //       jensen_measure_val: '',
        //       treynor_measure_val: '',
        //       sharpe_measure_val: '',
        //       total_yield_val: '',
        //       volume_val: '',
        //       report_val: ''
        //     },
        //     {
        //       id: 10003,
        //       name: '李四',
        //       jensen_measure_key: '0.1564',
        //       treynor_measure_key: '0.6548',
        //       sharpe_measure_key: '0.2654',
        //       total_yield_key: '0.26',
        //       volume_key: '300',
        //       report_key: '5',
        //       total: '',
        //       jensen_measure_val: '',
        //       treynor_measure_val: '',
        //       sharpe_measure_val: '',
        //       total_yield_val: '',
        //       volume_val: '',
        //       report_val: ''
        //     }
        //   ]
        // },
        // {
        //   id: 10004,
        //   name: '小组2',
        //   jensen_measure_key: '0.1564',
        //   treynor_measure_key: '0.6548',
        //   sharpe_measure_key: '0.2654',
        //   total_yield_key: '0.26',
        //   volume_key: '300',
        //   report_key: '5',
        //   total: '',
        //   jensen_measure_val: '',
        //   treynor_measure_val: '',
        //   sharpe_measure_val: '',
        //   total_yield_val: '',
        //   volume_val: '',
        //   report_val: '',
        //   children: [
        //     {
        //       id: 10006,
        //       name: '张三',
        //       jensen_measure_key: '0.1564',
        //       treynor_measure_key: '0.6548',
        //       sharpe_measure_key: '0.2654',
        //       total_yield_key: '0.26',
        //       volume_key: '300',
        //       report_key: '5',
        //       total: '',
        //       jensen_measure_val: '',
        //       treynor_measure_val: '',
        //       sharpe_measure_val: '',
        //       total_yield_val: '',
        //       volume_val: '',
        //       report_val: ''
        //     },
        //     {
        //       id: 10052,
        //       name: '李四',
        //       jensen_measure_key: '0.1564',
        //       treynor_measure_key: '0.6548',
        //       sharpe_measure_key: '0.2654',
        //       total_yield_key: '0.26',
        //       volume_key: '300',
        //       report_key: '5',
        //       total: '',
        //       jensen_measure_val: '',
        //       treynor_measure_val: '',
        //       sharpe_measure_val: '',
        //       total_yield_val: '',
        //       volume_val: '',
        //       report_val: ''
        //     }
        //   ]
        // },
      ],
      type: 0
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios.post('/tradelabLx/systemRating/scoreAll').then((res) => {
        if (res.data.code === '200') {
          if (res.data.data.length === 0) {
            this.type = 1
            this.getDataRank('0');
          } else {
            this.type = 0
            this.lstTag = res.data.data;
            this.getDataRank('1');
          }
        }
      });
    },
    setDataList() {
      this.tableData = [];
      let arr = this.lstTag;
      let arrData = [];
      for (let i in arr) {
        if (arr[i].children.length !== 0) {
          let cild = [];
          let prod = arr[i].children
          for (let j in prod) {
            cild[j] = {
              id: prod[j].fmid, name: prod[j].name,
              jensen_measure_key: prod[j].jensen_measure_key !== null ? prod[j].jensen_measure_key : 0,
              treynor_measure_key: prod[j].treynor_measure_key !== null ? prod[j].treynor_measure_key : 0,
              sharpe_measure_key: prod[j].sharpe_measure_key !== null ? prod[j].sharpe_measure_key : 0,
              total_yield_key: prod[j].total_yield_key !== null ? prod[j].total_yield_key : 0,
              volume_key: prod[j].volume_key !== null ? prod[j].volume_key : 0,
              report_key: prod[j].report_key !== null ? prod[j].report_key : 0,
              total: prod[j].total,
              jensen_measure_val: prod[j].jensen_measure_val,
              treynor_measure_val: prod[j].treynor_measure_val,
              sharpe_measure_val: prod[j].sharpe_measure_val,
              total_yield_val: prod[j].total_yield_val,
              volume_val: prod[j].volume_val,
              report_val: prod[j].report_val,
            }
          }
          arrData[i] = {
            id: arr[i].acid, name: arr[i].RealName,
            jensen_measure_key: arr[i].jensen_measure_key !== null ? arr[i].jensen_measure_key : 0,
            treynor_measure_key: arr[i].treynor_measure_key !== null ? arr[i].treynor_measure_key : 0,
            sharpe_measure_key: arr[i].sharpe_measure_key !== null ? arr[i].sharpe_measure_key : 0,
            total_yield_key: arr[i].total_yield_key !== null ? arr[i].total_yield_key : 0,
            volume_key: arr[i].volume_key !== null ? arr[i].volume_key : 0,
            report_key: arr[i].report_key !== null ? arr[i].report_key : 0,
            total: arr[i].total,
            jensen_measure_val: arr[i].jensen_measure_val,
            treynor_measure_val: arr[i].treynor_measure_val,
            sharpe_measure_val: arr[i].sharpe_measure_val,
            total_yield_val: arr[i].total_yield_val,
            volume_val: arr[i].volume_val,
            report_val: arr[i].report_val,
            children: cild
          }
        } else {
          arrData[i] = {
            id: arr[i].acid, name: arr[i].RealName,
            jensen_measure_key: arr[i].jensen_measure_key !== null ? arr[i].jensen_measure_key : 0,
            treynor_measure_key: arr[i].treynor_measure_key !== null ? arr[i].treynor_measure_key : 0,
            sharpe_measure_key: arr[i].sharpe_measure_key !== null ? arr[i].sharpe_measure_key : 0,
            total_yield_key: arr[i].total_yield_key !== null ? arr[i].total_yield_key : 0,
            volume_key: arr[i].volume_key !== null ? arr[i].volume_key : 0,
            report_key: arr[i].report_key !== null ? arr[i].report_key : 0,
            total: arr[i].total,
            jensen_measure_val: arr[i].jensen_measure_val,
            treynor_measure_val: arr[i].treynor_measure_val,
            sharpe_measure_val: arr[i].sharpe_measure_val,
            total_yield_val: arr[i].total_yield_val,
            volume_val: arr[i].volume_val,
            report_val: arr[i].report_val,
            children: []
          }
        }
      }
      this.tableData = arrData;
    },
    editAge(e) {
      if (e.children) {
        let arr = e.children;
        for (let i in arr) {
          arr[i].jensen_measure_val = e.jensen_measure_val === '' || e.jensen_measure_val === null ? '' : parseFloat(e.jensen_measure_val);
          arr[i].treynor_measure_val = e.treynor_measure_val === '' || e.treynor_measure_val === null ? '' : parseFloat(e.treynor_measure_val);
          arr[i].sharpe_measure_val = e.sharpe_measure_val === '' || e.sharpe_measure_val === null ? '' : parseFloat(e.sharpe_measure_val);
          arr[i].total_yield_val = e.total_yield_val === '' || e.total_yield_val === null ? '' : parseFloat(e.total_yield_val);
          arr[i].volume_val = e.volume_val === '' || e.volume_val === null ? '' : parseFloat(e.volume_val);
          arr[i].report_val = e.report_val === '' || e.report_val === null ? '' : parseFloat(e.report_val);
          arr[i].total = e.total;
        }
      }

    },
    handleOk(e) {
      // let all = this.zs+this.tln+this.xp+this.all+this.cj+this.bg;
      let all = this.zs + this.tln + this.xp + this.cj + this.bg;
      if (all === 100) {
        ElMessage.success("分配成功");
        this.distri = false;
      } else {
        ElMessage.error("权重分配不匹配");
      }
    },
    saveData(num) {
      let arr = [];
      for (let i in this.tableData) {
        arr.push({
          acid: this.tableData[i].id, name: this.tableData[i].name,
          jensenMeasureKey: parseFloat(this.tableData[i].jensen_measure_key),
          treynorMeasureKey: parseFloat(this.tableData[i].treynor_measure_key),
          sharpeMeasureKey: parseFloat(this.tableData[i].sharpe_measure_key),
          totalYieldKey: parseFloat(this.tableData[i].total_yield_key),
          volumeKey: parseFloat(this.tableData[i].volume_key),
          reportKey: parseFloat(this.tableData[i].report_key),
          total: parseFloat(this.tableData[i].total),
          jensenMeasureVal: parseFloat(this.tableData[i].jensen_measure_val),
          treynorMeasureVal: parseFloat(this.tableData[i].treynor_measure_val),
          sharpeMeasureVal: parseFloat(this.tableData[i].sharpe_measure_val),
          totalYieldVal: parseFloat(this.tableData[i].total_yield_val),
          volumeVal: parseFloat(this.tableData[i].volume_val),
          reportVal: parseFloat(this.tableData[i].report_val),
        })
        if (this.tableData[i].children) {
          let child = this.tableData[i].children;
          for (let j in child) {
            arr.push({
              acid: this.tableData[i].id, fmid: child[j].id, name: child[j].name,
              jensenMeasureKey: parseFloat(child[j].jensen_measure_key),
              treynorMeasureKey: parseFloat(child[j].treynor_measure_key),
              sharpeMeasureKey: parseFloat(child[j].sharpe_measure_key),
              totalYieldKey: parseFloat(child[j].total_yield_key),
              volumeKey: parseFloat(child[j].volume_key),
              reportKey: parseFloat(child[j].report_key),
              total: parseFloat(child[j].total),
              jensenMeasureVal: parseFloat(child[j].jensen_measure_val),
              treynorMeasureVal: parseFloat(child[j].treynor_measure_val),
              sharpeMeasureVal: parseFloat(child[j].sharpe_measure_val),
              totalYieldVal: parseFloat(child[j].total_yield_val),
              volumeVal: parseFloat(child[j].volume_val),
              reportVal: parseFloat(child[j].report_val),
            })
          }
        }
      }
      let newList = arr;
      let newarr = [];
      for (let i in newList) {
        if (newList[i].fmid) {
          newarr.push({ cname: newList[i].name, points: newList[i].total, assignmentId: 1 })
        }
      }
      let arrlist = [];
      for (let j in this.ItemList) {
        if (this.ItemList[j].tfundFundmembers) {
          let lis = this.ItemList[j].tfundFundmembers;
          for (let a in lis) {
            arrlist.push({ siteId: parseInt(lis[a].classid), username: lis[a].studentid })
          }
        }
      }
      let endList = []
      for (let i in newarr) {
        endList.push(Object.assign(newarr[i], arrlist[i]));
      }
      this.TBlist = endList;
      // return
      axios.post('/tradelabLx/systemRating/addScore', arr).then((res) => {
        if (res.data.code === '200') {
          this.$message.success('提交成功');
          if (num === '1') {
            // this.$axios.post('/transcript/submitTranscriptApi',this.TBlist).then((res) => {
            //   if (res.data.errCode === 0){
            //     this.$message.success('提交成功')
            //   }
            // });
            let tokens = sessionStorage.getItem('codeKey')
            // data:JSON.stringify(this.TBlist)

            let data = this.TBlist
            axios.post('/api2/docking/synchronizationScore', data, {
              headers: {
                'key': tokens,
              }
            }).then((res) => {
              // if (res.data.errCode === 0){
              //   this.$message.success('提交成功')
              // }
            });
          }
          // this.grodName = res.data.data;
        }
      });
    },
    TBData() {
      let th = this;
      ElMessageBox.alert('上传成绩？', '成绩只可以提交一次，请谨慎提交！', {
        confirmButtonText: '确定',
        callback: (action) => {
          th.saveData('1');
        },
      });
    },
    distribution() {
      this.distri = true;
    },
    addList() {
      if (this.type === 0) {
        for (let i in this.tableData) {
          let v1 = this.tableData[i].jensen_measure_val;
          let v2 = this.tableData[i].treynor_measure_val;
          let v3 = this.tableData[i].sharpe_measure_val
          // let v4 = this.tableData[i].total_yield_val
          let v5 = this.tableData[i].volume_val
          let v6 = this.tableData[i].report_val
          // v4 * (this.all / 100)
          this.tableData[i].total = (v1 * (this.zs / 100) + v2 * (this.tln / 100)
            + v3 * (this.xp / 100) + v5 * (this.cj / 100) + v6 * (this.bg / 100)).toFixed(2);
          if (this.tableData[i].children) {
            for (let j in this.tableData[i].children) {
              let v1s = this.tableData[i].children[j].jensen_measure_val === '' || this.tableData[i].children[j].jensen_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].jensen_measure_val);
              let v2s = this.tableData[i].children[j].treynor_measure_val === '' || this.tableData[i].children[j].treynor_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].treynor_measure_val);
              let v3s = this.tableData[i].children[j].sharpe_measure_val === '' || this.tableData[i].children[j].sharpe_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].sharpe_measure_val);
              // let v4s = this.tableData[i].children[j].total_yield_val === ''     || this.tableData[i].children[j].total_yield_val === null ? 0 : parseFloat(this.tableData[i].children[j].total_yield_val);
              let v5s = this.tableData[i].children[j].volume_val === '' || this.tableData[i].children[j].volume_val === null ? 0 : parseFloat(this.tableData[i].children[j].volume_val);
              let v6s = this.tableData[i].children[j].report_val === '' || this.tableData[i].children[j].report_val === null ? 0 : parseFloat(this.tableData[i].children[j].report_val);
              // v4s * (this.all / 100)
              this.tableData[i].children[j].total = (v1s * (this.zs / 100) + v2s * (this.tln / 100) + v3s * (this.xp / 100) + v5s * (this.cj / 100) + v6s * (this.bg / 100)).toFixed(2);
            }
          }
        }
      } else {
        for (let i in this.tableData) {
          let v1 = this.tableData[i].jensen_measure_val === '' || this.tableData[i].jensen_measure_val === null ? 0 : parseFloat(this.tableData[i].jensen_measure_val);
          let v2 = this.tableData[i].treynor_measure_val === '' || this.tableData[i].treynor_measure_val === null ? 0 : parseFloat(this.tableData[i].treynor_measure_val);
          let v3 = this.tableData[i].sharpe_measure_val === '' || this.tableData[i].sharpe_measure_val === null ? 0 : parseFloat(this.tableData[i].sharpe_measure_val);
          // let v4 = this.tableData[i].total_yield_val === ''     || this.tableData[i].total_yield_val  === null? 0 : parseFloat(this.tableData[i].total_yield_val);
          let v5 = this.tableData[i].volume_val === '' || this.tableData[i].volume_val === null ? 0 : parseFloat(this.tableData[i].volume_val);
          let v6 = this.tableData[i].report_val === '' || this.tableData[i].report_val === null ? 0 : parseFloat(this.tableData[i].report_val);
          // v4 * (this.all / 100)
          this.tableData[i].total = (v1 * (this.zs / 100) + v2 * (this.tln / 100) + v3 * (this.xp / 100) + v5 * (this.cj / 100) + v6 * (this.bg / 100)).toFixed(2);
          if (this.tableData[i].children) {
            for (let j in this.tableData[i].children) {
              let v1s = this.tableData[i].children[j].jensen_measure_val === '' || this.tableData[i].children[j].jensen_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].jensen_measure_val);
              let v2s = this.tableData[i].children[j].treynor_measure_val === '' || this.tableData[i].children[j].treynor_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].treynor_measure_val);
              let v3s = this.tableData[i].children[j].sharpe_measure_val === '' || this.tableData[i].children[j].sharpe_measure_val === null ? 0 : parseFloat(this.tableData[i].children[j].sharpe_measure_val);
              // let v4s = this.tableData[i].children[j].total_yield_val === ''     || this.tableData[i].children[j].total_yield_val === null ? 0 : parseFloat(this.tableData[i].children[j].total_yield_val);
              let v5s = this.tableData[i].children[j].volume_val === '' || this.tableData[i].children[j].volume_val === null ? 0 : parseFloat(this.tableData[i].children[j].volume_val);
              let v6s = this.tableData[i].children[j].report_val === '' || this.tableData[i].children[j].report_val === null ? 0 : parseFloat(this.tableData[i].children[j].report_val);
              // v4s * (this.all / 100)
              this.tableData[i].children[j].total = (v1s * (this.zs / 100) + v2s * (this.tln / 100) + v3s * (this.xp / 100) + v5s * (this.cj / 100) + v6s * (this.bg / 100)).toFixed(2);
            }
          }
        }
      }
    },
    markPeople() {
      if (this.mark !== '') {
        let data = {
          acid: this.infoItem.ACID,
          fraction: this.mark,
          className: this.infoItem.course_class_name
        };
        axios.post('/tradelabLx/systemRating/teamMembers', data).then((res) => {
          if (res.data.code === '200') {
            this.grodName = res.data.data;
          }
        });
      } else {
        ElMessage.warning("分数必须填写！")
      }
    },
    editMoneyTeam(e) {
      // this.infoItem  = e;
      // this.visible = true;
      // this.$axios.post('/api2/scoring/reportCount?acid='+e.ACID).then((res) => {
      //   if (res.data.errCode === 0){
      //     this.grodName = res.data.data;
      //   }
      // });
      // this.$axios.post('/api2/tStockHq/index').then((res) => {
      //   if (res.data.errCode === 0){
      //     let arr = res.data.data;
      //     let arrs = [];
      //     for (let i in arr){
      //       arrs[i] = arr[i].toFixed(4)
      //     }
      //     this.measure = arrs;
      //   }
      // })
    },
    afterVisibleChange(val) { },
    onClose() {
      this.infoItem = {};
      this.visible = false;
    },
    getDataRank(num) {
      let Tid = JSON.parse(sessionStorage.getItem("classId"));
      // let Tid = JSON.parse(sessionStorage.getItem("user")).userId;
      axios.post('/tradelabLx/systemRating/teamMembers?classId=' + Tid).then((res) => {
        if (res.data.code === '200') {
          this.tableData = [];
          // this.rankData = res.data.data;
          let rank = res.data.data;
          this.ItemList = res.data.data;
          if (num === '1') {
            this.setDataList();
            return;
          }
          let arr = [];
          for (let i in rank) {
            let numPeo = rank[i].tfundFundmembers;
            if (numPeo.length !== 0) {
              let cild = [];
              for (let j in numPeo) {
                cild[j] = {
                  id: numPeo[j].fmid, name: numPeo[j].realname,
                  jensen_measure_key: rank[i].index !== null ? rank[i].index.jensenMeasureKey : 0,
                  treynor_measure_key: rank[i].index !== null ? rank[i].index.treynorMeasureKey : 0,
                  sharpe_measure_key: rank[i].index !== null ? rank[i].index.sharpeMeasureKey : 0,
                  total_yield_key: rank[i].totalYieldKey !== null ? rank[i].totalYieldKey : 0,
                  volume_key: rank[i].volumeKey !== null ? rank[i].volumeKey : 0,
                  report_key: rank[i].reportKey !== null ? rank[i].reportKey : 0,
                  total: '',
                  jensen_measure_val: '',
                  treynor_measure_val: '',
                  sharpe_measure_val: '',
                  total_yield_val: '',
                  volume_val: '',
                  report_val: '',
                }
              }
              arr[i] = {
                id: rank[i].ngUsers.acid, name: rank[i].ngUsers.realname,
                jensen_measure_key: rank[i].index !== null ? rank[i].index.jensenMeasureKey : 0,
                treynor_measure_key: rank[i].index !== null ? rank[i].index.treynorMeasureKey : 0,
                sharpe_measure_key: rank[i].index !== null ? rank[i].index.sharpeMeasureKey : 0,
                total_yield_key: rank[i].totalYieldKey !== null ? rank[i].totalYieldKey : 0,
                volume_key: rank[i].volumeKey !== null ? rank[i].volumeKey : 0,
                report_key: rank[i].reportKey !== null ? rank[i].reportKey : 0,
                total: '',
                jensen_measure_val: '',
                treynor_measure_val: '',
                sharpe_measure_val: '',
                total_yield_val: '',
                volume_val: '',
                report_val: '',
                children: cild
              }
            } else {
              arr[i] = {
                id: rank[i].ngUsers.acid, name: rank[i].ngUsers.realname,
                jensen_measure_key: rank[i].index !== null ? rank[i].index.jensenMeasureKey : 0,
                treynor_measure_key: rank[i].index !== null ? rank[i].index.treynorMeasureKey : 0,
                sharpe_measure_key: rank[i].index !== null ? rank[i].index.sharpeMeasureKey : 0,
                total_yield_key: rank[i].totalYieldKey !== null ? rank[i].totalYieldKey : 0,
                volume_key: rank[i].volumeKey !== null ? rank[i].volumeKey : 0,
                report_key: rank[i].reportKey !== null ? rank[i].reportKey : 0,
                total: '',
                jensen_measure_val: '',
                treynor_measure_val: '',
                sharpe_measure_val: '',
                total_yield_val: '',
                volume_val: '',
                report_val: '',
              }
            }
          }
          this.tableData = arr;
        }
      });
    },
  },
  // watch:{
  //   tableData: {
  //     handler(val, oldval) {
  //
  //     },
  //     deep: true
  //   }
  // },
}
</script>

<style scoped>.ptag-title {
  position: relative;
  margin-top: 30px;
}

.input-title {
  position: absolute;
  top: -6px;
  left: 22%;
}</style>
