import axios from '@/axios/globalaxios'

export const uploadFundFile = data =>{
    return axios({
        url: "/tradelabLx/openFund/uploadOpenFundFile",
        method: 'post',
        data:data
    })
}

export const openFundApply = data =>{
    return axios({
        url: "/tradelabLx/openFund/openFundApply",
        method: 'post',
        data:data
    })
}

export const getFundApplyStatus = data =>{
    return axios({
        url: "/tradelabLx/openFund/getFundApplyStatus",
        method: 'post',
        data:data
    })
}

export const openFundRepeatApply = data =>{
    return axios({
        url: "/tradelabLx/openFund/openFundRepeatApply",
        method: 'post',
        data:data
    })
}

export const listManageFee = data =>{
    return axios({
        url: "/tradelabLx/openFund/listManageFee",
        method: 'post',
        data:data
    })
}

export const manualStroke = data =>{
    return axios({
        url: "/tradelabLx/openFund/manualStroke",
        method: 'post',
        data:data
    })
}

export const listGroupUser = data =>{
    return axios({
        url: "/tradelabLx/openFund/listGroupUser",
        method: 'post',
        data:data
    })
}

export const getFundInfo = data =>{
    return axios({
        url: "/tradelabLx/openFund/getFundInfo",
        method: 'post',
        data:data
    })
}

export const uploadVideoOpenFundFile = data =>{
    return axios({
        url: "/tradelabLx/openFund/uploadVideoOpenFundFile",
        method: 'post',
        data:data
    })
}

export const updateFundInfo = data =>{
    return axios({
        url: "/tradelabLx/openFund/updateFundInfo",
        method: 'post',
        data:data
    })
}

export const delistingLiquidation = data =>{
    return axios({
        url: "/tradelabLx/openFund/delistingLiquidation",
        method: 'post',
        data:data
    })
}
