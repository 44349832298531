import {findFunds} from './detailsApi'
export const submitForms = (name,data)=>{
    this.$refs[name].validate((valid) => {
        if (valid) {
            alert('submit!');
        } else {
            return false;
        }
    });
}
export const resetForms = (name,ctx,data)=>{
    ctx[name].resetFields();
}

export const getMoneys = (data) =>{

}