<template>
  <div class="box-card">
    <el-card class="process">
      <div style="margin-bottom: 10px" class="box-title">清算管理</div>
      <div v-if="step === 0" class="step">
        <nav>
          <span class="demonstration">清算类型：</span>
          <el-radio-group v-model="labelType" @change="getList">
            <el-radio label="课程"></el-radio>
            <el-radio label="大赛"></el-radio>
          </el-radio-group>
          <div class="btn">
            <el-button size="mini" type="primary" @click="getAcid">确定选择</el-button>
          </div>
        </nav>
        <spread-table v-loading="loading" :tableHeight="tableHeight" :currentPage="0" :ref="table2" :smallType="true" @handleSelectionChange="handleSelectionChange" :selection="true" :tableColumn="colList[labelType]" :tableData="spreadTable"></spread-table>
      </div>
      <div v-if="step === 1" class="step">
        <nav>
          <span class="demonstration">清算时间区间：</span>
          <el-date-picker size="mini" style="width: 250px;" type="daterange" v-model="dateRange" start-placeholder="开始日期" end-placeholder="结束日期" />
          <div class="btn">
            <el-button size="mini" type="primary" @click="getList">上一步</el-button>
            <el-button size="mini" type="warning" @click="liquidation('edit')">历史委托单</el-button>
            <el-button size="mini" type="primary" @click="liquidation('liq')">清算</el-button>
          </div>
        </nav>
        <spread-table v-loading="loading" :tableHeight="tableHeight" :currentPage="0" :ref="table2" :smallType="true" @handleSelectionChange="handleSelectionChange" :selection="true" :tableColumn="acidColList[labelType]" :tableData="acidTable"></spread-table>
      </div>
    </el-card>

    <el-dialog title="历史委托单" v-model="showRoleDia" width="90%">
      <nav>
        <span class="demonstration">账户ID：</span>
        <el-select style="margin-right: 8px;" filterable clearable size="mini" v-model="acidValue" placeholder="请选择">
          <el-option v-for="item in acidOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <span class="demonstration">日期：</span>
        <el-select style="margin-right: 8px;" filterable clearable size="mini" v-model="dateValue" placeholder="请选择">
          <el-option v-for="item in dateOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-button v-if="!editMode" size="mini" type="warning" @click="findHistoryTable">进入修改模式</el-button>
        <template v-else>
          <el-button size="mini" type="warning" @click="findHistoryTable">筛选</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(-1, 'add')">新增</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(-1, 'save')">保存</el-button>
        </template>
      </nav>
      <div style="max-height: 400px;overflow:auto">
        <el-table :data="historyTable" style="width: 100%;" max-height="400">
          <el-table-column fixed="left" align="center" label="账户ID" width="100" prop="acid" />
          <el-table-column fixed="left" align="center" label="日期" width="100" prop="date" />
          <template v-for="item in editList" :key="item.key">
            <el-table-column align="center" :label="item.label" width="110">
              <template #default="scope">
                <div @click.stop="handleEdit(scope.$index)">
                  <div v-if="scope.$index === scopeIndex">
                    <el-input-number style="width: 100px;" v-model="scope.row[item.key]" size="mini" :min="0" :controls="false" :precision="item.precision"></el-input-number>
                  </div>
                  <div v-else>{{ scope.row[item.key] === 0 ? 0 : scope.row[item.key] || '-' }}</div>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column v-if="editMode" fixed="right" label="操作">
            <template #default="scope">
              <el-button type="text" style="color: rgb(245, 108, 108)" @click.stop="handleEdit(scope.$index, 'del')">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from "@/components/SpreadTable";
import {
  getLists,
  getAcids,
  handleSelectionChanges,
  liquidations,
  findHistoryTables,
  handleSaves,
} from "./liquidationMethods";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let refs2 = "";
    const table2 = (el) => {
      refs2 = el;
    };
    onMounted(() => {
      getList()
    });
    let data = reactive({
      labelType: "课程",
      colList: {
        '课程': [
          { name: "大赛ID", text: "courseID" },
          { name: "大赛名称", text: "name" },
        ],
        '大赛': [
          { name: "大赛ID", text: "competitionID" },
          { name: "大赛名称", text: "name" },
        ],
      },
      acidColList: {
        '课程': [
          { name: "acid", text: "acid" },
          { name: "组名", text: "courseName" },
        ],
        '大赛': [
          { name: "acid", text: "acid" },
          { name: "组名", text: "competitionName" },
          { name: "用户名", text: "name" },
        ],
      },
      loading: false,
      spreadTable: [],
      acidTable: [],
      tableHeight: 400,
      selectList: [],
      acidSelectList: [],
      showRoleDia: false,
      startTime: undefined,
      endTime: undefined,
      dateRange: null,
      step: 0,
      acidOptions: [],
      dateOptions: [],
      editMode: false,
      acidValue: '',
      dateValue: '',
      saveValue: {},
      scopeIndex: -1,
      editList: [
        { label: "委托ID", key: "omid", width: 120, precision: 0 },
        { label: "委托状态", key: "flag", width: 120, precision: 0 },
        { label: "交易所号", key: "exchange", width: 120, precision: 0 },
        { label: "证券类型", key: "stype", width: 120, precision: 0 },
        { label: "证券内码", key: "intenalsid", width: 120, precision: 0 },
        { label: "代码", key: "code", width: 120, precision: 0 },
        { label: "持仓方向", key: "tposition", width: 120, precision: 0 },
        { label: "买卖方向", key: "direction", width: 120, precision: 0 },
        { label: "委托价格", key: "orderprice", width: 120, precision: 3 },
        { label: "委托量", key: "ordervol", width: 120, precision: 0 },
        { label: "成交量", key: "tradedvol", width: 120, precision: 0 },
        { label: "取消量", key: "cancelvol", width: 120, precision: 0 },
        { label: "平均成交价", key: "averageprice", width: 120, precision: 3 },
      ],
      historyTable: [
        {
          "omhid": 4209,
          "omid": 5,
          "date": 20210727,
          "flag": 9,
          "acid": 71155521,
          "stid": 0,
          "oid": 0,
          "exchange": 1,
          "stype": 1,
          "intenalsid": 271723,
          "code": "688338",
          "tposition": 0,
          "direction": 0,
          "orderprice": 97.76,
          "ordervol": 1000,
          "averageprice": 0,
          "tradedvol": 0,
          "commitvol": 0,
          "commitvol2": 0,
          "entrustNo": "S202107271406290010",
          "entrustNo2": "L202107271406290009",
          "trademoduleid": 0,
          "account": "SIMACC",
          "cancelorderprogress": 0,
          "cancelvol": 0,
          "actualtradeprice": 0,
          "adddatetime": 1627371388000,
          "systemCenterNo": "",
          "seatNo": "",
          "threadid": 0,
          "orderadjusted": 0,
          "updatedatetime": "2021-07-27 15:36:53",
          "acceptdatetime": "2021-07-27 15:36:29",
          "senddatetime": null,
          "sendtrademoduleid": null,
          "sendthreadid": null,
          "sended": null,
          "errormsg": "价格超过涨跌停板价"
        },
        {
          "omhid": 4209,
          "omid": 5,
          "date": 20210727,
          "flag": 9,
          "acid": 71155521,
          "stid": 0,
          "oid": 0,
          "exchange": 1,
          "stype": 1,
          "intenalsid": 271723,
          "code": "688338",
          "tposition": 0,
          "direction": 0,
          "orderprice": 97.76,
          "ordervol": 1000,
          "averageprice": 0,
          "tradedvol": 0,
          "commitvol": 0,
          "commitvol2": 0,
          "entrustNo": "S202107271406290010",
          "entrustNo2": "L202107271406290009",
          "trademoduleid": 0,
          "account": "SIMACC",
          "cancelorderprogress": 0,
          "cancelvol": 0,
          "actualtradeprice": 0,
          "adddatetime": 1627371388000,
          "systemCenterNo": "",
          "seatNo": "",
          "threadid": 0,
          "orderadjusted": 0,
          "updatedatetime": "2021-07-27 15:36:53",
          "acceptdatetime": "2021-07-27 15:36:29",
          "senddatetime": null,
          "sendtrademoduleid": null,
          "sendthreadid": null,
          "sended": null,
          "errormsg": "价格超过涨跌停板价"
        },
      ],
    });

    let getList = (val) => {
      getLists(val, data);
    };

    let getAcid = () => {
      getAcids(data)
    }
    let handleSelectionChange = (val) => {
      handleSelectionChanges(val, data);
    };
    let liquidation = (mode) => {
      liquidations(mode, data, refs2);
    };

    const findHistoryTable = () => {
      findHistoryTables(data)
    }
    const handleEdit = (index, mode) => {
      if (data.editMode) {
        if (data.scopeIndex === -1 || data.historyTable[data.scopeIndex].omid || data.historyTable[data.scopeIndex].omid === 0 || (mode === 'del' && index === data.scopeIndex)) {
          switch (mode) {
            case 'save':
              data.scopeIndex = index
              handleSaves(data)
              break;
            case 'del':
              if (index === data.scopeIndex) {
                data.scopeIndex = -1
              } else if (index < data.scopeIndex) {
                --data.scopeIndex
              }
              data.historyTable.splice(index, 1)
              break;
            case 'add':
              data.historyTable.unshift({
                "acid": data.saveValue.acid,
                "omid": undefined,
                "date": data.saveValue.date,
                "flag": undefined,
                "exchange": undefined,
                "stype": undefined,
                "intenalsid": undefined,
                "code": undefined,
                "tposition": undefined,
                "direction": undefined,
                "orderprice": undefined,
                "ordervol": undefined,
                "tradedvol": undefined,
                "cancelvol": undefined,
                "averageprice": undefined
              })
              data.scopeIndex = 0
              break;
            default:
              data.scopeIndex = index
              break;
          }
        } else {
          ElMessage.warning(`请输入第${data.scopeIndex + 1}行的委托ID`)
        }
      }
    }
    return {
      ...toRefs(data),
      getList,
      getAcid,
      handleSelectionChange,
      liquidation,
      table2,
      findHistoryTable,
      handleEdit,
    };
  },
});
</script>

<style scoped lang="less">
.box-card {
  /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    // background-color: #faad14;
    // border-color: #faad14;
  }
  /deep/ .el-radio-button__inner:hover {
    // color: #faad14;
    // border-color: #faad14;
  }
  nav {
    position: relative;
    line-height: 32px;
    .demonstration {
      font-size: 14px;
    }
    .btn {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.process{
  height: calc(100vh - 165px);
  border-radius: 6px;
  background-color: #fff;
  padding:20px;
  overflow: auto;

}
</style>