<template>
  <div
    class="forum-video"
    @mouseenter="videoMousenter"
    @mouseleave="videoMouseleave"
  >
    <div v-show="data.isShow">
      <div v-html="data.suspendUrl" v-show="data.isShow"></div>
      <div v-html="data.player" v-if="data.isShow" style="position: absolute; top:0;left:0"></div>
    </div>
    <div v-html="data.suspendUrl" v-show="!data.isShow"></div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { react } from "@babel/types";
let forumVideo = ref();

const props = defineProps({
  videoUrl: {
    type: String,
  },
});
let data = reactive({
  isShow: false,
  isHidden: false,
  newUrl:'',
  suspendUrl:
    '<iframe src="//player.bilibili.com/player.html?aid=334283843&bvid=BV1sw411971F&cid=375568444&page=1" autostart="false" scrolling="no" danmaku="0" border="0" frameborder="no" framespacing="0" > </iframe>',
  player:
    '<iframe src="//player.bilibili.com/player.html?aid=334283843&bvid=BV1sw411971F&cid=375568444&page=1" autostart="false" scrolling="no" danmaku="0" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>',
});

const videoMousenter = () => {
  data.player = data.newUrl;
  data.isShow = true;
};
const videoMouseleave = () => {
  data.isShow = false;
  data.player =data.suspendUrl;
};

//校验链接是否有autoplay
const isAutoplay = (url) => {
	console.log("url",url);
  //获取暂停时的url地址
  if (!url.includes("autoplay=0")) {
    let newUrl = url.split("?");
    newUrl[0] = newUrl[0] + "?autoplay=0&";
    data.suspendUrl = newUrl.join("");
  }
  //获取播放时的url地址
  if (!url.includes("autoplay=1")) {
    let newUrl = url.split("?");
    newUrl[0] = newUrl[0] + "?autoplay=1&";
    data.player =newUrl.join("");
    data.newUrl= data.player
  }
};
isAutoplay(props.videoUrl);
</script>
<style lang="less">
.forum-video {
  height: 100%;
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
  }
  .player {
    object-fit: fill;
    width: calc(100% - 2px);
    height: 100%;
    margin-left: -1px;
  }
  iframe {
    object-fit: fill;
    width: calc(100% - 2px) !important;
    height: 100%;
    margin-left: 1px;
    height: 100% !important;
  }
}
</style>
