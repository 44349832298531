<!-- 开放式基金赎回页面 -->
<template>
  <el-row>
    <el-col :span="9">
      <div class="left">
        <el-form :model="data" ref="dataRef" :rules="rules" :hide-required-asterisk="true" size="mini">
          <el-form-item label="证券代码" prop="fundNameSelect">
            <el-select style="width: 100%;" v-model="data.fundNameSelect" filterable remote reserve-keyword placeholder="基金名称"
              :remote-method="listFund" :loading="data.loading" size="mini" @change="getFundInfo">
              <el-option v-for="(item, index) in data.fundList" :key="item.id" :label="item.fundName" :value="item.id" />
            </el-select>
						<!-- <span style="text-align: center;display: inline-block;width: 50%;">{{ data.fundInfo.fundName }}</span> -->
          </el-form-item>
          <el-divider></el-divider>
          <!-- <el-form-item label="基金名称" prop="fundName" >{{ data.fundInfo.fundName }}</el-form-item>
          <el-divider></el-divider> -->
          <el-form-item label="基金份额" prop="fundShare" style="text-align:right">{{ data.fundInfo.fundShare }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <!-- <el-form-item label="基金净资产总值:" prop="netAssets">{{ data.fundInfo.netAssets }}</el-form-item> -->
          <el-form-item label="单位基金净资产" prop="unitNetAssets" style="text-align:right">{{ data.fundInfo.unitNetAssets }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="可交易份额" prop="freeShare" style="text-align:right">{{ data.fundInfo.freeShare }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="交易费率" prop="fee" style="text-align:right">{{ data.fundInfo.fee }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="赎回数量" prop="number" style="text-align:right">
            <el-input style="width: 100%;" v-model="data.number" size="mini" placeholder="请输入赎回数量！" clearable />
          </el-form-item>
          <el-divider></el-divider>
          <el-button size="mini" style="width: 100%;margin: 20px 0;" type="primary" class="handlebtn" @click="redeem">赎回</el-button>
        </el-form>
    </div>
		</el-col>
    <el-col :span="15">
      <div style="margin: 20px;height: 60vh;overflow: auto;">
        <div style="margin-bottom: 10px"><i class="card-line"></i>行情数据</div>
        <!-- <el-form-item label="行情数据:"></el-form-item> -->
        <span v-if="data.date.length !=0">
          <ECharts :heightCharts="heightCharts" :option="data.option"></ECharts>
        </span>
        <span v-else>
          <el-empty description="暂无数据" />
        </span>
      </div>
    </el-col>
  </el-row>
</template>

<script setup>
import { reactive, toRefs, ref, onMounted } from 'vue';
import ECharts from '@/components/Echarts'
import { listApprovaledFund, openFundRedeemInfo, openFundRedeem, getNetWorthData } from '../../api.js';
import { ElMessage } from 'element-plus';
import { findFundTrendByAcid } from '@/views/course-manage/group-manage/groupApi.js'
const dataRef = ref(null)
let data = reactive({
	loading: ref(false),
	number: ref(''),
	fundInfo: {
		"id": '',
		"fundName": '',
		"freeAmount": "",
		"frozenAmount": "",
		"amounts": "",
		"fundShare": '',
		"netAssets": "",
		"unitNetAssets": "",
		"freeShare": "",
		"frozenShare": "",
		"acid": '',
		"holdingTime": "",
		"fee": ""
	},
	fundNameSelect: ref(''), //证券代码
	number: '',//购买数量
	option: {},//行情数据表
	heightCharts: 300,
	date:[],
	value:[],
})

//校验
const rules = reactive({
	fundNameSelect: { required: true, message: '请选择证券代码！', trigger: 'change', },
	number: { required: true, message: '请输入赎回数量!', trigger: 'blur', },
})
//下拉查询
const listFund = (fund) => {
	var formData = new FormData();
	formData.append("fundName", fund);
	listApprovaledFund(formData).then(res => {
		data.fundList = res.data.data;
	})
}

//基金详情
const getFundInfo = () => {
	var formData = new FormData();
	formData.append("acid", sessionStorage.getItem("acid"));
	formData.append("fundId", data.fundNameSelect);
	openFundRedeemInfo(formData).then(res => {
		data.fundInfo = res.data.data;
		marketQuotations();
	})
}

//赎回
const redeem = () => {
	dataRef.value.validate((valid) => {
		if (valid) {
			let parm = {
				"acid": sessionStorage.getItem("acid"),
				"fundId": data.fundNameSelect,
				"fee": data.fundInfo.fee,
				"tradeShare": data.number,
				"userId": JSON.parse(sessionStorage.getItem('user')).userId,
				"unitNetAssets": data.fundInfo.unitNetAssets
			}
			openFundRedeem(parm).then(res => {
				if (res.data.code == '200') {
					ElMessage({ type: 'success', message: '赎回成功！' })
					data.fundNameSelect = ''
					data.number = ''
					data.fundInfo = {}
				} else {
					ElMessage({ type: 'error', message: res.data.msg })
				}
			})
		}
	})
}
// 行情走势图
const marketQuotations = () => {

	var formData = new FormData();
	formData.append("fundId", data.fundInfo.id);
	getNetWorthData(formData).then((res) => {
		let arr = res.data.data.fundTrendInformations
		console.log(res);
		
		if (res.data.code == 200) {
			data.date = res.data.data.date;
			data.value = res.data.data.value;
			data.option = {
				tooltip: {
					trigger: "axis",
				},
				xAxis: {
					type: "category",
					data: data.date,
				},
				yAxis: {
					type: "value",
				},
				series: [{
					data: data.value,
					type: "line",
					smooth: true,
					lineStyle: {
						color: "#FEAE10",
					},
					itemStyle: {
						color: "#FEAE10",
					},
				},],
			};
		}
	})
}
</script>

<style lang="less" scoped>
.ruleForm {
	.ruleForm-item-right {
		width: 50%;
		max-width: 300px;
		min-width: 120px;
	}
}

.el-form-item {
	margin-bottom: 0px;
}

.el-form-item__label {
	margin-left: 50px;
	text-align: justify;
}
.left{
//   height: 60vh;
  overflow: auto;
  margin: 20px;
  padding: 20px 13%;
  background: #F4F4FA;
}
/deep/ .el-divider--horizontal{
  margin: 15px 0  !important;
  padding: 0 !important;
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;

  background: #3B6EFB;
}
.text-divider.el-divider--horizontal{
	margin:8px 0px!important;
}
</style>