<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 10px;">
      <!-- 外汇table切换暂时不用 -->

      <el-col :span="24" v-if="[1, 2, 3, 5, 4 ].indexOf(courseType) === -1">
        <el-radio-group v-model="financeRadio" :disabled="financeRadioDisabled" size="small" style="margin:5px -15px;" text-color="#3B6EFB" fill="#FFFF" @change="tableChange" >
          <el-radio-button label="证券" value="证券" />
          <el-radio-button label="外汇" value="外汇" />
        </el-radio-group>
     
      </el-col>
    </el-row>
    <div>
      <div style="margin-bottom: 20px;float: left;"><i class="card-line"></i>新建报告</div>
      <div v-for="(item ,index) in reportList" :key="item.key" @click="tabBtn(item.router)" style="float: right;"><el-button v-if="item.name != '新建'" size="mini" :type="item.name == '新建'?'warning':'primary'" style="margin-right: 10px;"> {{ item.name }} </el-button></div>
    </div>
    
      <div class="main-box" style="clear: both;">
        <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="6">
        <span class="report-title">周次</span>
        <el-select v-model="weekValue" placeholder="请选择" size="mini" :disabled="!showStudent">
          <el-option v-for="(item,index) in weekOption" :key="item.timeRoundId" :label="item.currentWeek" :value="item.timeRoundId">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span class="report-title">报告类型</span>
        <el-select v-model="reportValue" placeholder="请选择" size="mini" :disabled="disabled" @change="reportChange">
          <el-option v-for="item in reportOptions" :key="item.filetype" :label="item.type" :value="item.filetype">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span class="report-title">报告名称</span>
        <el-input v-model="fileName" placeholder="请输入报告名称" size="mini" :disabled="!showStudent" style="width: 150px"></el-input>
      </el-col>
      <el-col :span="6" v-show="showCodeSearch">
        <span v-if="financeRadio != '外汇'">
          <span class="report-title" style="float: left; position: relative;top: 5px;">绑定证券</span>
          <span v-show="disabled || !showStudent" style="border: 1px solid #E5E7EC; padding:2px 5px; width: 150px; border-radius: 5px; color: #C1C4CB; float: left;background-color: #F5F7FA;"><span v-for="(item,index) in codeList" :key="index" style="font-size: 13px;">{{item.sharesName}}</span></span>
          <CodeSearch @codeSelectChane="codeSelectChane"  :codeList="codeList" v-show="!disabled && showStudent" :disabled="disabled" :codeType="codeType" :multiple="true"></CodeSearch>
        </span>

        <span v-else>
          <span class="report-title" style="float: left; position: relative;top: 5px;">绑定币种</span>
          <span v-show="disabled || !showStudent" style="border: 1px solid #E5E7EC; padding:2px 5px; width: 150px; border-radius: 5px; color: #C1C4CB; float: left;background-color: #F5F7FA;"><span v-for="(item,index) in codeList" :key="index" style="font-size: 13px;">{{item.name}},</span></span>
          <el-select v-show="!disabled && showStudent" v-model="codeList" multiple filterable placeholder="请选择币种" :loading="loading" size="small">
            <el-option v-for="item in currencyOptions" :key="item.value" :disabled="disabled" :label="item.label" :value="item.value" />
          </el-select>
        </span>

      </el-col>
    </el-row>
    <wang-editor :value="valueEdit" :disable="!showStudent" :ref="valueDo" @valueChange="valueChange"></wang-editor>
    <div style="margin: 20px 0">
      <el-button type="primary" size="mini" @click="upFile(0)" v-if="showStudent">确认上传</el-button>
      <el-button type="warning" size="mini" plain v-if="!showDrafr && showStudent" @click="upFile(1)" style="margin:0 20px">保存草稿</el-button>
      <el-button type="warning" size="mini" plain v-if="disabled" @click="backRouter" style="margin:0 20px">返回</el-button>
<!--      <span class="report-title" style="color:rgba(0,0,0,.65);">
        关联报告: <i v-for="(item,index) in moreFile" style="cursor: pointer" :key="index" @click="checkMoreFile(item)">{{item.name+', '}}</i>
      </span> -->
    </div>

    <check-file :value="valueFile" :fileName="fileMoreName" :diaStart="diaStart" @setClose="closeDia"></check-file>
  
</div>

  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeMount, reactive, toRefs, onUnmounted, ref } from "vue";
import WangEditor from "@/components/WangEnduit";
import { reportData } from "./reportData";
import checkFile from "../../upreport-manage/checkFile";

import CodeSearch from "@/components/CodeSearch/MultIndex"
import {
  valueChanges,
  getTypes,
  reportChanges,
  upFiles,
  codeSelectChanes,
  getCodLists,
  getMores,
  checkMoreFiles,
  getWeekLists,
  // loadmores
} from './reportMethods'
import { useRoute, useRouter } from "vue-router";
import { Data } from "../../../trade-personal/components/Funds";

export default defineComponent({
  name: "index",
  components: {
    WangEditor,
    CodeSearch,
    checkFile,
  },
  setup() {
    let data = reactive({
      financeRadioDisabled:false,
      currencyOptions: [],
      financeRadio: '证券',
      showStudent: true,
      biweekly: false,
      monocycle: false,
      weekValue: '',
      weekOption: [],
      reportOptions: [],
      reportValue: '',
      valueEdit: '',
      fileName: '',
      fileType: 1,
      loadingUp: false,
      showCodeSearch: false,
      disabled: false,
      showDrafr: false,
      codeList: [],
      pageParame: [],
      foreignExchangePageParame: [],
      itemParame: {},
      codes: [],
      moreFile: [],
      // 查看
      fileMoreName: '',
      diaStart: false,
      valueFile: '',
      checkType: '',

      codeAll: [],
      codeType: '0',
      routePageType: '0',
      courseType: 0,
      reportList:[]
    });
    const route = useRoute();
    const router = useRouter();
    let refs = ''
    let valueDo = el => { refs = el }
    onBeforeMount(() => {
      if (route.query.showStudent) {
        data.showStudent = JSON.parse(route.query.showStudent)
      }

    })
    onMounted(() => {
      if(sessionStorage.getItem('reportList')){
        data.reportList=JSON.parse(sessionStorage.getItem('reportList'))
      }
      if (route.query.financeRadio) {
        data.financeRadio = route.query.financeRadio
      }
      // if (JSON.parse(sessionStorage.getItem('courseType'))!== 5){
      data.currencyOptions = JSON.parse(localStorage.getItem('CurrencyOptions'))
      data.codeType = JSON.parse(sessionStorage.getItem('courseType')) + ''
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      // }
      if (route.query.pageType) {
        data.routePageType = route.query.pageType
        data.fileType = route.query.pageType
        getType(1)
        data.disabled = true
        if (route.query.pageType === '4') {
          data.financeRadioDisabled = true
          data.reportValue = '调仓报告'
          if (data.financeRadio == '外汇') {
            data.foreignExchangePageParame = JSON.parse(route.query.value)
            console.log("data.foreignExchangePageParame",data.foreignExchangePageParame);
            if (data.foreignExchangePageParame && data.foreignExchangePageParame.length > 0) {
              data.codes = data.foreignExchangePageParame.map(item => { return item.fundfile })
            }
          } else {
            data.pageParame = JSON.parse(route.query.value)
            if (data.pageParame && data.pageParame.length > 0) {
              data.codes = data.pageParame.map(item => { return item.fundfile })
            }
          }

          data.showDrafr = true
          // getMore()
          reportChange(parseInt(route.query.pageType))
        }
        if (route.query.pageType === '5') {
          console.log("route.query.value",route.query.value);
          data.financeRadioDisabled = true
          data.reportValue = '风控报告'
          data.itemParame = JSON.parse(route.query.value)
          data.codes.push(data.itemParame.fileId)
          data.showDrafr = true
          // getMore()
          reportChange(parseInt(route.query.pageType))
        }
        if (route.query.pageType === '6') {
          data.financeRadioDisabled = true
          data.pageParame = JSON.parse(route.query.value)
          if (data.pageParame.filetype === 1) getCodList()
          data.reportValue = data.pageParame.fileTypeName
          reportChange(data.pageParame.filetype)
          data.fileName = data.pageParame.name
          
          if (data.financeRadio == "外汇") {
            data.codeList = data.pageParame.currencyList
          } else {
            data.codeList = data.pageParame.tiedStockList
          }
          console.log("data.codeList",data.codeList);
          data.checkType = '1'
          data.fileType = data.pageParame.filetype
          //添加周次回显
          data.weekValue = data.pageParame.currentTimeRoundId
          //添加内容回显
          data.codes.push(data.pageParame.fileId)
          getMore()
          // data.showDrafr = true
        }
      } else {
        getType(0)
      }
      getWeekList()

    })
    // let weekValueChange = (index) => {
    //   if ((index + 1) % 2 == 0) {
    //     data.monocycle = false
    //   } else {
    //     data.monocycle = true
    //   }
    // }
    // onUnmounted(()=>{
    //   data.valueEdit = ''
    // })
    let tableChange = () => {
      data.weekValue = ''
      data.reportValue = ''
      data.fileName = ''
      data.showCodeSearch = false
      data.codeList = []
      data.valueEdit = ''
    }
    let getWeekList = () => { getWeekLists(data) }
    let getType = (num) => { getTypes(num, data) }
    let upFile = (num) => { upFiles(num, data, router) }
    let valueChange = (value) => { valueChanges(value, data) }
    let reportChange = (value) => { reportChanges(value, data) }
    let getCodList = () => { getCodLists(data) }
    let codeSelectChane = (value) => { codeSelectChanes(value, data) }
    let getMore = () => { getMores(data) }
    let checkMoreFile = (val) => { checkMoreFiles(val, data) }
    let closeDia = () => { data.diaStart = false }
    let backRouter = () => { router.go(-1) }
    // let loadmore = () => {loadmores()}
    const tabBtn=(url)=>{
      router.push(url)
    }
    return {
      ...toRefs(data),
      // loadmore,
      getWeekList,
      getType,
      upFile,
      getCodList,
      valueChange,
      reportChange,
      codeSelectChane,
      valueDo,
      getMore,
      checkMoreFile,
      closeDia,
      backRouter,
      tableChange,
      tabBtn
    }
  }
})
</script>

<style scoped lang="less">
.report-title {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.radio-button {
  border: none;
  color: #dca550;
  &:hover {
    color: #dca550;
  }
}
::v-deep.el-radio-button {
  .el-radio-button__inner {
    background: #FFFF;
    color: #333;
    font-size: 14px !important;
    border: 0 !important;
  }
}
// .main-box{
//   min-height: 600px;
//   overflow-y: auto;
//   overflow-x: hidden;
// }
</style>
