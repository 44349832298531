export const marketData = {
  tableColumn: [
    { name: "代码", text: "code" },
    { name: "名称", text: "name" },
    { name: "涨跌额", text: "nes" },
    { name: "涨跌幅", text: "changeS" },
    { name: "现价", text: "nowprice" },
    { name: "成交金额", text: "currentPrice" },
    { name: "成交股数", text: "hands" },
    { name: "今开", text: "open" },
    { name: "昨收", text: "close" },
    { name: "今高", text: "high" },
    { name: "今低", text: "lose" },
  ],
  showDiv: false,
  radio: "https://www.10jqka.com.cn/",
  defaultMoreUrl:'http://q.10jqka.com.cn/',
  UrlList: [
    { url: "https://www.10jqka.com.cn/", name: "同花顺",moreUrl:'http://q.10jqka.com.cn/' },
    // { url: "http://q.10jqka.com.cn/index/index/board/all/field/zdf/order/desc/page/1/ajax/1/", name: "同花顺" },
    { url: "https://www.eastmoney.com/", name: "东方财富",moreUrl:'http://quote.eastmoney.com/center/' },

    // {url:'https://hstrade.tebon.com.cn/#!/hq/ggStockInfo?stockcode=300937',name:'德邦'},
    // { url: "https://finance.sina.com.cn/forex/", name: "外汇" },
  ],
  tableData: [
    { code: "11", name: "22", nes: "22", changeS: "223", start: 0 },
    { code: "11", name: "22", nes: "22", changeS: "223", start: 1 },
  ],
  list: [],
  intervalId: null,
  loading: true,
  startIndex: 0,
  addIndex: 0,
};
