/**
 * @author wuDada
 * @class
 * @name 行情大盘数据
 * @description 功能类 组件（prop）参数
 * @param {Number} tableHeight  显示高度，默认为300
 * @param {String} HqType  默认查询股票类型（stock）
 */
export const markerProps = {
    tableHeight:{ // table 高度
        type: Number,
        default: 300
    },
    HqType:{
        type: String,
        default: 'stock'
    }
}