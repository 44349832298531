<template>
  <div></div>
</template>

<script>
import axios from "@/axios/globalaxios";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { findCurrencyType } from "@/views/trade-personal/components/ForeignExchangeTransactions/ForeignExchangeTransactionsApi.js";

export default {
  name: "logins",
  created() {
    const router = useRouter()
    let ticjet = this.getquerystr("ticket");
    if (ticjet) sessionStorage.setItem("codeKey", ticjet);
    let key = sessionStorage.getItem("codeKey");
    if (key) {
      // 登录
      axios.post('/tradelabLx/user/ssoLogin', {
        token: key,
      }).then(res => {
        if (res.data.code === '200') {
          this.getAllCode(1)
          this.getAllCode(2)
          this.getAllCode(3)
          this.getAllCode(null)
          let nesRow = res.data.data
          // localStorage.setItem('user',JSON.stringify(nesRow))
          // localStorage.setItem('token', nesRow.token)
          sessionStorage.setItem('user', JSON.stringify(nesRow))
          sessionStorage.setItem('token', nesRow.token)
          sessionStorage.setItem('acid', res.data.data.acId)
          sessionStorage.setItem('logger_tv', encodeURI(escape("信息是" + res.data.data.userRoleName)));
          sessionStorage.setItem('roleList', JSON.stringify(res.data.data.userRoleResources))
          sessionStorage.setItem('competition-status', '')

          ElMessage.success(res.data.msg)
          this.getCurrencyOptions();
          router.push('/index')
        } else {
          ElMessage.error(res.data.msg)
        }
      })
    } else {
      // window.location.href = "https://login-test.sufe.edu.cn/cas/login?service=http://10.2.47.158:8081/login/Single";
      window.location.href = "https://login.sufe.edu.cn/cas/login?service=http://10.2.47.158/login/Single";
      // window.location.href = "https://login.sufe.edu.cn/cas/login?service=https://exp1.sufe.edu.cn/exp/tradelabLx/login/Single";
    }
  },
  methods: {
    getCurrencyOptions() {
      findCurrencyType().then((res) => {
        if (res.data.code == 200) {
          let options = []
          res.data.data.currencyData.forEach(element => {
            options.push({
              value: element.id,
              label: element.name,
              abbr: element.abbr,
            })
          });
          console.log("options", options);
          localStorage.setItem('CurrencyOptions', JSON.stringify(options))
        }
      })
    },


    getAllCode(num) {
      let name = num === null ? 'all' : num === 1 ? 'shares' : num === 2 ? 'bond' : num === '3' ? 'forward' : 'all'
      axios.post('/tradelabLx/transaction/findStopwatchByFtype', { type: num }).then(res => {
        if (res.data.code === '200') {
          let resNew = res.data.data
          localStorage.setItem(name, JSON.stringify(resNew.stopwatches))
        }
      })
    },
    getquerystr(getstr) {
      let searchurl = location.search;
      searchurl = decodeURI(searchurl);
      searchurl = searchurl.slice(1);
      searchurl.split("=");
      var arr = searchurl.split("&");
      var obj = {};
      arr.forEach(item => {
        let keys = item.split("=")[0];
        let vals = item.split("=")[1];
        obj[keys] = vals;
      });
      return obj[getstr];
    },
  }
}
</script>

<style scoped></style>
