import {
  findFileByFindType,
  delDraftBox,
  findForeignFileByFindType,
  findForeignFileByFindId,
} from "./startApi";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import { repHtml } from "../../../teacher-manage/fine-report/fineMethods";
export const getDrafrLists = (data) => {
  data.disabled = true
  let parame = {
    classId: JSON.parse(sessionStorage.getItem("classId")),
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    findType: data.pageType,
    // lastState: data.lastState,
    // filetype: data.filetype,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  data.spreadTable = []
  if (data.financeRadio == '外汇') {
    parame.courseId = parame.classId;
    findForeignFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.currencyManagement.list;
        data.total = newRes.currencyManagement.total;
        dealDate(data.spreadTable, ["date"]);
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  } else {
    findFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.reportManagementPageInfo.list;
        data.total = newRes.reportManagementPageInfo.total;
        dealDate(data.spreadTable, ["date"]);
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  }
  
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      res[dealArr[j]] =
        res[dealArr[j]].toString().slice(0, 4) +
        "-" +
        res[dealArr[j]].toString().slice(4, 6) +
        "-" +
        res[dealArr[j]].toString().slice(6);
    }
  }
  return arr;
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getDrafrLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getDrafrLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    fieldId: item.fileId,
  };
  delDraftBox(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getDrafrLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data, router) => {
  if (data.financeRadio == '外汇') { 
    findForeignFileByFindId({ fileId: val.item.fileId }).then((res) => {
      console.log("res--1",res.data);
      if (res.data.code == "200") {
        data.reportHtml = res.data.data.files;
      }
    });
  } else {
    repHtml(data, val.item.fileId);
  }
  data.showHtml = true;
  // data.reportHtml = val.item.files
};
