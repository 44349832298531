//获取后台路由(动态路由)
export function getRouters() {
  return new Promise((resolve, reject) => {
    const userRoleResources = [
      {
        name: "Layout",
        path: "/",
        component: "Layout",
        children: [
          {
            path: "/megagame/rank", //大赛排名
            name: "rank",
            meta: { requiteAuth: true },
            component: "megagame/rank"
          },
          {
            name: "roleManage", //角色管理
            path: "/roleManage",
            component: "system-manage/role-manage"
          },
          {
            path: "/index",
            name: "homePage",
            meta: { requiteAuth: true, title: "首页" },
            component: "Home.vue"
          },
          {
            path: "/information",
            name: "information",
            component: "information"
          },
          {
            path: "/userClass",
            name: "userClass",
            meta: { requiteAuth: true },
            component: "course-manage/class-manage"
          },
          {
            path: "/groupList",
            name: "groupList",
            meta: { requiteAuth: true },
            component: "course-manage/class-manage/group-list"
          },
          {
            name: "classManage", //教师 班级管理
            path: "/classManage",
            component: "teacher-manage/class-manage"
          },
          {
            name: "trade-personalstock",
            path: "/trade-personal/stock",
            meta: { requiteAuth: true },
            component: "trade-personal/indexStock.vue"
          },
          {
            name: "groupTeacherManage", //教师 小组管理
            path: "/groupManage",
            component: "teacher-manage",
            children: [
              {
                name: "groupRank", //教师 小组排名
                path: "/groupManage/groupRank",
                component: "teacher-manage/group-rank"
              },
              {
                name: "findReport", //教师 优秀研报
                path: "/groupManage/findReport",
                component: "teacher-manage/fine-report"
              },
              {
                name: "classGroup", //教师 班级分组
                path: "/groupManage/classGroup",
                component: "teacher-manage/class-group"
              },
              {
                name: "modelEdit", //教师 班级分组
                path: "/groupManage/modelEdit",
                component: "teacher-manage/model-edit"
              }
            ]
          },
          {
            name: "megagame", //大赛管理
            path: "/megagame",
            component: "megagame",
            // meta: { requiteAuth: true },
            children: [
              {
                path: "/megagame/group-manage", //教师端小组管理
                name: "group-manage",
                // meta: { requiteAuth: true },
                component: "megagame/participants-group"
              },
              {
                path: "/megagame/rank", //大赛排名
                name: "rank",
                // meta: { requiteAuth: true },
                component: "megagame/rank"
              },
              {
                path: "/megagame/key-ratio", //关键比率
                name: "key-ratio",
                meta: { requiteAuth: true },
                component: "megagame/key-ratio"
              }

              // {
              //   path: "/megagame/asset-allocation", //资产配置
              //   name: "asset-allocation",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/asset-allocation"),
              // },
              // {
              //   path: "/megagame/risk-analysis", //风险分析
              //   name: "risk-analysis",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/risk-analysis"),
              // },
              // {
              //   path: "/megagame/security", //重仓证券
              //   name: "security",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/security"),
              // },
              // {
              //   path: "/megagame/industry-allocation", //行业配置
              //   name: "industry-allocation",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/industry-allocation"),
              // },
              // {
              //   path: "/megagame/history-return", //历史回报
              //   name: "history-return",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/history-return"),
              // },
              // {
              //   path: "/megagame/trade/:id", //交易管理
              //   name: "trade",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/course-manage/trade-manage"),
              // },

              // {
              //   path: "/megagame/user-manage", //教师端成员管理
              //   name: "user-manage",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/participants"),
              // },
              // {
              //   path: "/megagame/participants", //学生端成员管理
              //   name: "participants",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/participants-user"),
              // },
              // {
              //   path: "/megagame/participants", //学生端成员管理
              //   name: "participants",
              //   meta: { requiteAuth: true },
              //   component: () => import("@/views/megagame/participants-user"),
              // },
              // {
              //   path: '/megagame/participants/competition', //学生端成员管理
              //   name: 'participants',
              //   meta: {requiteAuth:true},
              //   component: () => import('../views/teacher-manage/Liquidation'),
              // },
            ]
          }
        ]
      }
      // {
      //   "path":"",
      //   'redirect': 'Organization',
      //   "hidden":false,
      //   "component":"Layout",
      //   "meta":{
      //       "title":"组织管理"
      //   },
      //   "children":[
      //       {
      //           "name":"Organization",
      //           "path":"Organization",
      //           "hidden":false,
      //           "component":"organization/index",
      //           "meta":{
      //               "title":"组织管理"
      //           }
      //       }
      //   ]
      // },
      // {menuDescription : "用户管理", menuUrl :  "/userManage"},
      // {menuDescription: "角色管理",menuUrl: "/roleManage"}
    ]
    resolve(userRoleResources)
  })
}
// export const addResource = data =>{
//   return axios({
//       url: "/tradelabLx/resource/addResource",
//       method: 'post',
//       data:data
//   })
// }
export const getMenu= (data)=> {
  let menu = [{
    name: "Layout",
    path: "/",
    component: () => import('@/components/layout/index.vue') ,
    children: []
  }]
  data.forEach((element,index) => {
    let obj = {
      name: element.url ,
      path: element.url,
      component: element.fileUrl,
      meta:{title:element.urlDescription},
      children: []
    }
    menu[0].children.push(obj)
    if(element?.childResources.length>0){
      element.childResources.forEach((item) => {
        let chilObj = {
          name: item.url ,
          path: item.url,
          meta:{title:item.urlDescription},
          component: item.fileUrl,
        }
        menu[0].children[index].children.push(chilObj)
      })
    
    }
  })
  const list = filterAsyncRouter(menu[0].children,false, true);
  return menu
}
// 遍历后台传来的路由字符串，转换为组件对象
export const filterAsyncRouter= (asyncRouterMap, lastRouter = false, type = false)=> {
    return asyncRouterMap.filter(route => {
     
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        if (route.component) {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            } else if (route.component === 'ParentView') {
                route.component = ParentView
            } else if (route.component === 'InnerLink') {
                route.component = InnerLink
            } else {
                route.component = loadView(route.component)
            }
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}

export const filterChildren= (childrenMap, lastRouter = false) =>{
    var children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView' && !lastRouter) {
                el.children.forEach(c => {
                    c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
    return children
}

export const loadView = (view) => {
    return () => import(`@/views/${view}`);
}
