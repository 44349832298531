import { findWarningByAcid } from "./summaryApi";

// 切换条数
export const getWarnLists = (data) => {
  data.loading = true;
  let param = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findWarningByAcid(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.warnTableData = newRes.accountWarningMessages.list;
      data.total = newRes.accountWarningMessages.total;
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};
// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getWarnLists(data);
};
// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getWarnLists(data);
};
