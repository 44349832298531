export const groupData = {
    drafrList : [
        {name:'ACID',text: 'acid'},
        {name:'小组ID',text: 'groupId'},
        {name:'小组名称',text: 'groupName'},
        {name:'资产情况',text: 'amounts'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 450,
    pageType: 1,
    lastState: 1000,

    userInput: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[]
}