<template>
  <div class="megagame">
    <main-table v-if="mainMode" :mineMode="mineMode" />
    <div v-else >
      <el-card>
        <el-page-header class="back-header" :content="competitionName" title="返回" @back="backRouter" />
        <!-- <div style="font-size: 25px; margin-bottom: 10px;"><span style="font-size: 18px; margin: 3px 10px;color: #DDDFE5;position: relative; top: -2px;">| </span> {{competitionName}}</div> -->
        <nav-son-menu ref="child" v-if="toGroupListMega" :menuData="menuList" :routerType="false" :direction="true" />
       <div class="megagame-main">
        <router-view />
       </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import NavSonMenu from "@/components/NavSonMenu";
import mainTable from "./main-table";
import { useRoute, useRouter } from "vue-router";
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch,
  onBeforeUnmount,
} from "vue";
import { fatherRoute, menuData } from "./datas";
import { userInfo } from "./methods";
import { } from "./apis";

export default defineComponent({
  name: "megagame",
  components: {
    NavSonMenu,
    mainTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let data = reactive(menuData);
    const child = ref(null);

    onMounted(() => {
      //获取大赛管理
      let arr = JSON.parse(sessionStorage.getItem('roleList')).filter((item) => {
        if (item.url === '/megagame') {
          return item;
        }
      })
      arr[0].childResources.sort((a, b) => {
	    return a.sort - b.sort
      })
      let roleList=[]
      let filteList=['/megagame/groupListMega']
      // ,'/megagame/trade/:id'
      let newMenuList=   arr[0].childResources.filter((item)=>{
        if(!filteList.includes(item.url)){
            return item
        }
      })
      let index = 0
      data.menuList=[]
      newMenuList.forEach((elem,index)=>{
        ++index
        let obj={
          name: elem.urlDescription,
          router: elem.url,
          key: index,
        }
        if(elem.url==='/megagame/trade/:id'){
          data.menuIndex = index;
          obj.router='/megagame/trade'
          obj.children=[
          { name: "股票", router: fatherRoute + "/trade/1", hide: true, key: 12 },
          { name: "债券", router: fatherRoute + "/trade/2", hide: true, key: 13 }, 
          { name: "期货", router: fatherRoute + "/trade/3", hide: true, key: 14 },  //开放期货时，  此页面  data.menuList[menuIndex].children[3].hide = true  也解开
          { name: '外汇', router: fatherRoute + '/table?showAbbr=false', key: 16, hide: true, },
          ];

        }
        if (elem.url == '/megagame/table') {
          newMenuList.forEach((item, index2) => {
            ++index2
            if (item.url === '/megagame/trade/:id') {
              data.menuIndex = index2;
            }
          })
        }
        data.menuList.push(obj)
      })  
      data.mainMode = route.name === "/megagame";
      back();
      window.addEventListener("popstate", setKey, false);
      calMainMode(route.name)
    });

    let backRouter = () => { router.push({ path: "/megagame" }) }
    
    watch(() => route.name, (n) => {
      if (route.name == "login") {
        return
      }
      calMainMode(n)
    });

    const setKey = () => {
      if (child.value) {
        const menu = data.menuList.filter((menu) => menu.router.indexOf(route.name) > -1);
        if (menu.length > 0) {
          child.value.setKey(menu[0].key);
        }
      }
    };

    onBeforeUnmount(() => { window.removeEventListener("popstate", setKey) });

    const back = () => {
      if (route.path !== "/megagame") {
        sessionStorage.setItem('mineMode', '1');
        // router.push({ path: route.path });
      } 
      const mineMode = sessionStorage.getItem('mineMode')
      if (mineMode == '1') {
        data.mineMode = '1'
        sessionStorage.setItem('mineMode', '0')
      } else {
        data.mineMode = '0'
      }
    };

    const calMainMode = (name) => {
      data.mainMode = name === "/megagame";
      if (route.name == '/megagame/groupListMega') { data.toGroupListMega = false; return }
      data.toGroupListMega = true 
      if (!data.mainMode) {
        
        const mega = JSON.parse(sessionStorage.getItem("mega")) || {};
        data.competitionName = mega.name;
        const transactionType = mega.transactionType;
        //matchType 组队类型 0个人 1小组
        //transactionType 1股票 2债券 3期货 4混合 5股票债券
        //childeren [0]股票 [1]债券 [2]期货
      let megagameList=  ['/megagame/trade','/megagame/group-manage','/megagame/user-manage','/megagame/participants']
      data.menuList.forEach((item)=>{
      if(megagameList.includes(item.router)){
        item.hide=true
      }
    })
        const isTeacher = userInfo().roleName === "教师";
        if (isTeacher) {
          if (mega.matchType == 1) {
            filterMatchType(['/megagame/group-manage'])
          }
        }
        if (mega.matchType == 1) {
          if (isTeacher) {
          } else {
            calTransactionType(transactionType)
            filterMatchType(['/megagame/trade','/megagame/participants'])
          }
        } else if (isTeacher) {
          filterMatchType(['/megagame/user-manage'])
        } else {
          //等于组队类型 不显示小组管理
          filterMatchType(['/megagame/trade'])
          calTransactionType(transactionType)
        }
      }
      setKey();
    };
    const filterMatchType = (url)=>{
     data.menuList.forEach((item)=>{
              if(url.includes(item.router)){
               item.hide=false;
              }
            })
    }
    const calTransactionType = (transactionType) => {
      let isMenu = false
      let menuIndex = null
      data.menuList.forEach((item,index)=>{
        if(item.router=='/megagame/trade'){
          isMenu=true
          data.menuIndex = index;
          menuIndex = index
        }
        if (item.router == '/megagame/table') {
          data.menuList.forEach((item2, index2) => { 
            if (item2.router == '/megagame/trade') { 
              data.menuIndex = index2;
            }
          })
          isMenu=true
          data.menuList[index].hide = true
        }
      }
      )
      if(!isMenu){
        return
      }
      data.menuList[menuIndex].children[0].hide = true
      data.menuList[menuIndex].children[1].hide = true
      data.menuList[menuIndex].children[2].hide = true
      data.menuList[menuIndex].children[3].hide = true
      switch (transactionType) {
        case 1:
          data.menuList[menuIndex].children[0].hide = false
          break;
        case 2:
          data.menuList[menuIndex].children[1].hide = false
          break;
        case 3:
          data.menuList[menuIndex].children[2].hide = false
          break;
        case 6:
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 5:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[1].hide = false
          break;
        case 7:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 8:
          data.menuList[menuIndex].children[1].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 9:
          data.menuList[menuIndex].children[2].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 4:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[1].hide = false
          data.menuList[menuIndex].children[2].hide = false
          break;
        case 10:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[1].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 11:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[2].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 12:
          data.menuList[menuIndex].children[1].hide = false
          data.menuList[menuIndex].children[2].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
        case 13:
          data.menuList[menuIndex].children[0].hide = false
          data.menuList[menuIndex].children[1].hide = false
          data.menuList[menuIndex].children[2].hide = false
          data.menuList[menuIndex].children[3].hide = false
          break;
      }
      sessionStorage.setItem('transactionManagement',JSON.stringify(data.menuList[menuIndex].children))
      data.menuList[menuIndex].children.hide = false
    }

    return {
      ...toRefs(data),
      back,
      child,
      filterMatchType,
      backRouter
    };
  },
});
</script>

<style lang="less">
.megagame {
  .normal-item {
    margin-right: 8px;
    width: 350px;
  }
  .normal-btn {
    width: 100px;
    height: 40px;
  }
  .right-search {
    position: absolute;
    right: 42px;
  }
  .right-export {
    position: absolute;
    top: -56px;
    right: 0;
  }
  .history-return {
    .right-export {
      top: -93px;
    }
  }
  .back-header {
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.megagame-main{
  height: calc(100vh - 270px);
  background-color: #fff;
  overflow: auto;
  overflow-x: hidden;
}
</style>
