export const classData = {
    classInput:'',
    classStart:1,
    classStartList:[],

    visbleBoolean: false,
    modeItem: [],
    loading: false,
    itemList: [],
    page: 1,
    pageSize: 10,
    classId: 0,
}