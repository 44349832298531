<template>
  <div class="container">
    <el-card class="box-card" style="min-height: auto">
      <div style="margin-bottom: 10px"><i class="card-line"></i>修改密码</div>
      <el-form :rules="rules" ref="formLabelRef" label-width="100px"
        :model="formLabelAlign" style="max-width: 460px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="formLabelAlign.oldPassword" placeholder="请输入原登录密码" type="password" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="formLabelAlign.newPassword" placeholder="请输入新的登录密码" type="password" />
        </el-form-item>
        <el-form-item label="确认密码" prop="password">
          <el-input v-model="formLabelAlign.password" placeholder="请再次输入登录密码" type="password" />
        </el-form-item>
        <el-button type="primary" style="margin-left:100px;width: 361px;" @click="changePassword">确认修改</el-button>
      </el-form>
    </el-card>
  </div>

</template>

<script>
import { useRouter } from 'vue-router'
import Schema from 'async-validator';
import { changePasswords, confirmPassFns } from './personalMtehods'
import { reactive, ref, toRefs } from 'vue'
// import { v4 as uuidv4 } from "uuid";
export default {
  name: "layout",

  setup() {
    // const uuid = ref(uuidv4());
    const router = useRouter()
    const formLabelRef = ref(null)
    let data = reactive({
      formLabelAlign: {
        oldPassword: '',
        newPassword: '',
        password: '',
      },
      
      rules: {
        oldPassword: [{ required: true, message: '原登录密码不能为空', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '新的登录密码不能为空', trigger: 'blur' },
          {
            pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/,
            message: "密码需为8~20位且需包含数字、大小写字母、特殊字符两种及以上",
          }
        ],
        password: [{ required: true, message: '确认登录密码不能为空', trigger: 'blur' },
        {
          pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/,
          message: "密码需为8~20位且需包含数字、大小写字母、特殊字符两种及以上",
        },
        {  message:'两次输入密码不一致！',   validator: (message, value) => value == data.formLabelAlign.newPassword , trigger: 'blur' }
        ],
      },
    })
    // const validator = new Schema(data.rules);
    // validator.validate({ password: data.formLabelAlign.newPassword}, (errors, fields) => {
    //   if (errors) {
    //     return handleErrors(errors, fields);
    //   }
    //   // validation passed
    // });
    let changePassword = () => { changePasswords(data, formLabelRef, router) }
    // let confirmPassFn = () => { confirmPassFns(data) }
    return {
      // uuid,
      ...toRefs(data),
      formLabelRef,
      changePassword,
      // confirmPassFn
    };
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 20px 50px;
}

.container {
  width: 100%;
  height: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  .el-form {
    margin-top: 35px;
    padding-top: 30px;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }

  .card-line {
    display: inline-block;
    width: 3px;
    height: 13px;
    border-radius: 8px;
    margin-right: 10px;
    background: #faad14;
  }
}
</style>
