<template>
    <div id="market">
      <div class="box-card">
        <div style="margin-top: 20px;">
          <el-radio-group v-model="radio" size="mini" @change="urlChange">
            <el-radio-button v-for="(item,index) in UrlList"
                             :key="index" :label="item.url">{{item.name}}</el-radio-button>
          </el-radio-group>
          <div class="more">
          <a :href="defaultMoreUrl" target="_blank">查看行情</a>
        </div>
        </div>
        
        <div style="margin-top: 15px;height: 60vh;">
          <iframe :src="radio" style="width: 100%;height: 100%"></iframe>
        </div>
      </div>
<!--        <el-table :data="tableData" header-align="center" :height="tableHeight+'px'" size="mini"-->
<!--                  :cell-class-name="fnRowClass" v-loading="loading"-->
<!--                  :header-cell-style="{background:'#191e29',color:'wheat'}" style="width: 100%">-->
<!--            <el-table-column  align="center" v-for="(item,index) in tableColumn" :key="index" :prop="item.text" :label="item.name"></el-table-column>-->
<!--        </el-table>-->
<!--        <el-button type="warning" size="mini" @click="previousPage">上一页</el-button>-->
<!--        <el-button type="warning" size="mini" @click="nextPage">下一页</el-button>-->
    </div>
</template>

<script>
    import {defineComponent, onMounted, onUnmounted, reactive, toRefs, watch} from 'vue'
    import {markerProps} from "./marktProps";
    import {marketData} from "./marketData";
    import {getHqLists,getTypeLists,clears,dataRefrehs,previousPages,nextPages} from "./marketMethods";

    export default defineComponent({
        name: "index",
        props: markerProps,
        setup(props,ctx){
            onMounted(()=>{
                let tableHeight = parseInt((props.tableHeight - 46-30) / 36)
                data.addIndex =  tableHeight
                getTypeList()
                dataRefreh()
            })
            watch(() => props.HqType, (val, prevVal) => {
                data.loading = true
                clear()
                props.HqType = val
                getTypeList()
                dataRefreh()
            })
            let data = reactive(marketData);
            let fnRowClass = ({ row }) =>{return row.start === '0' ? "classColor" :"classColor2"}
            let getTypeList = () =>{getTypeLists(props.HqType,data)}
            let getHqList = ()=>{getHqLists(data)}
            let clear = () =>{clears(data)};
            let dataRefreh = () =>{dataRefrehs(data)}
            let previousPage = () =>{previousPages(data)}
            let nextPage = () =>{nextPages(data)}
            onUnmounted(() => {clear()})
          let urlChange = (e) => {
            // 查看更多行情
            if(e==='https://www.10jqka.com.cn/'){
              data.defaultMoreUrl='http://q.10jqka.com.cn/'
            }else{
              data.defaultMoreUrl='http://quote.eastmoney.com/center/'
            }
            console.log(e,'e')
          }
            return{
                ...toRefs(data),
                fnRowClass,
                getHqList,
                getTypeList,
                previousPage,
                nextPage,
              urlChange
            }
        }
    })
</script>

<style lang="less">
  .more{
    float: right;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3B6EFB;
    line-height: 26px;
    width: 68px;
    height: 28px;
    text-align: center;
    background: #F9FAFF;
    border-radius: 4px;
    border: 1px solid #3B6EFB;
    cursor: pointer;
    a{
      color: #3B6EFB;
    }
  }

#market{
    margin-left: 25px;
    .el-table__body-wrapper{
        cursor: pointer;
        background: #191e29;
    }
    .el-table__body {
        .el-table__row {
            background: #191e29;
            color: white;
        }
    }
    .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #2f4f4f !important;
    }
    .classColor{
        color: #fb8585;
    }
    .classColor2{
        color: greenyellow;
    }
}
</style>
