<template>
  <div class="process">
    <div class="box-card" style="padding: 20px;">
      <div style="margin-bottom: 10px;margin-top: -8px;" class="box-title">学校管理</div>

      <div style="float: right;margin-top: -33px;margin-bottom: 20px;">
        <span style="font-size: 13px;font-weight: 400">学校名称：</span>
        <el-input v-model="userInput" style="width: 200px;margin: 0 10px" placeholder="请输入关键字" clearable size="mini"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
        <!-- <el-switch v-model=value active-color="#13ce66" inactive-color="#ff4949" @click="control" inactive-text="注册开关：" style="float: right" content="注册开关！" placement="top">
        </el-switch> -->
        <el-button size="mini" type="warning" @click="schoolRoleDia = true">添加学校</el-button>

      </div>
      <spread-table v-loading="loading" :beforeRouteName="beforeRouteName" :tableHeight="tableHeight" @SwitchButton="SwitchButton" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" @controls="control" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>

    <el-dialog title="添加学校" v-model="schoolRoleDia" width="30%">
      <div>
        <span>学校名称：</span>
        <el-input v-model="schoolName" size="mini" style="width: 200px" placeholder="请输入内容"></el-input>
      </div>
      <div style="margin-top: 20px">
        <span>学校域名：</span>
        <el-input v-model="schoolUrl" size="mini" style="width: 200px" placeholder="请输入内容"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="schoolRoleDia = false">取 消</el-button>
          <el-button type="primary" @click="schoolSaveInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="设置信息" v-model="showRoleDia" width="30%">
      <div>
        <span>学校名称：</span>
        <el-input v-model="studentName" size="mini" style="width: 200px" placeholder="请输入内容"></el-input>
      </div>
      <div style="margin-top: 20px">
        <span>学校域名：</span>
        <el-input v-model="studentUrl" size="mini" style="width: 200px" placeholder="请输入内容"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="backDia">取 消</el-button>
          <el-button type="primary" @click="saveInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { schoolData } from "./schoolData";
import { clickButtons, currentChanges, sizeChanges, getUserLists, saveInfos, backInfo, schoolSaveInfos, SwitchButtons } from "./schoolMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '学校名称', text: 'schoolName' },
        { name: '学校域名', text: 'schoolDomain' },
        { name: '创建人', text: 'createUser' },
        { name: '修改人', text: 'updateUser' },
        { name: '创建时间', text: 'createTime' },
      ],
      beforeRouteName: "schoolManage",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['修改', '删除'],
      pageType: 1,
      lastState: 1000,
      userInput: undefined,

      schoolRoleDia: false,
      schoolName: '',
      schoolUrl: '',

      showRoleDia: false,
      studentName: '',
      studentUrl: '',
      studentItem: {},
      value: true
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let backDia = () => { backInfo(data) }
    let saveInfo = () => { saveInfos(data) }
    let control = () => { controls(data) }
    let SwitchButton = (val) => { SwitchButtons(val, data) }
    let schoolSaveInfo = () => { schoolSaveInfos(data) }
    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      backDia,
      saveInfo,
      control,
      schoolSaveInfo,
      SwitchButton
    }
  }
})
</script>

<style scoped lang="less">
// :deep(.el-card__body){
//   height: calc(100vh - 165px);
// }
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.process{
  height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding:0px 20px;
  padding-top:20px;
}
</style>