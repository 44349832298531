<template>
  <div style="width: 100%; background: #ffff">
    <el-row :gutter="12" style="margin: 0 20px">
      <el-col :span="24">
        <div style="height: 50px; line-height: 50px">
          <i class="card-line"></i>{{ data.fundName }}
        </div>
      </el-col>
      <el-col :span="12">
        <div style="height: 500px; margin-right: 10px" :class="{ 'video-line': !options.src }">
          <span><i class="card-line"></i>影视</span>
          <div v-if="options.src" class="video-style">
            <div v-html="options.src"></div>
          </div>
          <div v-else>
            <el-empty description="暂无影视" />
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div style="height: 500px" :class="{ 'video-line': !options.src }">
          <span><i class="card-line"></i>介绍</span>
          <div class="infoText" v-if="data.info" v-html="data.info" style="white-space: pre-wrap"></div>
          <div v-else>
            <el-empty description="暂无介绍" />
          </div>
        </div>
      </el-col>
      <el-col :span="12" style="margin-top: 30px">
        <div style="margin: 10px;0; color:red;font-weight: 900">
          {{ data.averageNAV }}
        </div>
        <div style="
            font-size: 9px;
            margin-bottom: 20px;
            padding-left: 10px;
            color: #686480;
          ">
          单位净值[{{ data.averageName }}]
        </div>
        <div style="margin-right: 10px">
          <span><i class="card-line"></i>单位净值图</span>
          <ECharts :heightCharts="heightCharts" :option="data.option1"></ECharts>
        </div>
      </el-col>
      <el-col :span="12" style="margin-top: 30px">
        <div style="margin: 10px;0;color:red;font-weight: 900">
          {{ data.accumulatedNet }}
        </div>
        <div style="
            font-size: 9px;
            margin-bottom: 20px;
            padding-left: 10px;
            color: #686480;
          ">
          累计净值[{{ data.accumulatedName }}]
        </div>
        <div style="margin-left: 10px">
          <span><i class="card-line"></i>总净值图</span>
          <ECharts :heightCharts="heightCharts" :option="data.option2"></ECharts>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <div>
          <span><i class="card-line"></i>研报</span>
          <mini-tag :tagList="data.tagList" @tagChange="tagChange" style="float: right"></mini-tag>

          <div style="margin-top: 20px">
            <el-table :data="data.tableValue" style="width: 100%" max-height="400px"
              :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
              <el-table-column v-for="(item, index) in data.tableData" :prop="item.text" :label="item.name"
                :key="index"></el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button size="mini" @click="lookOver(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <checkFile :value="data.valueFile" :fileName="data.fileName" :diaStart="data.diaStart" @setClose="closeDia">
          </checkFile>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <div style="margin-top: 20px">
          <span><i class="card-line"></i>文档</span>

          <div class="dox-list" v-if="data.searchList.length > 0">
            <div>
              <el-table :data="data.searchList" style="width: 100%">
                <el-table-column label="文件名称" align="left"><template #default="scope"><span
                      @click="getExcel(scope.row.fullUrl)">{{
                        scope.row.fileName
                      }}</span></template></el-table-column>
                <el-table-column fixed="right" label="操作" width="120"><template #default="scope"><a href="#"
                      @click="downloads(scope.row)">下载</a></template></el-table-column>
              </el-table>
              <!-- <el-table class="item-line" style="box-size:border-box; ">
                <span style="width: 80%;" @click="getExcel(item.fullUrl)">{{ item.fileName }}</span>
                <span style="cursor:pointer;" @click="downloads(item)"><a href="#">下载</a></span>
                //文件预览功能 
                <el-dialog title="预览" :visible.sync="data.open1" width="1000px" height="800px" append-to-body>
                  <iframe width="900px" height="600px" :src='data.url1'></iframe>
                </el-dialog>
              </el-table> -->
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无文档数据" />
          </div>
        </div>
      </el-col>

      <el-col>
        <div class="p" style="padding-top: 20px; margin-bottom: 20px; clear: both">
          <i class="card-line"></i>讨论区
        </div>
        <div>
          <FundInvestor :fundId="fundId" ref="investor"></FundInvestor>
        </div>
        <div style="height: 20px"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import ECharts from "@/components/Echarts";
import { onMounted, reactive, ref, getCurrentInstance } from "vue";
import checkFile from "../upreport-manage/checkFile.vue";
import MiniTag from "@/components/MiniTag";
import { useRoute, useRouter } from "vue-router";
import { getTaskFundInfo } from "./forumApi.js";
import videoplay from "vue3-video-play";
import FundInvestor from "@/views/trade-personal/components/FundInvestor/index3.vue";
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// export default {}
const poster = ref("");
let route = useRoute();
let router = useRouter();
let fundId = route.query.fundId;
const investor = ref(null);
onMounted(() => {
  //获取信息
  getData(fundId);
  //getNetUnitValueChart()
  listPl(fundId);
});
const listPl = (fundId) => {
  investor.value.listPl(fundId);
};
const options = reactive({
  width: "100%", //播放器高度
  height: "z", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  src: "", //视频源，地址可通过传参传入
  muted: true, //静音
  // webFullScreen: true,
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
  autoPlay: true, //自动播放
  loop: true, //循环播放
  mirror: true, //镜像画面
  ligthOff: true, //关灯模式
  volume: 0.3, //默认音量大小
  control: true, //是否显示控制器
});

let data = reactive({
  vueExample: getCurrentInstance(),
  totalNetWorth: {},
  unitNetWorth: {},
  averageNAV: "",
  averageName: "",
  accumulatedNet: "",
  accumulatedName: "",
  // dialogVisible4:true,
  searchList: [],
  option1: {},
  option2: {},
  heightCharts: 300,
  tableData: [
    { name: "文件ID", text: "fileId" },
    { name: "报告名称", text: "name" },
    { name: "报告类型", text: "fileTypeName" },
    // { name: "报告状态", text: "fileStateName" },
    { name: "上传时间", text: "date" },
  ], //表头
  operation: ["查看"],
  tagList: [
    { name: "证券", key: 1, type: "groupTag" },
    { name: "外汇", key: 4, type: "groupTag" },
  ],
  tableValue: [], //数据内容
  open1: "",
  url1: "",
  fileUrl: "",
  tableVal: {},
  tagName: "证券",
  diaStart: false,
  fileName: "",
  valueFile: "",
  info: "",
  fundName: "",
});

// 方案一
// let look = (url)=>{
//           //需要判断一下是office文件还是pdf  pdf不用加微软的api
//          let str =  url.substring(url.lastIndexOf(".")+1)
//           if(str == 'pdf'){
//             data.url1 = url
//             data.open1=true;
//             data.fileUrl = url
//           }else{
//             data.url1 = 'https://view.officeapps.live.com/op/embed.aspx?src='+url
//             data.open1=true;
//             data.fileUrl = url
//           }
//         };

// 方案二
let getExcel = (val) => {
  let file = val; // 'https://image.yoshebao.com/temp/20220323/终止协议范本(报工伤用).docx'
  // file="https://image.yoshebao.com/temp/20220830/38f857866a6a64e4f0ae39a9574c09f3.jpg";
  // file="https://image.yoshebao.com/temp/20220204/a21ba71f4c5229fd5a065c121589fa0c.xls"";
  // file="https://image.yoshebao.com/temp/20220323/终止协议范本(报工伤用).docx";
  // file="https://www.gjtool.cn/pdfh5/git.pdf";
  // file="https://zhidao.baidu.com/question/528206706552245005.html";
  let lastIndex = file.lastIndexOf(".");
  let suffix = file.substring(lastIndex + 1);
  //文件格式是word文档、ppt、excel文件文件时
  if (
    suffix == "doc" ||
    suffix == "docx" ||
    suffix == "ppt" ||
    suffix == "xls" ||
    suffix == "xlsx"
  ) {
    let fileUrl = encodeURIComponent(file);
    //使用Office Web查看器
    let officeUrl =
      "http://view.officeapps.live.com/op/view.aspx?src=" + fileUrl;
    window.open(officeUrl, "_target");
  } else {
    //其他文件格式比如pdf、图片、html
    window.open(file);
  }
};

// 查看按钮
let lookOver = (row) => {
  console.log("row222", row);
  data.valueFile = row.files;
  data.diaStart = true;
  console.log(" data.diaStart", data.diaStart);
};

let closeDia = () => {
  data.diaStart = false;
};

let downloads = (item) => {
  // const elink = document.createElement('a');
  // elink.href = item.fileUrl;
  // elink.target = '_self'; // 当前也 target打开新页面
  // elink.download = item.fileName;  //自定义文件名
  // elink.style.display = 'none';
  // document.body.appendChild(elink);
  // elink.click();   //模拟出发a 点击事件
  // document.body.removeChild(elink); // 删除a
  let url = "/tradelabLx/documentSharing/download/openFunddFile";
  let params = {
    fileName: item.fileName,
    url: item.fileUrl,
    //url:"/apps/files/2023-03-20ea0fb55319a844c393309e68083bee7e.pdf"
  };
  console.log("params", params);
  const { proxy } = data.vueExample;
  proxy.$DownloadAsExcel(url, params, item.fileName);
};
// 获取数据
let getData = () => {
  var formData = new FormData();
  formData.append("fundId", fundId);
  getTaskFundInfo(formData).then((res) => {
    console.log("reswwww", res);
    data.info = res.data.data.info;
    data.fundName = res.data.data.fundName;
    data.tableValue = [];
    if (data.tagName == "证券") {
      data.tableValue = res.data.data.fileByTypeAndAcid;
    } else {
      data.tableValue = res.data.data.currencyFiles;
    }
    options.src = res.data.data.videoUrl;
    data.totalNetWorth = res.data.data.totalNetWorth;
    data.unitNetWorth = res.data.data.unitNetWorth;
    data.searchList = res.data.data.docFiles;
    getTotalUnitValueChart();
    getNetUnitValueChart();
  });
};
// 点击标题展示文件预览
// let getExcel = (item) => {
//   look(item)
// console.log('laoaoo');
// }
// 单位净值图
let getNetUnitValueChart = () => {
  let date = data.unitNetWorth.date;
  let value = data.unitNetWorth.value;
  data.averageName =
    date[date.length - 1].substring(4, 6) +
    "-" +
    date[date.length - 1].substring(6, 8);
  data.averageNAV = value[value.length - 1];
  data.option1 = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: date,
    },
    yAxis: {
      type: "value",
    },
    grid: {
      top: "20%", //距上边距
      left: "5%", //距离左边距
      right: "10%", //距离右边距
      bottom: "10%", //距离下边距
      containLabel: true,
    },
    series: [
      {
        data: value,
        type: "line",
        smooth: true,
        lineStyle: {
          color: "#FEAE10",
        },
        itemStyle: {
          color: "#FEAE10",
        },
      },
    ],
  };
};

// 总净值图
let getTotalUnitValueChart = () => {
  let date = data.totalNetWorth.date;
  let value = data.totalNetWorth.value;
  data.accumulatedName =
    date[date.length - 1].substring(4, 6) +
    "-" +
    date[date.length - 1].substring(6, 8);
  data.accumulatedNet = value[value.length - 1];
  data.option2 = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: date,
    },
    yAxis: {
      type: "value",
    },
    grid: {
      top: "20%", //距上边距
      left: "5%", //距离左边距
      right: "10%", //距离右边距
      bottom: "10%", //距离下边距
      containLabel: true,
    },
    series: [
      {
        data: value,
        type: "line",
        smooth: true,
        lineStyle: {
          color: "#FEAE10",
        },
        itemStyle: {
          color: "#FEAE10",
        },
      },
    ],
  };
};

// 研报类型查询
let tagChange = (value) => {
  console.log("value", value);
  data.tagName = value.name;
  getData();
};
</script>

<style lang="less" scoped>
.video-line {
  height: 300px !important;

  .infoText {
    max-height: 260px !important;
  }
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

.item {
  // cursor: pointer;
  border-bottom: 2px solid #ddd;
  // height: 40px;
  margin-top: 20px;

  .item-line {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    line-height: 20px;
  }
}

:deep(.el-card__body) {
  height: 100%;
}

.video-style {
  width: 100%;
  height: calc(100% - 20px);

  div {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.infoText {
  padding: 20px 30px;
  max-height: 440px;
  overflow: auto;
  line-height: 33px;
  font-size: 16px;
  text-indent: 32px;
}

.fundName {
  background: #fff;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
  width: 100%;
  margin-right: 20px;
}

:deep(.is-left .cell) {
  text-align: left !important;
}

.dox-list {
  display: block !important;
  max-height: 500px;
  overflow: auto;
}
</style>
