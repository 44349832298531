import {
  findUserByClassId,
  eliminateUsers,
  findDoesNotExistUserByClassId,
  pullInCourse,
  syncStudent,
} from "./studentApi";
// ./classGroupApi
// import {
//   delGroupByGroupId,
// } from "../../teacher-manage/class-group/classGroupApi";
import {
  ElMessage
} from "element-plus";
import {
  dealDate
} from "../../course-manage/trade-manage/tradeMethods";
import {
  ElMessageBox
} from "element-plus";

export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    classId: sessionStorage.getItem("classId"),
    userSNickname: data.userInput,
    jobNumber: data.jobNumber,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findUserByClassId(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.courseUsersPageInfo.list;
      data.total = newRes.courseUsersPageInfo.total;
      dealDate(data.spreadTable, ["createTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换条数22
export const sizeChangeStudents = (val, data) => {
  data.pageSizeStudent = val;
  addUsers(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};
// 切换页数22
export const currentChangeStudents = (val, data) => {
  data.currentPageStudent = val;
  addUsers(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    competitionCourseUserIds: [item.id],
  };
  eliminateUsers(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      // if(res.data.msg==='当前人员所在的小组只有一个人，如需要删除请直接删除小组'){
      //   ElMessageBox.confirm("该学生所在的小组只有1个人，删除学生会同时删除小组，确定删除?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }).then(() => {
      //     // delDrafrFiles(val.item, data);

      //   });
      // }else{
        ElMessage.error(res.data.msg);
      // }
  
    
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该学生, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "编辑") {
    dealRole(val.item, data);
  }
};

// 批量
export const handleSelectionChanges = (val, data) => {
  data.selectList = [];
  for (let i in val) {
    data.selectList.push({
      userId: val[i].userId,
      type: 1,
      courseId: sessionStorage.getItem("classId"),
      username: val[i].name,
    });
  }
};
// export const delGroupByGroupIdDra = = (item, data) => {
//     let parame = {
//       groupId: item.courseGroupId,
//     };
//     delGroupByGroupId(parame).then((res) => {
//       if (res.data.code === "200") {
//         ElMessage.success(res.data.msg);
//         getUserLists(data);
//       } else {
//         ElMessage.error(res.data.msg);
//       }
//     });
//   };
// 拉取22
export const clickButtonStudents = (val, data) => {
  if (val.type === "添加") {
    let item = val.item;
    let parame = {
      records: [{
        userId: item.userId,
        type: 1,
        courseId: sessionStorage.getItem("classId"),
        username: item.name,
      }, ],
    };
    pullInCourse(parame).then((res) => {
      if (res.data.code === "200") {
        if(data.spreadTableStudent.length==1&&data.currentPageStudent==Math.ceil(data.totalStudent/data.pageSizeStudent)){
          data.currentPageStudent= data.currentPageStudent==1?1:data.currentPageStudent-1
        }
        ElMessage.success(res.data.msg);
        addUsers(data);
        getUserLists(data);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  }
};


// 批量拉取22
export const addUserPeoples = (data) => {
  if (data.selectList.length === 0)
    return ElMessage.warning("至少选择一名学生");
  let parame = {
    records: data.selectList,
  };
  pullInCourse(parame).then((res) => {
    if (res.data.code === "200") {
       if(data.selectList.length==data.spreadTableStudent.length&&data.currentPageStudent==Math.ceil(data.totalStudent/data.pageSizeStudent)){
        data.currentPageStudent= data.currentPageStudent==1?1:data.currentPageStudent-1
       }
      ElMessage.success(res.data.msg);
      addUsers(data);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

// 设置角色
export const dealRole = (val, data) => {
  getRoleList(data);
  data.showRoleDia = true;
  data.userItem = val;
  data.userSNickname = "";
  data.userValue = val.roleName;
};

// 确认修改
export const saveInfos = (data) => {
  let parame = {
    id: data.userItem.id,
    userRoleId: data.userValue,
  };
  saveSysUserRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.userItem = {};
      backInfo(data);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 取消修改
export const backInfo = (data) => {
  data.userValue = "";
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
    }
  });
};

// 添加学生
export const addUsers = (data) => {
  data.loadingStudent = true;
  let parame = {
    classId: sessionStorage.getItem("classId"),
    pageSize: data.pageSizeStudent,
    page: data.currentPageStudent,
    userSNickname: data.userSNickname,
  };
  findDoesNotExistUserByClassId(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTableStudent = newRes.courseUsersPageInfo.list;
      data.totalStudent = newRes.courseUsersPageInfo.total;
      dealDate(data.spreadTableStudent, ["createTime"]);

      setTimeout(() => {
        data.loadingStudent = false;
      }, 300);
    }
  });
  data.showRoleDia = true;
};
// 同步课程下的学生信息
export const syncStudents = (data) => {
  let parame = {
    courseId: sessionStorage.getItem("classId"),
  };
  syncStudent(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    }else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const nameChanges = (data) => {
  addUsers(data);
};


//关闭弹窗的事件
export const closeDialogs = (data) => {
  data.userSNickname = ''
}