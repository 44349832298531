<template>
	<div style="margin-top: 40px;">
		<TrendChart ref="chart" :fundId="props.fundId"></TrendChart>
	</div>
	<div>
		<span class="span">总资产：{{ getData.fundInfo.totalAssets }}</span>
		<span class="span">基金份额：{{ getData.fundInfo.share }}</span>
		<span class="span">基金净值：{{ getData.fundInfo.netAssets }}</span>
		<span class="span">基金单位净值：{{ getData.fundInfo.nuitNetAssets }}</span>
	</div>
	<div style="margin-top: 40px;">
		<p style="margin-bottom: 20px;">讨论区</p>
		<FundInvestor ref="investor"></FundInvestor>
	</div>
</template>

<script setup >
import TrendChart from '@/components/TrendChart/index2.vue' //走势图
import FundInvestor from '@/views/trade-personal/components/FundInvestor/index2.vue'
import { number } from 'echarts';
import { ref, reactive, onMounted, defineProps } from 'vue'
// import { props } from '../../../../components/CodeSearch/codeProps.js';
// import { props } from '../../../../components/CodeSearch/codeProps.js';
import { getFundInfo } from '../fundApi.js'
// defineProps({

// })
const props = defineProps({
	fundId: {
		type: Number,
	},
	fundName: {
		type: String,
	},
	acid: {
		type: Number
	}
})

let getData = reactive({
	fundInfo: {
		"totalAssets": '',
		"share": '',
		"netAssets": '',
		"nuitNetAssets": '',
	}
})
const chart = ref(null);
const investor = ref(null);

onMounted(() => {
	marketQuotations();
	listPl();
	getFundInfo1();
})

const marketQuotations = () => {
	chart.value.marketQuotations();
}
const listPl = () => {
	investor.value.listPl();
}
const getFundInfo1 = () => {
	var formData = new FormData();
	formData.append("fundId", props.fundId);
	getFundInfo(formData).then(res => {
		getData.fundInfo = res.data.data;
	});
}
	// defineExpose({
	//   marketQuotations,
	//   listPl,
	//   getFundInfo1
	// });
</script>
<style lang="less" scoped>
.span{
	margin-left: 10%;
}
</style>