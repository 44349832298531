const stock = {
    AccountColums:[
        {
            name: "股票账户信息",
            dataIndex: "order",
            width:'',
            align:''
        },
    ],
    PositionColums:[
        {
            name: "股票持仓信息",
            dataIndex: "order",
            width:'',
            align:''
        },
    ]
}

const futures = {
    AccountColums:[
        {
            name: "期货账户信息",
            dataIndex: "order",
            width:'',
            align:''
        }
    ],
    PositionColums:[
        {
            name: "期货持仓信息",
            dataIndex: "order",
            width:'',
            align:'' 
        }
    ]
}

const bonds = {
    AccountColums:[
        {
            name: "债券账户信息",
            dataIndex: "order",
            width:'',
            align:''
        },
    ],
    PositionColums:[
        {
            name: "债券持仓信息",
            dataIndex: "order",
            width:'',
            align:''
        }
    ]
}

export const Data = {
    stock,
    futures,
    bonds
}


export const FundsData = {
    AccountColums:[],
    PositionColums:[],
    tableData:[],
    paginationConfig:{
        page:1,
        pageSize:20,
        total:100
    }
}