<template>
  <el-form ref="salebuy" :model="formline" id="commonform" size="mini" :rules="rules" :hide-required-asterisk="true">
    <div class="flexform">
      <el-form-item label="证券代码" prop="name">
        <CodeSearch @codeSelectChane="codeSelectChane" codeType="2" :multiple="false" :codeList="code"></CodeSearch>

      </el-form-item>
      <el-form-item label="证券名称" >
        <span style="font-size: 14px">{{codeName}}</span>

      </el-form-item>
      <el-form-item label="卖出价格" prop="price">
        <el-input v-model="formline.price" style="width:100%"></el-input>
      </el-form-item>
      <el-form-item label="卖出数量" prop="num">
        <el-input v-model="formline.num" @blur="blurNumber" style="width:100%"></el-input>
        <!-- <span class="unit">张</span> -->
      </el-form-item>
      <el-form-item label="报价方式" prop="way">
        <el-radio-group v-model="formline.way">
          <el-radio :label="2">限价</el-radio>
          <el-radio :label="3">市价</el-radio>
        </el-radio-group>
      </el-form-item>
      
      <el-form-item class="space">
        <el-button style="width: 100%;" type="primary" class="handlebtn" @click="isSalebuy">卖出下单</el-button>
        <div>
          <el-button style="width: 100%;margin-top: 10px;" class="reset" @click="resetForm">重填</el-button>
        </div>
      </el-form-item>
      
    </div>
  </el-form>
</template>

<script>
import CodeSearch from "@/components/CodeSearch"

import { reactive, ref, toRefs, watch } from "vue";
import { codeDeal, codeDealName, dealCodeSearchNum } from "../commonMethods.js";
import { dealCodeSearch } from "../../../course-manage/trade-manage/tradeMethods";
import { placeAnOrder } from "../../../course-manage/trade-manage/components/TradeDetails/detailsApi";
import { ElMessage } from "element-plus";
export default {
  name: "bondsaleform",
  components: {
    CodeSearch
  },
  props: {
    price: {
      type: String
    }
  },
  setup(props, ctx) {
    const salebuy = ref();
    const formline = reactive({
      name: "",
      way: "",
      price: "",
      radio: "",
    });
    let data = reactive({
      code: 'sh127627',
      codeName: 'PR安丘债'
    })
    const rules = reactive({
      way: [{ required: true, message: "请选择报价方式", trigger: "trigger" }],
      price: [{ required: true, message: "请输入卖出价格", trigger:["blur","change"] }],
      num: [{ required: true, message: "请输入卖出数量", trigger: "blur" }],
    });

    watch(() => [formline.num, props.price], ([newNum, newPrice]) => {
      if (isNaN(newNum)) formline.num = ''
      if (newPrice) formline.price = parseFloat(newPrice)
      if (newPrice === 0) formline.price = ''
    })

    const resetForm = () => {
      ctx.emit('editPrice', 0)
      salebuy.value.resetFields();
      // formline.price = ''
      formline.num = ''
    };

    const isSalebuy = async () => {
      salebuy.value.validate((valid) => {
        if (valid) {
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            tposition: 1,
            insCode: codeDeal(data.code.slice(2), '2'),
            direction: 1,
            orderPrice: parseFloat(formline.price),
            orderVol: parseFloat(formline.num),
            code:data.code
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              ElMessage.success(res.data.msg)
              resetForm()
            } else {
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    };

    let blurNumber = (val) => {
      let num = parseInt(val.target.value)
      let minNum = dealCodeSearchNum(data.code.slice(2), '2')
      formline.num = num - num % minNum
    }

    // 切换 搜索
    let codeSelectChane = (value) => {
      // data.codeList = value
      data.code = dealCodeSearch(value, { routeId: '2' })
      data.codeName = codeDealName(value, '2')
      ctx.emit('codeChange', data.code)
    }

    return {
      ...toRefs(data),
      blurNumber,
      formline,
      salebuy,
      rules,
      resetForm,
      isSalebuy,
      codeSelectChane,
    };
  },
};
</script>

<style lang="less" scoped>
#commonform {
  .flexform {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      width: 100%;
      display: flex;
      padding-bottom: 20px;
      // display: flex;
      // &:deep(.el-form-item__label) {
      //   width: 100px;
      // }
      &:deep(.el-form-item__content) {
        flex: 1;
        position: relative;
        padding-right: 20px;
        .unit {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(10%, -50%);
        }
      }
    }
  }
  .space {
    &:deep(.el-form-item__content) {
      // display: flex;
      justify-content: space-around;
    }
  }
  .hiddenlabel {
    &:deep(.el-form-item__label) {
      visibility: hidden;
    }
  }

  .handlebtn {
    background: #feae10;
    border-color: #feae10;
  }
  .reset {
    // color: #feae10;
    background:#F4F4FA 
    // border-color: #feae10;
  }
}
</style>
