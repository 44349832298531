import axios from '@/axios/globalaxios'

// 查询用户管理 信息
export const findGroupByClassId = data =>{
    return axios({
        url: "/tradelabLx/course/findGroupByClassId",
        method: 'post',
        data:data
    })
}
// 删除 用户信息
export const delGroupByGroupId = data =>{
    return axios({
        url: "/tradelabLx/course/delGroupByGroupId",
        method: 'post',
        data:data
    })
}
// 更新 用户信息
export const saveSysUserRole = data =>{
    return axios({
        url: "/tradelabLx/sysUser/saveSysUserRole",
        method: 'post',
        data:data
    })
}



