<template>
   <div v-if="route.path!=='/noticeManage'">
      <router-view/>
      </div>
  <div v-else  class="process">
    <div class="box-card"  style="margin: 20px;">
      <div style="float: left;margin-bottom: 20px;margin-top: -8px;" class="title">公告管理</div>
      <div style="float: right;margin-bottom: 20px;margin-top: -13px;">
        <span style="font-size: 13px;font-weight: 400;display: inline-block;margin-right:7px">选择学校:</span>
        <el-select :disabled="adminDisabled"  v-model="selectSchool" placeholder="请选择" size="mini">
          <el-option v-for="item in schoolList" :key="item.id" :label="item.schoolName" :value="item.id">
          </el-option>
        </el-select>
        <span style="font-size: 13px;font-weight: 400;display:inline-block;margin-left:20px">公告标题：</span>
        <el-input v-model="userInput" style="width: 200px" clearable placeholder="请输入关键字" size="mini"></el-input>
        <el-button type="primary" size="mini" style="margin-left: 15px" @click="getUserList" icon="el-icon-search">搜索</el-button>
        <el-button type="warning" size="mini" style="margin-left: 15px" @click="addNotice">创建公告</el-button>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :action="action" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>
  </div>
</template>

<script>
import { addAnnouncement, announcementById, schoolList, update } from "./noticeApi";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { noticeData } from "./noticeData";
import { clickButtons, currentChanges, sizeChanges, getUserLists, DateChanges } from "./noticeMethods";
import { useRouter,useRoute  } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    let route= useRoute()
    onMounted(() => {
      getUserList()
      getSchool()
    })
    let data = reactive({
      drafrList: [
        { name: '学校名称', text: 'schoolName' },
        { name: '浏览次数', text: 'views' },
        { name: '公告名称', text: 'announcementName' },
        { name: '发布人', text: 'publisher' },
        { name: '发布时间', text: 'releaseTime' },
      ],
      action:true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['编辑', '删除',],
      pageType: 1,
      filetype: null,
      schoolList: [],
      userInput: '',
      selectSchool:JSON.parse(sessionStorage.getItem('user')).userRoleName!=='超级管理员'?JSON.parse(sessionStorage.getItem('user')).schoolId:'',
      adminDisabled:JSON.parse(sessionStorage.getItem('user')).userRoleName!=='超级管理员'

    })
    let getSchool = () => {
      schoolList({
        pageIndex: 1,
        pageSize: 1000,
      }).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.schoolList = newRes.schoolPageInfo.list
        }
      })
    }
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let DateChange = (val) => { DateChanges(val, data) }
    let addNotice = (val) => {
      router.push({ path: '/noticeManage/addNotice' })
    }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      DateChange,
      addNotice,
      getSchool,
      route
    }
  }
})
</script>

<style scoped lang="less">
.title {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #03032C;
  line-height: 18px;
  padding-left:8px;
  border-left: 6px solid #3B6EFB ;

}
.process{
  height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding:0px 20px;
  padding-top:20px;

}
// :deep(.el-card__body){
//   height: calc(100vh - 165px);
// }
</style>