<template>
  <el-card class="box-card" @click="checkCard(item)">
    <template #header>
      <div class="card-header">
        <span class="title" :style="{padding:(styleType===0?'21px 20px':'20px')}"><strong>{{item.courseName}}</strong> </span>
      </div>
    </template>
    <div class="courseContainer">
      <div class="specific">
        <div class="item">
          <div class="label">教师：</div>
          <div class="props">{{item.createUserName}}</div>
        </div>
        <div class="item">
          <div class="label">学生人数：</div>
          <div class="props">{{item.numberOfPeople}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="props" style="font-size: 12px">{{item.createTime}}</div>
        </div>
        <div class="item">
          <div class="label">大赛时间：</div>
          <div class="props"  style="font-size: 12px">{{item.classTime}} ~ {{ item.endTime }}</div>
        </div>
      </div>
      <div class="set">
        <span @click.stop="setCourse(item)"  style="margin-right: 15px">设置</span>
        <span @click.stop="delCourse(item)"  style="color: #f56c6c">删除</span>
      </div>
    </div>

  </el-card>
</template>

<script>
import {reactive,toRefs} from "vue"
import {useRouter} from "vue-router";
import {delGroupByGroupId, updCourse} from "../../classApi";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name:'course',
  props:{
      item:{type:[Object,Number,String]},
    classStart:{type:Number},
    styleType:{type:Number,default:0}
  },
  setup(props,{emit}) {
    let router = useRouter()
   const setCourse = (item) => {
       emit('setcourse',item)
   }
    let checkCard = (item) =>{
      if (props.classStart === 2) return ElMessage.warning('课程状态不可进入')
      sessionStorage.setItem('courseType',item.courseType)
      sessionStorage.setItem('classId',item.id)
      sessionStorage.setItem("courseName", item.courseName);
      router.push('/groupManage/groupRank')
    }
    let delCourse = (val) =>{
      ElMessageBox.confirm('此操作将永久删除该大赛, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let parame = {
          course:{
            id: val.id,
            isDelete: true
          }
        }
        updCourse(parame).then(res=>{
          if (res.data.code === '200'){
            ElMessage.success(res.data.msg)
            emit('refreshData',0)
          }else{
            ElMessage.error(res.data.msg)
          }
        })
      })
    }
    return {
      checkCard,
      setCourse,
      delCourse,
    }
  }
}
</script>

<style lang="less" scoped>
.box-card{
  cursor: pointer;
  height: auto;
  margin-bottom: 20px;
  width: 100%;
    .title{
        display: inline-block;
        text-align: center;
        width: 100%;
        background: #FECD75;
        padding: 18px 20px;
        box-sizing: border-box;

    }
    &:deep(.el-card__header) {
        padding: 0 0 ;
        border-bottom: #FECD75 !important;
    }
    &:deep(.el-card__body) {
        padding: 0px 0;
    }
    .courseContainer{
        .specific{
            background: #FECD75;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            overflow: hidden;
            .item{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    padding: 0 40px;
                    padding-right: 10px;
                    margin-bottom: 10px;
                    font-size: 14px;
                    .label{
                        width: 85px;
                        background: rgba(228, 186, 109, 0.8);
                        text-align: center;
                        margin-right: 10px;
                        padding: 5px 0;
                        border-radius: 4px;
                    }

            }
        }
        .set{
            text-align: right;
            font-size: 14px;
            color:#1890FF;
            background: #fff;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}
</style>
