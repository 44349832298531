<template>
  <div>
    <div class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>学生管理</div>

      <div style="margin: 10px 0">
        <span style="font-size: 13px;font-weight: 400">学生姓名：</span>
        <el-input v-model="userInput" style="width: 200px;margin: 0 10px" placeholder="请输入关键字" clearable size="mini"></el-input>
        <span style="font-size: 13px;font-weight: 400">学号：</span>
        <el-input v-model="jobNumber" style="width: 200px;margin: 0 10px" placeholder="请输入关键字" clearable size="mini"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
        <el-button size="mini" type="warning" style="float:right" @click="addUser">添加</el-button>
        <el-button size="mini" type="primary" style="float:right" @click="syncStudent">同步</el-button>
      </div>

      <!--      <div style="margin: 10px 0">-->
      <!--        <span style="font-size: 13px;font-weight: 400">用户名：</span>-->
      <!--        <el-input v-model="userInput" style="width: 200px;margin: 0 10px" placeholder="请输入关键字" clearable size="mini"></el-input>-->
      <!--        <el-button size="mini" type="warning" @click="getUserList">搜索</el-button>-->
      <!--      </div>-->
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>

    <el-dialog title="添加学生" v-model="showRoleDia" width="50%" @close="closeDialog">
      <span>
        <div style="margin-bottom: 20px">
          <span>学生： <el-input v-model="userSNickname" size="mini" style="width: 200px" @change="" placeholder="姓名"></el-input>
            <el-button size="mini" type="primary" style="margin-left: 15px" @click="nameChange" icon="el-icon-search">查询</el-button>

            <el-button size="mini" type="warning" style="float: right;margin-right: 15px" @click="addUserPeople">批量添加</el-button>
          </span>
        </div>
        <spread-table v-loading="loadingStudent" :tableHeight="tableHeightStudent" :operation="operationStudent" :smallType="true" :pageSize="pageSizeStudent" :currentPage="currentPageStudent" :total="totalStudent" :selection="true" @handleSelectionChange="handleSelectionChange" @sizeChange="sizeChangeStudent" @currentChange="currentChangeStudent" @clickButton="clickButtonStudent" :tableColumn="drafrListStudent" :tableData="spreadTableStudent"></spread-table>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <!--      <el-button @click="showRoleDia = false">取 消</el-button>-->
          <el-button type="primary" @click="showRoleDia = false,getUserList, closeDialog">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { studentData } from "./studentData";
import {
  clickButtons,
  currentChanges,
  sizeChanges,
  getUserLists,
  addUsers,
  sizeChangeStudents,
  currentChangeStudents,
  clickButtonStudents,
  handleSelectionChanges,
  backInfo,
  saveInfos,
  addUserPeoples,
  syncStudents,
  nameChanges,
  closeDialogs
} from "./studentMethods";
import { useRouter } from "vue-router";
import { syncStudent } from "./studentApi";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '大赛名称', text: 'courseName' },
        { name: '姓名', text: 'name' },
        { name: '学号', text: 'userName' },
        { name: '手机号', text: 'phone' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      operation: ['删除'],//,'成员权限'
      spreadTable: [],
      tableHeight: 400,
      pageType: 1,
      lastState: 1000,

      userInput: undefined,
      jobNumber: undefined,

      showRoleDia: false,
      userItem: {},
      userValue: '',
      userOptions: [],


      loadingStudent: false,
      tableHeightStudent: 300,
      operationStudent: ['添加'],//,'成员权限'
      pageSizeStudent: 10,
      currentPageStudent: 1,
      totalStudent: 0,
      drafrListStudent: [
        { name: '姓名', text: 'name' },
        { name: '学号', text: 'userName' },
        { name: '手机号', text: 'phone' },
      ],
      selectList: [],
      spreadTableStudent: [],

      userSNickname: ''
    })
  
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let sizeChangeStudent = (val) => { sizeChangeStudents(val, data) }
    let currentChangeStudent = (val) => { currentChangeStudents(val, data) }
    let clickButtonStudent = (val) => { clickButtonStudents(val, data, router) }
    let handleSelectionChange = (val) => { handleSelectionChanges(val, data) }
    let addUserPeople = () => { addUserPeoples(data) }

    let backDia = () => { backInfo(data) }
    let saveInfo = () => { saveInfos(data) }
    let addUser = () => { addUsers(data) }
    let syncStudent = () => { syncStudents(data) }
    let nameChange = () => { nameChanges(data) }
    let closeDialog= () =>{ closeDialogs(data) }
    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      sizeChangeStudent,
      currentChangeStudent,
      clickButtonStudent,
      addUserPeople,
      handleSelectionChange,
      backDia,
      saveInfo,
      addUser,
      syncStudent,
      nameChange,
      closeDialog
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background:  #3B6EFB;
}
</style>