//债券 买入
export const bbuyData = {
    code:'sh127627',
    codeList: 'sh127627',
}
//债券卖出
export const bsaleData = {
    code:'sh127627',
    codeList: 'sh127627',
}

