<template>
  <div>
    <el-card class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>小组列表</div>

      <div style="margin: 10px 0">
        <el-button size="mini" type="warning" @click="showRoleDia = true">创建小组</el-button>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </el-card>

    <el-dialog title="新建小组" v-model="showRoleDia" width="30%">
      <span>
        <span>小组名称：</span>
        <el-input v-model="userValue" placeholder="请输入内容" style="width:200px" size="mini"></el-input>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="backDia">取 消</el-button>
          <el-button type="primary" @click="saveInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { listData } from "./listData";
import {
  clickButtons, currentChanges, sizeChanges, getUserLists,
  backInfo, saveInfos
} from "./listMethods";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let data = reactive(listData)
    const route = useRoute();
    let router = useRouter();
    onMounted(() => {
      data.parame = route.query
      sessionStorage.setItem('cac', JSON.stringify(data.parame))
      getUserList()
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let backDia = () => { backInfo(data) }
    let saveInfo = () => { saveInfos(data, router) }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      backDia,
      saveInfo,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
</style>
