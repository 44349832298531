import axios from '@/axios/globalaxios'

export const viewSchoolCourses = data =>{
    return axios({
        url: "/tradelabLx/financial/viewSchoolCourses",
        method: 'post',
        data:data
    })
}

export const pullUsersByCourseIdAndCompetitionId = data =>{
    return axios({
        url: "/tradelabLx/financial/pullUsersByCourseIdAndCompetitionId",
        method: 'post',
        data:data
    })
}
