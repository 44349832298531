<template>
  <div ref="unitNetValue" class="unit-net-value"></div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
var option;
const props = defineProps({
	unitNetWorth: {
		date:[],
		value:[]
	}
})
option = {
  title: {
    text: "单位净值图",
    left:'center'
  },
  xAxis: {
    type: "category",
    data: props.unitNetWorth.date,
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: props.unitNetWorth.value,
      type: "line",
    },
  ],
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
},
};
let unitNetValue = ref();
onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      let psgTimeCharts = echarts.init(unitNetValue.value);
      //传入一个配置项
      psgTimeCharts.setOption(option);
    }, 100);
  });
});
// 传递一个dom元素
</script>
<style lang="less" scope>
.unit-net-value {
  width: 100%;
  height: 100%;
  display: block;
  //   div {
  //     // width: 100% !important;
  //     // height: 100% !important;
  //     // canvas {
  //     //   width: 100% !important;
  //     //   height: 100% !important;
  //     // }
  //   }
}
</style>
