<template>
  <div>
    <!-- 外汇个人交易-交易 -->
    <el-row>
      <el-col :span="9">
        <div class="labelSpan">
          <div style="width: 80%;margin-left: 10%;">
            <el-form ref="ruleFormRef" :model="ruleForm" status-icon  :rules="rules" :hide-required-asterisk="true">
              <el-form-item label="买入币种" style="margin-top:30px;" prop="region">

                <el-select v-model="ruleForm.region" @change="moneyTypeChange('1')" size="mini" style="width: 100%;">
                  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.abbr"
                    :disabled="item.disabled" />
                </el-select>
              </el-form-item>
              <el-form-item label="卖出币种" style="margin-top:30px;" prop="region2">

                <el-select v-model="ruleForm.region2" @change="moneyTypeChange('2')" size="mini" style="width: 100%;">
                  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.abbr"
                    :disabled="item.disabled" style="width: 100%;" />
                </el-select>

              </el-form-item>


              <el-form-item label="卖出金额"  style="margin-top:30px;" class="ruleForm-item-right" prop="money">

                <el-input v-model="ruleForm.money" oninput="value=value.replace(/[^0-9.]/g,'')" size="small"
                  placeholder="请输入购买金额！" @input="moneyInputChange" />

              </el-form-item>
              <el-form-item label="买入汇率"  style="margin-top:30px;" class="ruleForm-item-right" prop="exchangeRate">

                <el-input v-model="ruleForm.exchangeRate" oninput="value=value.replace(/[^0-9.]/g,'')" size="small"
                  placeholder="请输入汇率！" />

              </el-form-item>
              <el-form-item
                :label="(changeName(data.ruleForm.region)) + (changeName(data.ruleForm.region) ? '-' : '') + '账户余额'"
                class="ruleForm-item-right"  >{{ data.freeAmount }}
              </el-form-item>
              <el-form-item
                :label="(changeName(data.ruleForm.region2)) + (changeName(data.ruleForm.region2) ? '-' : '') + '账户余额'"
                class="ruleForm-item-right" >{{ data.amounts }}
              </el-form-item>
              <el-form-item>
                
              </el-form-item>
                <el-button style="width: 100%;font-size: 14px" size="mini" type="primary" @click="addForeignExchangeOrder">模拟购汇</el-button>
                <div style="width: 100%;text-align: center; color: #75728C;">
                  <!-- <span  @click="resetForm" style="font-size: 14px;" class="reset">重填</span> -->
                  <el-button  @click="resetForm" style="font-size: 14px;width: 100%;margin-top: 10px;margin-bottom: 10px;" size="mini" class="reset">重填</el-button>
                </div>
              <!-- <div class="item-button"><el-button size="mini" type="primary" style="width: 100% " @click="addForeignExchangeOrder">购汇</el-button></div>
              <div class="item-button" style="text-align: center; color: #75728C;"><span style="width: 100% " @click="resetForm">重填</span></div> -->
            </el-form>
          </div>
          <!-- <div label-width="10px" style="padding-left: 80px; padding-top:20px">交易信息：</div> -->

        </div>
      </el-col>
      <el-col :span="14">
        <div style="margin-left:30px">
          <div class="echarts" id="main"></div>
          <div label-width="120px">行情信息：</div>
          <el-form ref="ruleFormRefss" :model="form" status-icon label-width="120px">
            <el-form-item label="买入价：">{{ form.buyingPrice }}</el-form-item>
            <el-form-item label="卖出价：">{{ form.sellingPrice }}</el-form-item>
            <el-form-item label="最低买入价：">{{ form.minimumBidPrice }}</el-form-item>
            <el-form-item label="最高卖出价：">{{ form.maximumSellingPrice }}</el-form-item>
            <!-- <el-form-item label="走势图："></el-form-item> -->
          </el-form>
          
        </div>
      </el-col>
    </el-row>
    <!-- <div style="width: 50%; float: left">

    </div> -->

  </div>
</template>

<script>
import * as echarts from 'echarts';
import { reactive, toRefs, onMounted, ref, defineComponent } from 'vue';
import { getTransactionCurrency, addForeignExchangeOrders, accountAmount, resetForms, transactionCurrencyss } from './ForeignExchangeTransactionsMethods'
export default {
  props: {
    abbrList: {
      type: Object,
      default: []
    },
    showAbbr: {
      type: Boolean,
    }
  },
  setup(props) {

    onMounted(() => {
      data.showAbbr = props.showAbbr
      if (props.showAbbr) {
        transactionCurrencyss(data)
      }
      getTransactionCurrency(data)

      // accountAmount(data)
      getEcharts()
    })
    const ruleFormRef = ref(null)
    let data = reactive({
      optronAllMoney: [],
      options: [],
      options2: [],
      showAbbr: false,
      ruleForm: {
        region: '',// 买方币种
        region2: '',// 卖方币种
        radio1: null,//交易方向
        money: '',//购买金额
        form: '',//行情信息
        exchangeRate: '1',//购买汇率
      },
      form: { buyingPrice: 1.0735, sellingPrice: 1.0752, minimumBidPrice: 1.0716, maximumSellingPrice: 1.076 },
      moneyTypeId1: '',
      moneyTypeId2: '',
      amounts: '0.00',// 可用金额
      freeAmount: '0.00',//免费金额
     
    })
    let addForeignExchangeOrder = () => { addForeignExchangeOrders(data, ruleFormRef) }
    // 重置按钮
    let resetForm = () => { resetForms(data) }
    let moneyTypeChange = (type) => {
      data.ruleForm.radio1 = null
      let moneyTypeId = ''
      // accountAmount(data)
      if (type == '1') {
        //禁止选中相同的种币
        data.options2.forEach(item => {
          if (item.abbr == data.ruleForm.region) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        });
        moneyTypeId = data.optronAllMoney.find(function (i) {
          return i.abbr === data.ruleForm.region
        });
        data.moneyTypeId1 = moneyTypeId.id
      } else if (type == '2') {
        //禁止选中相同的种币
        data.options.forEach(every => {
          if (every.abbr == data.ruleForm.region2) {
            every.disabled = true
          } else {
            every.disabled = false
          }
        });
        moneyTypeId = data.optronAllMoney.find(function (i) {
          return i.abbr === data.ruleForm.region2
        });
        data.moneyTypeId2 = moneyTypeId.id
      }
      accountAmount(data)
    }


    // 购买金额的判断
    let moneyInputChange = (val) => {
      if (data.ruleForm.radio1 == data.ruleForm.region2) {
        if (Number(data.freeAmount) < Number(val)) {
          data.ruleForm.money = data.freeAmount
        }
      } else {
        if (Number(data.amounts) < Number(val)) {
          data.ruleForm.money = data.amounts
        }
      }
    }
    const rules = reactive({
      region: { required: true, message: '请选择买入币种！', trigger: 'blur', },
      region2: { required: true, message: '请选择卖出币种！', trigger: 'blur', },
      // radio1: { required: true, message: '请选择交易方向！', trigger: 'blur', },
      money: { required: true, message: '请输入卖出金额！', trigger: 'blur', },
      exchangeRate:{required: true,message: '交易汇率不能为空',trigger: 'blur',}
    })
    let changeName = (name) => {
      if (name == 'undefined' || name == 'null' || name == '' || !name) { return '' }
      if (name == 'CNY') {
        return '人民币'
      } else if (name == 'USD') {
        return '美元'
      } else if (name == 'HKD') {
        return '港元'
      }
      else if (name == 'EUR') {
        return '欧元'
      }
      else if (name == 'GBP') {
        return '英镑'
      }
      else if (name == 'JPY') {
        return '日元'
      }
      else if (name == 'AUD') {
        return '澳大利亚元'
      }
      else if (name == 'CAD') {
        return '加拿大元'
      }
      else if (name == 'CHF') {
        return '瑞士法郎'
      }
      else if (name == 'SUFEY') {
        return '财大点券'
      }
    }

    // 获取Echarts图表
    let getEcharts = () => {
      // Echarts 图
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: '今日行情',
          subtext: '行情数据'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // prettier-ignore
          data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} '
          },
          axisPointer: {
            snap: true
          }
        },
        series: [
          {
            name: '价格：',
            type: 'line',
            smooth: true,
            // prettier-ignore
            data: [10, 28, 25, 26, 27, 30, 55, 50, 40, 39, 38, 39, 40, 50, 60, 75, 80, 70, 60, 40],
            markArea: {
              itemStyle: {
                color: 'rgba(255, 173, 177, 0.4)'
              },
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
    return {
      ...toRefs(data),
      data,
      rules,
      ruleFormRef,
      addForeignExchangeOrder,
      moneyTypeChange,
      changeName,
      getEcharts,
      resetForm,
      moneyInputChange,
    }
  }
};
</script >
<style lang="less" scoped>
// .ruleForm {
//   .ruleForm-item-right {
//     width: 80%;
//   }
// }

.el-form-item {
  margin-bottom: 10px;
}

// .el-form-item__label {
//   margin-left: 250px;
//   text-align: justify;
// }

.item-button {
  width: 100%;
}

// .el-form-item__content {
//   width: 500px;
// }

.echarts {
  width: 700px;
  height: 300px;
}

.labelSpan {
  padding-top: 10px;
  min-width: 280px;
  background: #F4F4FA;
  min-height: 500px;
  text-align: center;
  margin: 10px 10px;
}
.reset {
    font-size: 14px;
    background:#F4F4FA ;
    cursor:pointer;
  }
</style>