<template>
    <div>
      <GroupManage v-if="route.path!=='/classManage'&&route.path!=='/index'" />
      <el-card class="box-cards" v-else>
      <template #header>
        <div class="card-header">
          <span>我的大赛</span>
        </div>
      </template>
      <div class="courseContainer">
        <div style="margin-bottom: 20px" v-if="type===0">
          <span>大赛状态：</span>
          <el-select v-model="classStart" placeholder="请选择" size="mini" @change="refreshData">
            <el-option :key="0" label="未开始" :value="0"></el-option>
            <el-option :key="1" label="进行中" :value="1"></el-option>
            <el-option :key="2" label="已结束" :value="2"></el-option>
          </el-select>
          <el-button type="warning" size="mini" style="margin-left: 10px" @click="createClass">创建大赛</el-button>
  
          <el-input size="mini" style="float: right;width: 150px"
              placeholder="请输入关键字" suffix-icon="el-icon-search"
              v-model="classInput" @input="refreshData">
          </el-input>
        </div>
        <el-row :gutter="20">
          <el-empty v-if="itemList.length===0" description="暂无课程"  style="width: 100%;"></el-empty>
          <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6"  v-for="(item,index) in itemList" :key="index">
            <class-card :classStart="classStart" :item="item" @setcourse="setcourse" @refreshData="refreshData" :styleType="1"></class-card>
          </el-col>
        </el-row>
      </div>
      <gradeform :visbleBoolean="visbleBoolean" @modify="modify" @closeDio="closeDio"
                 :modifyData="modeItem" :setType="setType" />
  
    </el-card>
    </div>
  
  
  </template>
  
  <script>
  import classCard from "./components/ClassCard/newIndex.vue";
  import {classData} from "./classData";
  import {onMounted, reactive, toRefs} from "vue";
  import gradeform from "@/components/Gradeform/index.vue"
  import {getClassLists} from "./classMethods";
  import  GroupManage from "../index.vue"
  import { useRoute } from "vue-router";
   
  
  export default {
    name: "index",
    components:{classCard,gradeform,GroupManage},
    props:{
      type:{
        type: Number,
        default: 0
      }},
    setup(props){
      let data = reactive(classData)
      let start = reactive({setType:0})
      let route= useRoute();
      onMounted(() => {
        getClassList()
      })
      const setcourse = (item) => {
        start.setType = 0
        data.modeItem = item
        data.visbleBoolean = true;
      }
      const modify = (formdata) => {
        data.visbleBoolean = false;
        getClassList()
      }
      let closeDio = (formd) =>{
        data.visbleBoolean = false;
        getClassList()
      }
      let getClassList = () =>{
        getClassLists(data,props.type)
      }
      let createClass = () =>{  start.setType = 1,data.visbleBoolean = true;}
      let refreshData = ()  =>{getClassList()}
  
      return{
        closeDio,
        setcourse,
        modify,
        createClass,
        refreshData,
        ...toRefs(data),
        ...toRefs(start),
        route
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>