import axios from '@/axios/globalaxios'

// 查询资金
export const findFunds = data =>{
    return axios({
        url: "/tradelabLx/transaction/findFunds",
        method: 'post',
        data:data
    })
}
// 下单
export const placeAnOrder = data =>{
    return axios({
        url: "/tradelabLx/transaction/placeAnOrderJava",
        method: 'post',
        data:data
    })
}

// 导出
export const exportSettlementInfo = data =>{
    return axios({
        url: "/tradelabLx/export/exportSettlementInfo",
        method: 'post',
        data:data,
        responseType: 'blob',
    })
}
// 导出文件数量
export const getSize = data =>{
    return axios({
        url: "/tradelabLx/export/getSize",
        method: 'post',
        data:data,
    })
}
