<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="trade" size="mini" :hide-required-asterisk="true">
    <el-form-item label="">
      <el-select v-show="!megaMode" v-model="ruleForm.name" placeholder="输入股票名称/代码" @change="codeChange"
        style="width:100%;" filterable>
        <el-option v-for="(item, index) in codeList" :value="item.code" :key="item.code" :label="item.code">{{ item.code
        }}
          {{ item.name }}</el-option>
      </el-select>
      <CodeSearch v-show="megaMode" :disabled="loadingCharts" :showDefault="false" :megaMode="megaMode"
        v-model="ruleForm.name" :multiple="false" :codeType="codeType" :codeList="codeSList"
        @codeSelectChane="codeChange" />
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="应计利息" v-if="$route.params.id === '2'">
      <el-input v-model="interest" disabled class="trade-item-right" style="width:100%"></el-input>
    </el-form-item>

    <el-form-item label="开平" prop="resource" v-if="$route.params.id === '3'">
      <el-radio-group v-model="ruleForm.resource">
        <el-radio :label="0">开仓</el-radio>
        <el-radio :label="1">平仓</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="保证金" v-if="$route.params.id === '3'">
      <el-input v-model="ensureMon" disabled class="trade-item-right"></el-input>
    </el-form-item>
    <el-form-item label="风险度" v-if="$route.params.id === '3'">
      <el-input v-model="risk" disabled class="trade-item-right"></el-input>
    </el-form-item>
    <el-form-item label="交易价格" prop="price">
      <!--      <el-input-number v-model="ruleForm.price" class="trade-item-right"></el-input-number>-->
      <el-input-number :min="0" v-model="ruleForm.price" @change="capital" controls-position="right" style="width:100%"
        class="trade-item-right"></el-input-number>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="交易数量" prop="number">
      <el-input-number style="width:100%" v-model="ruleForm.number" @blur="blurNumber" controls-position="right"
        class="trade-item-right"></el-input-number>

      <!-- {{ $route.params.id === '3' ? '手' : $route.params.id === '2' ? '张' : '股' }} -->
      <br>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item>
      <el-button @click="allClick" size="mini">全部</el-button>
      <el-button size="mini" class="bin-button" @click="radio = 0.5" :label="0.5">1/2</el-button>
      <el-button size="mini" class="bin-button" @click="radio = 0.33" :label="0.33">1/3</el-button>
      <el-button size="mini" class="bin-button" @click="radio = 0.25" :label="0.25">1/4</el-button>
      <el-button size="mini" class="bin-button" @click="radio = 0.2" :label="0.2">1/5</el-button>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="报价方式" prop="wayTrade">
      <el-select v-model="ruleForm.wayTrade" placeholder="请选择" disabled style="width:100%">
        <el-option label="市价" value="shanghai"></el-option>
        <el-option label="限价" value="beijing"></el-option>
      </el-select>
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item label="可用资金" prop="usable">
      <div style="width:100%;text-align:right" :disabled="true" :controls="false" controls-position="right"
        class="trade-item-right">{{
          ruleForm.usable }}</div>
      <!--      <el-input-number v-model="ruleForm.usable" class="trade-item-right"></el-input-number>-->
    </el-form-item>
    <el-divider></el-divider>
    <el-form-item v-if="showStudent">
      <div style="width: 100%;margin:10px 0px;">
        <el-button style="width: 45%;float: left;" :type="purchase ? 'info' : 'primary'" size="mini" :disabled="purchase"
          :loading="tradeLoading" @click="submitForm('ruleForm', 0)">买入下单</el-button>
        <el-button style="width: 45%;float: right;" :type="sellOut ? 'info' : 'warning'" size="mini" :disabled="sellOut"
          :loading="sellOutLoading" @click="submitForm('ruleForm', 1)">卖出下单</el-button>
      </div>
      <el-button style="width: 100%;text-align:center;display: inline-block;margin-top:10px;" size="small"
        @click="resetForm('ruleForm')">重填</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { findFunds, placeAnOrder } from "./detailsApi";
import { findTargetamountByAcid } from "../../../stock-manage/stockApi";
import { cloneDeep } from "lodash";
import { checkAuditTime, getCharts } from "../../tradeMethods";
import { teadeData } from "../../teadeData";
import { ElMessage } from "element-plus";
import CodeSearch from '@/components/CodeSearch';
// import {getCodLists} from '../../upreport-manage/UprePort-Edit/reportMethods'
import { findStockPoolByAcid } from "../../../stock-manage/stockApi";
import axios from "@/axios/globalaxios";
import { findPortfolioManagementDetailedByAcidAndType } from "../../tradeApi";

export default {
  props: {
    price: {
      type: String,
      default: ''
    },
    codeList: {
      type: Array,
      default: []
    },
    megaMode: {
      type: Boolean,
      default: false,
    },
    loadingCharts: {
      type: Boolean,
      default: false,
    },
    codeType: {
      type: String,
      default: '1',
    },
    tradeRadio: {
      type: Number,
      default: 0
    }
  },
  components: {
    CodeSearch,
  },
  data() {
    return {
      showStudent: true,
      title: {
        '1': {
          price: '买入价格',
          abuser: '可用资金',
          number: '买入数量',
          button: '买入下单',
        },
        '2': {
          price: '卖出价格',
          number: '卖出数量',
          button: '卖出下单',
        }
      },
      ruleForm: {
        name: '',
        wayTrade: 'shanghai',
        resource: 0,
        price: 0,
        usable: 0,
        number: 0,
      },
      fid: '',
      allNumber: 0,
      radio: '',
      rules: {
        name: [
          { required: true, message: '请输入证券代码', trigger: 'blur' },
        ],
        wayTrade: [
          { required: true, message: '请选择报价方式', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择开平', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入买入价格', trigger: ['blur', 'change'] },
        ],
        usable: [
          { required: true, message: '请输入可用资金', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '请输入最大可买', trigger: 'blur' },
        ],
      },
      // 大赛
      codeSList: [],
      exchange: 0,
      tradeLoading: false,
      getNums: 100,
      interest: '',
      ensureMon: '',
      risk: '',
      sellOutLoading: false,
      sellOut: false,
      purchase: false
    };
  },
  mounted() {
    this.showStudent = JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师' ? false : true
    this.getPath()
    this.getNum()
    this.$nextTick(() => {
      if (this.codeList.length !== 0 && !this.megaMode && !this.ruleForm.name) {
        this.getData(this.codeList)

      }


    })

  },
  watch: {
    price(val) {
      this.ruleForm.price = val !== '' ? parseFloat(val) : 0;
    },
    codeList(val) {
      if (val.length !== 0 && !this.megaMode) {
        // this.ruleForm.name = val[0].code
        // let codeName = this.codeType === '0' ? 'all' : this.codeType === '1' ? 'shares' : this.codeType === '2' ? 'bond' : 'forward'
        // let codeList = JSON.parse(localStorage.getItem(codeName))
        // let newCodeList = codeList.filter(v => { if (v.fgsid === val[0].code) return v.fid })[0]
        // this.fid = newCodeList.fid.trim()
        // // this.fid = val[0].fid
        // this.getRiskAndMore()
        // if (this.tradeRadio !== 1) {
        //   this.getNumCode(this.ruleForm.name)
        // } else {
        //   for (let i in this.codeList) {
        //     if (this.codeList[i].code === this.ruleForm.name) {
        //       this.allNumber = JSON.parse(JSON.stringify(this.codeList[i].fid))
        //     }
        //   }
        //   bthis.ruleForm.numer = this.allNumber - this.allNumber % this.getNums
        // }
        this.getData(val)
      }
      if (val.length === 0) {
        this.ruleForm.name = ''
        this.codeSList = []
      }
    },
    codeType(val) {
      if (this.megaMode) {
        this.ruleForm.name = ''
        this.codeSList = []
      }
    },
    'radio': function (val) {
      if (this.megaMode) {
        this.calNumber()
      } else {
        if (val == 0.33) {
          this.ruleForm.number = (this.allNumber / 3) - (this.allNumber / 3) % this.getNums
        } else {
          this.ruleForm.number = (this.allNumber * val) - (this.allNumber * val) % this.getNums
        }

      }
    },
    '$route': function (val) {
      if (val.path == '/login') {
        return
      }
      this.getPath()

    },
  },
  methods: {
    /**
     * 查询保证金风险度与应计利息
     * ensureMon  risk  interest
     */
    capital(val) {
      this.$emit("clickPrice", val)

    },
    getData(val) {
      this.ruleForm.name = val[0].code
      let codeName = this.codeType === '0' ? 'all' : this.codeType === '1' ? 'shares' : this.codeType === '2' ? 'bond' : 'forward'
      let codeList = JSON.parse(localStorage.getItem(codeName))
      let newCodeList = codeList.filter(v => { if (v.fgsid === val[0].code) return v.fid })[0]
      this.fid = newCodeList.fid
      // this.fid = val[0].fid
      this.getRiskAndMore()
      if (this.tradeRadio !== 1) {
        this.getNumCode(this.ruleForm.name)
      } else {
        for (let i in this.codeList) {
          if (this.codeList[i].code === this.ruleForm.name) {
            this.allNumber = JSON.parse(JSON.stringify(this.codeList[i].fid))
          }
        }
        this.ruleForm.number = this.allNumber - this.allNumber % this.getNums
      }
      this.codeChange(val[0].code);
    },
    getRiskAndMore() {
      if (parseInt(this.$route.params.id) === 1) return
      let parame = {}
      if (parseInt(this.$route.params.id) === 2) {
        parame = {
          acid: JSON.parse(sessionStorage.getItem('acid')),
          type: parseInt(this.$route.params.id),
          fid: this.fid
        }
      }
      if (parseInt(this.$route.params.id) === 3) {
        parame = {
          type: 3,
          acid: JSON.parse(sessionStorage.getItem('acid')),
          fid: this.fid
        }
      }
      findPortfolioManagementDetailedByAcidAndType(parame).then(res => {
        if (res.data.code === '200') {
          let resNew = res.data.data
          this.ensureMon = resNew.portfolioManagementDetailed.margin || 0
          this.risk = resNew.portfolioManagementDetailed.riskratio || 0
          this.interest = resNew.portfolioManagementDetailed.accruuedInterest || 0
        }
      })
    },
    getNum() {
      this.getNums = this.$route.params.id === '1' ? 100 : this.$route.params.id === '2' ? 10 : this.$route.params.id === '3' ? 1 : 100
    },
    getPath() {
      // this.megaMode ? this.getCodLists() : this.getMoneys()
      this.getMoneys()
    },
    blurNumber(val) {
      let num = parseInt(val.target.value)
      this.dealCodeSearch(num)
    },
    dealCodeSearch(nums) {
      let num = 100
      axios.post('/tradelabLx/transaction/findStopwatchByFtype', { type: parseInt(this.$route.params.id) }).then(res => {
        if (res.data.code === '200') {
          let codeList = res.data.data.stopwatches
          for (let j in codeList) {
            if (this.ruleForm.name === codeList[j].fgsid) {
              num = codeList[j].vt
            }
          }
          this.ruleForm.number = nums - nums % num
        }
      })
    },
    codeChange(val) {
      this.ruleForm.name = val
      if (!this.megaMode) {
        let codeName = this.codeType === '0' ? 'all' : this.codeType === '1' ? 'shares' : this.codeType === '2' ? 'bond' : 'forward'
        let codeList = JSON.parse(localStorage.getItem(codeName))
        let newCodeList = codeList.filter(v => { if (v.fgsid === val) return v.fid })[0]
        this.fid = newCodeList.fid
        this.exchange = newCodeList.fjys
        this.$emit('codeChange', val)
        if (this.tradeRadio !== 1) {
          this.getNumCode(val)
        } else {
          for (let i in this.codeList) {
            if (this.codeList[i].code === val) {
              this.allNumber = JSON.parse(JSON.stringify(this.codeList[i].fid))
            }
          }
          this.ruleForm.number = this.allNumber - this.allNumber % this.getNums
        }
      }
      if (this.megaMode) {
        let codeName = this.codeType === '0' ? 'all' : this.codeType === '1' ? 'shares' : this.codeType === '2' ? 'bond' : 'forward'
        let codeList = JSON.parse(localStorage.getItem(codeName))
        let newCodeList = codeList.filter(v => { if (v.fgsid === val) return v.fid })[0]
        this.fid = newCodeList.fid
        this.exchange = newCodeList.fjys
        this.$emit('changeTitle', codeList.filter(v => { if (v.fgsid == val) return v.fid })[0].fname)
        this.$emit('codeChange', val)
        this.getNumCode(val)
        this.$emit('getChart', this.ruleForm.name)
        this.$nextTick(() => {
          this.getMoneys()
        })
      }
      this.getRiskAndMore()
    },
    getNumCode(val) {
      let parame = {
        // fmid:1145,
        acid: JSON.parse(sessionStorage.getItem('acid')),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        exchange: '0',
        internalsid: val,
        lastState: 1,
        pageSize: 10,
        page: 1,
      }
      findTargetamountByAcid(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          this.allNumber = newRes.tfundTargetamountPageInfo.list[0] ? newRes.tfundTargetamountPageInfo.list[0].targetamount : 0
          this.ruleForm.number = this.allNumber - this.allNumber % this.getNums
        }
      })
    },
    getMoneys() {
      let num = this.$route.params.id === (this.megaMode ? '3' : '3') ? 10 : 0
      findFunds({
        acid: JSON.parse(sessionStorage.getItem('acid')),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        exchange: num
      }).then(res => {
        if (res.data.code === '200') {
          this.ruleForm.usable = res.data.data.funds[0] ? res.data.data.funds[0].freeAmount : 0
        }
      })
    },
    submitForm(formName, num) {
      if (this.$route.params.id === '3' && this.exchange === 10) {
        let timeStart = checkAuditTime('01:00', '21:00')
        if (timeStart === 2) return ElMessage.warning('夜盘不可交易时间内禁止下单（21：00-01：00）')
      }
      let type = this.$route.params.id === '3' ? this.ruleForm.resource : num
      let totalPrice = this.interest + this.ruleForm.price * this.ruleForm.number
      // let typeBuy = this.type === 1 ? 0: 1
      if (this.ruleForm.price === 0) return ElMessage.warning(type === 0 ? '买入' + '价格不能为0' : '卖出' + '价格不能为0')
      if (this.ruleForm.number === 0) return ElMessage.warning(type === 0 ? '买入' + '数量不能为0' : '卖出' + '数量不能为0')
      if (totalPrice > this.ruleForm.usable && type === 0) {
        ElMessage.warning('买入总价不能大于可用资金')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //添加校验，点击买入时，卖出按钮置灰，点击卖出时，买入按钮置灰
          if (num === 0) {
            this.tradeLoading = true;
            this.sellOut = true;
          } else {
            if (!this.megaMode && this.allNumber < parseInt(this.ruleForm.number)) {
              ElMessage.warning('卖出数量不能超过持仓量')
              return this.ruleForm.number = this.allNumber - this.allNumber % this.getNums
            }
            this.sellOutLoading = true;
            this.purchase = true;
          }
          //     sellOut:false,
          // purchase:false
          /*
          * tposition 开仓  0 ，平仓方向 1
          * direction 买 0 卖 1 方向
          * 股票,债券 买  tp 0 dir 0
          * 股，债 卖  tp 1 dir 1
          * 期货 买开 tp  0 dir 0
          *    买平  tp 1  dir 0
          *    卖开   tp 0 dir 1
           *    卖平 tp 1 dir 1
          * */
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            // accountId: JSON.parse(sessionStorage.getItem('classInfo')).acid,
            tposition: type,
            insCode: this.fid,
            direction: num,
            orderPrice: this.ruleForm.price,
            orderVol: this.ruleForm.number,
            code: this.ruleForm.name
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              ElMessage.success(res.data.msg)
              this.ruleForm.number = '';
              this.ruleForm.price = '';
              this.$emit("clickPrice", '');
              this.resetForm('ruleForm')
              this.getMoneys();
            } else {
              ElMessage.error(res.data.msg)
            }
          }).finally(() => {
            setTimeout(() => {
              this.tradeLoading = false;
              this.sellOut = false;
              this.sellOutLoading = false;
              this.purchase = false;
            }, 300)
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      if (formName == 'ruleForm') {
        this.getPath()
      }
    },
    allClick() {
      this.radio = '';
      this.megaMode ? this.calNumber() : this.getNumCode(this.ruleForm.name)
    },
    // 基金
    getCodLists() {
      let parame = {
        acid: JSON.parse(sessionStorage.getItem('acid')),
        pageSize: 1000,
        page: 1,
      }
      findStockPoolByAcid(parame).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          let arr = []
          let temp = []
          arr = newRes.returnStockPoolInformations.list
          for (let i in arr) {
            temp.push(arr[i].marketSID)
          }
          this.codeSList = cloneDeep(temp)
        }
      })
    },
    //计算交易数量
    calNumber() {
      const price = this.ruleForm.price
      if (price > 0) {
        const radio = this.radio || 1
        if (radio == 0.33) {
          let temp = Math.floor((this.ruleForm.usable / 3) / this.ruleForm.price)
          this.ruleForm.number = temp - temp % this.getNums
        } else {
          let temp = Math.floor((this.ruleForm.usable * radio) / this.ruleForm.price)
          this.ruleForm.number = temp - temp % this.getNums
        }
      } else {
        ElMessage.warning('请输入交易价格！')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.trade {
  padding: 5%;
  padding-bottom: 0px;
  width: 90%;
  min-width: 300px;

  .trade-item-right {
    width: 100%;
  }
}

.bin-button {
  background: none;
  margin-left: 5px;
}

:deep(.el-divider--horizontal) {
  margin: 0px;
  margin-bottom: 4px;
}</style>
