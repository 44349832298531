
// 获取现有成员
import {delGroupUser, findGroupRole, findUserLogByGroupId, updGroupRole} from "./groupApi";
import {ElMessage, ElMessageBox} from "element-plus";
import { userInfo } from "../methods";
import {getUserByCompetitionGroup} from "@/views/megagame/apis";
export const getUserLists = (data,page)=>{
    data.loading = true
    const id = JSON.parse(sessionStorage.getItem('mega')).id;
    if(page!=1){
        data.peopleData = [];
        data.total = 0;
        data.peopleJobLogData = [];
        data.total2 = 0;
        data.peopleOptions = [];
        data.operation=[];
        data.roleName='';
    }

    let reqData = {
        competitionId: id,
        roleName:userInfo().roleName,
        userId:userInfo().userId,
        pageIndex: data.currentPage,
        pageSize: data.pageSize,
    };

    getUserByCompetitionGroup(reqData)
        .then(res => {
            const resData = res.data;
            if (resData.status === 0 || resData.code == 200) {
                if( resData.data.sysUserList==undefined){
                    data.loading = false;
                    return
                }
                data.peopleData = resData.data.sysUserList.list
                data.total = resData.data.sysUserList.total
                data.peopleOptions = data.peopleData.map(v=>{
                    return {userId: v.userId,name:v.userName}
                })

                setTimeout(()=>{
                    data.loading = false
                },300)


                if(resData.data.memberRoleName=='基金经理'){
                    data.roleName='基金经理';
                    data.operation=['删除'];
                }

                let logParam = {
                    groupId: resData.data.sysUserList.list[0].competitionGroupId,
                    type:'0',
                    pageIndex: data.currentPage2,
                    pageSize: data.pageSize2,
                }
                findUserLogByGroupId(logParam).then(res=>{
                    if (res.data.code === '200'){
                        let newRes = res.data.data
                        data.peopleJobLogData = newRes.pageInfo.list

                        data.peopleJobLogData.forEach(group => {
                            group.userName = group.sysUser.name;
                        })

                        data.total2 = newRes.pageInfo.total
                        setTimeout(()=>{
                            data.loading = false
                        },300)
                    }
                })
        }
    })
}


// 切换条数
export const sizeChanges = (val, data) =>{
    data.pageSize = val
}

// 切换条数
export const sizeChanges2 = (val, data) =>{
    data.pageSize2 = val
}

// 切换页数
export const currentChanges = (val,data) =>{
    data.currentPage = val
    getUserLists(data, '1');
}

// 切换页数
export const currentChanges2 = (val,data) =>{
    data.currentPage2 = val
    getUserLists(data,'1');
}

// 点击删除功能
export const clickButtons = (val,data) =>{
    if (val.type === '删除'){
        ElMessageBox.confirm('此操作将永久删除该成员, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
         if (data.total == 1) {
            ElMessage.error("当前小组人数不足，请添加成员后删除");
            return
          } 
          delUserPeople(val.item,data)
        })
    }
}

// 删除 用户
export const delUserPeople = (val,data) =>{

    const id = JSON.parse(sessionStorage.getItem('mega')).id;

    const reqData = {
        competitionId: id,
        competitionGroupId: val.competitionGroupId,
        userId: val.userId,
        userName: val.userName,
    }

    delGroupUser(reqData).then(res=>{
        if (res.data.code === '200'){
            ElMessage.success(res.data.msg)
            getUserLists(data)
        }else {
            ElMessage.error(res.data.msg)
        }
    })
}
// 职位变更
export const peopleChanges = (val,data) =>{}

export const getUserTypes = (data) =>{
    findGroupRole().then(res=>{
        if (res.data.code === '200'){
            data.nowJobOptions = res.data.data.memberRoles
        }
    })
}
// 职务变更按钮
export const alterJobs = (data) =>{
    let parame = {
        userId: data.peopleValue,
        userName:userInfo().userName,
        competitionGroupId:data.peopleData[0].competitionGroupId,
        memberRoleId:data.nowJob

    }
    updGroupRole(parame).then(res=>{
        if (res.data.code === '200'){
            ElMessage.success(res.data.msg)
            data.peopleValue = ''
            data.nowJob = ''
            getUserLists(data)
        }else {
            ElMessage.error(res.data.msg)
        }
    })
}