<template>
  <!-- 期货交易 -->
  <el-form ref="storage" :model="formline" label-width="80px" :rules="rules">
    <el-form-item label="开平">
      <el-radio-group v-model="formline.resource">
        <el-radio :label="0">开仓</el-radio>
        <el-radio :label="1">平仓</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="买卖">
      <el-radio-group v-model="formline.buysale">
        <el-radio :label="0">买入</el-radio>
        <el-radio :label="1">卖出</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="类型">
      <el-radio-group v-model="formline.type">
        <el-radio label="限价"></el-radio>
        <el-radio label="市价"></el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="价格" prop="price">
      <el-input v-model="formline.price" placeholder="请输入" style="width:200px;" size="mini"></el-input>
    </el-form-item>
    <el-form-item label="数量" prop="num">
      <el-input v-model="formline.num" @blur="blurNumber" placeholder="请输入" style="width:200px;" size="mini"></el-input>
    </el-form-item>
    <el-form-item label="可用金额" prop="freeAmount">
      <el-input v-model="formline.freeAmount" disabled="true"  style="width:200px;" size="mini"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit" size="mini">确定</el-button>
      <el-button size="mini" @click="resetFormdata">重填</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive, toRefs, ref, watch,onMounted } from "vue";
import { codeDeal, codeDealJys, dealCodeSearchNum } from "../commonMethods.js";
import { placeAnOrder } from "../../../course-manage/trade-manage/components/TradeDetails/detailsApi";
import { ElMessage } from "element-plus";
import { checkAuditTime } from "../../../course-manage/trade-manage/tradeMethods";
import { findFunds  } from "@/views/course-manage/declare-manage/components/tradeDetails/detailsApi.js"
export default {
  name: "storageForm",
  components: {},
  props: {
    code: {
      type: String
    },
    price: {
      type: String
    }
  },
  setup(props, ctx) {
    const formline = reactive({
      resource: 0,
      buysale: 0,
      type: '限价',
      price: '',
      num: '',
      freeAmount: '',
    })
    onMounted(()=>{
      getMoneys()
    })
    const rules = reactive({
      price: [{ required: true, message: '请输入交易价格', trigger:["blur","change"] }],
      num: [{ required: true, message: '请输入交易数量', trigger: 'blur' }],

    })
    const storage = ref()
    watch(() => [props.price], ([newPrice]) => {
      formline.price = parseFloat(newPrice)
      if (newPrice === 0) formline.price = ''
    })
    const onSubmit = async () => {
      let code = props.code.indexOf('nf_') !== -1 ? props.code.slice(3) : props.code
      if (codeDealJys(code, '3') === 10) {
        let timeStart = checkAuditTime('01:00', '21:00')
        if (timeStart === 2) return ElMessage.warning('夜盘不可交易时间内禁止下单（21：00-01：00）')
      }
      storage.value.validate((valid) => {
        if (valid) {
          /*
         * tposition 开仓  0 ，平仓方向 1
         * direction 买 0 卖 1 方向
         * 股票,债券 买  tp 0 dir 0
         * 股，债 卖  tp 1 dir 1
         * 期货 买开 tp  0 dir 0
         *    买平  tp 1  dir 0
         *    卖开   tp 0 dir 1
          *    卖平 tp 1 dir 1
         * */
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            tposition: formline.resource,
            insCode: codeDeal(code, '3'),
            direction: formline.buysale,
            orderPrice: parseFloat(formline.price),
            orderVol: parseFloat(formline.num),
            code:props.code
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              ElMessage.success(res.data.msg)
              resetFormdata()
            } else {
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    }
    const resetFormdata = () => {
      storage.value.resetFields()
      ctx.emit('editPrice', 0)
      formline.num = ''
    }


    let blurNumber = (val) => {
      let num = parseInt(val.target.value)
      let minNum = dealCodeSearchNum(props.code.slice(3), '3')
      formline.num = num - num % minNum
      if (isNaN(formline.num)) formline.num = ''
    }
    let getMoneys = ()=>{
        // let num = this.$route.params.id === (this.megaMode ? '3' : '3') ? 10 : 0
      findFunds({
        acid: JSON.parse(sessionStorage.getItem('acid')),
        exchange: 10
      }).then(res => {
        if (res.data.code === '200') {
          let data = res.data.data
          formline.freeAmount = data.funds[0].freeAmount || 0
        }
      })
      }
    return {
      formline,
      storage,
      rules,
      blurNumber,
      onSubmit,
      resetFormdata,
      getMoneys
    };
  },
};
</script>

<style lang="less" scoped>

</style>
