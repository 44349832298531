/*
 * 判断当前是哪个tab分类
 * @param {String} type
 * @param {Object} Data
 * @return {Object}
 */
export const JudgmentTpye = (type,Data) => {
    let result;
      if(type === '1') {
        result = Data.stock
      }else if (type === '2') {
        result = Data.futures
      }else{
        result = Data.bonds
      }
      return result
  }

  /*
   * 判断当前是哪个tab分类和哪一个通用组件
   * @param {String} type
   * @param {String} ComponentName 复用组件区分名称
   * @param {Object} Data
   */

  export const JudgmentComponents = (type,ComponentName,Data) => {
    let tab = type
    let Alias = ComponentName
    let result;
    if(ComponentName === 'TransactionDay' && type === '1') {
      result = Data['stock'].TransactionDayColums
    }else if(ComponentName === 'DelegsignmentDay' && type === '1') {
      result = Data['stock'].DelegsignmentDayColums
    }else if(ComponentName === 'HistoryTransaction' && type === '1') {
      result = Data['stock'].HistoryTransactionColums
    }else if(ComponentName === 'capitalSharesTion' && type === '1') {
      result = Data['stock'].capitalShares
    }else if(ComponentName === 'TransactionDay' && type === '2') {
      result = Data['futures'].TransactionDayColums
    }else if(ComponentName === 'DelegsignmentDay' && type === '2') {
      result = Data['futures'].DelegsignmentDayColums
    }else if(ComponentName === 'HistoryTransaction' && type === '2') {
      result = Data['futures'].HistoryTransactionColums
    }else if(ComponentName === 'capitalSharesTion' && type === '2') {
      result = Data['futures'].capitalShares
    }else if(ComponentName === 'TransactionDay' && type === '3') {
      result = Data['bonds'].TransactionDayColums
    }else if(ComponentName === 'DelegsignmentDay' && type === '3') {
      result = Data['bonds'].DelegsignmentDayColums
    }else if(ComponentName === 'HistoryTransaction' && type === '3') {
      result = Data['bonds'].HistoryTransactionColums
    }else if(ComponentName === 'capitalSharesTion' && type === '3') {
      result = Data['bonds'].capitalShares
    }

    return {
      tab,
      Alias,
      result
    }
  }

  /*
   *
   * @param {String} type 当前所在类别 股票 期货 债券 xxx
   * @param {String} ComponentName 复用组件区分名称
   * @param {Object} Data
   */

  export const JudgmentHistoryCapital = (type,ComponentName,Data) => {
     let result = []
      if(type === '1' && ComponentName === 'History') {
        result = Data['stock'].historyColums
      }else if(type === '1' && ComponentName === 'Capital'){
        result = Data['stock'].capitalColums
      }else if(type === '2' && ComponentName === 'History') {
        result = Data['futures'].historyColums
      }else if (type === '2' && ComponentName === 'Capital') {
        result = Data['futures'].capitalColums
      }else if(type === '3' && ComponentName === 'History') {
        result = Data['bonds'].historyColums
      }else if(type === '3' && ComponentName === 'Capital') {
        result = Data['bonds'].capitalColums
      }
      return {result}
  }
