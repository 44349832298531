<template>
  <div>
    <div style="margin: -40px 15px 20px">
      <el-tabs v-model="activeName" @tab-click="tagChage" >
        <el-tab-pane label="资产总额" name="1"></el-tab-pane>
        <el-tab-pane label="交易金额" name="2"></el-tab-pane>
        <el-tab-pane label="交易次数" name="3"></el-tab-pane>
      </el-tabs>
      <el-table size="mini" stripe  v-loading="loading" :data="tableData"  style="width: 100%"  :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }" >
        <el-table-column :prop="item.prop" :label="item.name" v-for="(item, index) in tableColumn[activeName]"
          :key="index"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { totalAssetsSort } from "../../../../teacher-manage/group-rank/groupApi";
import { findOrderTransactionRankingInformation } from "../../homeApi";
import dayjs from "dayjs";

export default {
  name: "rankCard",
  setup(props,{emit}) {
    let data = reactive({
      activeName: '1',
      tableColumn: {
        '1': [{ name: '排名', prop: 'rank' }, { name: '小组名称', prop: 'groupName' }, { name: '资产总额', prop: 'amounts' }],
        '2': [{ name: '排名', prop: 'rank' }, { name: '小组名称', prop: 'accountName' }, { name: '金额', prop: 'transactionAmount' }],
        '3': [{ name: '排名', prop: 'rank' }, { name: '小组名称', prop: 'accountName' }, { name: '次数', prop: 'numberOfTransactions' }],
      },
      tableData: [],
      total: 0,
      loading: false
    })
    onMounted(() => { getList() })
    let tagChage = () => { getList() }
    let getList = () => {
      data.tableData = []
      data.loading = true

      if (data.activeName === '1') {
        let parame = {
          classId: sessionStorage.getItem('classId'),
          pageSize: 10,
          page: 1,
        }
        totalAssetsSort(parame).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            let arr = delArr(newRes.totalAssetsSorts.list)
            sessionStorage.setItem('groupRanking',JSON.stringify(newRes.totalAssetsSorts.list) )
            emit('onGroup',true)
            data.tableData = arr
            data.total = newRes.totalAssetsSorts.total
            setTimeout(() => {
              data.loading = false
            }, 300)
          }else{
            emit('onGroup',false)
          }
        })
      } else {
        var date = new Date();
        let parame = {
          classId: sessionStorage.getItem('classId'),
          startTime: dayjs(date - 3600 * 1000 * 24 * 30).format('YYYY-MM-DD'),
          endTime: dayjs(date).format('YYYY-MM-DD'),
          pageSize: 10,
          page: 1,
        }
        findOrderTransactionRankingInformation(parame).then(res => {
          if (res.data.code === '200') {
            let newRes = res.data.data
            let arr = delArr(newRes.historicalTransactionsRanking.list)
            data.tableData = arr
            data.total = newRes.historicalTransactionsRanking.total
            setTimeout(() => {
              data.loading = false
            }, 300)
          }
        })
      }
    }
    let delArr = (arr) => {
      for (let i = 0; i < arr.length; i++) { arr[i].rank = i + 1 }
      return arr
    }
    return {
      ...toRefs(data),
      tagChage
    }
  }
}
</script>

<style scoped lang="less">
:deep(.el-tabs__item){
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  
  color: #03032C;
}
:deep(.el-tabs__nav-wrap){
  float: right;
}
    :deep(.el-tabs__item.is-active){
      color:#3B6EFB;
      font-weight: bold;
    }
    :deep(.el-tabs__active-bar){
      background-color:#3B6EFB;
    }
    :deep(.el-tabs__item):hover{
      color:#3B6EFB;
    }
    :deep(.el-tabs__active-bar){
      background-color: #fff;
      opacity: 0;
    }
    :deep(.el-tabs__nav-wrap::after){
      background-color: #fff;
      opacity: 0;
    }
    :deep(.el-table--striped .el-table__body tr.el-table__row--striped td){
      background: #F8F9FB;
    }
</style>