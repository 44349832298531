import { deleteRole, sysRoles } from "./roleApi";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  dealDate,
  getBackTablesList,
  getCapitalLists,
  getTradeLists,
} from "../../course-manage/trade-manage/tradeMethods";
export const getUserLists = (data) => {
  let parame = {
    roleName: data.userInput,
    pageSize: data.pageSize,
    pageIndex: data.currentPage,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.sysRolePageInfo.list;
      data.total = newRes.sysRolePageInfo.total;
      dealDate(data.spreadTable, ["createTime"]);
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    id: item.id,
  };
  deleteRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该角色, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "编辑") {
    data.id=val.item.id;
    data.showMenuForm=true;
  }
};
export const closes=(val, data) => {
  data.showMenuForm=false;
}
// 切换 历史查找事件
export const DateChanges = (val, data) => {
  // data.pageSize = 10
  // data.currentPage = 1
  if (val === null) {
    data.startTime = undefined;
    data.endTime = undefined;
  } else {
    data.startTime = Date.parse(val[0]) / 1000;
    data.endTime = Date.parse(val[1]) / 1000;
  }
};
