export const jobChangeData = {
    peopleList : [
        {name:'用户ID',text: 'modifiedUser'},
        {name:'人员名称',text: 'name'},
        {name:'变更之前',text: 'beforeChange'},
        {name:'新职务',text: 'afterChange'},
        {name:'变更时间',text: 'updateTime'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: false,
    peopleData:[],
    tableHeight: 470,
    operation: ['删除'],
    peopleValue: '',
    peopleOptions: [],

    nowJob: '',
    nowJobOptions: [],
}