import axios from '@/axios/globalaxios'


// 查询已委托的外汇订单 - 成交详情

export const forexOrders  = (data) => {
  return axios({
    url : '/tradelabLx/forexOrder/forexOrders',
    method: 'post',
    data:data
  })
}

//查找所有货币类型
export const findCurrencyType = () => {
  return axios({
    url: "/tradelabLx/forexOrder/findCurrencyType",
    method: 'get',
  })
}