<template>
  <div>
    <el-card class="box-card process">
      <div style="margin-bottom: 10px" class="title">公告管理</div>

      <div style="margin: 10px 0 ;float: right;">
        <span style="font-size: 13px;font-weight: 400">公告名：</span>
        <el-input v-model="userInput" style="width: 200px" clearable placeholder="请输入关键字" size="mini"></el-input>
        <el-button size="mini" style="margin-left: 15px" type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
        <el-button type="warning" style="float: right" size="mini" @click="$router.go(-1)">返回</el-button>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { noticeData } from "./noticeData";
import { clickButtons, currentChanges, sizeChanges, getUserLists, DateChanges } from "./noticeMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive(noticeData)
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let DateChange = (val) => { DateChanges(val, data) }
    let addNotice = (val) => {
      router.push({ path: '/noticeManage/addNotice' })
    }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      DateChange,
      addNotice,
    }
  }
})
</script>

<style scoped lang="less">
.title {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #03032C;
  line-height: 18px;
  padding-left:8px;
  border-left: 6px solid #3B6EFB ;

}
.process{
  height: calc(100vh - 150px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding:0px 20px;
  padding-top:20px;

}
</style>