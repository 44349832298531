import { sysUsers, deleteInfo, saveSysUserRole } from "./modelApi";
import { ElMessage } from "element-plus";
import { dealDate } from "../../course-manage/trade-manage/tradeMethods";
import { ElMessageBox } from "element-plus";

export const getUserLists = (data) => {
  data.loading = true;
  let parame = {
    name: data.userInput,
    pageSize: data.pageSize,
    pageIndex: data.currentPage,
  };
  sysUsers(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.sysUserVOS;
      data.total = newRes.total;
      dealDate(data.spreadTable, ["createTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    id: item.id,
  };
  deleteInfo(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data) => {
  if (val.type === "删除") {
    ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      delDrafrFiles(val.item, data);
    });
  }
  if (val.type === "设置角色") {
    dealRole(val.item, data);
  }
};

// 设置角色
export const dealRole = (val, data) => {
  getRoleList(data);
  data.showRoleDia = true;
  data.userItem = val;
  data.userValue = val.roleName;
};

// 确认修改
export const saveInfos = (data) => {
  let parame = {
    id: data.userItem.id,
    userRoleId: data.userValue,
  };
  saveSysUserRole(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      data.userItem = {};
      backInfo(data);
      getUserLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
// 取消修改
export const backInfo = (data) => {
  data.userValue = "";
  data.showRoleDia = false;
};

// 查找所有角色
export const getRoleList = (data) => {
  let parame = {
    pageSize: 1000,
    pageIndex: 1,
  };
  sysRoles(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.userOptions = newRes.sysRolePageInfo.list;
    }
  });
};
